import React from "react";
import { Label } from "reactstrap";
import { Link } from "react-router-dom";
import { APP_LOGO } from "../../config/index";
import AuthFooter from "../../components/Footers/AuthFooter";

const LoginPage = () => {
  return (
    <div className="vayco-public login_singup">
      <div className="app animated fadeIn ">
        <div className="public-header">
          <Link to="/" className="public-logo">
            <img
              src={APP_LOGO}
              alt="Logo"
              className="company-logo"
              loading="lazy"
            />
            <span>Military Veteran Community Resources</span>
          </Link>

          <a
            rel="noreferrer"
            className="d-flex flex-column align-items-center text-dark"
            target="_blank"
            href="https://www.in.gov/dva/idva-vet-connect/"
          >
            <i className="fas fa-headset fs-30 w-auto"></i>
            <span className="fs-14 mt-1">Need Help?</span>
          </a>
        </div>
        <div className="login_wrapepr d-flex align-items-center justify-content-center px-3">
          <div className="d-flex flex-column text-center">
            <Link to="/service-selection" className="btn btn-primary">
              Get Resources
            </Link>
            <Link to="/stronger-veterans" className="btn btn-primary mt-4">
              Veteran Resource Provider
            </Link>
            <Label className="mt-3">Select which option is best for you</Label>
            <p className="create-account mt-4 mb-0">
              Already a Veteran Resource Provider?
              <Link to="/login" className="ml-1">
                Login
              </Link>
            </p>
          </div>
        </div>
        <AuthFooter />
      </div>
    </div>
  );
};

export default LoginPage;
