import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  FormGroup,
  Label,
  InputGroupText,
  Input,
  Spinner,
} from "reactstrap";
import { InputGroup } from "react-bootstrap";
import {
  capitalize,
  extractQueryParams,
  formatDateAndTime,
  getYesterdayDate,
  showToast,
  uploadFileOnServer,
} from "../../helper-methods";
import { createOffer, getOfferById, updateOffer } from "../../http/http-calls";
import ReactDatetime from "react-datetime";

const AddOfferModal = ({
  isOpen,
  toggle,
  data,
  amenityArray,
  getAllOffers,
  resetCurrentData,
  groups,
}) => {
  const [formFields, setFormFields] = useState({
    name: {
      value: "",
      error: null,
      isDirty: false,
      isValidationRequired: true,
    },
    amenityId: {
      value: "",
      error: null,
      isDirty: false,
      isValidationRequired: true,
    },
    startTimeStamp: {
      value: "",
      error: null,
      isDirty: false,
      isValidationRequired: true,
    },
    endTimeStamp: {
      value: "",
      error: null,
      isDirty: false,
      isValidationRequired: true,
    },
    description: {
      value: "",
      error: null,
      isDirty: false,
      isValidationRequired: true,
    },
    groupId: {
      value: "",
      error: null,
      isDirty: false,
      isValidationRequired: true,
    },
    offerImage: {
      value: {
        uploadData: null,
        previewBlob: null,
        type: null,
        uploadUrl: null,
      },
      error: null,
      isDirty: false,
      isValidationRequired: true,
    },
  });
  const [loading, setLoading] = useState(false);
  const [offer, setOffer] = useState(null);
  const [isDescriptionFieldFocus, setIsDescriptionFieldFocus] = useState(false);

  const _resetStateModal = () => {
    setFormFields({
      name: {
        value: "",
        error: null,
        isDirty: false,
        isValidationRequired: true,
      },
      amenityId: {
        value: "",
        error: null,
        isDirty: false,
        isValidationRequired: true,
      },
      startTimeStamp: {
        value: "",
        error: null,
        isDirty: false,
        isValidationRequired: true,
      },
      endTimeStamp: {
        value: "",
        error: null,
        isDirty: false,
        isValidationRequired: true,
      },
      description: {
        value: "",
        error: null,
        isDirty: false,
        isValidationRequired: true,
      },
      groupId: {
        value: "",
        error: null,
        isDirty: false,
        isValidationRequired: true,
      },
      offerImage: {
        value: {
          uploadData: null,
          previewBlob: null,
          type: null,
          uploadUrl: null,
        },
        error: null,
        isDirty: false,
        isValidationRequired: true,
      },
    });
    setLoading(false);
  };

  const _closeModal = () => {
    _resetStateModal();
    toggle();
  };

  const _setForm = (offer) => {
    const newFormFields = { ...formFields };

    newFormFields.name.value = offer?.name || "";
    newFormFields.amenityId.value = offer?._amenity || "";
    newFormFields.startTimeStamp.value = offer?.startTimeStamp || "";
    newFormFields.endTimeStamp.value = offer?.endTimeStamp || "";
    newFormFields.description.value = offer?.description || "";

    if (offer.offerImage)
      newFormFields.offerImage.value.uploadUrl = offer.offerImage;

    setFormFields(newFormFields);
    setOffer(offer);
  };

  const _getOfferById = (id) => {
    setLoading(true);
    getOfferById(id)
      .then((res) => {
        _setForm(res.offer);
        setLoading(false);
      })
      .catch((error) => {
        console.log("error>>", error);
        _closeModal();
        showToast(
          error?.reason?.length
            ? error.reason
            : "Server error, Try again after sometime.",
          "error"
        );
      });
  };

  const _resetOfferImage = () => {
    const newFormFields = { ...formFields };
    newFormFields["offerImage"] = {
      value: {
        uploadData: null,
        previewBlob: null,
        type: null,
        uploadUrl: null,
      },
      error: null,
      isDirty: false,
      isValidationRequired: true,
    };
    setFormFields(newFormFields);
  };

  const _validateFormFields = (newFormFields) => {
    return new Promise((resolve) => {
      let isFormValid = true;

      Object.keys(newFormFields).forEach((key) => {
        if (
          newFormFields[key].isDirty &&
          newFormFields[key].isValidationRequired
        ) {
          switch (key) {
            case "name": {
              if (newFormFields[key]?.value?.trim().length) {
                newFormFields[key].error = null;
                newFormFields[key].isDirty = false;
              } else {
                newFormFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "groupId":
            case "amenityId": {
              if (newFormFields[key]?.value?.length) {
                newFormFields[key].error = null;
                newFormFields[key].isDirty = false;
              } else {
                newFormFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "offerImage": {
              if (
                newFormFields[key]?.value?.uploadData ||
                newFormFields[key]?.value?.uploadUrl
              ) {
                newFormFields[key].error = null;
                newFormFields[key].isDirty = false;
              } else {
                newFormFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "description": {
              if (newFormFields[key]?.value?.trim().length) {
                if (
                  newFormFields[key]?.value?.length < 10 ||
                  newFormFields[key]?.value?.length > 200
                ) {
                  newFormFields[key].error =
                    "*Minimum 10 & Maximum 200 characters are required";
                  isFormValid = false;
                } else {
                  newFormFields[key].error = null;
                  newFormFields[key].isDirty = false;
                }
              } else {
                newFormFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "startTimeStamp": {
              if (newFormFields[key]?.value) {
                if (
                  (offer &&
                    offer[key] !== newFormFields[key].value &&
                    new Date(newFormFields[key].value) < new Date()) ||
                  (!offer && new Date(newFormFields[key].value) < new Date())
                ) {
                  newFormFields[key].error =
                    "*Date & time should be grater than current date & time";
                  isFormValid = false;
                } else {
                  if (newFormFields.endTimeStamp.value) {
                    if (
                      new Date(newFormFields[key].value) <
                      new Date(newFormFields.endTimeStamp.value)
                    ) {
                      newFormFields[key].error = null;
                      newFormFields[key].isDirty = false;
                    } else {
                      newFormFields[key].error = "*Invalid Date";
                      isFormValid = false;
                    }
                  } else {
                    newFormFields[key].error = null;
                    newFormFields[key].isDirty = false;
                  }
                }
              } else {
                newFormFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "endTimeStamp": {
              if (newFormFields[key]?.value) {
                if (
                  (offer &&
                    offer[key] !== newFormFields[key].value &&
                    new Date(newFormFields[key].value) < new Date()) ||
                  (!offer && new Date(newFormFields[key].value) < new Date())
                ) {
                  newFormFields[key].error =
                    "*Date & time should be grater than current date & time";
                  isFormValid = false;
                } else {
                  if (newFormFields.startTimeStamp.value) {
                    if (
                      new Date(newFormFields[key].value) >
                      new Date(newFormFields.startTimeStamp.value)
                    ) {
                      newFormFields[key].error = null;
                      newFormFields[key].isDirty = false;
                    } else {
                      newFormFields[key].error = "*Invalid Date";
                      isFormValid = false;
                    }
                  } else {
                    newFormFields[key].error = null;
                    newFormFields[key].isDirty = false;
                  }
                }
              } else {
                newFormFields[key].error = null;
                newFormFields[key].isDirty = false;
              }
              break;
            }
            default:
          }
        }
      });

      setFormFields(newFormFields);

      resolve(isFormValid);
    });
  };

  const _onChangeOfferImage = (e) => {
    try {
      if (e?.target?.files?.length) {
        const newFormFields = { ...formFields };

        const file = e.target.files[0];

        const fileType = file.type.split("/")[0];

        if (fileType === "image") {
          const previewBlob = URL.createObjectURL(file);

          newFormFields.offerImage.value["uploadData"] = file;
          newFormFields.offerImage.value["previewBlob"] = previewBlob;
          newFormFields.offerImage.value["type"] = fileType;
          newFormFields.offerImage.value["uploadUrl"] = null;
        } else {
          showToast("Only image file is allowed", "error");
          return;
        }

        setFormFields(newFormFields);
        _validateFormFields(newFormFields);
      }
    } catch (error) {
      console.log("error>>", error);
      showToast("Something went wrong, Try again after sometime.", "error");
    }
  };

  const _onChangeFormFields = (key, value) => {
    const newFormFields = { ...formFields };
    if (key === "description") {
      if (value?.length > 200) {
        if (newFormFields.description.value?.length >= 200)
          value = newFormFields.description.value.slice(0, 200);
        else
          value =
            newFormFields.description.value +
            value.slice(0, 200 - newFormFields.description.value?.length);
      }
    }

    if (key === "startTimeStamp" || key === "endTimeStamp")
      newFormFields[key].value = value ? new Date(value).toISOString() : "";
    else newFormFields[key].value = value;

    newFormFields[key].isDirty = true;
    setFormFields(newFormFields);
    _validateFormFields(newFormFields);
  };

  const _updateOffer = (id, payload) => {
    updateOffer(id, payload)
      .then((res) => {
        showToast("Offer updated successfully.", "success");
        resetCurrentData();
        _closeModal();
      })
      .catch((error) => {
        console.log("error>>", error);
        setLoading(false);
        showToast(
          error?.reason?.length
            ? error.reason
            : "Server error, Try again after sometime.",
          "error"
        );
      });
  };

  const _createOffer = (payload) => {
    createOffer(payload)
      .then((res) => {
        showToast("Offer created successfully.", "success");
        getAllOffers();
        _closeModal();
      })
      .catch((error) => {
        console.log("error>>", error);
        setLoading(false);
        showToast(
          error?.reason?.length
            ? error.reason
            : "Server error, Try again after sometime.",
          "error"
        );
      });
  };

  const _onClickSave = async () => {
    try {
      const newFormFields = { ...formFields };
      Object.keys(newFormFields).forEach(
        (key) => (newFormFields[key].isDirty = true)
      );
      setFormFields(newFormFields);
      const isFormValid = await _validateFormFields(newFormFields);

      if (isFormValid) {
        setLoading(true);

        const payload = {
          name: newFormFields.name.value.trim(),
          amenityId: newFormFields.amenityId.value,
          groupId: newFormFields.groupId.value,
          startTimeStamp: newFormFields.startTimeStamp.value,
          description: newFormFields.description.value.trim(),
        };

        if (newFormFields.endTimeStamp?.value)
          payload["endTimeStamp"] = newFormFields.endTimeStamp.value;

        if (newFormFields.offerImage?.value?.uploadData) {
          const uploadDataFilter = [
            {
              uploadData: newFormFields.offerImage.value.uploadData,
              previewBlob: newFormFields.offerImage.value.previewBlob,
              type: newFormFields.offerImage.value.type,
            },
          ];
          const response = await uploadFileOnServer(uploadDataFilter);
          payload["offerImage"] = response[0].url;
          newFormFields.offerImage.value.uploadUrl = response[0].url;
          newFormFields.offerImage.value.uploadData = null;
          setFormFields(newFormFields);
        } else {
          payload["offerImage"] =
            newFormFields?.offerImage?.value?.uploadUrl || "";
        }

        const { resortId } = extractQueryParams();
        payload["resortId"] = resortId;

        if (data?.id) {
          _updateOffer(data.id, payload);
        } else {
          _createOffer(payload);
        }
      }
    } catch (error) {
      console.log("error>>", error);
      setLoading(false);
      showToast(
        error?.reason?.length
          ? error.reason
          : "Something went wrong, Try again after sometime.",
        "error"
      );
    }
  };

  useEffect(() => {
    if (isOpen && data?.id) _getOfferById(data.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, data]);

  return (
    <Modal
      className="offerModal"
      isOpen={isOpen}
      toggle={() => _closeModal()}
      centered
    >
      <ModalHeader toggle={() => _closeModal()} className="text-center">
        {data?.id ? "Update" : "Create"} Offer {loading ? <Spinner /> : null}
      </ModalHeader>
      <ModalBody>
        <div className="innerForm">
          <Row className="no-margin">
            <Col>
              <div className="amenity-location profile_center mb-4">
                <div className="  d-flex align-items-center justify-content-center">
                  {formFields.offerImage.value.previewBlob ||
                  formFields.offerImage.value.uploadUrl ? (
                    <div className={`input-gallery-col`}>
                      <div className="group_image">
                        <img
                          src={
                            formFields.offerImage.value.previewBlob ||
                            formFields.offerImage.value.uploadUrl
                          }
                          alt="cover"
                          loading="lazy"
                        />
                        {!loading ? (
                          <i
                            className="fa fa-close"
                            disabled={loading}
                            onClick={() => _resetOfferImage()}
                          />
                        ) : null}
                      </div>
                      <Label>
                        <span className="replace_map">
                          <Input
                            type="file"
                            value=""
                            className="d-none"
                            disabled={loading}
                            accept="image/*"
                            onChange={(e) => _onChangeOfferImage(e)}
                          />
                          Replace
                        </span>
                      </Label>
                    </div>
                  ) : (
                    <div className="group_image plus">
                      <Input
                        type="file"
                        value=""
                        id="groupImage"
                        disabled={loading}
                        className="d-none"
                        accept="image/*"
                        onChange={(e) => _onChangeOfferImage(e)}
                      />
                      <Label for="groupImage" className="mb-0">
                        <img
                          src={require("../../assets/img/plus.png").default}
                          alt="category"
                          className="img-fluid"
                          loading="lazy"
                        />
                      </Label>
                    </div>
                  )}
                  <span className="ml-3 fs-14 text-secondary">
                    Offer picture should be in the standard format PNG, JPG
                    &amp; no more than 5 MB &amp; dimensions should be 1020 x
                    720
                  </span>
                </div>
                {formFields.offerImage.error ? (
                  <div className="form-error">
                    {formFields.offerImage.error}
                  </div>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label>Name</Label>
                <InputGroup>
                  <InputGroupText>
                    <img
                      src={require("../../assets/img/user.svg").default}
                      alt="user"
                      className="img-fluid w-20"
                      loading="lazy"
                    />
                    <Input
                      placeholder="Enter the offer name"
                      value={formFields.name.value}
                      onChange={(e) =>
                        _onChangeFormFields("name", e.target.value)
                      }
                    />
                  </InputGroupText>
                </InputGroup>
                {formFields.name.error ? (
                  <div className="form-error">{formFields.name.error}</div>
                ) : null}
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label>Amenity</Label>
                <InputGroup>
                  <InputGroupText>
                    <img
                      src={require("../../assets/img/category.svg").default}
                      style={{ width: "20px" }}
                      alt="category"
                      className="img-fluid"
                      loading="lazy"
                    />
                  </InputGroupText>
                  <Input
                    type="select"
                    value={formFields.amenityId.value}
                    onChange={(e) =>
                      _onChangeFormFields("amenityId", e.target.value)
                    }
                  >
                    <option value="">Select amenity</option>
                    {amenityArray.map(
                      (each) =>
                        (each.id || each._id) &&
                        each.name && (
                          <option
                            key={each.id || each._id}
                            value={each.id || each._id}
                          >
                            {capitalize(each.name)}
                          </option>
                        )
                    )}
                  </Input>
                </InputGroup>
                {formFields.amenityId.error ? (
                  <div className="form-error">{formFields.amenityId.error}</div>
                ) : null}
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label>Group</Label>
                <InputGroup>
                  <InputGroupText>
                    <img
                      src={require("../../assets/img/category.svg").default}
                      style={{ width: "20px" }}
                      alt="category"
                      className="img-fluid"
                      loading="lazy"
                    />
                  </InputGroupText>
                  <Input
                    type="select"
                    value={formFields.groupId.value}
                    onChange={(e) =>
                      _onChangeFormFields("groupId", e.target.value)
                    }
                  >
                    <option value="">Select group</option>
                    {groups.map(
                      (each) =>
                        (each.id || each._id) &&
                        each.name && (
                          <option
                            key={each.id || each._id}
                            value={each.id || each._id}
                          >
                            {capitalize(each.name)}
                          </option>
                        )
                    )}
                  </Input>
                </InputGroup>
                {formFields.groupId.error ? (
                  <div className="form-error">{formFields.groupId.error}</div>
                ) : null}
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label>Start Date &amp; Time</Label>
                <InputGroup>
                  <InputGroupText>
                    <img
                      src={require("../../assets/img/calendar.svg").default}
                      alt="user"
                      className="img-fluid w-20"
                      loading="lazy"
                    />
                    <ReactDatetime
                      inputProps={{
                        className: "form-control",
                        placeholder: "Select start date & time",
                        value: formatDateAndTime(
                          formFields.startTimeStamp.value
                        ),
                      }}
                      value={
                        formFields.startTimeStamp.value
                          ? new Date(formFields.startTimeStamp.value)
                          : ""
                      }
                      onChange={(e) =>
                        _onChangeFormFields("startTimeStamp", e._d)
                      }
                      isValidDate={(current) =>
                        current.isAfter(getYesterdayDate())
                      }
                      timeConstraints={{
                        minutes: {
                          step: 15,
                        },
                      }}
                      dateFormat={true}
                      timeFormat={true}
                    />
                  </InputGroupText>
                </InputGroup>
                {formFields.startTimeStamp.error ? (
                  <div className="form-error">
                    {formFields.startTimeStamp.error}
                  </div>
                ) : null}
              </FormGroup>
            </Col>

            <Col md="6">
              <FormGroup>
                <Label>End Date &amp;Time</Label>
                <InputGroup>
                  <InputGroupText>
                    <img
                      src={require("../../assets/img/calendar.svg").default}
                      alt="user"
                      className="img-fluid w-20"
                      loading="lazy"
                    />
                    <ReactDatetime
                      inputProps={{
                        className: "form-control",
                        placeholder: "Select end date & time",
                        value: formatDateAndTime(formFields.endTimeStamp.value),
                      }}
                      value={
                        formFields.endTimeStamp.value
                          ? new Date(formFields.endTimeStamp.value)
                          : ""
                      }
                      onChange={(e) =>
                        _onChangeFormFields("endTimeStamp", e._d)
                      }
                      isValidDate={(current) =>
                        current.isAfter(getYesterdayDate())
                      }
                      timeConstraints={{
                        minutes: {
                          step: 15,
                        },
                      }}
                      dateFormat={true}
                      timeFormat={true}
                    />
                  </InputGroupText>
                </InputGroup>
                {formFields.endTimeStamp.error ? (
                  <div className="form-error">
                    {formFields.endTimeStamp.error}
                  </div>
                ) : null}
              </FormGroup>
            </Col>

            <Col md="12">
              <FormGroup>
                <Label>Description</Label>
                <InputGroup className="textarea">
                  <InputGroupText>
                    <i className="fa fa-paragraph" />
                  </InputGroupText>

                  <Input
                    type="textarea"
                    placeholder="Enter the description"
                    name="description"
                    value={formFields.description.value}
                    onChange={(e) =>
                      _onChangeFormFields("description", e.target.value)
                    }
                    onFocus={() => setIsDescriptionFieldFocus(true)}
                    onBlur={() => setIsDescriptionFieldFocus(false)}
                  />
                </InputGroup>

                <div className="d-flex justify-content-between">
                  {formFields.description.error ? (
                    <div className="form-error">
                      {formFields.description.error}
                    </div>
                  ) : null}
                  {isDescriptionFieldFocus ? (
                    <span className="leftcount">
                      {200 - formFields.description.value?.length} left
                    </span>
                  ) : null}
                </div>
              </FormGroup>
            </Col>
          </Row>

          <div className="d-flex justify-content-center my-4">
            <Button
              color="primary"
              className="btn-cancel mr-3"
              onClick={() => _closeModal()}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              className="btn-save"
              disabled={loading}
              onClick={() => _onClickSave()}
            >
              {loading ? <i className="fa fa-spinner fa-spin mr-1" /> : null}{" "}
              {data?.id ? "Update" : "Create"}
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default AddOfferModal;
