import React, { Component } from "react";
import { Button, FormGroup, InputGroup, Modal } from "react-bootstrap";
import { Label, Input } from "reactstrap";
import TextEditor from "../../components/TextEditor";

class AddInviteUserModal extends Component {
  _onCloseModal = () => {
    this.props.toggle();
  };

  render() {
    const { isOpen } = this.props;

    return (
      <Modal
        scrollable={true}
        show={isOpen}
        onHide={() => this._onCloseModal()}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="add-admin-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-left">Create Post</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="innerForm">
            {/* ==== Title ==== */}
            <FormGroup>
              <Label>Title</Label>
              <InputGroup>
                <Input
                  type="text"
                  placeholder="Enter"
                  autoComplete="off"
                  name="name"
                  onChange={(e) =>
                    this._onChangeFormFields("name", e.target.value)
                  }
                  onBlur={() => this._onBlurFormFields("name")}
                />
              </InputGroup>
            </FormGroup>

            {/* ==== Type ==== */}
            <FormGroup>
              <Label>Type</Label>
              <InputGroup>
                <Input type="select" name="select" id="exampleSelect">
                  <option>Full Time</option>
                  <option>Part Time</option>
                  <option>Contract</option>
                </Input>
              </InputGroup>
            </FormGroup>

            {/* ==== Job Description ==== */}
            <FormGroup>
              <Label>Job Description</Label>
              <TextEditor />
            </FormGroup>
          </div>
        </Modal.Body>

        <Modal.Footer className="d-flex justify-content-center pt-3">
          <Button
            className="btn-cancel mr-3"
            onClick={() => this._onCloseModal()}
          >
            Cancel
          </Button>
          <Button className="btn-save" variant="primary">
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default AddInviteUserModal;
