import { capitalize, logout } from "../../helper-methods";
import React from "react";
import { Button, Dropdown, Navbar, Nav, Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import { DEFAULT_PROFILE_PICTURE } from "../../config";

// icons
import profileIcon from "../../assets/img/Icon_Profile.svg";
import settingsIcon from "../../assets/img/Icon_Setting.svg";
// import lockIcon from "../../assets/img/Icon_LockScreen.svg";
import logoutIcon from "../../assets/img/Icon_Logout.svg";
import { useHistory } from "react-router-dom";

function AdminNavbar() {
  const history = useHistory();
  const [collapseOpen, setCollapseOpen] = React.useState(false);

  const userCredential = useSelector((state) => state["userCredential"]);

  const _logout = () => {
    logout(history);
  };

  const _redirectTo = (link) => {
    if (history?.push) history.push(link);
  };

  return (
    <Navbar expand="lg" className="header_top">
      <Container fluid>
        <div className="navbar-wrapper">
          <div className="navbar-minimize">
            <Button
              className="btn-icon d-none d-xl-block"
              variant="dark"
              onClick={() => document.body.classList.toggle("sidebar-mini")}
            >
              <img
                src={require("../../assets/img/fast-forward.png").default}
                alt="react-logo"
                className="menudir"
                loading="lazy"
              />
            </Button>
            <Button
              className="btn-icon d-block d-xl-none"
              variant="dark"
              onClick={() =>
                document.documentElement.classList.toggle("nav-open")
              }
            >
              <i className="fas fa-bars visible-on-sidebar-regular" />
              <i className="fas fa-bars visible-on-sidebar-mini" />
            </Button>
          </div>
        </div>
        <button
          className="navbar-toggler navbar-toggler-right border-0 d-none"
          type="button"
          onClick={() => setCollapseOpen(!collapseOpen)}
        >
          <span className="navbar-toggler-bar burger-lines" />
          <span className="navbar-toggler-bar burger-lines" />
          <span className="navbar-toggler-bar burger-lines" />
        </button>

        <Navbar.Collapse className="justify-content-end" in={collapseOpen}>
          <Nav navbar className="header_right align-items-center">
            {/* <Dropdown as={Nav.Item} className="notification_dropdown">
              <Dropdown.Toggle
                as={Nav.Link}
                id="dropdown-414718872"
                variant="default"
              >
                <i className="fa fa-bell" />
                <span className="notification" />
              </Dropdown.Toggle>

              <Dropdown.Menu alignRight>
                <Dropdown.Item onClick={(e) => e.preventDefault()}>
                  Notification 1
                </Dropdown.Item>
                <Dropdown.Item onClick={(e) => e.preventDefault()}>
                  Notification 2
                </Dropdown.Item>
                <Dropdown.Item onClick={(e) => e.preventDefault()}>
                  Notification 3
                </Dropdown.Item>
                <Dropdown.Item onClick={(e) => e.preventDefault()}>
                  Notification 4
                </Dropdown.Item>
                <Dropdown.Item onClick={(e) => e.preventDefault()}>
                  Notification 5
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => history.push("/notifications")}
                >
                  See More
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown> */}

            <Dropdown as={Nav.Item} className="user_info">
              <Dropdown.Toggle
                as={Nav.Link}
                id="dropdown-41471887333"
                variant="default"
                className="user_wrapper"
              >
                <img
                  src={
                    userCredential?.user?.avatar
                      ? userCredential?.user?.avatar
                      : DEFAULT_PROFILE_PICTURE
                  }
                  onError={(e) => (e.target.src = DEFAULT_PROFILE_PICTURE)}
                  alt="avatar"
                  width="50"
                  loading="lazy"
                />
                <div className="userInfo">
                  {userCredential?.user?.name?.first ? (
                    <h3>{capitalize(userCredential.user.name.first)}</h3>
                  ) : null}
                  <span>Admin</span>
                </div>
                <i className="fa fa-chevron-down" />
              </Dropdown.Toggle>
              <Dropdown.Menu
                alignRight
                aria-labelledby="navbarDropdownMenuLink"
              >
                <Dropdown.Item onClick={() => _redirectTo("/profile")}>
                  <img src={profileIcon} alt="Profile" loading="lazy" />
                  Profile
                </Dropdown.Item>
                <Dropdown.Item onClick={() => _redirectTo("/settings")}>
                  <img src={settingsIcon} alt="Profile" loading="lazy" />
                  Settings
                </Dropdown.Item>
                <Dropdown.Item
                  className="text-danger"
                  onClick={() => _logout()}
                >
                  <img src={logoutIcon} alt="Profile" loading="lazy" />
                  Log out
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default AdminNavbar;
