import TeamMembers from "./views/protectedPages/TeamMembers";
import BlogPage from "./views/protectedPages/BlogPage";
import UserPage from "./views/protectedPages/UserPage";
import JobsPage from "./views/protectedPages/JobsPage";
import ForumsPage from "./views/protectedPages/ForumsPage";

// ====== icons ========
import TeamIcon from "./assets/img/team.svg";
import BlogsIcon from "./assets/img/blogs.svg";
import UserIcon from "./assets/img/user.svg";
import JobsIcon from "./assets/img/jobs.svg";
import ForumIcon from "./assets/img/forum.svg";

var routes = [
  {
    path: "/users",
    layout: "",
    name: "users",
    icon: "fa fa-user-circle",
    imageIcon: UserIcon,
    component: UserPage,
  },
  {
    path: "/blogs",
    layout: "",
    name: "Blogs",
    icon: "fa fa-user-circle",
    imageIcon: BlogsIcon,
    component: BlogPage,
  },
  {
    path: "/jobs",
    layout: "",
    name: "Jobs",
    icon: "fa fa-user-circle",
    imageIcon: JobsIcon,
    component: JobsPage,
  },
  {
    path: "/forums",
    layout: "",
    name: "Forums",
    icon: "fa fa-user-circle",
    imageIcon: ForumIcon,
    component: ForumsPage,
  },
  {
    path: "/team-members",
    layout: "",
    name: "Team Members",
    icon: "fa fa-users",
    imageIcon: TeamIcon,
    component: TeamMembers,
  },
];
export default routes;
