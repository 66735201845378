import React from "react";
import PublicNavbar from "../../components/Navbars/PublicNavbar";
import AuthFooter from "../../components/Footers/AuthFooter";
import { Button, Container, Col, Row, Table } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { phoneEmailUrlClickCount } from "../../http/http-calls";
import { showToast } from "../../helper-methods";

const ServiceList = () => {
  const location = useLocation();
  const history = useHistory();

  // console.log("searchList", location.state);

  const _emailPhoneUrlClickHandler = async (type, service) => {
    let newServiceArray = service?.map((each) => each?._id);
    try {
      const res = await phoneEmailUrlClickCount({
        clickType: type,
        service: newServiceArray,
      });
      console.log("type", res);
      showToast(
        res.message && res.message.length
          ? "Success"
          : "Provider Added Successfully!",
        "success"
      );
    } catch (error) {
      console.log("error", error);
      if (error.reason) {
        showToast(
          error.reason && error.reason.length
            ? error.reason
            : "Server error. Try again after sometime.",
          "error"
        );
      }
    }
  };

  return (
    <div className="vayco-admin public-protected">
      {/* //Protected Routes */}
      <div className="wrapper">
        <div className="main-panel">
          <PublicNavbar />
          <div className="content">
            <Container fluid className="mobilePadding-0">
              {/* ==== Page Header ===== */}
              <div className="page_Inner_header">
                <Row className="align-items-center">
                  <Col xl={5} md={4}>
                    <div className="page_title">
                      <h1 className="space_remove">Your Resources List</h1>
                    </div>
                  </Col>
                </Row>
              </div>

              {/* ========= Table ======== */}
              <Table responsive className="check-switch m-0">
                <thead>
                  <tr>
                    <th>Description</th>
                    <th>Contact</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Address</th>
                    <th>URL</th>
                  </tr>
                </thead>
                <tbody>
                  {location?.state?.map((data, index) => (
                    <tr key={index}>
                      <td>
                        {data?.providerName === "CVSO" ? (
                          <img
                            className="tableImage"
                            src={
                              require("../../assets/img/verified.png").default
                            }
                            alt="CVSO"
                          />
                        ) : null}
                        {data?.providerName}
                      </td>
                      <td>
                        {data?.contactName?.last === undefined
                          ? data?.contactName?.first
                          : data?.contactName?.first +
                            " " +
                            data?.contactName?.last}
                      </td>
                      <td
                        onClick={() =>
                          _emailPhoneUrlClickHandler("email", data?._service)
                        }
                      >
                        <a
                          href={`mailto: ${data?.providerEmail}`}
                          className="mr-1"
                        >
                          <i className="fas fa-envelope"></i>
                        </a>
                        {data?.providerEmail}
                      </td>
                      <td
                        onClick={() =>
                          _emailPhoneUrlClickHandler("phone", data?._service)
                        }
                      >
                        <a
                          href={`tel: ${data?.providerPhone}`}
                          className="mr-1"
                        >
                          <i className="fas fa-phone"></i>
                        </a>
                        {data?.providerPhone}
                      </td>
                      <td>{data?.providerAddress}</td>
                      <td
                        // onClick={() =>
                        //   _emailPhoneUrlClickHandler("url", data?._service)
                        // }
                        onClick={() =>
                          history.push(`/${data?.providerWebsiteUrl}`)
                        }
                      >
                        <a
                          href={data?.providerWebsiteUrl}
                          rel="noreferrer"
                          target="_blank"
                        >
                          <i className="fas fa-home mr-1"></i>
                          {data?.providerWebsiteUrl}
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              <div
                className="d-flex justify-content-sm-between align-items-start text-center mt-5 flex-wrap justify-content-center"
                style={{ gap: "15px" }}
              >
                <Link to="/service-selection" className="btn btn-secondary">
                  New Search
                </Link>
                <a
                  rel="noreferrer"
                  className="btn-primary"
                  target="_blank"
                  href="https://www.in.gov/dva/idva-vet-connect/"
                >
                  More Info
                </a>
              </div>
              <p
                className="fs-14 mt-2 text-sm-right text-center"
                style={{ color: "#919191" }}
              >
                More info on how IDVA can help you
              </p>
            </Container>
          </div>
          <AuthFooter />
        </div>
      </div>
    </div>
  );
};

export default ServiceList;
