import React from "react";
import PublicNavbar from "../../components/Navbars/PublicNavbar";
import AuthFooter from "../../components/Footers/AuthFooter";
import {
  Button,
  Container,
  Col,
  Row,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { Link } from "react-router-dom";

const MoreInfoLogin = () => {
  return (
    <div className="vayco-admin public-protected">
      {/* //Protected Routes */}
      <div className="wrapper">
        <div className="main-panel">
          <PublicNavbar />
          <div className="content">
            <Container fluid className="mobilePadding-0">
              {/* ==== Page Header ===== */}
              <div className="page_Inner_header">
                <Row className="align-items-center">
                  <Col xl={5} md={4}>
                    <div className="page_title">
                      <h1 className="space_remove">Contact Us</h1>
                    </div>
                  </Col>
                </Row>
              </div>

              <Row className="justify-content-center">
                <Col xl="6" lg="8" md="10">
                  <Card className="mt-3">
                    <CardBody className="py-0">
                      <Form>
                        {/* ==== Name ==== */}
                        <FormGroup>
                          <Label>Name</Label>
                          <Input
                            type="text"
                            placeholder="Enter"
                            autoComplete="off"
                          />
                        </FormGroup>

                        {/* ==== Email ==== */}
                        <FormGroup>
                          <Label>Email</Label>
                          <Input
                            type="email"
                            placeholder="Enter"
                            autoComplete="off"
                          />
                        </FormGroup>

                        {/* ==== Phone ==== */}
                        <FormGroup>
                          <Label>Phone</Label>
                          <Input
                            type="text"
                            placeholder="Enter"
                            autoComplete="off"
                          />
                        </FormGroup>

                        {/* ==== Description ==== */}
                        <FormGroup>
                          <Label>Description</Label>
                          <Input
                            type="textarea"
                            placeholder="Enter"
                            autoComplete="off"
                            rows="5"
                          />
                        </FormGroup>

                        {/* ==== Submit ==== */}
                        <div className="d-flex justify-content-center mt-5 mb-2">
                          <Link to="/">
                            <Button color="primary">Submit</Button>
                          </Link>

                          {/* On Submit -> Thank you page after submission. “ Thank you, we will be in touch soon.”   */}
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
          <AuthFooter />
        </div>
      </div>
    </div>
  );
};

export default MoreInfoLogin;
