import React, { useState } from "react";
import { Input, Label, Button, List, Media, Alert } from "reactstrap";
import { Container, Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { formatDate, capitalizeEveryFirstLetter } from "../../helper-methods";
import SweetAlert from "react-bootstrap-sweetalert";
import { DEFAULT_PROFILE_PICTURE } from "../../config/index";

const ForumView = () => {
  const history = useHistory();

  const [comments, setComments] = useState([
    {
      _id: Date.now(),
      name: "anna martin",
      avatar: "https://www.w3schools.com/howto/img_avatar2.png",
      title:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
  ]);

  const [title, setTitle] = useState(
    "What 10 Studies Say About The Best Times To Post On Social Media"
  );

  const [commentValue, setCommentValue] = useState("");

  const [alert, setAlert] = useState(null);

  const _hideAlert = () => {
    setAlert(null);
  };

  const _removeComment = (index) => {
    const newComments = [...comments];
    newComments.splice(index, 1);
    setComments(newComments);

    _hideAlert();
  };

  const _removeCommentAlert = (index) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => _removeComment(index)}
        onCancel={() => _hideAlert()}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes, remove it!"
        cancelBtnText="Cancel"
        showCancel
      >
        You are removing{" "}
        {comments[index]?.name
          ? `${capitalizeEveryFirstLetter(comments[index].name)}'s`
          : ""}{" "}
        comment. You will not be able to recover it!
      </SweetAlert>
    );
  };

  const _postComment = () => {
    if (commentValue?.trim()?.length) {
      const newComments = [...comments];
      newComments.push({
        _id: Date.now(),
        name: "super admin",
        title: commentValue.trim(),
      });
      setComments(newComments);
      setCommentValue("");
    }
  };

  const _onEnterPressed = (event) => {
    const code = event.keyCode || event.which;

    if (code === 13 && !event.shiftKey && !event.ctrlKey) {
      if (event) event.preventDefault();

      //13 is the enter keycode
      //Do stuff in here
      if (commentValue?.trim()?.length) {
        _postComment();
      }
    }
  };

  return (
    <>
      {alert}

      <div className="content">
        <Container fluid className="mobilePadding-0">
          <div className="page_Inner_header">
            <Row className="align-items-center">
              <Col className="d-flex justify-content-between">
                <div className="page_title">
                  <Button
                    color="link"
                    className="pl-0 pr-2"
                    onClick={() => history.goBack()}
                  >
                    <i className="fa fa-chevron-left mt-1" />
                    <span className="fs-20 pl-1">Back To Forums</span>
                  </Button>
                </div>
                <div>
                  <Input type="select" name="select" id="exampleSelect">
                    <option>Active</option>
                    <option>Inactive</option>
                  </Input>
                </div>
              </Col>
            </Row>
          </div>

          <div className="blogs-card bg-white p-3 rounded">
            {/* ===== Dynmamic Title ====== */}
            <div className="custom-title">
              <Label>Title</Label>
              <Input
                type="text"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              />
            </div>

            {/* ===== Author - Date Published ====== */}
            <div className="author-date d-flex justify-content-between my-4">
              <span>
                Author: <b>Jacob</b>
              </span>
              <span>
                Date Published: <b>{formatDate(new Date())}</b>
              </span>
            </div>

            {/* ====== Comments ====== */}
            <div className="commentSection">
              <span>Comments</span>
              <div className="comments-list mt-3 ml-3">
                <List type="unstyled">
                  {comments?.length ? (
                    comments.map((comment, index) => (
                      <li key={comment._id}>
                        <Alert
                          color="info"
                          toggle={() => _removeCommentAlert(index)}
                          className="p-2"
                        >
                          <Media>
                            <Media left className="mr-3">
                              <Media
                                object
                                src={
                                  comment?.avatar
                                    ? comment.avatar
                                    : DEFAULT_PROFILE_PICTURE
                                }
                                onError={(e) =>
                                  (e.target.src = DEFAULT_PROFILE_PICTURE)
                                }
                                alt="avatar"
                                className="rounded-circle"
                              />
                            </Media>
                            <Media body>
                              <Media heading className="my-1">
                                {comment?.name
                                  ? capitalizeEveryFirstLetter(comment.name)
                                  : "N/A"}
                              </Media>
                              <p className="m-0">{comment?.title || "N/A"}</p>
                            </Media>
                          </Media>
                        </Alert>
                      </li>
                    ))
                  ) : (
                    <li>There is no comment to display</li>
                  )}
                </List>
              </div>

              {/* ======== Comments Pagination ======== */}
              <div className="row mt-4">
                <div className="col-md-6">
                  <span className="showng_entries">
                    Showing 10 entries out of 25
                  </span>
                </div>
                <div className="col-md-6">
                  <ReactPaginate
                    forcePage={0}
                    className="pagination justify-content-end"
                    breakLabel="..."
                    nextLabel={<i className="fa fa-chevron-right" />}
                    previousLabel={<i className="fa fa-chevron-left" />}
                    previousClassName="previous"
                    nextClassName="next"
                    onPageChange={({ selected }) => {
                      // _onPageChange(selected)
                    }}
                    pageRangeDisplayed={3}
                    pageCount={Math.ceil(25 / 10)}
                    pageClassName="page-item"
                    renderOnZeroPageCount={null}
                    containerClassName={"pagination"}
                    previousLinkClassName={"page-link"}
                    nextLinkClassName={"page-link"}
                    activeClassName={"active"}
                    disabledClassName={"page-item"}
                  />
                </div>
              </div>

              {/* ===== Add Comments ====== */}
              <div className="add-comments mt-4">
                <Input
                  type="textarea"
                  rows="1"
                  placeholder="Enter the comment..."
                  value={commentValue}
                  onChange={(e) => setCommentValue(e.target.value)}
                  onKeyPress={(e) => _onEnterPressed(e)}
                />
                <Button
                  className="sendMsg mt-3 mr-2"
                  title="Post Comment"
                  disabled={commentValue?.trim()?.length ? false : true}
                  onClick={() => _postComment()}
                >
                  <i className="fa fa-paper-plane text-second" />
                </Button>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default ForumView;
