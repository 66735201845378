import React from "react";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";
import { useState } from "react";
import {
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  FormGroup,
} from "reactstrap";
import { DateRangeInputSelectConfig } from "../config/helper-config";
import { sleepTime } from "../helper-methods";

const CustomAdvDateRangePicker = ({
  startDate,
  startDateId,
  endDate,
  endDateId,
  maxDate,
  isDisabledOutsideRange,
  isMaxDateValidation,
  onDatesChange,
}) => {
  const [focusedInput, setFocusedInput] = useState(null);

  const [selectedOption, setSelectedOption] = useState("Custom");

  const _maxDateValidation = () => {
    if (isMaxDateValidation && maxDate) {
      return moment(maxDate);
    }
    return moment();
  };

  const _onChangeDropdown = async (value) => {
    setSelectedOption(value);

    let date = DateRangeInputSelectConfig.find((obj) => obj.label === value);

    if (!date) {
      onDatesChange(null, null);

      setSelectedOption("Custom");

      setFocusedInput(null);

      return;
    }

    if (date.label === "Custom") {
      onDatesChange(null, null);

      await sleepTime(1);

      setFocusedInput("startDate");

      return;
    }

    if (date.isValueForStartDate) {
      onDatesChange(moment(date.value), moment());
    } else {
      onDatesChange(moment(), moment(date.value));
    }

    setFocusedInput(null);
  };

  return (
    <FormGroup>
      <div className="dateRange">
        <InputGroup>
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <Input
                type="select"
                value={selectedOption}
                onChange={(e) => _onChangeDropdown(e.target.value)}
              >
                {React.Children.toArray(
                  DateRangeInputSelectConfig.map((obj, index) => (
                    <option key={index} value={obj.label}>
                      {obj.label}
                    </option>
                  ))
                )}
              </Input>
            </InputGroupText>
          </InputGroupAddon>

          <DateRangePicker
            startDate={startDate} // momentPropTypes.momentObj or null,
            startDateId={`startDateId_${startDateId}`} // PropTypes.string.isRequired,
            endDate={endDate} // momentPropTypes.momentObj or null,
            endDateId={`endDateId_${endDateId}`} // PropTypes.string.isRequired,
            onDatesChange={({ startDate, endDate }) => {
              onDatesChange(startDate, endDate);
            }} // PropTypes.func.isRequired,
            focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
            onFocusChange={(newFocusInput) => setFocusedInput(newFocusInput)}
            isOutsideRange={(day) =>
              isDisabledOutsideRange || (isMaxDateValidation && maxDate)
                ? moment().endOf("day").diff(day) <= 0 ||
                  (isMaxDateValidation &&
                    maxDate &&
                    moment(day) > _maxDateValidation())
                : null
            }
            maxDate={isDisabledOutsideRange ? _maxDateValidation() : null}
            numberOfMonths={1}
          />
        </InputGroup>
      </div>
    </FormGroup>
  );
};

export default CustomAdvDateRangePicker;
