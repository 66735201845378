import React, { useState, useEffect } from "react";
import PublicNavbar from "../../components/Navbars/PublicNavbar";
import { Button, Container, Col, Row, FormGroup, Label } from "reactstrap";
import AuthFooter from "../../components/Footers/AuthFooter";
// import { Link } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { countyList } from "../../config/helper-config";
import { showToast } from "../../helper-methods";
import { fetchServices, userService } from "../../http/http-calls";
import { useHistory } from "react-router-dom";
import { getCurrentBrowserFingerPrint } from "@rajesh896/broprint.js";

const animatedComponents = makeAnimated();

const VeteranServiceSelection = () => {
  /* eslint-disable */

  const history = useHistory();

  const [services, setServices] = useState([]);
  const [formData, setFormData] = useState({
    deviceId: "",
    countyList: [],
    serviceList: [],
  });

  const [isDirty, setIsDirty] = useState({
    countyList: false,
    serviceList: false,
  });

  const [errors, setErrors] = useState({});
  const [flag, setFlag] = useState(false);
  // const [searchList, setSearchList] = useState([]);

  useEffect(() => {
    _fetchAllServices();
    let id = window.localStorage.getItem("myDeviceId");
    // console.log("deviceId",id);
    setFormData({ ...formData, deviceId: id });

    getCurrentBrowserFingerPrint().then((fingerprint) => {
      console.log("fingerPrint", fingerprint);
      setFormData({ ...formData, deviceId: fingerprint });
      // fingerprint is your unique browser id.
      // This is well tested

      // the result you receive here is the combination of Canvas fingerprint and audio fingerprint.
    });
  }, []);

  const _fetchAllServices = async () => {
    try {
      const res = await fetchServices();
      console.log("allservices", res);
      setServices(res?.data);
    } catch (error) {
      console.log("error", error);
      showToast(
        error.reason && error.reason.length
          ? error.reason
          : "Server error. Try again after sometime.",
        "error"
      );
    }
  };

  // serviceList
  const serviceList = services?.map((each) => {
    // console.log("service",each);
    return {
      id: each?._id,
      label: each?.serviceName,
      value: each?.serviceName,
    };
  });

  const _addVeteranServiceHandler = async () => {
    const newIsDirty = { ...isDirty };
    Object.keys(isDirty).forEach((each) => (newIsDirty[each] = true));
    const error = _validateForm(formData, newIsDirty);
    console.log("errors => ", error);
    if (!error) {
      setFlag(true);
      console.log("formData", formData);
      try {
        const res = await userService(formData);
        console.log("service", res);
        let defaultProvider = [...res?.defaultProvider, ...res?.providers];
        let finalArray = res?.defaultProvider
          ? defaultProvider
          : res?.providers;
        history.push({ pathname: "/service-list", state: finalArray });
      } catch (error) {
        console.log("error", error);
        showToast(
          error.reason && error.reason.length
            ? error.reason
            : "Server error. Try again after sometime.",
          "error"
        );
      }
      setFlag(flag);
    }
  };
  // //onChange
  // const _handelOnChange = (field, value) => {
  //   const newFormData = { ...formData };
  //   const newIsDirty = { ...isDirty };

  //   newFormData[field] = value;

  //   newIsDirty[field] = true;
  //   setFormData(newFormData);
  //   setIsDirty(newIsDirty);
  //   _validateForm(newFormData, newIsDirty);
  // };

  const _validateForm = (data, dirties) => {
    const newErrors = { ...errors };

    Object.keys(dirties).forEach((each) => {
      if (each === "countyList" && dirties[each]) {
        if (!data?.countyList?.length) {
          newErrors.countyList = "*Required";
        } else {
          delete newErrors[each];
          dirties[each] = false;
        }
      } else if (each === "serviceList" && dirties[each]) {
        if (!data?.serviceList?.length) {
          newErrors.serviceList = "*Required";
        } else {
          delete newErrors[each];
          dirties[each] = false;
        }
      }
    });
    setIsDirty(dirties);
    setErrors(newErrors);
    return Object.keys(newErrors).length ? true : false;
  };

  const handleCountryListChange = (newValue, actionMeta) => {
    let newFormData = formData;
    const newIsDirty = { ...isDirty };
    newIsDirty.countyList = true;
    // console.group("Value Changed >>>");
    // console.log(newValue);
    newFormData.countyList = newValue?.map((item) => {
      return item.value;
    });
    setFormData({ ...formData, ...newFormData });
    // console.log(`action: ${actionMeta.action}`);
    // console.groupEnd();
    _validateForm(formData, newIsDirty);
  };

  const handleServiceListChange = (newValue, actionMeta) => {
    // console.log("newValue >>", newValue, actionMeta);

    let newFormData = formData;
    const newIsDirty = { ...isDirty };
    newIsDirty.serviceList = true;

    // if(editDetails && actionMeta === "remove-value"){
    //   console.log(">>>>>>>>>>>>");
    //   let nwewValue = prevData.service.filter(({ id: id1 }) => !newValue.removedValue.some(({ id: id2 }) =>  id2 === id1))
    //   console.log("deleteId", nwewValue);
    // }
    // else{
    newFormData.serviceList = newValue?.map((item) => {
      return item.value;
    });
    // }
    setFormData({ ...formData, ...newFormData });
    _validateForm(formData, newIsDirty);

    // console.log(">>>>>.",prevData.service.filter(({ id: id1 }) => !newValue.some(({ id: id2 }) => id2 === id1)));
  };

  const _cancelFormSubmission = () => {
    history.push("/home");
  };
  return (
    <div className="vayco-admin public-protected">
      {/* //Protected Routes */}
      <div className="wrapper">
        <div className="main-panel">
          <PublicNavbar />
          <div className="content">
            <Container fluid className="mobilePadding-0">
              {/* ==== Page Header ===== */}
              <div className="page_Inner_header">
                <div className="page_title">
                  <h1 className="space_remove">Resource Selection</h1>
                </div>
              </div>

              <div className="card-box">
                <Row style={{ gap: "20px 0" }}>
                  <Col md="6">
                    <FormGroup className="mb-0 mr-2">
                      <Label>Choose your County</Label>
                      <Select
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        // defaultValue={[countyList[2]]}
                        isMulti
                        options={countyList}
                        onChange={handleCountryListChange}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        placeholder="Select all that apply"
                      />
                      {errors.countyList ? (
                        <div className="form-error">{errors.countyList}</div>
                      ) : null}
                    </FormGroup>
                  </Col>

                  <Col md="6">
                    <FormGroup className="mb-0 mr-2">
                      <Label>Service List</Label>
                      <Select
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        // defaultValue={[serviceList[1]]}
                        isMulti
                        options={serviceList}
                        onChange={handleServiceListChange}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        placeholder="Select all that apply"
                      />
                      {errors.serviceList ? (
                        <div className="form-error">{errors.serviceList}</div>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
              </div>

              <div className="submitWrapper">
                <Button onClick={() => _cancelFormSubmission()}>Cancel</Button>
                <Button
                  color="primary"
                  onClick={() => _addVeteranServiceHandler()}
                  disabled={flag === true}
                >
                  Search
                </Button>
              </div>
            </Container>
          </div>
          <AuthFooter />
        </div>
      </div>
    </div>
  );
};

export default VeteranServiceSelection;
