import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import PublicNavbar from "../../components/Navbars/PublicNavbar";
// import Chips from "react-chips";
import AuthFooter from "../../components/Footers/AuthFooter";
import {
  Button,
  Container,
  Col,
  Row,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Form,
  FormGroup,
  Label,
  Input,
  Tooltip,
  CustomInput,
  InputGroupAddon,
  List,
} from "reactstrap";
import VerificationCaptcha from "../../components/VerificationCaptcha";

import Select from "react-select";
// import makeAnimated from "react-select/animated";
import { countyList } from "../../config/helper-config";
import {
  createProvider,
  duplicate,
  fetchProvider,
  fetchServices,
  updateProvider,
} from "../../http/http-calls";
import { debounce, showToast } from "../../helper-methods";
import regexConfig from "../../config/regex-config";
import { useHistory } from "react-router-dom";
import { InputGroup } from "react-bootstrap";
import { countryCodes } from "../../config/country-codes";
import { zipCdes } from "../../config/zipcodeConfig";
// const animatedComponents = makeAnimated();

const StrongerVeteransPage = () => {
  const childRef = useRef(); //for capcha in child componenet
  const history = useHistory();

  const [services, setServices] = useState([]);
  const [formData, setFormData] = useState({
    contactName: "",
    providerName: "",
    providerEmail: "",
    countryCode: "+1",
    providerPhone: "",
    providerAddress: "",
    providerWebsiteUrl: "",
    isNonProfit: "",
    county: [],
    service: [],
    isDiscount: "",
  });

  const [isDirty, setIsDirty] = useState({
    contactName: false,
    providerName: false,
    providerEmail: false,
    countryCode: false,
    providerPhone: false,
    providerAddress: false,
    providerWebsiteUrl: false,
    county: false,
    service: false,
    isDiscount: false,
  });

  const [errors, setErrors] = useState({});
  const [flag, setFlag] = useState(false);

  // const initialObj = {
  //   county: "",
  //   isValid: false,
  //   error: "",
  //   isDirty: false,
  //   zipCode: "",
  //   isZipCodeValid: true,
  //   isZipCodeError: "",
  //   isZipCodeDirty: false,
  //   zipCodesState: [],
  //   countyDeatils: {},
  // };

  // const [countyData, setCountyData] = useState([initialObj]);
  const [isToken, setIsToken] = useState("");
  const [status, setStatus] = useState("");

  const [prevData, setPrevData] = useState({
    contactName: "",
    providerName: "",
    providerEmail: "",
    countryCode: "+1",
    providerPhone: "",
    providerAddress: "",
    providerWebsiteUrl: "",
    county: [],
    service: [],
    isDiscount: "",
    isNonProfit: false,
  });

  // const [prevCountyData, setPrevCountyData] = useState([]);

  const _fetchAllServices = async () => {
    try {
      const res = await fetchServices();
      console.log("allservices", res);
      setServices(res?.data);
    } catch (error) {
      console.log("error", error);
      showToast(
        error.reason && error.reason.length
          ? error.reason
          : "Server error. Try again after sometime.",
        "error"
      );
    }
  };

  // serviceList
  const serviceList = services?.map((each) => {
    // console.log("service",each);
    return {
      id: each?._id,
      label: each?.serviceName,
      value: each?.serviceName,
    };
  });

  const [hideIDVA, setIDVA] = useState("true");

  const _toggleIDVA = () => setIDVA(!hideIDVA);

  const [tooltipOpen, setTooltipOpen] = useState([false, false]);

  const _toggle = (i) => {
    const newArray = tooltipOpen.map((each, index) => {
      return index === i ? !each : false;
    });
    setTooltipOpen(newArray);
  };

  // const [countyTooltipOpen, setCountyTooltipOpen] = useState(
  //   Array(countyData.length).fill(false)
  // );

  // const _countyToggleView = (index) => {
  //   const newTooltips = [...countyTooltipOpen];
  //   newTooltips[index] = !newTooltips[index];
  //   setCountyTooltipOpen(newTooltips);
  // };

  const _clearFieldValue = () => {
    setFormData({
      contactName: { first: "", last: "" },
      providerName: "",
      providerEmail: "",
      providerPhone: "",
      providerAddress: "",
      providerWebsiteUrl: "",
      isNonProfit: "",
      county: [],
      service: [],
      isDiscount: "",
    });
    setIsDirty({
      contactName: false,
      providerName: false,
      providerEmail: false,
      providerPhone: false,
      providerAddress: false,
      providerWebsiteUrl: false,
      county: false,
      service: false,
      isDiscount: false,
    });
    setErrors({});
    history.push("/home");
  };

  const _addProviderHandler = async () => {
    // const newIsDirty = { ...isDirty };
    // Object.keys(isDirty).forEach((each) => (newIsDirty[each] = true));
    const newIsDirty = {
      contactName: true,
      providerName: true,
      providerEmail: true,
      countryCode: true,
      providerPhone: true,
      providerAddress: true,
      providerWebsiteUrl: true,
      service: true,
      isDiscount: true,
    };

    const error = _validateForm(formData, newIsDirty, true);
    // _validate(countyData);
    console.log("errors => ", error);

    // let hasError = true;

    // for (let i = 0; i < countyData.length; i++) {
    //   console.log(">>>>>>>", countyData[i].isValid);
    //   if (!countyData[i].isValid) {
    //     hasError = true;
    //     break;
    //   } else {
    //     hasError = false;
    //   }
    //   // if (!countyData[i].isZipCodeValid) {
    //   //   hasError = true;
    //   //   console.log("2", hasError);
    //   //   break;
    //   // } else {
    //   //   hasError = false;
    //   // }
    // }
    // console.log("hasError", hasError);

    if (!error) {
      let countyDetails = formData?.county?.map((data) =>
        zipCdes?.find((countyData) => countyData?.county === data)
      );

      let capchValidation;
      if (!isToken?.length) {
        capchValidation = childRef.current.doSubmit(); //called child capcha submittion function
        console.log(">>>", capchValidation);
      }

      if (capchValidation === true || isToken?.length) {
        if (formData.isNonProfit === "") {
          delete formData.isNonProfit;
          setFlag(true);
          // console.log(">>>", formData);
          let name = formData.contactName.split(" ");
          let first = name[0];
          let last = name[1];
          let payload = {
            ...formData,
            contactName: { first: first, last: last },
            providerPhone: formData.countryCode + "-" + formData.providerPhone,
            // county: countyData?.map((data) => data?.countyDeatils),
            county: countyDetails,
            isDiscount: formData?.isDiscount === "yes" ? true : false,
          };
          if (isToken?.length) {
            try {
              const res = await updateProvider(payload);
              console.log("providerUpdate", res);
              _editDetailsHandler();
              showToast(
                res.message && res.message.length
                  ? res.message
                  : "Provider Updated Successfully!",
                "success"
              );
            } catch (error) {
              console.log("error", error);
              showToast(
                error.reason && error.reason.length
                  ? error.reason
                  : "Server error. Try again after sometime.",
                "error"
              );
            }
          } else {
            try {
              _toggleIDVA();
              const res = await createProvider(payload);
              console.log("providerCreated", res);
              showToast(
                res.message && res.message.length
                  ? res.message
                  : "Provider Added Successfully!",
                "success"
              );
            } catch (error) {
              console.log("error", error);
              showToast(
                error.reason && error.reason.length
                  ? error.reason
                  : "Server error. Try again after sometime.",
                "error"
              );
            }
          }
          setFlag(false);
        } else {
          setFlag(true);
          // console.log(">>>", formData);
          let name = formData.contactName.split(" ");
          let first = name[0];
          let last = name[1];
          let payload = {
            ...formData,
            contactName: { first: first, last: last },
            providerPhone: formData.countryCode + "-" + formData.providerPhone,
            // county: countyData?.map((data) => data?.countyDeatils),
            county: countyDetails,
            isDiscount: formData?.isDiscount === "yes" ? true : false,
          };
          if (isToken?.length) {
            try {
              const res = await updateProvider(payload);
              console.log("providerUpdate", res);
              _editDetailsHandler();
              showToast(
                res.message && res.message.length
                  ? res.message
                  : "Provider Updated Successfully!",
                "success"
              );
            } catch (error) {
              console.log("error", error);
              showToast(
                error.reason && error.reason.length
                  ? error.reason
                  : "Server error. Try again after sometime.",
                "error"
              );
            }
          } else {
            try {
              _toggleIDVA();
              const res = await createProvider(payload);
              console.log("providerCreated", res);
              showToast(
                res.message && res.message.length
                  ? res.message
                  : "Provider Added Successfully!",
                "success"
              );
            } catch (error) {
              console.log("error", error);
              showToast(
                error.reason && error.reason.length
                  ? error.reason
                  : "Server error. Try again after sometime.",
                "error"
              );
            }
          }
          setFlag(false);
        }
      }
    }
  };

  const _checkHandler = async (field, value, allFieldError) => {
    const newIsDirty = { ...isDirty };
    const newErrors = { ...allFieldError };

    let dup = await _duplicateCheck(field, value);
    console.log("dup", dup);

    if (dup) {
      newErrors[field] = "Already Exist";
    } else {
      delete newErrors[field];
      newIsDirty[field] = false;
    }

    setErrors(newErrors);
    setIsDirty(newIsDirty);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const optimizedFn = useCallback(debounce(_checkHandler), []);

  const _duplicateCheck = async (field, value) => {
    let fieldName =
      field === "providerName"
        ? "compnayname"
        : field === "providerEmail"
        ? "email"
        : field === "providerPhone"
        ? "phone"
        : "url";

    let payload = { [field]: value };
    try {
      const res = await duplicate(fieldName, payload);
      console.log("res", res);
      return res.error;
    } catch (error) {
      console.log("error", error);
      return error.error;
    }
  };

  //onChange
  const _handelOnChange = async (field, value) => {
    const newFormData = { ...formData };
    const newIsDirty = { ...isDirty };
    const newErrors = { ...errors };

    newFormData[field] = value;

    newIsDirty[field] = true;
    setFormData(newFormData);

    setIsDirty(newIsDirty);
    setErrors(newErrors);
  };

  const _onBlurFormFields = (key) => {
    const newFormFields = { ...formData };
    const newIsDirty = {
      [key]: true,
    };
    _validateForm(newFormFields, newIsDirty);
  };

  const _validateForm = (data, dirties, submit) => {
    const newErrors = { ...errors };

    Object.keys(dirties).forEach((each) => {
      if (each === "contactName" && dirties[each]) {
        if (!data.contactName) {
          newErrors.contactName = "*Required";
        } else if (
          data.contactName.trim().length < 2 ||
          data.contactName.trim().length > 25
        ) {
          newErrors.contactName =
            "*Name should be greater than 2 characters and less than 25 characters";
        } else if (
          data.contactName.trim().length &&
          !new RegExp(regexConfig.name).test(data.contactName)
        ) {
          newErrors.contactName =
            "*Numeric and Special Symbols are not allowed. Please enter a valid name";
        } else {
          delete newErrors[each];
          dirties[each] = false;
        }
      } else if (each === "providerName" && dirties[each]) {
        if (!data?.providerName) {
          newErrors.providerName = "*Required";
        } else if (
          data.providerName.trim().length < 3 ||
          data.providerName.trim().length > 40
        ) {
          newErrors.providerName = "*Company name should be 3 - 40 characters";
        } else if (
          data.providerName.trim().length &&
          !new RegExp(regexConfig.name).test(data.providerName)
        ) {
          newErrors.providerName =
            "*Numeric and Special Symbols are not allowed. Please enter a valid name";
        } else {
          if (submit === undefined) {
            optimizedFn(each, data.providerName, newErrors);
          }
        }
        // else {
        //   delete newErrors[each];
        //   dirties[each] = false;
        // }
      } else if (each === "providerEmail" && dirties[each]) {
        if (!data?.providerEmail) {
          newErrors.providerEmail = "*Required";
        } else if (data.providerEmail.trim().length > 70) {
          newErrors.providerEmail = "*Invalid Email";
        } else if (
          data.providerEmail.trim().length &&
          !new RegExp(regexConfig.email).test(data.providerEmail)
        ) {
          newErrors.providerEmail = "*Please enter a valid email ID";
        } else {
          if (submit === undefined) {
            optimizedFn(each, data.providerEmail, newErrors);
          }
        }
        // else {
        //   delete newErrors[each];
        //   dirties[each] = false;
        // }
      } else if (each === "providerPhone" && dirties[each]) {
        if (!data?.providerPhone) {
          newErrors.providerPhone = "*Required";
        } else if (
          data.providerPhone.trim().length < 8 ||
          data.providerPhone.trim().length > 12
        ) {
          newErrors.providerPhone =
            "*Only Numbers allowed min 8 - max 12 characters";
        } else if (
          data.providerPhone.trim().length &&
          !new RegExp(regexConfig.phone).test(data.providerPhone)
        ) {
          newErrors.providerPhone = "*Please enter a valid phone number";
        } else {
          if (submit === undefined) {
            optimizedFn(
              each,
              data?.countryCode + "-" + data.providerPhone,
              newErrors
            );
          }
        }
        // else {
        //   delete newErrors[each];
        //   dirties[each] = false;
        // }
      } else if (each === "providerAddress" && dirties[each]) {
        if (!data?.providerAddress) {
          newErrors.providerAddress = "*Required";
        } else if (
          data.providerAddress.trim().length < 8 ||
          data.providerAddress.trim().length > 200
        ) {
          newErrors.providerAddress =
            "*Address allowed min 8 - max 200 characters";
        } else {
          delete newErrors[each];
          dirties[each] = false;
        }
      } else if (each === "providerWebsiteUrl" && dirties[each]) {
        if (!data?.providerWebsiteUrl) {
          newErrors.providerWebsiteUrl = "*Required";
        } else if (data?.providerWebsiteUrl.trim().length > 100) {
          newErrors.providerWebsiteUrl = "*Url allowed max 100 characters";
        } else if (
          data.providerWebsiteUrl.trim().length &&
          !new RegExp(regexConfig.url).test(data.providerWebsiteUrl)
        ) {
          newErrors.providerWebsiteUrl = "*Please enter a valid url";
        } 
        // else {
        //   if (submit === undefined) {
        //     optimizedFn(each, data.providerWebsiteUrl, newErrors);
        //   }
        // }
        else {
          delete newErrors[each];
          dirties[each] = false;
        }
      } else if (each === "county" && dirties[each]) {
        if (!data?.county?.length) {
          newErrors.county = "*Required";
        } else {
          delete newErrors[each];
          dirties[each] = false;
        }
      } else if (each === "service" && dirties[each]) {
        if (!data?.service?.length) {
          newErrors.service = "*Required";
        } else {
          delete newErrors[each];
          dirties[each] = false;
        }
      } else if (each === "isDiscount" && dirties[each]) {
        if (!data?.isDiscount?.length) {
          newErrors.isDiscount = "*Required";
        } else {
          delete newErrors[each];
          dirties[each] = false;
        }
      }
    });
    setIsDirty(dirties);
    setErrors(newErrors);
    return Object.keys(newErrors).length ? true : false;
  };

  const handleVeteransChange = (newValue, actionMeta) => {
    // console.log("newValue >>", newValue, actionMeta);

    let newFormData = formData;
    const newIsDirty = { ...isDirty };
    newIsDirty.service = true;

    // if(editDetails && actionMeta === "remove-value"){
    //   console.log(">>>>>>>>>>>>");
    //   let nwewValue = prevData.service.filter(({ id: id1 }) => !newValue.removedValue.some(({ id: id2 }) =>  id2 === id1))
    //   console.log("deleteId", nwewValue);
    // }
    // else{
    newFormData.service = newValue?.map((item) => {
      return item.id;
    });
    // }
    setFormData({ ...formData, ...newFormData });
    _validateForm(formData, newIsDirty);

    // console.log(">>>>>.",prevData.service.filter(({ id: id1 }) => !newValue.some(({ id: id2 }) => id2 === id1)));
  };

  //multiple data handle

  // const _addInput = () => {
  //   let newArray = deepClone(countyData);
  //   newArray.push({
  //     county: "",
  //     isValid: false,
  //     error: "",
  //     isDirty: false,
  //     zipCode: "",
  //     zipCodesState: [],
  //     isZipCodeValid: true,
  //     isZipCodeError: "",
  //     isZipCodeDirty: false,
  //     countyDeatils: {},
  //   });
  //   setCountyData(newArray);
  // };

  // const _deleteInput = (index) => {
  //   let newArray = deepClone(countyData);
  //   newArray.splice(index, 1);
  //   setCountyData(newArray);
  // };

  const handleCountryChange = (newValue) => {
    // setCountyData((prevData) => {
    //   const newArray = [...prevData];
    //   newArray[index][name] = selectedOption.value;
    //   newArray[index].isZipCodeDirty = true;

    //   const filteredData = zipCdes.filter(
    //     (data) => data?.county === selectedOption.value
    //   );
    //   newArray[index]["zipCodesState"] = filteredData;
    //   newArray[index]["zipCode"] = filteredData[0]?.postalCode
    //   newArray[index]["countyDeatils"] = filteredData[0]

    //   return newArray;
    // });
    // _validate();
    let newFormData = formData;
    const newIsDirty = { ...isDirty };
    newIsDirty.county = true;

    newFormData.county = newValue?.map((item) => {
      return item.value;
    });
    // }
    setFormData({ ...formData, ...newFormData });
    _validateForm(formData, newIsDirty);
  };

  // const _onChangeHandler = (key, value, index) => {
  //   setCountyData((prevData) => {
  //     const newArray = [...prevData];
  //     newArray[index][key] = value;
  //     // newArray[index].isZipCodeDirty = true;

  //     const countyData = zipCdes.filter(
  //       (data) => data?.postalCode === Number(value)
  //     );
  //     newArray[index].countyDeatils = countyData[0];

  //     return newArray;
  //   });
  //   _validate();
  // };

  // const _validate = () => {
  //   setCountyData((prevData) => {
  //     const newArray = [...prevData];

  //     const upadtedArray = newArray.map((data) => {
  //       if (data?.county?.length) {
  //         data.error = "";
  //         data.isValid = true;
  //       } else {
  //         data.error = "*Required";
  //         data.isValid = false;
  //         data.isDirty = true;
  //       }

  //       // if (data?.zipCode?.length) {
  //       //   data.isZipCodeError = "";
  //       //   data.isZipCodeValid = true;
  //       // } else {
  //       //   data.isZipCodeError = "*Required";
  //       //   data.isZipCodeValid = false;
  //       //   data.isZipCodeDirty = true;
  //       // }
  //       return data;
  //     });

  //     return upadtedArray;
  //   });
  // };

  const _setEditData = (editDetails) => {
    if (editDetails !== null) {
      // console.log("editDetails", editDetails);
      let phoneNumber = editDetails.providerPhone.split("-");

      setFormData({
        contactName: editDetails?.contactName?.full,
        providerName: editDetails?.providerName,
        providerEmail: editDetails?.providerEmail,
        countryCode: phoneNumber[0],
        providerPhone: phoneNumber[1],
        providerAddress: editDetails?.providerAddress,
        providerWebsiteUrl: editDetails?.providerWebsiteUrl,
        county: editDetails?._county?.map((data) => data?.countyName),
        service: editDetails?._service?.map((data) => data?._id),
        isDiscount: editDetails?.isDiscount ? "yes" : "no",
        isNonProfit: editDetails?.isNonProfit === true ? true : false,
      });
      setPrevData({
        contactName: editDetails?.contactName?.full,
        providerName: editDetails?.providerName,
        providerEmail: editDetails?.providerEmail,
        countryCode: phoneNumber[0],
        providerPhone: phoneNumber[1],
        providerAddress: editDetails?.providerAddress,
        providerWebsiteUrl: editDetails?.providerWebsiteUrl,
        county: editDetails?._county?.map((data) => data?.countyName),
        service: editDetails?._service?.map((data) => data?._id),
        isDiscount: editDetails?.isDiscount ? "yes" : "no",
        isNonProfit: editDetails?.isNonProfit === true ? true : false,
      });

      // let arrayData = editDetails?._county?.map((data) => ({
      //   county: data?.countyName,
      //   isValid: true,
      //   error: "",
      //   isDirty: false,
      //   zipCode: data?.postalCode,
      //   isZipCodeValid: true,
      //   isZipCodeError: "",
      //   isZipCodeDirty: false,
      //   zipCodesState: zipCdes.filter(
      //     (codes) => codes?.county === data?.countyName
      //   ),
      //   countyDeatils: data,
      // }));
      // setCountyData(arrayData);
      // setPrevCountyData(arrayData);
      setStatus(editDetails?.status);
    }
  };

  const _editDetailsHandler = async () => {
    try {
      const res = await fetchProvider();
      _setEditData(res?.provider);
    } catch (error) {
      showToast("error", error?.reason);
    }
  };

  useEffect(() => {
    _fetchAllServices();
    let token = sessionStorage.getItem("token");
    if (token) {
      setIsToken(token);
      _editDetailsHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="vayco-admin public-protected">
      {/* //Protected Routes */}
      <div className="wrapper">
        <div className="main-panel">
          <PublicNavbar />
          <div className="content">
            <Container fluid className="mobilePadding-0">
              <div className={!hideIDVA ? "d-none" : ""}>
                <Row className="justify-content-center" noGutters>
                  <Col xl="6" lg="8" md="10">
                    <Card className="p-0 p-sm-3">
                      <CardHeader>
                        {!isToken?.length ? (
                          <CardTitle className="fs-22 text-theme fw-bold text-center">
                            New Provider Registration
                          </CardTitle>
                        ) : (
                          <CardTitle className="fs-22 text-theme fw-bold text-center">
                            <span
                              style={{
                                display: "inline-block",
                                marginRight: "5px",
                              }}
                            >
                              Status:{" "}
                            </span>
                            <h5
                              style={{
                                color:
                                  status === "pending"
                                    ? "#FFD700"
                                    : status === "accepted"
                                    ? "green"
                                    : "red",
                                display: "inline-block",
                                margin: "0",
                                fontSize: "14px",
                              }}
                            >
                              {status?.charAt(0)?.toUpperCase() +
                                status?.slice(1)}
                            </h5>
                          </CardTitle>
                        )}
                      </CardHeader>
                      <CardBody>
                        <Form>
                          <Row>
                            <Col lg="6">
                              {/* ==== Primary Contact Name ==== */}
                              <FormGroup>
                                <Label>Primary Contact Name</Label>
                                <Input
                                  type="text"
                                  placeholder="Enter"
                                  autoComplete="off"
                                  value={formData?.contactName}
                                  onChange={(event) =>
                                    _handelOnChange(
                                      "contactName",
                                      event.target.value
                                    )
                                  }
                                  onBlur={() =>
                                    _onBlurFormFields("contactName")
                                  }
                                />
                                {errors.contactName ? (
                                  <div className="form-error">
                                    {errors.contactName}
                                  </div>
                                ) : null}
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              {/* ==== Company Name ==== */}
                              <FormGroup>
                                <Label>Company Name</Label>
                                <Input
                                  type="text"
                                  placeholder="Enter"
                                  autoComplete="off"
                                  value={formData?.providerName}
                                  onChange={(event) =>
                                    _handelOnChange(
                                      "providerName",
                                      event.target.value
                                    )
                                  }
                                  onBlur={() =>
                                    _onBlurFormFields("providerName")
                                  }
                                />
                                {errors.providerName ? (
                                  <div className="form-error">
                                    {errors.providerName}
                                  </div>
                                ) : null}
                              </FormGroup>
                            </Col>
                          </Row>

                          {/* ==== Email ==== */}
                          <FormGroup>
                            <Label>Email</Label>
                            <Input
                              type="email"
                              placeholder="Enter"
                              autoComplete="off"
                              value={formData?.providerEmail}
                              onChange={(event) =>
                                _handelOnChange(
                                  "providerEmail",
                                  event.target.value
                                )
                              }
                              onBlur={() => _onBlurFormFields("providerEmail")}
                            />
                            {errors.providerEmail ? (
                              <div className="form-error">
                                {errors.providerEmail}
                              </div>
                            ) : null}
                          </FormGroup>

                          {/* ==== Phone ==== */}
                          <FormGroup>
                            <Label>Phone</Label>
                            <InputGroup>
                              <InputGroupAddon addonType="prepend">
                                <Input
                                  type="select"
                                  placeholder="Enter"
                                  value={formData?.countryCode}
                                  onChange={(event) =>
                                    _handelOnChange(
                                      "countryCode",
                                      event.target.value
                                    )
                                  }
                                  style={{
                                    minWidth: "70px",
                                    maxWidth: "90px",
                                  }}
                                >
                                  {/* <option value="">select</option> */}
                                  {countryCodes.map((each) => (
                                    <option
                                      key={each.code}
                                      value={each.dial_code}
                                    >
                                      {each.code} ({each.dial_code})
                                    </option>
                                  ))}
                                </Input>
                              </InputGroupAddon>
                              <Input
                                type="text"
                                placeholder="Enter"
                                autoComplete="off"
                                value={formData?.providerPhone}
                                onChange={(event) =>
                                  _handelOnChange(
                                    "providerPhone",
                                    event.target.value
                                  )
                                }
                                onBlur={() =>
                                  _onBlurFormFields("providerPhone")
                                }
                              />
                            </InputGroup>

                            {errors.providerPhone ? (
                              <div className="form-error">
                                {errors.providerPhone}
                              </div>
                            ) : null}
                          </FormGroup>

                          {/* ==== Addtess ==== */}
                          <FormGroup>
                            <Label>Address</Label>
                            <Input
                              type="text"
                              placeholder="Enter"
                              autoComplete="off"
                              value={formData?.providerAddress}
                              onChange={(event) =>
                                _handelOnChange(
                                  "providerAddress",
                                  event.target.value
                                )
                              }
                              onBlur={() =>
                                _onBlurFormFields("providerAddress")
                              }
                            />
                            {errors.providerAddress ? (
                              <div className="form-error">
                                {errors.providerAddress}
                              </div>
                            ) : null}
                          </FormGroup>

                          {/* ==== Website URL ==== */}
                          <FormGroup>
                            <Label>Website URL</Label>
                            <Input
                              type="text"
                              placeholder="Enter"
                              autoComplete="off"
                              value={formData?.providerWebsiteUrl}
                              onChange={(event) =>
                                _handelOnChange(
                                  "providerWebsiteUrl",
                                  event.target.value
                                )
                              }
                              onBlur={() =>
                                _onBlurFormFields("providerWebsiteUrl")
                              }
                            />
                            {errors.providerWebsiteUrl ? (
                              <div className="form-error">
                                {errors.providerWebsiteUrl}
                              </div>
                            ) : null}
                          </FormGroup>

                          {/* ======= service country list ======= */}
                          <List className="serviceCountryList">
                            {/* {countyData?.map((data, index) => ( */}
                            <li>
                              <FormGroup>
                                <Label>
                                  Select the County or Counties your serve
                                  <i
                                    id="county_strong_veteran"
                                    className="fa fa-info-circle ml-1"
                                    // onMouseOver={() =>
                                    //   _countyToggleView(index)
                                    // }
                                    // onMouseOut={() =>
                                    //   _countyToggleView(index)
                                    // }
                                  />
                                  <Tooltip
                                    autohide={false}
                                    placement="right"
                                    isOpen={tooltipOpen[0]}
                                    target="county_strong_veteran"
                                    toggle={() => _toggle(0)}
                                  >
                                    Resources county!
                                  </Tooltip>
                                </Label>

                                {/* inputs */}
                                <div
                                  className="d-flex flex-wrap"
                                  style={{ gap: "20px" }}
                                >
                                  <div className="flex-1">
                                    <Select
                                      isSearchable={true}
                                      closeMenuOnSelect={false}
                                      // components={animatedComponents}
                                      // value={{
                                      //   label: data?.county,
                                      //   value: data?.county,
                                      // }}
                                      value={formData?.county?.map(
                                        (each, index) => ({
                                          id: index,
                                          label: each,
                                          value: each,
                                        })
                                      )}
                                      isMulti={true}
                                      options={countyList}
                                      // onChange={(
                                      //   selectedOption,
                                      //   { action, name }
                                      // ) =>
                                      //   handleCountryChange(selectedOption, {
                                      //     action,
                                      //     name: "county",
                                      //     index,
                                      //   })
                                      // }
                                      onChange={handleCountryChange}
                                      onBlur={() => _onBlurFormFields("county")}
                                      className="react-select-container"
                                      classNamePrefix="react-select"
                                      placeholder="Select all that apply"
                                    />
                                    {errors.county ? (
                                      <div className="form-error">
                                        {errors.county}
                                      </div>
                                    ) : null}
                                  </div>
                                  {/* <div className="flex-1">
                                      <Input
                                        type="select"
                                        name="zipcode"
                                        disabled={!data?.zipCodesState?.length}
                                        value={data?.zipCode}
                                        onChange={(e) =>
                                          _onChangeHandler(
                                            "zipCode",
                                            e.target.value,
                                            index
                                          )
                                        }
                                      >
                                        
                                        {data?.zipCodesState?.map(
                                          (code, index) => (
                                            <option
                                              key={index}
                                              value={code?.postalCode}
                                            >
                                              {code?.postalCode}
                                            </option>
                                          )
                                        )}
                                      </Input>
                                      {data.isZipCodeDirty &&
                                      !data.isZipCodeValid ? (
                                        <div className="form-error">
                                          Required!
                                        </div>
                                      ) : null}
                                    </div> */}
                                  {/* <div style={{ marginTop: "9px" }}>
                                      {index === 0 ? (
                                        <img
                                          src={
                                            require("../../assets/img/add.png")
                                              .default
                                          }
                                          alt=""
                                          className="addInputs"
                                          onClick={() =>
                                            flag ? null : _addInput()
                                          }
                                        />
                                      ) : (
                                        <img
                                          src={
                                            require("../../assets/img/minus.png")
                                              .default
                                          }
                                          alt=""
                                          className="removeInputs"
                                          onClick={() =>
                                            flag ? null : _deleteInput(index)
                                          }
                                        />
                                      )}
                                    </div> */}
                                </div>
                              </FormGroup>
                            </li>
                            {/* ))} */}
                          </List>

                          <FormGroup>
                            <Label className="d-flex align-items-center">
                              Select the Resource or Resources You Provide
                              <i
                                className="fa fa-info-circle ml-1"
                                id="service_strong_veteran_page"
                              />
                              <Tooltip
                                autohide={false}
                                placement="right"
                                isOpen={tooltipOpen[1]}
                                target="service_strong_veteran_page"
                                toggle={() => _toggle(1)}
                              >
                                provide veterans!
                              </Tooltip>
                            </Label>
                            <Select
                              closeMenuOnSelect={false}
                              // components={animatedComponents}
                              value={formData?.service?.map((each) => ({
                                id: each,
                                label: services.find((item) => item.id === each)
                                  ?.serviceName,
                                value: services.find((item) => item.id === each)
                                  ?.serviceName,
                              }))}
                              isMulti
                              options={serviceList}
                              onChange={handleVeteransChange}
                              className="react-select-container"
                              classNamePrefix="react-select"
                              placeholder="Select all that apply"
                            />
                            {errors.service ? (
                              <div className="form-error">{errors.service}</div>
                            ) : null}
                          </FormGroup>

                          <FormGroup className="mt-4 mb-3">
                            <CustomInput
                              type="checkbox"
                              className="custom-color"
                              id="exampleCustomCheckbox"
                              label="Are You a Non Profit?"
                              checked={formData?.isNonProfit}
                              onChange={(event) =>
                                _handelOnChange(
                                  "isNonProfit",
                                  event.target.checked
                                )
                              }
                            />
                          </FormGroup>

                          <FormGroup>
                            <Label>Do you want to give veteran discount?</Label>
                            <div className="d-flex" style={{ gap: "20px" }}>
                              <div className="form-check">
                                <Input
                                  type="radio"
                                  name="isDiscount"
                                  id="discountYes"
                                  checked={formData?.isDiscount === "yes"}
                                  onChange={() =>
                                    _handelOnChange("isDiscount", "yes")
                                  }
                                  onBlur={() => _onBlurFormFields("isDiscount")}
                                  className="form-check-input"
                                />
                                <Label
                                  check
                                  htmlFor="discountYes"
                                  className="form-check-label"
                                >
                                  Yes
                                </Label>
                              </div>
                              <div
                                className="form-check"
                                style={{ marginLeft: "30px" }}
                              >
                                <Input
                                  type="radio"
                                  name="isDiscount"
                                  id="discountNo"
                                  checked={formData?.isDiscount === "no"}
                                  onChange={() =>
                                    _handelOnChange("isDiscount", "no")
                                  }
                                  onBlur={() => _onBlurFormFields("isDiscount")}
                                  className="form-check-input"
                                />
                                <Label
                                  check
                                  htmlFor="discountNo"
                                  className="form-check-label"
                                >
                                  No
                                </Label>
                              </div>
                            </div>

                            {errors.isDiscount ? (
                              <div className="form-error">
                                {errors.isDiscount}
                              </div>
                            ) : null}
                          </FormGroup>

                          {/* captcha */}
                          {isToken?.length ? null : (
                            <VerificationCaptcha ref={childRef} />
                          )}
                        </Form>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>

                {/* ==== Submit ==== */}
                <div className="submitWrapper">
                  {isToken?.length ? null : (
                    <Button
                      color="secondary"
                      onClick={() => _clearFieldValue()}
                    >
                      Cancel
                    </Button>
                  )}
                  {isToken?.length && status !== "pending" ? null : (
                    <Button
                      color="primary"
                      onClick={() => _addProviderHandler()}
                      disabled={
                        JSON.stringify(prevData) === JSON.stringify(formData) ||
                        flag === true
                      }
                    >
                      {isToken?.length ? "Update" : "Submit"}
                    </Button>
                  )}
                </div>
              </div>

              {/* On Submit show Success */}
              <div
                className={` ${
                  hideIDVA ? "d-none " : ""
                } success_message_stronger`}
              >
                <h1 style={{ fontSize: "40px" }}>SUCCESS</h1>
                <p>
                  Your provider application was submitted to the IDVA for
                  approval.
                </p>
                <p className="mt-3 fw-bold">Average response time: 24-72hrs </p>
                <ul className="socialMedia">
                  <li>
                    <a
                      className="facebook"
                      target="_blank"
                      href="https://www.facebook.com/indiana.veterans/"
                      rel="noreferrer"
                    >
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="twitter"
                      target="_blank"
                      href="https://twitter.com/InVetAffairs/"
                      rel="noreferrer"
                    >
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="instagram"
                      target="_blank"
                      href="https://www.instagram.com/in_dva/"
                      rel="noreferrer"
                    >
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="linkedin"
                      target="_blank"
                      href="https://www.linkedin.com/company/indiana-department-of-veterans-affairs/"
                      rel="noreferrer"
                    >
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="youtube"
                      target="_blank"
                      href="https://www.youtube.com/channel/UCkWjgqICyheTJDDr-GzRs9Q"
                      rel="noreferrer"
                    >
                      <i className="fab fa-youtube"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </Container>
          </div>
          <AuthFooter />
        </div>
      </div>
    </div>
  );
};

export default StrongerVeteransPage;
