import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Input,
  List,
  Table,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import { DEFAULT_PROFILE_PICTURE } from "../../config";

const UserView = () => {
  const history = useHistory();
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  return (
    <div className="content user-detials-page">
      <Container fluid className="mobilePadding-0">
        <div className="page_Inner_header">
          <Row className="align-items-center">
            <Col className="d-flex justify-content-between">
              <div className="page_title">
                <Button
                  color="link"
                  className="pl-0 pr-2"
                  onClick={() => history.goBack()}
                >
                  <i className="fa fa-chevron-left mt-1" />
                  <span className="fs-20 pl-1">Back To Users</span>
                </Button>
              </div>
              <div>
                <Input type="select" name="select" id="exampleSelect">
                  <option>Active</option>
                  <option>Inactive</option>
                </Input>
              </div>
            </Col>
          </Row>
        </div>

        <Row>
          <Col md="3">
            {/* ====== Profile Box ===== */}
            <div className="user_box">
              <div className="user_image">
                <img
                  src={DEFAULT_PROFILE_PICTURE}
                  alt="avatar"
                  loading="lazy"
                />
              </div>
              <div className="user_content">
                <h1 className="text-capitalize">Rebecca</h1>
                <List>
                  <li>
                    <span>Email:</span> <strong>johndoe@gmail.com</strong>
                  </li>
                  <li>
                    <span>Phone:</span> <strong>9988440289</strong>
                  </li>
                  <li>
                    <span>Member Since:</span> <strong>22 Feb 2020</strong>
                  </li>
                </List>
              </div>
            </div>
          </Col>

          <Col md="9">
            <span className="d-block mb-2">Children</span>
            <Table responsive className="check-switch m-0">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Age</th>
                  <th>Activities</th>
                  <th>Games</th>
                  <th>Special Needs</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Aaron</td>
                  <td>7</td>
                  <td>Swimming</td>
                  <td>Football</td>
                  <td>Physical</td>
                </tr>
                <tr>
                  <td>Brayden</td>
                  <td>5</td>
                  <td>Dancing</td>
                  <td>Basketball</td>
                  <td>Physical</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>

        <Row className="mt-5">
          <Col md="6">
            <div className="d-flex justify-content-between align-items-end mb-2">
              <span>JOB</span>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={{ active: activeTab === "1" }}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    Applied <i class="fa fa-tasks"></i>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={{ active: activeTab === "2" }}
                    onClick={() => {
                      toggle("2");
                    }}
                  >
                    Saved <i class="fa fa-bookmark-o"></i>
                  </NavLink>
                </NavItem>
              </Nav>
            </div>

            <TabContent activeTab={activeTab}>
              {/* ======= Applied Jobs ======= */}
              <TabPane className={{ active: activeTab === "1" }}>
                <Table responsive className="check-switch m-0">
                  <thead>
                    <tr>
                      <th>Job Title</th>
                      <th>Type</th>
                      <th>Pay</th>
                      <th>Education Requirements</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Sr. Developer</td>
                      <td>Full Time</td>
                      <td>$15/hr</td>
                      <td>Graduate</td>
                    </tr>
                    <tr>
                      <td>Sr. Developer</td>
                      <td>Full Time</td>
                      <td>$15/hr</td>
                      <td>Graduate</td>
                    </tr>
                  </tbody>
                </Table>
              </TabPane>
              {/* ======= Saved Jobs ======== */}
              <TabPane className={{ active: activeTab === "2" }}>
                <Table responsive className="check-switch m-0">
                  <thead>
                    <tr>
                      <th>Job Title</th>
                      <th>Type</th>
                      <th>Pay</th>
                      <th>Education Requirements</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Frontend Developer</td>
                      <td>Part Time</td>
                      <td>$45/hr</td>
                      <td>Post Graduate</td>
                    </tr>
                    <tr>
                      <td>Frontend Developer</td>
                      <td>Part Time</td>
                      <td>$45/hr</td>
                      <td>Post Graduate</td>
                    </tr>
                  </tbody>
                </Table>
              </TabPane>
            </TabContent>
          </Col>

          <Col md="6">
            <span className="d-block mb-2">Meets</span>
            <Table responsive className="check-switch m-0">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Child</th>
                  <th>Activity</th>
                  <th>Date</th>
                  <th>Timings</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Fumio</td>
                  <td>Hugo</td>
                  <td>Sleeping</td>
                  <td>22 Feb</td>
                  <td>6-9 PM</td>
                </tr>
                <tr>
                  <td>Moshe</td>
                  <td>Steffan</td>
                  <td>Sleeping</td>
                  <td>12 Jan</td>
                  <td>1-4 PM</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default UserView;
