import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import routes from "../../routes";
import HotelAmenities from "../protectedPages/HotelAmenities";
import AddAmenity from "../protectedPages/AddAmenity";
import TeamMembers from "../protectedPages/TeamMembers";
import Staff from "../protectedPages/Staff";
import GroupChat from "../protectedPages/GroupChat";
import Requests from "../protectedPages/Requests";
import AdminNavbar from "../../components/Navbars/AdminNavbar";
import Sidebar from "../../components/Sidebar/Sidebar";
import MediaQueueViewer from "../../components/media-queue-viewer";
import ProtectedRoute from "../../components/protected-routes";
// import { connectToSocket, disconnectToSocket } from "../../socket-io";
// import { showToast } from "../../helper-methods";
import OffersPage from "../protectedPages/OffersPage";
import NotificationsPage from "../protectedPages/NotificationsPage";
import SettingsPage from "../protectedPages/SettingsPage";
import Vacationers from "../protectedPages/Vacationers";
import Outings from "../protectedPages/Outings";
import Resorts from "../protectedPages/Resorts";
import ResortDetails from "../protectedPages/ResortDetails";
import SubscriptionInvoice from "../protectedPages/SubscriptionInvoice";
import ProfilePage from "../protectedPages/ProfilePage";
import ResortSettingsPage from "../protectedPages/ResortSettingsPage";
import KpiDashboard from "../protectedPages/KpiDashboard";
import BlogView from "../protectedPages/BlogViewPage";
import BlogPage from "../protectedPages/BlogPage";
import BlogCreate from "../protectedPages/BlogCreatePage";
import JobsPage from "../protectedPages/JobsPage";
import JobsCreate from "../protectedPages/JobsCreatePage";
import ForumsPage from "../protectedPages/ForumsPage";
import ForumView from "../protectedPages/ForumViewPage";
import UserPage from "../protectedPages/UserPage";
import UserView from "../protectedPages/UserViewPage";
import StrongerVeteransPage from "../publicPages/StrongerVeteransPage";

const AdminLayout = (props) => {
  return (
    <div className="vayco-admin">
      {/* //Protected Routes */}
      <div className="wrapper">
        <Sidebar routes={routes} />
        <div className="main-panel">
          <AdminNavbar {...props} />
          <Switch>
            <ProtectedRoute
              exact
              path="/hotel-amenities"
              component={HotelAmenities}
              redirectRoute={"/home"}
            />
            <ProtectedRoute
              exact
              path="/stronger-veterans"
              component={StrongerVeteransPage}
              redirectRoute={"home"}
            />
            <ProtectedRoute
              exact
              path="/add-amenity"
              component={AddAmenity}
              redirectRoute={"home"}
            />
            <ProtectedRoute
              exact
              path="/group-chat"
              component={GroupChat}
              redirectRoute={"home"}
            />
            <ProtectedRoute
              exact
              path="/requests"
              component={Requests}
              redirectRoute={"home"}
            />
            <ProtectedRoute
              exact
              path="/offers"
              component={OffersPage}
              redirectRoute={"home"}
            />
            <ProtectedRoute
              exact
              path="/notifications"
              component={NotificationsPage}
              redirectRoute={"home"}
            />
            <ProtectedRoute
              exact
              path="/resorts"
              component={Resorts}
              redirectRoute={"home"}
            />
            <ProtectedRoute
              exact
              path="/resort-details/:resortId"
              component={ResortDetails}
              redirectRoute={"home"}
            />
            <ProtectedRoute
              exact
              path="/settings/:resortId"
              component={ResortSettingsPage}
              redirectRoute={"home"}
            />
            <ProtectedRoute
              exact
              path="/staff"
              component={Staff}
              redirectRoute={"home"}
            />
            <ProtectedRoute
              exact
              path="/settings"
              component={SettingsPage}
              redirectRoute={"home"}
            />
            <ProtectedRoute
              exact
              path="/vacationers"
              component={Vacationers}
              redirectRoute={"home"}
            />
            <ProtectedRoute
              exact
              path="/outings"
              component={Outings}
              redirectRoute={"home"}
            />
            <ProtectedRoute
              exact
              path="/subscription-invoice"
              component={SubscriptionInvoice}
              redirectRoute={"home"}
            />
            <ProtectedRoute
              exact
              path="/profile"
              component={ProfilePage}
              redirectRoute={"home"}
            />
            <ProtectedRoute
              exact
              path="/kpi-dashboard"
              component={KpiDashboard}
              redirectRoute={"home"}
            />
            <ProtectedRoute
              exact
              path="/blogs-view"
              component={BlogView}
              redirectRoute={"home"}
            />
            <ProtectedRoute
              exact
              path="/blogs"
              component={BlogPage}
              redirectRoute={"home"}
            />
            <ProtectedRoute
              exact
              path="/blogs-create"
              component={BlogCreate}
              redirectRoute={"home"}
            />
            <ProtectedRoute
              exact
              path="/jobs"
              component={JobsPage}
              redirectRoute={"home"}
            />

            <ProtectedRoute
              exact
              path="/jobs-create"
              component={JobsCreate}
              redirectRoute={"home"}
            />

            <ProtectedRoute
              exact
              path="/forums"
              component={ForumsPage}
              redirectRoute={"home"}
            />

            <ProtectedRoute
              exact
              path="/forums-view"
              component={ForumView}
              redirectRoute={"home"}
            />

            <ProtectedRoute
              exact
              path="/users"
              component={UserPage}
              redirectRoute={"home"}
            />

            <ProtectedRoute
              exact
              path="/user-view"
              component={UserView}
              redirectRoute={"home"}
            />

            <Route
              exact
              path="*"
              render={() => <Redirect to="/stronger-veterans" />}
            />
          </Switch>

          <MediaQueueViewer />
        </div>
      </div>
    </div>
  );
};

export default AdminLayout;
