import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";

const BlogView = () => {
  const history = useHistory();

  return (
    <div className="content">
      <Container fluid className="mobilePadding-0">
        <div className="page_Inner_header">
          <Row className="align-items-center">
            <Col>
              <div className="page_title">
                <Button
                  color="link"
                  className="pl-0 pr-2"
                  onClick={() => history.goBack()}
                >
                  <i className="fa fa-chevron-left" />
                </Button>
                <h1 className="space_remove pr-2">
                  What 10 Studies Say About The Best Times To Post On Social
                  Media
                </h1>
              </div>
            </Col>
          </Row>
        </div>

        <div className="blogs-card">
          <div className="blogs-img">
            <img
              src={require("../../assets/img/blog-banner.jpg").default}
              alt="banner"
              className="img-fluid"
              loading="lazy"
            />
          </div>

          <p>
            The key thing to remember when writing how-to guides is to break
            things down into small actionable steps. This makes it easier for
            readers to follow your instructions. Including step-by-step
            screenshots further aids in this, especially for visual learners.
          </p>
          <p>
            Marketing bloggers could write tutorials on how to use different
            social media platforms. Beauty bloggers can guide their readers
            through makeup applications. It all depends on your target audience
            and what they want to read.
          </p>
          <p>
            Here’s an example from YourCreativeAura.com: How To Write A Song:
            Songwriting Basics, this post is broken into the 5 main sections of
            song creation, along with imagery and examples to make it easier for
            beginners to understand.
          </p>
        </div>
      </Container>
    </div>
  );
};

export default BlogView;
