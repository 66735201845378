import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Label,
  Input,
  FormGroup,
  InputGroup,
} from "reactstrap";
import TextEditor from "../../components/TextEditor";
import { useHistory } from "react-router-dom";
import { showToast, extractQueryParams } from "../../helper-methods";

const BlogCreate = () => {
  const history = useHistory();

  const [blog, setBlog] = useState(null);

  const [formFields, setFormFields] = useState({
    title: {
      value: "",
      error: null,
      isDirty: false,
      isValidationRequired: true,
    },
    type: {
      value: "",
      error: null,
      isDirty: false,
      isValidationRequired: true,
    },
    body: {
      value: "",
      error: null,
      isDirty: false,
      isValidationRequired: true,
    },
    coverImage: {
      value: {
        uploadData: null,
        previewBlob: null,
        type: null,
        uploadUrl: null,
      },
      error: null,
      isDirty: false,
      isValidationRequired: true,
    },
  });

  const _validateFormFields = (newFormFields) => {
    return new Promise((resolve) => {
      let isFormValid = true;

      Object.keys(newFormFields).forEach((key) => {
        if (
          newFormFields[key].isDirty &&
          newFormFields[key].isValidationRequired
        ) {
          switch (key) {
            case "title":
            case "type":
            case "body": {
              if (newFormFields[key]?.value?.trim().length) {
                newFormFields[key].error = null;
                newFormFields[key].isDirty = false;
              } else {
                newFormFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "coverImage": {
              if (
                newFormFields[key]?.value?.uploadData ||
                newFormFields[key]?.value?.uploadUrl
              ) {
                newFormFields[key].error = null;
                newFormFields[key].isDirty = false;
              } else {
                newFormFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            default:
          }
        }
      });

      setFormFields(newFormFields);

      resolve(isFormValid);
    });
  };

  const _onChangeFormFields = (key, value) => {
    const newFormFields = { ...formFields };

    newFormFields[key].value = value;
    newFormFields[key].isDirty = true;

    setFormFields(newFormFields);

    _validateFormFields(newFormFields);
  };

  const _onChangeFile = (e, key) => {
    try {
      if (e?.target?.files?.length) {
        const newFormFields = { ...formFields };

        const file = e.target.files[0];

        const fileType = file.type.split("/")[0];

        if (fileType === "image") {
          const previewBlob = URL.createObjectURL(file);

          newFormFields[key].value["uploadData"] = file;
          newFormFields[key].value["previewBlob"] = previewBlob;
          newFormFields[key].value["type"] = fileType;
          newFormFields[key].value["uploadUrl"] = null;
        } else {
          showToast("Only image file is allowed", "error");
          return;
        }

        setFormFields(newFormFields);
        _validateFormFields(newFormFields);
      }
    } catch (error) {
      console.log("error>>", error);
      showToast("Something went wrong, Try again after sometime.", "error");
    }
  };

  const _onClickSave = async () => {
    try {
      const newFormFields = { ...formFields };
      Object.keys(newFormFields).forEach(
        (key) => (newFormFields[key].isDirty = true)
      );
      setFormFields(newFormFields);

      const isFormValid = await _validateFormFields(newFormFields);

      if (isFormValid) {
        console.log("api call", formFields);
      }
    } catch (error) {
      console.log("error>>", error);
      showToast(
        error?.reason?.length
          ? error.reason
          : "Something went wrong, Try again after sometime.",
        "error"
      );
    }
  };

  useEffect(() => {
    const { blogId } = extractQueryParams();

    if (blogId) setBlog(blogId);
  }, []);

  return (
    <div className="content">
      <Container fluid className="mobilePadding-0">
        <div className="page_Inner_header">
          <Row className="align-items-center">
            <Col>
              <div className="page_title">
                <Button
                  color="link"
                  className="pl-0 pr-2"
                  onClick={() => history.goBack()}
                >
                  <i className="fa fa-chevron-left" />
                </Button>
                <h1 className="space_remove">{blog ? "Update" : "Add"} Blog</h1>
              </div>
            </Col>
          </Row>
        </div>

        {/* ======= Form Create Blog ====== */}
        <div className="innerForm">
          <Row>
            <Col md="9">
              {/* ==== Blog Title ==== */}
              <FormGroup>
                <Label>Blog Title</Label>
                <InputGroup>
                  <Input
                    type="text"
                    placeholder="Enter the title"
                    autoComplete="off"
                    value={formFields.title.value}
                    onChange={(e) =>
                      _onChangeFormFields("title", e.target.value)
                    }
                  />
                </InputGroup>
                {formFields.title.error ? (
                  <div className="form-error">{formFields.title.error}</div>
                ) : null}
              </FormGroup>
            </Col>
            <Col md="3">
              {/* ==== Add Title ==== */}
              <FormGroup>
                <Label>Type</Label>
                <InputGroup>
                  <Input
                    type="select"
                    value={formFields.type.value}
                    onChange={(e) =>
                      _onChangeFormFields("type", e.target.value)
                    }
                  >
                    <option value="">Select Type</option>
                    <option value="mommy">Mommy</option>
                    <option value="kid">Kid</option>
                  </Input>
                </InputGroup>
                {formFields.type.error ? (
                  <div className="form-error">{formFields.type.error}</div>
                ) : null}
              </FormGroup>
            </Col>
          </Row>

          {/* ==== Body ==== */}
          <FormGroup>
            <Label>Body</Label>
            <TextEditor
              content={formFields.body.value}
              onChange={(text) => _onChangeFormFields("body", text)}
            />
            {formFields.body.error ? (
              <div className="form-error">{formFields.body.error}</div>
            ) : null}
          </FormGroup>

          {/* ====== Add Cover Image ===== */}
          <div className="amenity-location mt-4">
            <div className="mt-3 d-flex align-items-center">
              {formFields.coverImage.value.previewBlob ||
              formFields.coverImage.value.uploadUrl ? (
                <div className={`input-gallery-col mr-4`}>
                  <div className="group_image m-auto">
                    <img
                      src={
                        formFields.coverImage.value.previewBlob ||
                        formFields.coverImage.value.uploadUrl
                      }
                      alt="cover"
                      loading="lazy"
                    />
                  </div>
                  <Label>
                    <span className="replace_map">
                      <Input
                        type="file"
                        value=""
                        className="d-none"
                        accept="image/*"
                        onChange={(e) => _onChangeFile(e, "coverImage")}
                      />
                      Replace
                    </span>
                  </Label>
                </div>
              ) : (
                <div className="group_image plus mr-4">
                  <Label className="mb-0">
                    <Input
                      type="file"
                      value=""
                      className="d-none"
                      accept="image/*"
                      onChange={(e) => _onChangeFile(e, "coverImage")}
                    />
                    <img
                      src={require("../../assets/img/plus.png").default}
                      alt="category"
                      className="img-fluid"
                      loading="lazy"
                    />
                  </Label>
                </div>
              )}

              <span className="fs-15 d-block location_text">
                <div className="custom-file-text">
                  <h3 className="fs-18 text-theme fw-bold mt-0 mb-1">
                    Add Cover Image (upload)
                  </h3>
                  <span className="fs-14 text-secondary">
                    Cover Image should be in the standard format PNG, JPG &amp;
                    no more than 5 MB
                  </span>
                </div>
              </span>
            </div>
            {formFields.coverImage.error ? (
              <div className="form-error">{formFields.coverImage.error}</div>
            ) : null}
          </div>

          {/* ==== Publish ==== */}
          <div className="d-flex justify-content-center mt-5">
            <Button
              className="btn-primary-outline mr-3"
              onClick={() => _onClickSave()}
            >
              Save Draft
            </Button>

            <Button
              color="primary"
              className="px-4"
              onClick={() => _onClickSave()}
            >
              {blog ? "Update" : "Publish"}
            </Button>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default BlogCreate;
