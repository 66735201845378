import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { Toaster } from "react-hot-toast";

import { persistor, store } from "./redux/store";
import AdminLayout from "./views/containers/AdminLayout";
import FullPageLoader from "./views/containers/full-page-loader";
import LoginPage from "./views/publicPages/LoginPage";
import RegisterPage from "./views/publicPages/RegisterPage";
import VendorLogin from "./views/publicPages/VendorLogin";
import ForgotPassword from "./views/publicPages/ForgotPassowrdPage";
import PublicRoute from "./components/public-route";
import ProtectedRoute from "./components/protected-routes";
import ErrorNotFound from "./components/Error-not-found";
import VeteranServiceSelection from "./views/publicPages/VeteranServiceSelection";
import ServiceList from "./views/publicPages/ServiceList";
import MoreInfoLogin from "./views/publicPages/MoreInfoLogin";
import StrongerVeteransPage from "./views/publicPages/StrongerVeteransPage";

  /* eslint-disable */

//One Signal
import OneSignalHelper from "./onesignal-helper";
import DeviceMetaData from "./device-metadata";
import { getIpData } from "./http/http-calls";

const App = () => {
  useEffect(() => {
    _initializeOneSignal();
  }, []);

  const _initializeOneSignal = async () => {
    console.log("inhere1");
    const hasPermission = await OneSignalHelper.getNotificationPermission();
    console.log("11111", hasPermission);
    if (!hasPermission) {
      OneSignalHelper.showPrompt();
    }
    _getDeviceDetails();
  };
  const _getDeviceDetails = () => {
    console.log("inhere2");

    return new Promise(async (resolve, reject) => {
      try {
        const deviceDetails = {};
        deviceDetails["name"] =
          DeviceMetaData.getBrowser() + " on " + DeviceMetaData.getOs();
        deviceDetails["ipAddress"] = (await getIpData())["ip"];
        // Get device id
        // First try to get from one signal
        let onesignalDeviceId = null;
        onesignalDeviceId = await window.OneSignal.getUserId(async function (
          userId
        ) {
          console.log("User id:", userId);
          return userId;
        });
        // onesignalDeviceId = await OneSignalHelper.getDeviceId();
        if (onesignalDeviceId) {
          deviceDetails["allowNotification"] = true;
          deviceDetails["deviceId"] = onesignalDeviceId;
        } else {
          deviceDetails["allowNotification"] = false;
          deviceDetails["deviceId"] = DeviceMetaData.getDeviceId();
        }
        console.log("Device:", onesignalDeviceId);
        // dispatch(updateDeviceId(onesignalDeviceId));
        localStorage.setItem("myDeviceId", onesignalDeviceId);
        resolve(deviceDetails);
      } catch (error) {
        console.log("Error:", error);
        reject(error);
      }
    });
  };

  return (
    <Provider store={store}>
      <PersistGate loading={<div></div>} persistor={persistor}>
        <Router>
          {/* //Public Routes */}
          <div className="vayco-auth">
            <FullPageLoader />

            <Toaster position="bottom-right" reverseOrder={false} />

            <Switch>
              <PublicRoute
                exact
                path="/register"
                component={RegisterPage}
                redirectRoute={"/"}
              />
              <PublicRoute
                exact
                path="/forgot-password"
                component={ForgotPassword}
                redirectRoute={"/"}
              />
              <PublicRoute
                exact
                path="/home"
                component={LoginPage}
                redirectRoute={"/"}
              />
              <PublicRoute
                exact
                path="/login"
                component={VendorLogin}
                redirectRoute={"/"}
              />


              <PublicRoute
                exact
                path="/service-selection"
                component={VeteranServiceSelection}
                redirectRoute={"/"}
              />

              <PublicRoute
                exact
                path="/service-list"
                component={ServiceList}
                redirectRoute={"/"}
              />

              <PublicRoute
                exact
                path="/more-info-login"
                component={MoreInfoLogin}
                redirectRoute={"/"}
              />

              <Route
                exact
                path="/stronger-veterans"
                component={StrongerVeteransPage}
                redirectRoute={"/"}
              />

              <ProtectedRoute
                exact
                path="*"
                component={AdminLayout}
                redirectRoute="/home"
              />

              <Route component={ErrorNotFound} />
            </Switch>
          </div>
        </Router>
      </PersistGate>
    </Provider>
  );
};

export default App;
