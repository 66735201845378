import React, { useState } from "react";
import {
  Container,
  Col,
  Row,
  Table,
  FormControl,
  InputGroup,
} from "react-bootstrap";
import {
  CustomInput,
  Input,
  Button,
  Card,
  FormGroup,
  Label,
  InputGroupAddon,
  InputGroupText,
  Tooltip,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import ReactPaginate from "react-paginate";
import AddInviteUserModal from "../../components/Modals/add-invite-user-modal";
import TextEditor from "../../components/TextEditor";
import {
  copyToClipboard,
  formatPhoneNumber,
  showToast,
} from "../../helper-methods";

const UserPage = () => {
  const history = useHistory();

  const [addInviteUserModal, setAddInviteUserModal] = useState({
    isOpen: false,
    data: null,
  });

  const [isShowTooltip, setIsShowTooltip] = useState({
    email: false,
    phone: false,
    comments: false,
  });

  const _toggleAddInviteUserModal = (isOpen = false, data = null) => {
    setAddInviteUserModal({
      isOpen,
      data,
    });
  };

  const [emailFormField, setEmailFormField] = useState({
    subject: "",
    text: "",
  });
  // eslint-disable-next-line no-unused-vars
  const [sendEmailLoading, setSendEmailLoading] = useState(false);

  const _sendEmailToUser = () => {
    const payload = {
      // idlist: selectedVacationerIds?.length ? selectedVacationerIds : [],
      idlist: [],
      subject: emailFormField?.subject?.trim().length
        ? emailFormField.subject.trim()
        : "",
      text: emailFormField?.text?.trim().length
        ? emailFormField.text.trim()
        : "",
    };

    if (!payload.idlist.length) {
      showToast("Please select atleast one user.", "error");
      return;
    }
    if (!payload.subject.length) {
      showToast("Please write some subject.", "error");
      return;
    }
    if (!payload.text.length) {
      showToast("Please write some message.", "error");
      return;
    }
  };

  const _toggleTooltip = (key, id = false) => {
    const newIsShowTooltip = { ...isShowTooltip };

    if (newIsShowTooltip[key] !== id) newIsShowTooltip[key] = id;
    else newIsShowTooltip[key] = false;

    setIsShowTooltip(newIsShowTooltip);
  };

  return (
    <div className="content">
      <Container fluid className="mobilePadding-0">
        <div className="page_Inner_header">
          <Row className="align-items-center">
            <Col xl={5} md={4}>
              <div className="page_title">
                <h1 className="space_remove">Users</h1>
              </div>
            </Col>
            <Col
              xl={7}
              md={8}
              className="text-right d-flex justify-content-md-end justify-content-between mt-3 mt-md-0 align-items-center page_header_right"
            >
              <Button
                color="primary"
                onClick={() => _toggleAddInviteUserModal(true)}
              >
                Invite User
              </Button>
            </Col>
          </Row>
        </div>

        {/* ======== Filter ======= */}
        <Card body className="search-filter pt-2 pb-3 mb-4">
          <div>
            <div className="d-flex justify-content-between align-items-end">
              <div className="d-flex justify-content-center align-items-end">
                {/* ==== Type ==== */}
                <FormGroup className="mb-0 mr-2">
                  <Label for="exampleEmail">Type</Label>
                  <Input type="select" name="status">
                    <option value="">All</option>
                    <option value="Mother">Mother</option>
                    <option value="Father">Father</option>
                    <option value="Other">Other</option>
                  </Input>
                </FormGroup>
                {/* ==== Children Added ==== */}
                <FormGroup className="mb-0 mr-2">
                  <Label for="exampleEmail">Children Added</Label>
                  <Input type="select" name="status">
                    <option value="">All</option>
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="3+">3+</option>
                  </Input>
                </FormGroup>
                {/* ==== Status ==== */}
                <FormGroup className="mb-0 mr-2">
                  <Label for="exampleEmail">Status</Label>
                  <Input type="select" name="status">
                    <option value="">All</option>
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </Input>
                </FormGroup>
                {/* ==== Jobs Applied ==== */}
                <FormGroup className="mb-0 mr-2">
                  <Label className="d-block">Jobs Applied</Label>
                  <Input type="select" name="status">
                    <option value="">All</option>
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2-5">2-5</option>
                    <option value="6-10">6-10</option>
                    <option value="10+">10+</option>
                  </Input>
                </FormGroup>
                {/* ==== Meets ==== */}
                <FormGroup className="mb-0 mr-2">
                  <Label className="d-block">Meets</Label>
                  <Input type="select" name="status">
                    <option value="">All</option>
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2-5">2-5</option>
                    <option value="6-10">6-10</option>
                    <option value="10+">10+</option>
                  </Input>
                </FormGroup>
              </div>

              <div className="d-flex justify-content-end align-items-end">
                <FormGroup className="mb-0">
                  <Label>Search</Label>
                  <InputGroup>
                    <FormControl
                      type="text"
                      placeholder="Enter"
                      autoComplete="off"
                    />
                    <InputGroup.Text>
                      <i className="fas fa-search" />
                    </InputGroup.Text>
                  </InputGroup>
                </FormGroup>
                <Button color="primary" className="btn-clear ml-4">
                  <i className="fa fa-eraser fs-15" />
                </Button>
              </div>
            </div>
          </div>
        </Card>

        {/* ========= Table ======== */}
        <Table responsive className="check-switch m-0">
          <thead>
            <tr>
              <th className="p-0">
                <div className="check-box-container">
                  <CustomInput
                    type="checkbox"
                    id={`custom_checkbox_userpage_selected_all`}
                  />
                </div>
              </th>
              <th>User</th>
              <th>Age</th>
              <th>Type</th>
              <th>Kids</th>
              <th>Jobs</th>
              <th>Meets</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="p-0">
                <div className="check-box-container">
                  <CustomInput
                    type="checkbox"
                    id={`custom_checkbox_userpage_selected_${"user.id"}`}
                  />
                </div>
              </td>
              <td className="blog-table-title">
                <span className="d-block mb-1">Rockey</span>

                <i
                  className="fa fa-phone fs-11 cursor-pointer w-auto bg-theme text-white mr-3 p-1"
                  id={`resort_poc_phone_tooltip_${"user"}`}
                  onClick={() =>
                    copyToClipboard(formatPhoneNumber("123456789"))
                  }
                >
                  <Tooltip
                    placement="bottom"
                    isOpen={isShowTooltip.phone === "phone" ? true : false}
                    target={`resort_poc_phone_tooltip_${"user"}`}
                    toggle={() => _toggleTooltip("phone", "phone")}
                  >
                    {formatPhoneNumber("123456789")}
                  </Tooltip>
                </i>

                <i
                  className="fa fa-envelope fs-11 cursor-pointer w-auto bg-theme text-white p-1"
                  id={`resort_poc_email_tooltip_${"user"}`}
                  onClick={() => copyToClipboard("example@gmail.com")}
                >
                  <Tooltip
                    placement="bottom"
                    isOpen={isShowTooltip.email === "email" ? true : false}
                    target={`resort_poc_email_tooltip_${"user"}`}
                    toggle={() => _toggleTooltip("email", "email")}
                  >
                    {"example@gmail.com"}
                  </Tooltip>
                </i>
              </td>
              <td>23</td>
              <td>Mommy</td>
              <td>3</td>
              <td>
                <Button
                  color="link"
                  className="text-second p-0"
                  onClick={() => history.push("/blogs-view")}
                >
                  2
                </Button>
              </td>
              <td>
                <Button
                  color="link"
                  className="text-second p-0"
                  onClick={() => history.push("/blogs-view")}
                >
                  3
                </Button>
              </td>
              <td>
                <Input type="select" name="select" id="exampleSelect">
                  <option>Active</option>
                  <option>Inactive</option>
                </Input>
              </td>
              <td>
                <Button
                  color="link"
                  className="text-second p-0"
                  onClick={() => history.push("/user-view")}
                >
                  View
                </Button>
              </td>
            </tr>
          </tbody>
        </Table>

        {/* ======== Pagination ======== */}
        <div className="row mt-4">
          <div className="col-md-6">
            <span className="showng_entries">Showing 10 entries out of 25</span>
          </div>
          <div className="col-md-6">
            <ReactPaginate
              forcePage={0}
              className="pagination justify-content-end mb-2"
              breakLabel="..."
              nextLabel={<i className="fa fa-chevron-right" />}
              previousLabel={<i className="fa fa-chevron-left" />}
              previousClassName="previous"
              nextClassName="next"
              onPageChange={({ selected }) => {
                // _onPageChange(selected)
              }}
              pageRangeDisplayed={3}
              pageCount={Math.ceil(25 / 10)}
              pageClassName="page-item"
              renderOnZeroPageCount={null}
              containerClassName={"pagination"}
              previousLinkClassName={"page-link"}
              nextLinkClassName={"page-link"}
              activeClassName={"active"}
              disabledClassName={"page-item"}
            />
          </div>
        </div>

        {/* ========= Contact ========= */}
        <div className="box-container d-flex flex-column mt-5">
          <div className="d-flex justify-content-between">
            <div style={{ flex: 1 }}>
              <h6>Send Email</h6>

              <InputGroup className="mb-2">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>Subject</InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Enter the subject"
                  value={emailFormField.subject}
                  disabled={sendEmailLoading}
                  onChange={(e) =>
                    setEmailFormField((prev) => {
                      return {
                        subject: e.target.value,
                        text: prev.text,
                      };
                    })
                  }
                />
              </InputGroup>

              <TextEditor
                content={emailFormField.text}
                onChange={(text) =>
                  setEmailFormField((prev) => {
                    return {
                      subject: prev.subject,
                      text,
                    };
                  })
                }
              />

              <Button
                className="btn-primary mt-4 d-block mr-auto px-5"
                disabled={sendEmailLoading}
                onClick={() => _sendEmailToUser()}
              >
                {sendEmailLoading ? (
                  <i className="fa fa-spinner fa-spin mr-1" />
                ) : null}{" "}
                Send
              </Button>
            </div>
          </div>
        </div>

        {/* modal */}
        <AddInviteUserModal
          isOpen={addInviteUserModal.isOpen}
          data={addInviteUserModal.data}
          toggle={() => _toggleAddInviteUserModal()}
        />
      </Container>
    </div>
  );
};

export default UserPage;
