import React from "react";
import { Link } from "react-router-dom";
import { APP_NAME } from "../../config";

function AuthFooter() {
  return (
    <>
      <footer className="footer public">
        <div className="left text-center mb-3">
          <span className="d-inline">
            <img
              src={require("../../assets/img/suicide.png").default}
              alt=""
              width="130px"
              className="mr-2"
            />
            {/* Suicide Prevention Support: */}
            <span>
              <a
                href="https://988lifeline.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                988
              </a>
            </span>{" "}
            {/* has been designated as the new three-digit dialing code that will
              route callers to the National Suicide Prevention Lifeline */}
          </span>
          <span className="separator"> &nbsp;|&nbsp; </span>
          <span className="d-inline">
            <a
              href="https://www.facebook.com/indiana.veterans/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Facebook
            </a>
          </span>
        </div>

        <div className="d-flex align-items-center justify-content-between w-100 flex-lg-row flex-column flex-wrap">
          <span className="text-center text-lg-left">
            &copy; 2024 {APP_NAME}
            {/* <Link to="/" style={{ marginLeft: 10 }}>
              Help for Veterans or their families facing financial hardships.
            </Link> */}
          </span>

          <span className="powered-by" style={{ color: "#333" }}>
            Powered by
            <a
              href="https://www.logic-square.com/"
              target="_blank"
              className="ml-2"
              rel="noopener noreferrer"
            >
              Logic Square
            </a>
          </span>
        </div>
      </footer>
    </>
  );
}

export default AuthFooter;
