import React, { useState } from "react";
import {
  Container,
  Col,
  Row,
  Table,
  FormControl,
  InputGroup,
} from "react-bootstrap";
import { Input, Button, Card, FormGroup, Label } from "reactstrap";
import { useHistory } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { formatDate } from "../../helper-methods";

const BlogPage = () => {
  const history = useHistory();

  const [filters, setFilters] = useState({
    status: "",
    type: "",
    search: "",
  });

  const _onChangeFilters = (key, value) => {
    const newFilters = { ...filters };
    newFilters[key] = value;
    setFilters(newFilters);
  };

  const _clearFilters = () => {
    const newFilters = { ...filters };
    Object.keys(newFilters).forEach((key) => {
      newFilters[key] = "";
    });
    setFilters(newFilters);
  };

  return (
    <div className="content">
      <Container fluid className="mobilePadding-0">
        <div className="page_Inner_header">
          <Row className="align-items-center">
            <Col xl={5} md={4}>
              <div className="page_title">
                <h1 className="space_remove">Blogs</h1>
              </div>
            </Col>
            <Col
              xl={7}
              md={8}
              className="text-right d-flex justify-content-md-end justify-content-between mt-3 mt-md-0 align-items-center page_header_right"
            >
              <Button
                className="btn btn-primary"
                onClick={() => history.push("/blogs-create")}
              >
                Create New
              </Button>
            </Col>
          </Row>
        </div>

        {/* ======== Filter ======= */}
        <Card body className="search-filter pt-2 pb-3 mb-4">
          <div>
            <div className="d-flex justify-content-between align-items-end">
              <div className="d-flex justify-content-center align-items-end">
                <FormGroup className="mb-0 mr-2">
                  <Label for="exampleEmail">Status</Label>
                  <Input
                    type="select"
                    value={filters.status}
                    onChange={(e) => _onChangeFilters("status", e.target.value)}
                  >
                    <option value="">All</option>
                    <option value="Draft">Draft</option>
                    <option value="Published">Published</option>
                  </Input>
                </FormGroup>
                <FormGroup className="mb-0">
                  <Label for="exampleEmail">Type</Label>
                  <Input
                    type="select"
                    value={filters.type}
                    onChange={(e) => _onChangeFilters("type", e.target.value)}
                  >
                    <option value="">All</option>
                    <option value="Mommy">Mommy</option>
                    <option value="Kids">Kids</option>
                  </Input>
                </FormGroup>
              </div>

              <div className="d-flex justify-content-end align-items-end">
                <FormGroup className="mb-0">
                  <Label>Search</Label>
                  <InputGroup>
                    <FormControl
                      type="text"
                      placeholder="Enter"
                      autoComplete="off"
                      value={filters.search}
                      onChange={(e) =>
                        _onChangeFilters("search", e.target.value)
                      }
                    />
                    <InputGroup.Text>
                      <i className="fas fa-search" />
                    </InputGroup.Text>
                  </InputGroup>
                </FormGroup>
                <Button
                  color="primary"
                  className="btn-clear ml-4"
                  onClick={() => _clearFilters()}
                >
                  <i className="fa fa-eraser fs-15" />
                </Button>
              </div>
            </div>
          </div>
        </Card>

        {/* ========= Table ======== */}
        <Table responsive className="table-fixed check-switch m-0">
          <thead>
            <tr>
              <th className="w-25">Title</th>
              <th>Author</th>
              <th>Type</th>
              <th>Publish Date</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="text-wrap-overflow">
                <Button
                  color="link"
                  className="text-second p-0"
                  onClick={() => history.push("/blogs-view")}
                >
                  What 10 Studies Say About The Best Times To Post On Social
                  Media
                </Button>
              </td>
              <td>Admin</td>
              <td>Mommy</td>
              <td>{formatDate(new Date())}</td>
              <td>
                <Input type="select" name="select" id="exampleSelect">
                  <option>Published</option>
                  <option>Draft</option>
                </Input>
              </td>
              <td>
                <div className="d-flex align-item-center">
                  <Button
                    className="btn-primary-outline"
                    onClick={() => history.push("/blogs-create?blogId=1234")}
                  >
                    Edit
                  </Button>
                </div>
              </td>
            </tr>
          </tbody>
        </Table>

        {/* ======== Pagination ======== */}
        <div className="row mt-4">
          <div className="col-md-6">
            <span className="showng_entries">Showing 10 entries out of 25</span>
          </div>
          <div className="col-md-6">
            <ReactPaginate
              forcePage={0}
              className="pagination justify-content-end mb-2"
              breakLabel="..."
              nextLabel={<i className="fa fa-chevron-right" />}
              previousLabel={<i className="fa fa-chevron-left" />}
              previousClassName="previous"
              nextClassName="next"
              onPageChange={({ selected }) => {
                // _onPageChange(selected)
              }}
              pageRangeDisplayed={3}
              pageCount={Math.ceil(25 / 10)}
              pageClassName="page-item"
              renderOnZeroPageCount={null}
              containerClassName={"pagination"}
              previousLinkClassName={"page-link"}
              nextLinkClassName={"page-link"}
              activeClassName={"active"}
              disabledClassName={"page-item"}
            />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default BlogPage;
