import React, { Component } from "react";
import {
  Button,
  FormGroup,
  InputGroup,
  Modal,
  Col,
  Row,
} from "react-bootstrap";
import { Label, InputGroupText, Input, Spinner } from "reactstrap";
// import { rolesAndPermissions } from "../../config/helper-config";
import { countryCodes } from "../../config/country-codes";
import { RegexConfig } from "../../config/RegexConfig";
import {
  deepClone,
  showToast,
  getPhoneNumberFromBrackets,
  getPhoneCodeFromBrackets,
  uploadFileOnServer,
  extractQueryParams,
} from "../../helper-methods";
import {
  checkAvailability,
  createTeamMember,
  getTeamMemberById,
  updateTeamMember,
} from "../../http/http-calls";

class AddTeamMemberModal extends Component {
  state = {
    formFields: {
      name: {
        value: "",
        error: null,
        isDirty: false,
        isValidationRequired: true,
      },
      email: {
        value: "",
        error: null,
        isDirty: false,
        isDublicateLoading: false,
        isValidationRequired: true,
      },
      phoneCode: {
        value: "+1",
        error: null,
        isDirty: false,
        isValidationRequired: false,
      },
      phone: {
        value: "",
        error: null,
        isDirty: false,
        isDublicateLoading: false,
        isValidationRequired: true,
      },
      location: {
        value: "",
        error: null,
        isDirty: false,
        isValidationRequired: true,
      },
      // roles: {
      //   value: [],
      //   error: null,
      //   isDirty: false,
      //   isValidationRequired: true,
      // },
      avatar: {
        value: {
          previewBlob: null,
          uploadData: null,
          type: "image",
          uploadUrl: null,
        },
        error: null,
        isDirty: false,
        isValidationRequired: true,
      },
    },
    loading: false,
  };

  _resetStateModal = () => {
    this.setState({
      formFields: {
        name: {
          value: "",
          error: null,
          isDirty: false,
          isValidationRequired: true,
        },
        email: {
          value: "",
          error: null,
          isDirty: false,
          isDublicateLoading: false,
          isValidationRequired: true,
        },
        phoneCode: {
          value: "+1",
          error: null,
          isDirty: false,
          isValidationRequired: false,
        },
        phone: {
          value: "",
          error: null,
          isDirty: false,
          isDublicateLoading: false,
          isValidationRequired: true,
        },
        location: {
          value: "",
          error: null,
          isDirty: false,
          isValidationRequired: true,
        },
        // roles: {
        //   value: [],
        //   error: null,
        //   isDirty: false,
        //   isValidationRequired: true,
        // },
        avatar: {
          value: {
            previewBlob: null,
            uploadData: null,
            type: "image",
            uploadUrl: null,
          },
          error: null,
          isDirty: false,
          isValidationRequired: true,
        },
      },
      loading: false,
    });
  };

  _onCloseModal = () => {
    this._resetStateModal();
    this.props.toggle();
  };

  _setForm = (teamMember) => {
    const { formFields } = deepClone(this.state);

    formFields.name.value = teamMember?.name?.full || "";
    formFields.phone.value = teamMember?.phone
      ? getPhoneNumberFromBrackets(teamMember.phone)
      : "";
    formFields.phoneCode.value = teamMember?.phone
      ? getPhoneCodeFromBrackets(teamMember.phone)
      : "";
    formFields.email.value = teamMember?.email || "";
    formFields.location.value = teamMember?.location || "";
    // formFields.roles.value = teamMember?.roles?.length ? teamMember.roles : [];

    if (teamMember.avatar)
      formFields.avatar.value.uploadUrl = teamMember.avatar;

    this.setState({ formFields });
  };

  _getTeamMemberById = (id) => {
    this.setState({ loading: true });
    getTeamMemberById(id)
      .then((res) => {
        this._setForm(res.teamMember);
        this.setState({ loading: false });
      })
      .catch((error) => {
        console.log("error>>", error);
        this._onCloseModal();
        showToast(
          error?.reason?.length
            ? error.reason
            : "Server error, Try again after sometime.",
          "error"
        );
      });
  };

  componentDidUpdate = (prevProps) => {
    const { isOpen, data } = this.props;
    if (isOpen && data?.id && isOpen !== prevProps.isOpen)
      this._getTeamMemberById(data.id);
  };

  /**
   * Check for duplicate username, email, phone
   */
  _checkDuplicateFields = (key, value) => {
    return new Promise(async (resolve) => {
      const { formFields } = deepClone(this.state);
      formFields[key].isDublicateLoading = true;
      this.setState({ formFields }, () => {
        const payload = {
          handle: value,
          handleType: key,
        };

        checkAvailability(payload)
          .then((res) => {
            resolve([res, null]);
          })
          .catch((error) => {
            resolve([null, error]);
          });
      });
    });
  };

  /**
   * validation or Check for duplicate username, email, phone , validate one at a time
   */
  _formFieldsApiValidation = (key) => {
    return new Promise(async (resolve) => {
      const { formFields: oldFormFields } = deepClone(this.state);

      let isFieldValid = true;

      if (
        oldFormFields[key].isDirty &&
        oldFormFields[key].isValidationRequired
      ) {
        if (oldFormFields[key].value?.trim().length) {
          if (
            RegexConfig[key].test(
              String(oldFormFields[key].value).toLowerCase()
            )
          ) {
            // const checkingValue =
            //   key !== "phone"
            //     ? oldFormFields[key].value.trim()
            //     : `(${oldFormFields.phoneCode.value})${oldFormFields[
            //         key
            //       ].value.trim()}`;

            // const { data } = this.props;

            // always resolve
            // const [res, resError] = !data?.id
            //   ? await this._checkDuplicateFields(key, checkingValue)
            //   : [null, null];
            const [res, resError] = [null, null];

            oldFormFields[key].isDublicateLoading = false;

            if (res?.error || resError?.error) {
              oldFormFields[key].error = `${oldFormFields[
                key
              ].value.trim()} already in use`;
              isFieldValid = false;
            } else {
              oldFormFields[key].error = null;
              oldFormFields[key].isDirty = false;
            }
          } else {
            oldFormFields[key].error = `*Invalid ${key}`;
            isFieldValid = false;
          }
        } else {
          oldFormFields[key].error = "*Required";
          isFieldValid = false;
        }
      }
      const { formFields: newFormFields } = deepClone(this.state);

      newFormFields[key].error = oldFormFields[key].error;
      newFormFields[key].isDirty = oldFormFields[key].isDirty;
      newFormFields[key].isDublicateLoading =
        oldFormFields[key].isDublicateLoading;

      this.setState({ formFields: newFormFields }, () => resolve(isFieldValid));
    });
  };

  _validateFormFields = () => {
    return new Promise((resolve) => {
      const { formFields } = deepClone(this.state);

      let isFormValid = true;

      Object.keys(formFields).forEach((key) => {
        if (formFields[key].isDirty && formFields[key].isValidationRequired) {
          switch (key) {
            case "location":
            case "name": {
              if (formFields[key].value?.trim().length) {
                formFields[key].error = null;
                formFields[key].isDirty = false;
              } else {
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            // case "roles": {
            //   if (formFields[key].value?.length) {
            //     formFields[key].error = null;
            //     formFields[key].isDirty = false;
            //   } else {
            //     formFields[key].error = "*Atleast one role is required";
            //     isFormValid = false;
            //   }
            //   break;
            // }
            default:
          }
        }
      });

      this.setState({ formFields }, () => resolve(isFormValid));
    });
  };

  _resetDisplayPicture = () => {
    const { formFields } = deepClone(this.state);

    formFields["avatar"] = {
      value: {
        previewBlob: null,
        uploadData: null,
        type: "image",
        uploadUrl: null,
      },
      error: null,
      isDirty: false,
      isValidationRequired: false,
    };

    this.setState({ formFields });
  };

  _onChangeDisplayPicture = (e, key = "avatar") => {
    try {
      if (e?.target?.files?.length) {
        const { formFields } = deepClone(this.state);

        const file = e.target.files[0];

        const fileType = file.type.split("/")[0];

        if (fileType === "image") {
          const previewBlob = URL.createObjectURL(file);

          formFields[key].value["uploadData"] = file;
          formFields[key].value["previewBlob"] = previewBlob;
          formFields[key].value["type"] = fileType;
          formFields[key].value["uploadUrl"] = null;
        } else {
          showToast("Only image file is allowed", "error");
          return;
        }

        this.setState({ formFields });
      }
    } catch (error) {
      console.log("error>>", error);
      showToast("Something went wrong, Try again after sometime.", "error");
    }
  };

  _onChangeFormFields = (key, value, checked) => {
    const { data } = this.props;

    if (data?.id && key === "email") return;

    if (key === "phone" && (isNaN(value) || value.includes("."))) return;

    const { formFields } = deepClone(this.state);
    formFields[key].value = value;
    this.setState({ formFields });

    // if (key === "roles") {
    //   if (checked) {
    //     formFields[key].value.push(value);
    //   } else {
    //     const valueIndex = formFields[key].value.indexOf(value);
    //     formFields[key].value.splice(valueIndex, 1);
    //   }
    // } else {
    // }
    // if (key === "roles") this._onBlurFormFields(key);
  };

  _onBlurFormFields = (key) => {
    const { formFields } = deepClone(this.state);
    formFields[key].isDirty = true;
    if (key === "username" || key === "email" || key === "phone") {
      this.setState({ formFields }, () => this._formFieldsApiValidation(key));
    } else {
      this.setState({ formFields }, () => this._validateFormFields());
    }
  };

  _markAllFieldDirty = () => {
    return new Promise((resolve) => {
      const { formFields } = deepClone(this.state);
      Object.keys(formFields).forEach((key) => {
        formFields[key].isDirty = true;
      });
      this.setState({ formFields }, () => resolve(true));
    });
  };

  _updateTeamMember = (id, payload) => {
    updateTeamMember(id, payload)
      .then((res) => {
        showToast("Team member updated successfully.", "success");
        this.props.resetCurrentData();
        this._onCloseModal();
      })
      .catch((error) => {
        console.log("error>>", error);
        this.setState({ loading: false });
        showToast(
          error?.reason?.length
            ? error.reason
            : "Server error, Try again after sometime.",
          "error"
        );
      });
  };

  _createTeamMember = (payload) => {
    createTeamMember(payload)
      .then((res) => {
        showToast("Team member added successfully.", "success");
        this.props.getAllTeamMembers();
        this._onCloseModal();
      })
      .catch((error) => {
        console.log("error>>", error);
        this.setState({ loading: false });
        showToast(
          error?.reason?.length
            ? error.reason
            : "Server error, Try again after sometime.",
          "error"
        );
      });
  };

  _onSave = async (event) => {
    try {
      if (event) event.preventDefault();

      this.setState({ loading: true });

      await this._markAllFieldDirty();

      const [isFormValid, isEmailFieldValid, isPhoneFieldValid] =
        await Promise.all([
          this._validateFormFields(),
          this._formFieldsApiValidation("email"),
          this._formFieldsApiValidation("phone"),
        ]);

      if (isFormValid && isEmailFieldValid && isPhoneFieldValid) {
        const { formFields } = deepClone(this.state);
        const { data } = this.props;

        let splitName = formFields.name.value.trim().split(" ");

        if (splitName?.length) {
          splitName = {
            first:
              splitName.length > 1
                ? splitName.slice(0, -1).join(" ")
                : splitName[0],
            last: splitName.length > 1 ? splitName[splitName.length - 1] : "",
          };
        }

        const payload = {
          name: {
            first: splitName.first?.trim() ? splitName.first.trim() : "",
            last: splitName.last?.trim() ? splitName.last.trim() : "",
          },
          email: formFields.email.value.trim(),
          phone: `(${
            formFields.phoneCode.value
          })${formFields.phone.value.trim()}`,
          // roles: formFields.roles.value,
          location: formFields.location.value.trim() || "",
        };

        const { resortId } = extractQueryParams();
        payload["resortId"] = resortId;

        if (data?.id) {
          if (formFields.avatar?.value?.uploadData) {
            const uploadDataFilter = [
              {
                uploadData: formFields.avatar.value.uploadData,
                previewBlob: formFields.avatar.value.previewBlob,
                type: formFields.avatar.value.type,
              },
            ];
            const response = await uploadFileOnServer(uploadDataFilter);
            payload["avatar"] = response[0].url;
            formFields.avatar.value.uploadUrl = response[0].url;
            formFields.avatar.value.uploadData = null;
            this.setState({ formFields });
          } else {
            payload["avatar"] = formFields?.avatar?.value?.uploadUrl || "";
          }
          this._updateTeamMember(data.id, payload);
        } else {
          this._createTeamMember(payload);
        }
      } else {
        this.setState({ loading: false });
      }
    } catch (error) {
      console.log("error>>", error);
      this.setState({ loading: false });
      showToast(
        error?.reason?.length
          ? error.reason
          : "Something went wrong, Try again after sometime.",
        "error"
      );
    }
  };

  render() {
    const { formFields, loading } = this.state;

    const { isOpen, data } = this.props;

    return (
      <Modal
        scrollable={true}
        show={isOpen}
        onHide={() => this._onCloseModal()}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="add-admin-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {data?.id ? "Update" : "Create"} Team Member{" "}
            {loading ? <Spinner /> : null}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="innerForm">
            <Row className="no-margin">
              {data?.id ? (
                // {/* profile pic avatar */}
                <Col md="12" className="mobilePadding-0">
                  <div className="amenity-location profile_center">
                    <div className=" d-flex align-items-center justify-content-center mb-4">
                      {formFields.avatar.value.previewBlob ||
                      formFields.avatar.value.uploadUrl ? (
                        <div className={`input-gallery-col`}>
                          <div className="group_image">
                            <img
                              src={
                                formFields.avatar.value.previewBlob ||
                                formFields.avatar.value.uploadUrl
                              }
                              alt="cover"
                              loading="lazy"
                            />
                            {!loading ? (
                              <i
                                className="fa fa-close"
                                disabled={loading}
                                onClick={() => this._resetDisplayPicture()}
                              />
                            ) : null}
                          </div>
                          <Label>
                            <span className="replace_map">
                              <Input
                                type="file"
                                value=""
                                className="d-none"
                                disabled={loading}
                                accept="image/*"
                                onChange={(e) =>
                                  this._onChangeDisplayPicture(e)
                                }
                              />
                              Replace
                            </span>
                          </Label>
                        </div>
                      ) : (
                        <div className="group_image plus">
                          <Input
                            type="file"
                            value=""
                            id="groupImage"
                            disabled={loading}
                            className="d-none"
                            accept="image/*"
                            onChange={(e) => this._onChangeDisplayPicture(e)}
                          />
                          <Label for="groupImage" className="mb-0">
                            <img
                              src={require("../../assets/img/plus.png").default}
                              alt="category"
                              className="img-fluid"
                              loading="lazy"
                            />
                          </Label>
                        </div>
                      )}
                    </div>
                  </div>
                </Col>
              ) : null}

              {/* name */}
              <Col md="6" className="mobilePadding-0">
                <FormGroup>
                  <Label>Name</Label>
                  <InputGroup>
                    <InputGroupText>
                      <img
                        src={require("../../assets/img/user.svg").default}
                        alt="user"
                        className="img-fluid"
                        loading="lazy"
                      />
                    </InputGroupText>
                    <Input
                      type="text"
                      placeholder="Enter the name"
                      autoComplete="off"
                      name="name"
                      value={formFields.name.value}
                      onChange={(e) =>
                        this._onChangeFormFields("name", e.target.value)
                      }
                      onBlur={() => this._onBlurFormFields("name")}
                    />
                  </InputGroup>
                  {formFields.name.error ? (
                    <div className="form-error">{formFields.name.error}</div>
                  ) : null}
                </FormGroup>
              </Col>

              {/* phone */}
              <Col md="6" className="mobilePadding-0">
                <FormGroup>
                  <Label>Phone Number</Label>
                  <InputGroup className="countryCode">
                    <InputGroupText>
                      <img
                        src={require("../../assets/img/phone.svg").default}
                        alt="user"
                        className="img-fluid"
                        loading="lazy"
                      />
                      <Input
                        type="select"
                        className="pl-0"
                        value={formFields.phoneCode.value}
                        onChange={(e) =>
                          this._onChangeFormFields("phoneCode", e.target.value)
                        }
                      >
                        {countryCodes.map((each) => (
                          <option key={each.code} value={each.dial_code}>
                            {each.code} ({each.dial_code})
                          </option>
                        ))}
                      </Input>
                    </InputGroupText>
                    <Input
                      type="text"
                      placeholder="Enter the phone number"
                      autoComplete="off"
                      value={formFields.phone.value}
                      onChange={(e) =>
                        this._onChangeFormFields("phone", e.target.value)
                      }
                      onBlur={() => this._onBlurFormFields("phone")}
                    />
                    {formFields.phone.isDublicateLoading ? (
                      <div className="spinnerLogin">
                        <Spinner style={{ width: "1rem", height: "1rem" }} />
                      </div>
                    ) : null}
                  </InputGroup>
                  {formFields.phone.error ? (
                    <div className="form-error">{formFields.phone.error}</div>
                  ) : null}
                </FormGroup>
              </Col>

              {/* email */}
              <Col md="6" className="mobilePadding-0">
                <FormGroup>
                  <Label>Email</Label>
                  <InputGroup>
                    <InputGroupText>
                      <img
                        src={require("../../assets/img/at-svg.svg").default}
                        alt="user"
                        className="img-fluid"
                        loading="lazy"
                      />
                    </InputGroupText>
                    <Input
                      type="text"
                      placeholder="Enter the email"
                      autoComplete="off"
                      name="email"
                      value={formFields.email.value}
                      disabled={data?.id ? true : false}
                      onChange={(e) =>
                        this._onChangeFormFields("email", e.target.value)
                      }
                      onBlur={() => this._onBlurFormFields("email")}
                    />
                    {formFields.email.isDublicateLoading ? (
                      <div className="spinnerLogin">
                        <Spinner style={{ width: "1rem", height: "1rem" }} />
                      </div>
                    ) : null}
                  </InputGroup>
                  {formFields.email.error ? (
                    <div className="form-error">{formFields.email.error}</div>
                  ) : null}
                </FormGroup>
              </Col>

              {/* location */}
              <Col md="6" className="mobilePadding-0">
                <FormGroup>
                  <Label>Location</Label>
                  <InputGroup>
                    <InputGroupText>
                      <img
                        src={require("../../assets/img/location.svg").default}
                        alt="user"
                        className="img-fluid"
                        loading="lazy"
                      />
                    </InputGroupText>
                    <Input
                      type="text"
                      placeholder="Enter the location"
                      autoComplete="off"
                      name="location"
                      value={formFields.location.value}
                      onChange={(e) =>
                        this._onChangeFormFields("location", e.target.value)
                      }
                      onBlur={() => this._onBlurFormFields("location")}
                    />
                  </InputGroup>
                  {formFields.location.error ? (
                    <div className="form-error">
                      {formFields.location.error}
                    </div>
                  ) : null}
                </FormGroup>
              </Col>
            </Row>

            {/* <h1 className="role_permission">Roles and Permissions</h1>
            <div className="check-box-container">
              {rolesAndPermissions.map((each) => (
                <CustomInput
                  type="checkbox"
                  key={`add_team_member_modal_role_and_permission_checkbox_${each.value}`}
                  id={`add_team_member_modal_role_and_permission_checkbox_${each.value}`}
                  label={each.label}
                  checked={formFields.roles.value.includes(each.value)}
                  onChange={(e) =>
                    this._onChangeFormFields(
                      "roles",
                      each.value,
                      e.target.checked
                    )
                  }
                />
              ))}
            </div>
            {formFields.roles.error ? (
              <div className="form-error">{formFields.roles.error}</div>
            ) : null} */}
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center pt-3">
          <Button
            className="btn-cancel mr-3"
            onClick={() => this._onCloseModal()}
          >
            Cancel
          </Button>
          <Button
            className="btn-save"
            variant="primary"
            onClick={() => this._onSave()}
            disabled={loading}
          >
            {loading ? <i className="fa fa-spinner fa-spin mr-1" /> : null}{" "}
            {data?.id ? "Update" : "Create"}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default AddTeamMemberModal;
