import React from "react";
import { Container } from "react-bootstrap";

function AdminFooter() {
  return (
    <>
      <footer className="footer">
        <Container fluid className="pl-4 ml-2">
          <nav>
            <ul className="footer-menu">
              <li>
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  Home
                </a>
              </li>
              <li>
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  Company
                </a>
              </li>
              <li>
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  Portfolio
                </a>
              </li>
              <li>
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  Blog
                </a>
              </li>
            </ul>
            <p className="copyright text-center">
              {/* © <script>document.write(new Date().getFullYear())</script>
              <a href="http://www.creative-tim.com">Creative Tim</a>, made with
              love for a better web */}
              <span className="powered-by" style={{ color: "#333" }}>
                Powered by
                <a
                  href="https://www.logic-square.com/"
                  target="_blank"
                  className="ml-2"
                  rel="noopener noreferrer"
                >
                  Logic Square
                </a>
              </span>
            </p>
          </nav>
        </Container>
      </footer>
    </>
  );
}

export default AdminFooter;
