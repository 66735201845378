import React, { useState } from "react";
import {
  Container,
  Col,
  Row,
  Table,
  FormControl,
  InputGroup,
} from "react-bootstrap";
import { Input, Button, Card, FormGroup, Label } from "reactstrap";
import CustomAdvDateRangePicker from "../../components/CustomAdvDateRangePicker";
import { useHistory } from "react-router-dom";
import ReactPaginate from "react-paginate";
import AddForumPostModal from "../../components/Modals/add-forum-post-modal";

const ForumsPage = () => {
  const history = useHistory();

  const [addForumPostModal, setAddForumPostModal] = useState({
    isOpen: false,
    data: null,
  });

  const _toggleAddForumPostModal = (isOpen = false, data = null) => {
    setAddForumPostModal({
      isOpen,
      data,
    });
  };

  const [filters, setFilters] = useState({
    type: "",
    dateRange: {
      startDate: null,
      endDate: null,
    },
    search: "",
  });

  const _onDatesChange = (startDate, endDate) => {
    const newFilters = { ...filters };

    newFilters.dateRange["startDate"] = startDate;
    newFilters.dateRange["endDate"] = endDate;

    setFilters(newFilters);
  };

  const _onChangeFilters = (key, value) => {
    const newFilters = { ...filters };
    newFilters[key] = value;
    setFilters(newFilters);
  };

  const _clearFilters = () => {
    const newFilters = { ...filters };
    Object.keys(newFilters).forEach((key) => {
      if (key === "dateRange")
        newFilters[key] = {
          startDate: null,
          endDate: null,
        };
      else newFilters[key] = "";
    });
    setFilters(newFilters);
  };

  return (
    <div className="content">
      <Container fluid className="mobilePadding-0">
        <div className="page_Inner_header">
          <Row className="align-items-center">
            <Col xl={5} md={4}>
              <div className="page_title">
                <h1 className="space_remove">Forums</h1>
              </div>
            </Col>
            <Col
              xl={7}
              md={8}
              className="text-right d-flex justify-content-md-end justify-content-between mt-3 mt-md-0 align-items-center page_header_right"
            >
              <Button
                color="primary"
                onClick={() => _toggleAddForumPostModal(true)}
              >
                Create Post
              </Button>
            </Col>
          </Row>
        </div>

        {/* ======== Filter ======= */}
        <Card body className="search-filter pt-2 pb-3 mb-4">
          <div>
            <div className="d-flex justify-content-between align-items-end">
              <div className="d-flex justify-content-center align-items-end">
                <FormGroup className="mb-0 mr-2">
                  <Label>Type</Label>
                  <Input
                    type="select"
                    value={filters.type}
                    onChange={(e) => _onChangeFilters("type", e.target.value)}
                  >
                    <option value="">All</option>
                    <option value="mommy">Mommy</option>
                    <option value="kid">Kid</option>
                  </Input>
                </FormGroup>
                <FormGroup className="mb-0">
                  <Label className="d-block">Post Start Date</Label>
                  <CustomAdvDateRangePicker
                    startDate={filters.dateRange.startDate}
                    endDate={filters.dateRange.endDate}
                    startDateId={"startDate_Forums_page"}
                    endDateId={`endDate_Forums_page`}
                    onDatesChange={(startDate, endDate) =>
                      _onDatesChange(startDate, endDate)
                    }
                  />
                </FormGroup>
              </div>

              <div className="d-flex justify-content-end align-items-end">
                <FormGroup className="mb-0">
                  <Label>Search</Label>
                  <InputGroup>
                    <FormControl
                      type="text"
                      placeholder="Enter"
                      autoComplete="off"
                      value={filters.search}
                      onChange={(e) =>
                        _onChangeFilters("search", e.target.value)
                      }
                    />
                    <InputGroup.Text>
                      <i className="fas fa-search" />
                    </InputGroup.Text>
                  </InputGroup>
                </FormGroup>
                <Button
                  color="primary"
                  className="btn-clear ml-4"
                  onClick={() => _clearFilters()}
                >
                  <i className="fa fa-eraser fs-15" />
                </Button>
              </div>
            </div>
          </div>
        </Card>

        {/* ========= Table ======== */}
        <Table responsive className="table-fixed check-switch m-0">
          <thead>
            <tr>
              <th className="w-25">Post</th>
              <th>Author</th>
              <th>Type</th>
              <th>Likes</th>
              <th>Comments</th>
              <th>Start Date</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="text-wrap-overflow">
                <Button
                  color="link"
                  className="text-second p-0"
                  onClick={() => history.push("/forums-view")}
                >
                  What 10 Studies Say About The Best Times To Post On Social
                  Media
                </Button>
              </td>
              <td>
                <Button color="link" className="text-second p-0">
                  Jacob
                </Button>
              </td>
              <td>Mommy</td>
              <td>3</td>
              <td>55</td>
              <td>Feb 22</td>
              <td>
                <Input type="select" name="select">
                  <option>Active</option>
                  <option>Inactive</option>
                </Input>
              </td>
            </tr>
          </tbody>
        </Table>

        {/* ======== Pagination ======== */}
        <div className="row mt-4">
          <div className="col-md-6">
            <span className="showng_entries">Showing 10 entries out of 25</span>
          </div>
          <div className="col-md-6">
            <ReactPaginate
              forcePage={0}
              className="pagination justify-content-end mb-2"
              breakLabel="..."
              nextLabel={<i className="fa fa-chevron-right" />}
              previousLabel={<i className="fa fa-chevron-left" />}
              previousClassName="previous"
              nextClassName="next"
              onPageChange={({ selected }) => {
                // _onPageChange(selected)
              }}
              pageRangeDisplayed={3}
              pageCount={Math.ceil(25 / 10)}
              pageClassName="page-item"
              renderOnZeroPageCount={null}
              containerClassName={"pagination"}
              previousLinkClassName={"page-link"}
              nextLinkClassName={"page-link"}
              activeClassName={"active"}
              disabledClassName={"page-item"}
            />
          </div>
        </div>

        {/* modal */}
        <AddForumPostModal
          isOpen={addForumPostModal.isOpen}
          data={addForumPostModal.data}
          toggle={() => _toggleAddForumPostModal()}
        />
      </Container>
    </div>
  );
};

export default ForumsPage;
