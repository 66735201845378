import React, { useState } from "react";
import { Label, Form, FormGroup, Input, Table } from "reactstrap";
import {
  Button,
  InputGroup,
  FormControl,
  Card,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import CustomDateRangePicker from "../../components/CustomDateRangePicker";

const Outings = (props) => {
  const [filters, setFilters] = useState({
    dateRange: {
      startDate: null,
      endDate: null,
      focusedInput: null,
      startDateId: "outingsStartDate",
      endDateId: "outingsEndDate",
    },
    resort: "",
    pax: "",
    dicount: "",
    status: "",
  });

  const _onDatesChange = (startDate, endDate) => {
    const newFilters = { ...filters };

    newFilters.dateRange["startDate"] = startDate;
    newFilters.dateRange["endDate"] = endDate;

    setFilters(newFilters);
  };

  const _onFocusChange = (input) => {
    const newFilters = { ...filters };

    newFilters.dateRange["focusedInput"] = input;

    setFilters(newFilters);
  };

  const _onChangeFilters = (key, value) => {
    const newFilters = { ...filters };
    newFilters[key] = value;
    setFilters(newFilters);
  };

  const _resetFilters = () => {
    setFilters({
      dateRange: {
        startDate: null,
        endDate: null,
        focusedInput: null,
        startDateId: "startDate",
        endDateId: "endDate",
      },
      resort: "",
      pax: "",
      dicount: "",
      status: "",
    });
  };

  return (
    <div className="content">
      <Container fluid className="mobilePadding-0">
        <div className="page_Inner_header">
          <Row className="align-items-center">
            <Col xl={5} md={4}>
              <div className="page_title">
                <h1 className="space_remove">Outings</h1>
              </div>
            </Col>
          </Row>
        </div>

        {/* ======== Filter ======= */}
        <Card body className="search-filter pt-2 pb-3 mb-4">
          <Form>
            <div className="d-flex justify-content-between align-items-end">
              <div className="d-flex justify-content-center align-items-end">
                <i className="fa fa-filter" />
                <FormGroup className="mb-0">
                  <Label>Resort</Label>
                  <Input
                    type="select"
                    value={filters.resort}
                    onChange={(e) => _onChangeFilters("resort", e.target.value)}
                  >
                    <option value="">All</option>
                    <option>0-50</option>
                    <option>51-100</option>
                    <option>100-250</option>
                    <option>250+</option>
                  </Input>
                </FormGroup>
                <FormGroup className="mb-0 ml-3">
                  <Label>PAX</Label>
                  <Input
                    type="select"
                    value={filters.pax}
                    onChange={(e) => _onChangeFilters("pax", e.target.value)}
                  >
                    <option value="">All</option>
                    <option>0</option>
                    <option>1 - 5</option>
                    <option>6 - 10</option>
                    <option>10 +</option>
                  </Input>
                </FormGroup>
                <FormGroup className="mb-0 ml-3">
                  <Label className="d-block">Date Range</Label>
                  <CustomDateRangePicker
                    dateRange={filters.dateRange}
                    onDatesChange={_onDatesChange}
                    onFocusChange={_onFocusChange}
                  />
                </FormGroup>
                <FormGroup className="mb-0 ml-3">
                  <Label>Discount</Label>
                  <Input
                    type="select"
                    value={filters.dicount}
                    onChange={(e) =>
                      _onChangeFilters("dicount", e.target.value)
                    }
                  >
                    <option value="">All</option>
                    <option>0%</option>
                    <option>10 - 25%</option>
                    <option>upto 10 %</option>
                    <option>25% +</option>
                  </Input>
                </FormGroup>
                <FormGroup className="mb-0 ml-3">
                  <Label>Status</Label>
                  <Input
                    type="select"
                    value={filters.status}
                    onChange={(e) => _onChangeFilters("status", e.target.value)}
                  >
                    <option value="">All</option>
                    <option>Booked</option>
                    <option>Completed</option>
                    <option>Cancelled by user</option>
                    <option>Cancelled by operator</option>
                  </Input>
                </FormGroup>
              </div>
              <div className="d-flex align-items-end ml-3">
                <FormGroup className="mb-0">
                  <Label>Search By Name</Label>
                  <InputGroup>
                    <FormControl
                      type="text"
                      placeholder="Enter"
                      autoComplete="off"
                      name="firstName"
                    />
                    <InputGroup.Text id="basic-addon2">
                      <i className="fas fa-search" />
                    </InputGroup.Text>
                  </InputGroup>
                </FormGroup>
                <Button
                  color="primary"
                  className="btn-clear ml-4"
                  onClick={() => _resetFilters()}
                >
                  <i className="fa fa-eraser fs-15" />
                </Button>
              </div>
            </div>
          </Form>
        </Card>

        <Table responsive className="m-0">
          <thead>
            <tr>
              <th>Vacationer</th>
              <th>Resort</th>
              <th>Outings</th>
              <th>Location</th>
              <th>PAX</th>
              <th>Amount</th>
              <th>Discount</th>
              <th>Date</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <Link to="#">John Doe</Link>
              </td>
              <td>
                <Link to="#">XYZ</Link>
              </td>
              <td>Skydiving</td>
              <td>Cabo</td>
              <td>4</td>
              <td>$100</td>
              <td>10%</td>
              <td>
                <p className="fs-15 m-0">
                  <span>Dec 16</span> - <span>06:00 pm</span>
                </p>
              </td>
              <td>
                <Input type="select" name="category">
                  <option>Booked</option>
                  <option>Completed</option>
                  <option>Cancelled by user</option>
                  <option>Cancelled by operator</option>
                </Input>
              </td>
              <td>
                <Link to="#">Invoice</Link>
              </td>
            </tr>
          </tbody>
        </Table>
      </Container>
    </div>
  );
};

export default Outings;
