import React from "react";
import { APP_LOGO } from "../../config/index";
import { Navbar, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { clearUserCredential } from "../../redux/actions/user-credential";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
function PublicNavbar(props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const _logout = () => {
    dispatch(clearUserCredential());
    sessionStorage.clear();
    history.push("/home");
  };

  return (
    <Navbar expand="lg" className="header_top">
      <Container
        fluid
        className="d-flex justify-content-between align-items-center"
      >
        <Link to="/" className="navbar-wrapper">
          <img
            src={APP_LOGO}
            alt="react-logo"
            className="logo-main"
            loading="lazy"
          />
          <span>Military Veteran Community Resources</span>
        </Link>

        <div>
          {/* <i role="button" className="fa fa-bell text-theme"></i>
          <Link to="/">
            <i role="button" className="fa fa-power-off text-danger ml-4"></i>
          </Link> */}
          <Link to="/" className="back-to-home" onClick={() => _logout()}>
            Back to Home
          </Link>
        </div>
      </Container>
    </Navbar>
  );
}

export default PublicNavbar;
