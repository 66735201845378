import React, { useEffect, useState } from "react";
import {
  Label,
  Form,
  FormGroup,
  Input,
  Table,
  CustomInput,
  InputGroupAddon,
  InputGroupText,
  Spinner,
} from "reactstrap";
import {
  Button,
  InputGroup,
  FormControl,
  Card,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import ReactPaginate from "react-paginate";
import TextEditor from "../../components/TextEditor";
import VacationerTripsModal from "../../components/Modals/vacationer-trips-modal";
import VacationerOutingModal from "../../components/Modals/vacationer-outing-modal";
import VacationerRequestModal from "../../components/Modals/vacationer-request-modal";
import {
  getAllVacationers,
  updateVacationerById,
  sendEmailToUser,
} from "../../http/http-calls";
import {
  capitalize,
  formatDateAndTime,
  formatPhoneNumber,
  showToast,
} from "../../helper-methods";
import { tripsCountConfig } from "../../config/helper-config";
import { DEFAULT_PROFILE_PICTURE } from "../../config";

let searchInterval;

const Vacationers = (props) => {
  const [vacationerTripsModal, setVacationerTripsModal] = useState({
    isOpen: false,
    data: null,
  });
  const [vacationerOutingModal, setVacationerOutingModal] = useState({
    isOpen: false,
  });

  const [vacationerRequestModal, setVacationerRequestModal] = useState({
    isOpen: false,
  });

  const _toggleVacationerTripsModal = (isOpen = false, data = null) => {
    setVacationerTripsModal({
      isOpen,
      data,
    });
  };

  const _toggleVacationerOutingModal = (isOpen = false) => {
    setVacationerOutingModal({
      isOpen,
    });
  };

  const _toggleVacationerRequestModal = (isOpen = false) => {
    setVacationerRequestModal({
      isOpen,
    });
  };

  const [filters, setFilters] = useState({
    trips: "",
    outings: "",
    isActive: "",
  });
  const [getAllVacationersPayload, setGetAllVacationersPayload] = useState({
    filters: {},
    search: "",
    skip: 0,
    limit: 10,
    pageNumber: 0,
  });
  const [vacationers, setVacationers] = useState([]);
  const [vacationersCount, setVacationersCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [statusToogleLoading, setStatusToogleLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [filtersLoading, setFiltersLoading] = useState(false);

  const [selectedVacationerIds, setSelectedVacationerIds] = useState([]);

  const [emailFormField, setEmailFormField] = useState({
    subject: "",
    text: "",
  });
  const [sendEmailLoading, setSendEmailLoading] = useState(false);

  const _getAllVacationers = (payload) => {
    setLoading(true);

    getAllVacationers(payload)
      .then((res) => {
        setVacationers(res.vacationers);
        setVacationersCount(res.count);
        setLoading(false);
        setSearchLoading(false);
        setFiltersLoading(false);
      })
      .catch((error) => {
        console.log("error>>", error);
        setLoading(false);
        setSearchLoading(false);
        setFiltersLoading(false);
        showToast(
          error?.reason?.length
            ? error.reason
            : "Server error, Try again after sometime.",
          "error"
        );
      });
  };

  const _onChangeFilters = (key, value) => {
    setFiltersLoading(true);

    const newFilters = { ...filters };
    newFilters[key] = value;
    setFilters(newFilters);

    const newGetAllVacationersPayload = { ...getAllVacationersPayload };
    newGetAllVacationersPayload["skip"] = 0;
    newGetAllVacationersPayload["pageNumber"] = 0;
    newGetAllVacationersPayload["filters"] = {};

    Object.keys(newFilters).forEach((eachFilterKey) => {
      if (newFilters[eachFilterKey]) {
        if (eachFilterKey === "isActive") {
          newGetAllVacationersPayload.filters[eachFilterKey] =
            newFilters[eachFilterKey] === "active" ? true : false;
        } else {
          try {
            newGetAllVacationersPayload.filters[eachFilterKey] = JSON.parse(
              newFilters[eachFilterKey]
            );
          } catch (error) {
            newGetAllVacationersPayload.filters[eachFilterKey] =
              newFilters[eachFilterKey];
          }
        }
      }
    });

    setGetAllVacationersPayload(newGetAllVacationersPayload);

    setSelectedVacationerIds([]);

    _getAllVacationers(newGetAllVacationersPayload);
  };

  const _clearFilters = () => {
    setFiltersLoading(true);

    const newFilters = { ...filters };
    Object.keys(newFilters).forEach((key) => {
      newFilters[key] = "";
    });
    setFilters(newFilters);

    const newGetAllVacationersPayload = { ...getAllVacationersPayload };
    newGetAllVacationersPayload["search"] = "";
    newGetAllVacationersPayload["skip"] = 0;
    newGetAllVacationersPayload["pageNumber"] = 0;
    newGetAllVacationersPayload["filters"] = {};
    setGetAllVacationersPayload(newGetAllVacationersPayload);

    setSelectedVacationerIds([]);

    _getAllVacationers(newGetAllVacationersPayload);
  };

  const _onPageChange = (pageNumber = 0) => {
    const newGetAllVacationersPayload = { ...getAllVacationersPayload };
    newGetAllVacationersPayload["skip"] =
      pageNumber * newGetAllVacationersPayload["limit"];
    newGetAllVacationersPayload["pageNumber"] = pageNumber;
    setGetAllVacationersPayload(newGetAllVacationersPayload);
    _getAllVacationers(newGetAllVacationersPayload);
  };

  const _onChangeSearch = (searchValue) => {
    clearInterval(searchInterval);
    setSearchLoading(true);
    const newGetAllVacationersPayload = { ...getAllVacationersPayload };
    newGetAllVacationersPayload["search"] = searchValue;
    newGetAllVacationersPayload["skip"] = 0;
    newGetAllVacationersPayload["pageNumber"] = 0;
    setGetAllVacationersPayload(newGetAllVacationersPayload);
    searchInterval = setTimeout(() => {
      setSelectedVacationerIds([]);
      _getAllVacationers(newGetAllVacationersPayload);
    }, 1000);
  };

  const _updateVacationerById = (vacationerId, payload) => {
    return new Promise((resolve, reject) => {
      updateVacationerById(vacationerId, payload)
        .then((res) => resolve(res))
        .catch((error) => reject(error));
    });
  };

  const _toggleVacationerStatus = async (vacationer, status = true) => {
    try {
      const newVacationers = [...vacationers];
      const findVacationer = newVacationers.find(
        (each) => each.id === vacationer.id
      );
      if (findVacationer) {
        setStatusToogleLoading(true);

        findVacationer["isActive"] = status;
        setVacationers(newVacationers);

        await _updateVacationerById(vacationer.id, {
          isActive: status ? true : false,
        });

        showToast("Status updated", "success");

        setStatusToogleLoading(false);
      } else {
        showToast("Something went wrong, Try again after sometime.", "error");
      }
    } catch (error) {
      console.log("error>>", error);
      setStatusToogleLoading(false);
      _getAllVacationers(getAllVacationersPayload);
      showToast(
        error?.reason?.length
          ? error.reason
          : "Server error, Try again after sometime.",
        "error"
      );
    }
  };

  const _isAllVacationerSelected = () => {
    if (vacationers?.length && selectedVacationerIds?.length) {
      let isAllSelected = true;

      for (let vacationer of vacationers) {
        if (!selectedVacationerIds.find((each) => each === vacationer.id)) {
          isAllSelected = false;
          break;
        }
      }

      return isAllSelected;
    } else {
      return false;
    }
  };

  const _toggleAllVacationerSelection = (checked = true) => {
    if (checked) {
      let newSelectedVacationerIds = [];
      newSelectedVacationerIds = Vacationers?.length
        ? Vacationers.map((vacationer) => vacationer.id)
        : [];
      setSelectedVacationerIds(newSelectedVacationerIds);
    } else {
      setSelectedVacationerIds([]);
    }
  };

  const _isVacationerSelected = (vacationer) => {
    return selectedVacationerIds?.length
      ? selectedVacationerIds.find((each) => each === vacationer.id)
      : false;
  };

  const _toggleVacationerSelection = (vacationer, checked = true) => {
    let newSelectedVacationerIds = [...selectedVacationerIds];
    if (checked) {
      newSelectedVacationerIds.push(vacationer.id);
      setSelectedVacationerIds(newSelectedVacationerIds);
    } else {
      newSelectedVacationerIds = newSelectedVacationerIds.filter(
        (each) => each !== vacationer.id
      );
      setSelectedVacationerIds(newSelectedVacationerIds);
    }
  };

  const _sendEmailToUser = () => {
    const payload = {
      idlist: selectedVacationerIds?.length ? selectedVacationerIds : [],
      subject: emailFormField?.subject?.trim().length
        ? emailFormField.subject.trim()
        : "",
      text: emailFormField?.text?.trim().length
        ? emailFormField.text.trim()
        : "",
    };

    if (!payload.idlist.length) {
      showToast("Please select atleast one user.", "error");
      return;
    }
    if (!payload.subject.length) {
      showToast("Please write some subject.", "error");
      return;
    }
    if (!payload.text.length) {
      showToast("Please write some message.", "error");
      return;
    }

    setSendEmailLoading(true);

    sendEmailToUser(payload)
      .then((res) => {
        showToast("Email sent successfully.", "success");

        setSelectedVacationerIds([]);
        setEmailFormField({
          subject: "",
          text: "",
        });
        setSendEmailLoading(false);
      })
      .catch((error) => {
        console.log("error>>", error);
        setSendEmailLoading(false);
        showToast(
          error?.reason?.length
            ? error.reason
            : "Server error, Try again after sometime.",
          "error"
        );
      });
  };

  useEffect(() => {
    _getAllVacationers(getAllVacationersPayload);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="content">
        <Container fluid className="mobilePadding-0">
          <div className="page_Inner_header">
            <Row className="align-items-center">
              <Col xl={5} md={4}>
                <div className="page_title">
                  <h1 className="space_remove">
                    Vacationers {filtersLoading ? <Spinner /> : null}
                  </h1>
                </div>
              </Col>
            </Row>
          </div>

          {/* ======== Filter ======= */}
          <Card body className="search-filter pt-2 pb-3 mb-4">
            <Form>
              <div className="d-flex justify-content-between align-items-end">
                <div className="d-flex justify-content-center align-items-end">
                  <i className="fa fa-filter" />
                  <FormGroup className="mb-0">
                    <Label>Trips</Label>
                    <Input
                      type="select"
                      value={filters.trips}
                      name="trips"
                      onChange={(e) =>
                        _onChangeFilters("trips", e.target.value)
                      }
                    >
                      <option value="">All</option>
                      {tripsCountConfig.map((each) => (
                        <option
                          key={JSON.stringify(each.value)}
                          value={JSON.stringify(each.value)}
                        >
                          {each.label}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                  <FormGroup className="mb-0 ml-3">
                    <Label>Outings</Label>
                    <Input
                      type="select"
                      value={filters.outings}
                      name="outings"
                      onChange={(e) =>
                        _onChangeFilters("outings", e.target.value)
                      }
                    >
                      <option value="">All</option>
                      {tripsCountConfig.map((each) => (
                        <option
                          key={JSON.stringify(each.value)}
                          value={JSON.stringify(each.value)}
                        >
                          {each.label}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                  <FormGroup className="mb-0 ml-3">
                    <Label>Status</Label>
                    <Input
                      type="select"
                      value={filters.isActive}
                      name="isActive"
                      onChange={(e) =>
                        _onChangeFilters("isActive", e.target.value)
                      }
                    >
                      <option value="">All</option>
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                    </Input>
                  </FormGroup>
                </div>

                <div className="d-flex align-items-end">
                  <FormGroup className="mb-0">
                    <Label>Search By Name</Label>
                    <InputGroup>
                      <FormControl
                        type="text"
                        placeholder="Enter"
                        autoComplete="off"
                        name="firstName"
                        value={getAllVacationersPayload.search}
                        onChange={(e) => _onChangeSearch(e.target.value)}
                      />
                      <InputGroup.Text id="basic-addon2">
                        {searchLoading ? (
                          <i className="fa fa-spinner fa-spin" />
                        ) : getAllVacationersPayload.search ? (
                          <i
                            className="fas fa-times"
                            onClick={() => _onChangeSearch("")}
                          />
                        ) : (
                          <i className="fas fa-search" />
                        )}
                      </InputGroup.Text>
                    </InputGroup>
                  </FormGroup>
                  <Button
                    color="primary"
                    className="btn-clear ml-4"
                    onClick={() => _clearFilters()}
                  >
                    <i className="fa fa-eraser fs-15" />
                  </Button>
                </div>
              </div>
            </Form>
          </Card>

          {selectedVacationerIds.length ? (
            <div>
              {selectedVacationerIds.length}{" "}
              {selectedVacationerIds.length > 1
                ? "Vacationers are"
                : "Vacationer is"}{" "}
              selected
            </div>
          ) : null}

          <Table responsive className="check-switch m-0">
            <thead>
              <tr>
                <th className="p-0">
                  <div className="check-box-container">
                    <CustomInput
                      type="checkbox"
                      id={`custom_checkbox_vacationers_selected_all`}
                      checked={_isAllVacationerSelected() ? true : false}
                      onChange={(e) =>
                        _toggleAllVacationerSelection(e.target.checked)
                      }
                    />
                  </div>
                </th>
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Trips</th>
                <th>Outings</th>
                <th>Request</th>
                <th>Last Active</th>
                <th>Status</th>
              </tr>
            </thead>

            <tbody>
              {vacationers.length ? (
                vacationers.map((vacationer) => (
                  <tr key={vacationer.id}>
                    <td className="p-0">
                      <div className="check-box-container">
                        <CustomInput
                          type="checkbox"
                          id={`custom_checkbox_vacationers_selected_${vacationer.id}`}
                          checked={
                            _isVacationerSelected(vacationer) ? true : false
                          }
                          onChange={(e) =>
                            _toggleVacationerSelection(
                              vacationer,
                              e.target.checked
                            )
                          }
                        />
                      </div>
                    </td>

                    <td>
                      <img
                        src={vacationer.avatar || DEFAULT_PROFILE_PICTURE}
                        onError={(e) =>
                          (e.target.src = DEFAULT_PROFILE_PICTURE)
                        }
                        alt="avatar"
                        className="rounded-circle"
                        height="50"
                        width="50"
                        loading="lazy"
                      />
                      <span className="ml-2">
                        {vacationer.name?.full
                          ? capitalize(vacationer.name.full)
                          : "N/A"}
                      </span>
                    </td>

                    <td>{vacationer.email || "N/A"}</td>
                    <td>
                      {vacationer?.phone
                        ? formatPhoneNumber(vacationer.phone)
                        : "N/A"}
                    </td>
                    <td>
                      {/* Trips */}
                      {vacationer?.tripCount ? (
                        <Button
                          className="btn_link"
                          onClick={() =>
                            _toggleVacationerTripsModal(true, vacationer)
                          }
                        >
                          {vacationer?.tripCount}
                        </Button>
                      ) : (
                        0
                      )}
                    </td>
                    <td>
                      {/* outing */}
                      <Button
                        className="btn_link"
                        onClick={() => _toggleVacationerOutingModal(true)}
                      >
                        5
                      </Button>
                    </td>
                    <td>
                      {/* request */}
                      <Button
                        className="btn_link"
                        onClick={() => _toggleVacationerRequestModal(true)}
                      >
                        5
                      </Button>
                    </td>

                    <td>
                      {vacationer.lastLogin
                        ? formatDateAndTime(vacationer.lastLogin)
                        : "N/A"}
                    </td>

                    <td>
                      <CustomInput
                        type="switch"
                        id={`custom_switch_vacationers_active_${vacationer.id}`}
                        checked={vacationer.isActive}
                        label={vacationer.isActive ? "Active" : "Inactive"}
                        disabled={statusToogleLoading}
                        onChange={(e) =>
                          _toggleVacationerStatus(vacationer, e.target.checked)
                        }
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr className="text-center">
                  <td colSpan="10">
                    {loading ? <Spinner /> : "There is no data to display."}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>

          {/* ====== Pagination ====== */}
          {vacationers?.length && vacationersCount ? (
            <div className="row mt-4">
              <div className="col-md-6">
                <span className="showng_entries">
                  Showing {vacationers?.length} entries out of{" "}
                  {vacationersCount}
                </span>
              </div>
              <div className="col-md-6">
                <ReactPaginate
                  forcePage={getAllVacationersPayload.pageNumber}
                  className="pagination justify-content-end mb-2"
                  breakLabel="..."
                  nextLabel={<i className="fa fa-chevron-right" />}
                  previousLabel={<i className="fa fa-chevron-left" />}
                  previousClassName="previous"
                  nextClassName="next"
                  onPageChange={({ selected }) => _onPageChange(selected)}
                  pageRangeDisplayed={3}
                  pageCount={Math.ceil(
                    vacationersCount / getAllVacationersPayload.limit
                  )}
                  pageClassName="page-item"
                  renderOnZeroPageCount={null}
                  containerClassName={"pagination"}
                  previousLinkClassName={"page-link"}
                  nextLinkClassName={"page-link"}
                  activeClassName={"active"}
                  disabledClassName={"page-item"}
                />
              </div>
            </div>
          ) : null}

          {/* ========= Contact ========= */}
          <div className="box-container d-flex flex-column  mt-5">
            <div className="d-flex justify-content-between">
              <div style={{ flex: 1 }}>
                <h6>Send Email</h6>

                <InputGroup className="mb-2">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>Subject</InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Enter the subject"
                    value={emailFormField.subject}
                    disabled={sendEmailLoading}
                    onChange={(e) =>
                      setEmailFormField((prev) => {
                        return {
                          subject: e.target.value,
                          text: prev.text,
                        };
                      })
                    }
                  />
                </InputGroup>

                <TextEditor
                  content={emailFormField.text}
                  onChange={(text) =>
                    setEmailFormField((prev) => {
                      return {
                        subject: prev.subject,
                        text,
                      };
                    })
                  }
                />

                <Button
                  className="BtnPurple mt-4 d-block mr-auto px-4"
                  disabled={sendEmailLoading}
                  onClick={() => _sendEmailToUser()}
                >
                  {sendEmailLoading ? (
                    <i className="fa fa-spinner fa-spin mr-1" />
                  ) : null}{" "}
                  Send
                </Button>
              </div>
            </div>
          </div>
        </Container>
      </div>

      {/* ======= Modal ======= */}
      <VacationerTripsModal
        isOpen={vacationerTripsModal.isOpen}
        data={vacationerTripsModal.data}
        toggle={() => _toggleVacationerTripsModal()}
      />
      <VacationerOutingModal
        isOpen={vacationerOutingModal.isOpen}
        toggle={() => _toggleVacationerOutingModal()}
      />
      <VacationerRequestModal
        isOpen={vacationerRequestModal.isOpen}
        toggle={() => _toggleVacationerRequestModal()}
      />
    </>
  );
};

export default Vacationers;
