import React, { useState } from "react";
import {
  Container,
  Col,
  Row,
  Table,
  FormControl,
  InputGroup,
} from "react-bootstrap";
import { Input, Button, Card, FormGroup, Label } from "reactstrap";
import { useHistory } from "react-router-dom";
import ReactPaginate from "react-paginate";

const JobsPage = () => {
  const history = useHistory();

  const [filters, setFilters] = useState({
    status: "",
    salary: "",
    jobFunction: "",
    stateOfWork: "",
    educationRequirement: "",
    search: "",
  });

  const _onChangeFilters = (key, value) => {
    const newFilters = { ...filters };
    newFilters[key] = value;
    setFilters(newFilters);
  };

  const _clearFilters = () => {
    const newFilters = { ...filters };
    Object.keys(newFilters).forEach((key) => {
      newFilters[key] = "";
    });
    setFilters(newFilters);
  };

  return (
    <div className="content">
      <Container fluid className="mobilePadding-0">
        <div className="page_Inner_header">
          <Row className="align-items-center">
            <Col xl={5} md={4}>
              <div className="page_title">
                <h1 className="space_remove">Jobs</h1>
              </div>
            </Col>
            <Col
              xl={7}
              md={8}
              className="text-right d-flex justify-content-md-end justify-content-between mt-3 mt-md-0 align-items-center page_header_right"
            >
              <Button
                className="btn btn-primary"
                onClick={() => history.push("/jobs-create")}
              >
                Create New
              </Button>
            </Col>
          </Row>
        </div>

        {/* ======== Filter ======= */}
        <Card body className="search-filter pt-2 pb-3 mb-4">
          <div>
            <div className="d-flex justify-content-between align-items-end">
              <div className="d-flex justify-content-center align-items-end">
                {/* ==== Status ==== */}
                <FormGroup className="mb-0 mr-2">
                  <Label for="exampleEmail">Status</Label>
                  <Input
                    type="select"
                    value={filters.status}
                    onChange={(e) => _onChangeFilters("status", e.target.value)}
                  >
                    <option value="">All</option>
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </Input>
                </FormGroup>

                {/* ==== Salary Range ==== */}
                <FormGroup className="mb-0 mr-2">
                  <Label for="exampleEmail">Salary Range</Label>
                  <Input
                    type="select"
                    value={filters.salary}
                    onChange={(e) => _onChangeFilters("salary", e.target.value)}
                  >
                    <option value="">All</option>
                    <option value="10-20">10-20 USD per hour</option>
                    <option value="20-30">20-30 USD per hour</option>
                    <option value="30-50">30-50 USD per hour</option>
                    <option value="50">50+ USD per hour</option>
                  </Input>
                </FormGroup>

                {/* ==== Job Function ==== */}
                <FormGroup className="mb-0 mr-2">
                  <Label for="exampleEmail">Job Function</Label>
                  <Input
                    type="select"
                    value={filters.jobFunction}
                    onChange={(e) =>
                      _onChangeFilters("jobFunction", e.target.value)
                    }
                  >
                    <option value="">All</option>
                    <option value="Sales">Sales</option>
                    <option value="Marketing">Marketing</option>
                    <option value="Engineering">Engineering</option>
                  </Input>
                </FormGroup>

                {/* ==== State of Work ==== */}
                <FormGroup className="mb-0 mr-2">
                  <Label for="exampleEmail">State of Work</Label>
                  <Input
                    type="select"
                    value={filters.stateOfWork}
                    onChange={(e) =>
                      _onChangeFilters("stateOfWork", e.target.value)
                    }
                  >
                    <option value="">All</option>
                    <option value="Remote">Remote</option>
                    <option value="Work from Office">Work from Office</option>
                    <option value="Hybrid">Hybrid</option>
                  </Input>
                </FormGroup>

                {/* ==== Education Requirements ==== */}
                <FormGroup className="mb-0">
                  <Label for="exampleEmail">Education Requirements</Label>
                  <Input
                    type="select"
                    value={filters.educationRequirement}
                    onChange={(e) =>
                      _onChangeFilters("educationRequirement", e.target.value)
                    }
                  >
                    <option value="">All</option>
                    <option value="High School">High School</option>
                    <option value="Graduate">Graduate</option>
                    <option value="Post Graduate">Post Graduate</option>
                    <option value="nono">None</option>
                  </Input>
                </FormGroup>
              </div>

              <div className="d-flex justify-content-end align-items-end">
                <FormGroup className="mb-0">
                  <Label>Search</Label>
                  <InputGroup>
                    <FormControl
                      type="text"
                      placeholder="Enter"
                      autoComplete="off"
                      value={filters.search}
                      onChange={(e) =>
                        _onChangeFilters("search", e.target.value)
                      }
                    />
                    <InputGroup.Text>
                      <i className="fas fa-search" />
                    </InputGroup.Text>
                  </InputGroup>
                </FormGroup>
                <Button
                  color="primary"
                  className="btn-clear ml-4"
                  onClick={() => _clearFilters()}
                >
                  <i className="fa fa-eraser fs-15" />
                </Button>
              </div>
            </div>
          </div>
        </Card>

        {/* ========= Table ======== */}
        <Table responsive className="check-switch  m-0">
          <thead>
            <tr>
              <th>Role</th>
              <th>Job Type</th>
              <th>State of work</th>
              <th>Pay Range</th>
              <th>Education Requirements</th>
              <th>Applied</th>
              <th>Saved</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {/* /jobs-view */}
                <Button
                  color="link"
                  className="text-second p-0"
                  onClick={() => history.push("/jobs-create?jobId=1234")}
                >
                  Web Designer
                </Button>
              </td>
              <td>Full Time</td>
              <td>In office</td>
              <td>$15-18</td>
              <td>Graduation</td>
              <td>70</td>
              <td>24</td>
              <td>
                <Input type="select" name="select" id="exampleSelect">
                  <option>Active</option>
                  <option>Inactive</option>
                </Input>
              </td>
            </tr>
            <tr>
              <td className="job-table-title">
                <Button
                  color="link"
                  className="text-second p-0"
                  onClick={() => history.push("/jobs-create?jobId=1234")}
                >
                  Web Developer
                </Button>
              </td>
              <td>Part Time</td>
              <td>Remote</td>
              <td>$25-30</td>
              <td>Graduation</td>
              <td>26</td>
              <td>8</td>
              <td>
                <Input type="select" name="select" id="exampleSelect">
                  <option>Active</option>
                  <option>Inactive</option>
                </Input>
              </td>
            </tr>
            <tr>
              <td>
                <Button
                  color="link"
                  className="text-second p-0"
                  onClick={() => history.push("/jobs-create?jobId=1234")}
                >
                  SEO
                </Button>
              </td>
              <td>Contract</td>
              <td>In office</td>
              <td>$12-15</td>
              <td>12th Pass</td>
              <td>40</td>
              <td>40</td>
              <td>
                <Input type="select" name="select" id="exampleSelect">
                  <option>Active</option>
                  <option>Inactive</option>
                </Input>
              </td>
            </tr>
          </tbody>
        </Table>

        {/* ======== Pagination ======== */}
        <div className="row mt-4">
          <div className="col-md-6">
            <span className="showng_entries">Showing 10 entries out of 25</span>
          </div>
          <div className="col-md-6">
            <ReactPaginate
              forcePage={0}
              className="pagination justify-content-end mb-2"
              breakLabel="..."
              nextLabel={<i className="fa fa-chevron-right" />}
              previousLabel={<i className="fa fa-chevron-left" />}
              previousClassName="previous"
              nextClassName="next"
              onPageChange={({ selected }) => {
                // _onPageChange(selected)
              }}
              pageRangeDisplayed={3}
              pageCount={Math.ceil(25 / 10)}
              pageClassName="page-item"
              renderOnZeroPageCount={null}
              containerClassName={"pagination"}
              previousLinkClassName={"page-link"}
              nextLinkClassName={"page-link"}
              activeClassName={"active"}
              disabledClassName={"page-item"}
            />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default JobsPage;
