import moment from "moment";

const county = [
  "Adams",
  "Allen",
  "Bartholomew",
  "Benton",
  "Blackford",
  "Boone",
  "Brown",
  "Carroll",
  "Cass",
  "Clark",
  "Clay",
  "Clinton",
  "Crawford",
  "Daviess",
  "Dearborn",
  "Decatur",
  "DeKalb",
  "Delaware",
  "Dubois",
  "Elkhart",
  "Fayette",
  "Floyd",
  "Fountain",
  "Franklin",
  "Fulton",
  "Gibson",
  "Grant",
  "Greene",
  "Hamilton",
  "Hancock",
  "Harrison",
  "Hendricks",
  "Henry",
  "Howard",
  "Huntington",
  "Jackson",
  "Jasper",
  "Jay",
  "Jefferson",
  "Jennings",
  "Johnson",
  "Knox",
  "Kosciusko",
  "LaGrange",
  "Lake",
  "LaPorte",
  "Lawrence",
  "Madison",
  "Marion",
  "Marshall",
  "Martin",
  "Miami",
  "Monroe",
  "Montgomery",
  "Morgan",
  "Newton",
  "Noble",
  "Ohio",
  "Orange",
  "Owen",
  "Parke",
  "Perry",
  "Pike",
  "Porter",
  "Posey",
  "Pulaski",
  "Putnam",
  "Randolph",
  "Ripley",
  "Rush",
  "St. Joseph",
  "Scott",
  "Shelby",
  "Spencer",
  "Starke",
  "Steuben",
  "Sullivan",
  "Switzerland",
  "Tippecanoe",
  "Tipton",
  "Union",
  "Vanderburgh",
  "Vermillion",
  "Vigo",
  "Wabash",
  "Warren",
  "Warrick",
  "Washington",
  "Wayne",
  "Wells",
  "White",
  "Whitley",
];
export const countyList = county.map((each) => {
  return {
    label: each,
    value: each,
  };
});

export const rolesAndPermissions = [
  { label: "Home Amenities", value: "HomeAmenities" },
  { label: "Team Members", value: "TeamMembers" },
  { label: "Feed", value: "Feed" },
  { label: "Requests", value: "Requests" },
];

export const assignToComplimentaryServicesConfig = [
  { label: "Front Desk", value: "FrontDesk" },
  { label: "House Keeping", value: "HouseKeeping" },
  { label: "Concierge", value: "Concierge" },
  { label: "In Room Dining", value: "InRoomDining" },
];

export const permissionsConfig = [
  { label: "View Amenity", value: "viewamenity" },
  { label: "Manage Amenity", value: "manageamenity" },
  { label: "Manage Settings", value: "managesettings" },
  { label: "Manage Team Member", value: "manageteam" },
  { label: "Manage Resort Details", value: "manageresort" },
  { label: "Manage Feed", value: "managefeed" },
  { label: "Manage Offer", value: "manageoffer" },
  { label: "Manage Subscription", value: "managesubscription" },
];

export const daysOfWeek = [
  { label: "Sunday", value: "sunday" },
  { label: "Monday", value: "monday" },
  { label: "Tuesday", value: "tuesday" },
  { label: "Wednesday", value: "wednesday" },
  { label: "Thursday", value: "thursday" },
  { label: "Friday", value: "friday" },
  { label: "Saturday", value: "saturday" },
];

export const requestStatusConfig = [
  { label: "Unassigned", value: "unassigned" },
  { label: "Pending", value: "pending" },
  { label: "In Progress", value: "inprogress" },
  { label: "Completed", value: "completed" },
  { label: "Cancelled", value: "cancelled" },
];

export const roomCountConfig = [
  { label: "0-50", value: { min: 0, max: 50 } },
  { label: "51-100", value: { min: 51, max: 100 } },
  { label: "101-250", value: { min: 101, max: 250 } },
  { label: "250+", value: { min: 250 } },
];

export const tripsCountConfig = [
  { label: "0", value: { min: 0, max: 0 } },
  { label: "1-5", value: { min: 1, max: 5 } },
  { label: "6-10", value: { min: 6, max: 10 } },
  { label: "10+", value: { min: 10 } },
];

export const subscriptionConfig = [
  { label: "Basic", value: "basic" },
  { label: "Plus", value: "plus" },
  { label: "Premium", value: "premium" },
];

export const MonthsInEngConfig = [
  { month: "01", short_name: "Jan", full_name: "January" },
  { month: "02", short_name: "Feb", full_name: "February" },
  { month: "03", short_name: "Mar", full_name: "March" },
  { month: "04", short_name: "Apr", full_name: "April" },
  { month: "05", short_name: "May", full_name: "May" },
  { month: "06", short_name: "Jun", full_name: "June" },
  { month: "07", short_name: "Jul", full_name: "July" },
  { month: "08", short_name: "Aug", full_name: "August" },
  { month: "09", short_name: "Sep", full_name: "September" },
  { month: "10", short_name: "Oct", full_name: "October" },
  { month: "11", short_name: "Nov", full_name: "November" },
  { month: "12", short_name: "Dec", full_name: "December" },
];

export const DaysOfWeekConfig = [
  { day: "01", short_name: "Sun", full_name: "Sunday" },
  { day: "02", short_name: "Mon", full_name: "Monday" },
  { day: "03", short_name: "Tue", full_name: "Tuesday" },
  { day: "04", short_name: "Wed", full_name: "Wednesday" },
  { day: "05", short_name: "Thu", full_name: "Thursday" },
  { day: "06", short_name: "Fri", full_name: "Friday" },
  { day: "07", short_name: "Sat", full_name: "Saturday" },
];

export const DateRangeInputSelectConfig = [
  {
    label: "Today",
    value: moment(),
    isValueForStartDate: false,
  },
  {
    label: "Tomorrow",
    value: moment().add(1, "days"),
    isValueForStartDate: false,
  },
  {
    label: "Yesterday",
    value: moment().subtract(1, "days"),
    isValueForStartDate: true,
  },
  {
    label: "Week",
    value: moment().add(1, "weeks"),
    isValueForStartDate: false,
  },
  {
    label: "Month",
    value: moment().add(1, "months"),
    isValueForStartDate: false,
  },
  {
    label: "Year",
    value: moment().add(1, "years"),
    isValueForStartDate: false,
  },
  {
    label: "Custom",
    value: moment(),
    isValueForStartDate: false,
  },
];

export const timeValues = [
  { label: "--:--", value: "" },
  { label: "12:00 AM", value: "00.00" },
  { label: "12:30 AM", value: "00.30" },
  { label: "01:00 AM", value: "1.00" },
  { label: "01:30 AM", value: "1.30" },
  { label: "02:00 AM", value: "2.00" },
  { label: "02:30 AM", value: "2.30" },
  { label: "03:00 AM", value: "3.00" },
  { label: "03:30 AM", value: "3.30" },
  { label: "04:00 AM", value: "4.00" },
  { label: "04:30 AM", value: "4.30" },
  { label: "05:00 AM", value: "5.00" },
  { label: "05:30 AM", value: "5.30" },
  { label: "06:00 AM", value: "6.00" },
  { label: "06:30 AM", value: "6.30" },
  { label: "07:00 AM", value: "7.00" },
  { label: "07:30 AM", value: "7.30" },
  { label: "08:00 AM", value: "8.00" },
  { label: "08:30 AM", value: "8.30" },
  { label: "09:00 AM", value: "9.00" },
  { label: "09:30 AM", value: "9.30" },
  { label: "10:00 AM", value: "10.00" },
  { label: "10:30 AM", value: "10.30" },
  { label: "11:00 AM", value: "11.00" },
  { label: "11:30 AM", value: "11.30" },
  { label: "12:00 PM", value: "12.00" },
  { label: "12:30 PM", value: "12.30" },
  { label: "01:00 PM", value: "13.00" },
  { label: "01:30 PM", value: "13.30" },
  { label: "02:00 PM", value: "14.00" },
  { label: "02:30 PM", value: "14.30" },
  { label: "03:00 PM", value: "15.00" },
  { label: "03:30 PM", value: "15.30" },
  { label: "04:00 PM", value: "16.00" },
  { label: "04:30 PM", value: "16.30" },
  { label: "05:00 PM", value: "17.00" },
  { label: "05:30 PM", value: "17.30" },
  { label: "06:00 PM", value: "18.00" },
  { label: "06:30 PM", value: "18.30" },
  { label: "07:00 PM", value: "19.00" },
  { label: "07:30 PM", value: "19.30" },
  { label: "08:00 PM", value: "20.00" },
  { label: "08:30 PM", value: "20.30" },
  { label: "09:00 PM", value: "21.00" },
  { label: "09:30 PM", value: "21.30" },
  { label: "10:00 PM", value: "22.00" },
  { label: "10:30 PM", value: "22.30" },
  { label: "11:00 PM", value: "23.00" },
  { label: "11:30 PM", value: "23.30" },
];
