import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Label,
  Input,
  FormGroup,
  InputGroup,
} from "reactstrap";
import TextEditor from "../../components/TextEditor";
import { useHistory } from "react-router-dom";
import { extractQueryParams, showToast } from "../../helper-methods";
import { RegexConfig } from "../../config/RegexConfig";

const JobsCreate = () => {
  const history = useHistory();

  const [job, setJob] = useState(null);

  const [formFields, setFormFields] = useState({
    title: {
      value: "",
      error: null,
      isDirty: false,
      isValidationRequired: true,
    },
    jobFunction: {
      value: "",
      error: null,
      isDirty: false,
      isValidationRequired: true,
    },
    jobType: {
      value: "",
      error: null,
      isDirty: false,
      isValidationRequired: true,
    },
    stateOfWork: {
      value: "",
      error: null,
      isDirty: false,
      isValidationRequired: true,
    },
    payRange: {
      value: "",
      error: null,
      isDirty: false,
      isValidationRequired: true,
    },
    educationRequirement: {
      value: "",
      error: null,
      isDirty: false,
      isValidationRequired: true,
    },
    applyJobLink: {
      value: "",
      error: null,
      isDirty: false,
      isValidationRequired: true,
    },
    description: {
      value: "",
      error: null,
      isDirty: false,
      isValidationRequired: true,
    },
  });

  const _validateFormFields = (newFormFields) => {
    return new Promise((resolve) => {
      let isFormValid = true;

      Object.keys(newFormFields).forEach((key) => {
        if (
          newFormFields[key].isDirty &&
          newFormFields[key].isValidationRequired
        ) {
          switch (key) {
            case "title":
            case "jobFunction":
            case "jobType":
            case "stateOfWork":
            case "payRange":
            case "educationRequirement":
            case "description": {
              if (newFormFields[key]?.value?.trim().length) {
                newFormFields[key].error = null;
                newFormFields[key].isDirty = false;
              } else {
                newFormFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "applyJobLink": {
              if (newFormFields[key]?.value?.trim().length) {
                if (
                  RegexConfig.url.test(
                    String(newFormFields[key].value).toLowerCase()
                  )
                ) {
                  newFormFields[key].error = null;
                  newFormFields[key].isDirty = false;
                } else {
                  newFormFields[key].error = "*Invalid link";
                  isFormValid = false;
                }
              } else {
                newFormFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            default:
          }
        }
      });

      setFormFields(newFormFields);

      resolve(isFormValid);
    });
  };

  const _onChangeFormFields = (key, value) => {
    const newFormFields = { ...formFields };

    newFormFields[key].value = value;
    newFormFields[key].isDirty = true;

    setFormFields(newFormFields);

    _validateFormFields(newFormFields);
  };

  const _onClickSave = async () => {
    try {
      const newFormFields = { ...formFields };
      Object.keys(newFormFields).forEach(
        (key) => (newFormFields[key].isDirty = true)
      );
      setFormFields(newFormFields);

      const isFormValid = await _validateFormFields(newFormFields);

      if (isFormValid) {
        console.log("api call", formFields);
      }
    } catch (error) {
      console.log("error>>", error);
      showToast(
        error?.reason?.length
          ? error.reason
          : "Something went wrong, Try again after sometime.",
        "error"
      );
    }
  };

  useEffect(() => {
    const { jobId } = extractQueryParams();

    if (jobId) setJob(jobId);
  }, []);

  return (
    <div className="content">
      <Container fluid className="mobilePadding-0">
        <div className="page_Inner_header">
          <Row className="align-items-center">
            <Col>
              <div className="page_title">
                <Button
                  color="link"
                  className="pl-0 pr-2"
                  onClick={() => history.goBack()}
                >
                  <i className="fa fa-chevron-left" />
                </Button>
                <h1 className="space_remove">{job ? "Update" : "Add"} Job</h1>
              </div>
            </Col>
          </Row>
        </div>

        {/* ======= Form Create Blog ====== */}
        <div className="innerForm">
          <Row>
            {/* ==== Job Title ==== */}
            <Col md="6">
              <FormGroup>
                <Label>Job Title</Label>
                <InputGroup>
                  <Input
                    type="text"
                    placeholder="Enter"
                    autoComplete="off"
                    value={formFields.title.value}
                    onChange={(e) =>
                      _onChangeFormFields("title", e.target.value)
                    }
                  />
                </InputGroup>
                {formFields.title.error ? (
                  <div className="form-error">{formFields.title.error}</div>
                ) : null}
              </FormGroup>
            </Col>
            {/* ==== Job Function ==== */}
            <Col md="6">
              <FormGroup>
                <Label>Job Function</Label>
                <InputGroup>
                  <Input
                    type="select"
                    value={formFields.jobFunction.value}
                    onChange={(e) =>
                      _onChangeFormFields("jobFunction", e.target.value)
                    }
                  >
                    <option value="">Select</option>
                    <option value="Sales">Sales</option>
                    <option value="Marketing">Marketing</option>
                    <option value="Engineering">Engineering</option>
                  </Input>
                </InputGroup>
                {formFields.jobFunction.error ? (
                  <div className="form-error">
                    {formFields.jobFunction.error}
                  </div>
                ) : null}
              </FormGroup>
            </Col>

            {/* ==== Job Type ==== */}
            <Col md="6">
              <FormGroup>
                <Label>Job Type</Label>
                <InputGroup>
                  <Input
                    type="select"
                    value={formFields.jobType.value}
                    onChange={(e) =>
                      _onChangeFormFields("jobType", e.target.value)
                    }
                  >
                    <option value="">Select</option>
                    <option value="Full Time">Full Time</option>
                    <option value="Part Time">Part Time</option>
                    <option value="Contract">Contract</option>
                  </Input>
                </InputGroup>
                {formFields.jobType.error ? (
                  <div className="form-error">{formFields.jobType.error}</div>
                ) : null}
              </FormGroup>
            </Col>

            {/* ==== State Of Work ==== */}
            <Col md="6">
              <FormGroup>
                <Label>State Of Work</Label>
                <InputGroup>
                  <Input
                    type="select"
                    value={formFields.stateOfWork.value}
                    onChange={(e) =>
                      _onChangeFormFields("stateOfWork", e.target.value)
                    }
                  >
                    <option value="">Select</option>
                    <option value="Remote">Remote</option>
                    <option value="Work from Office">Work from Office</option>
                    <option value="Hybrid">Hybrid</option>
                  </Input>
                </InputGroup>
                {formFields.stateOfWork.error ? (
                  <div className="form-error">
                    {formFields.stateOfWork.error}
                  </div>
                ) : null}
              </FormGroup>
            </Col>

            {/* ==== Pay Range ==== */}
            <Col md="4">
              <FormGroup>
                <Label>Pay Range</Label>
                <InputGroup>
                  <Input
                    type="text"
                    placeholder="Enter"
                    autoComplete="off"
                    value={formFields.payRange.value}
                    onChange={(e) =>
                      _onChangeFormFields("payRange", e.target.value)
                    }
                  />
                </InputGroup>
                {formFields.payRange.error ? (
                  <div className="form-error">{formFields.payRange.error}</div>
                ) : null}
              </FormGroup>
            </Col>

            {/* ==== Deg. Requirements ==== */}
            <Col md="4">
              <FormGroup>
                <Label>Education Requirements</Label>
                <InputGroup>
                  <Input
                    type="select"
                    value={formFields.educationRequirement.value}
                    onChange={(e) =>
                      _onChangeFormFields(
                        "educationRequirement",
                        e.target.value
                      )
                    }
                  >
                    <option value="">Select</option>
                    <option value="High School">High School</option>
                    <option value="Graduate">Graduate</option>
                    <option value="Post Graduate">Post Graduate</option>
                    <option value="nono">None</option>
                  </Input>
                </InputGroup>
                {formFields.educationRequirement.error ? (
                  <div className="form-error">
                    {formFields.educationRequirement.error}
                  </div>
                ) : null}
              </FormGroup>
            </Col>

            <Col md="4">
              <FormGroup>
                <Label>Apply Job Link</Label>
                <InputGroup>
                  <Input
                    type="text"
                    placeholder="Enter"
                    autoComplete="off"
                    value={formFields.applyJobLink.value}
                    onChange={(e) =>
                      _onChangeFormFields("applyJobLink", e.target.value)
                    }
                  />
                </InputGroup>
                {formFields.applyJobLink.error ? (
                  <div className="form-error">
                    {formFields.applyJobLink.error}
                  </div>
                ) : null}
              </FormGroup>
            </Col>
          </Row>

          {/* ==== Job Description ==== */}
          <FormGroup>
            <Label>Job Description</Label>
            <TextEditor
              content={formFields.description.value}
              onChange={(text) => _onChangeFormFields("description", text)}
            />
            {formFields.description.error ? (
              <div className="form-error">{formFields.description.error}</div>
            ) : null}
          </FormGroup>

          {/* ==== Publish ==== */}
          <div className="d-flex justify-content-center">
            <Button
              color="primary"
              className="mt-5 w-25"
              onClick={() => _onClickSave()}
            >
              {job ? "Update" : "Save"}
            </Button>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default JobsCreate;
