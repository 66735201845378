import { sleepTime } from '../helper-methods/index';

export default class OneSignalHelper {
  static showPrompt = () => {
    window.OneSignal.push(function() {
      window.OneSignal.showSlidedownPrompt();
    });
  };

  static getNotificationPermission = () => {
      return new Promise((resolve, reject) => {
          
          window.OneSignal.push([
              "isPushNotificationsEnabled",
              isEnabled => {
                  resolve(isEnabled);
        }
      ]);
    });
  };

  static onSubscriptionEnable = (callback) => {
    window.OneSignal.push(() => {
        window.OneSignal.on("subscriptionChange", isSubscribed => {
          if (isSubscribed) {
            callback();
          }
        });
      });
  }
  static getDeviceId = () => {
    return new Promise((resolve, reject) => {
      window.OneSignal.getUserId(function(userId) {
        resolve(userId);    
      });
      sleepTime(1500).then(() => {resolve(null)});
    });
  };
}