import {
  makeGetRequest,
  makePostRequest,
  makePutRequest,
  // uploadFile,
  makeDeleteRequest,
  // uploadFileMultiPart
} from "./http-service";
import { BASE_URL } from "../config/index";

export const login = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/login`, false, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const forgotPassword = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/forgotpassword`, false, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const requestInvitation = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/request/invitation`, false, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

//oneSignal
export const getIpData = () => {
  return new Promise((resolve, reject) => {
    fetch("https://ipapi.co/json/", {
      method: "GET",
    })
      .then((res) => {
        resolve(res.json());
      })
      .catch((e) => {
        console.log("getIpData call error: ", e);
        reject(e);
      });
  });
};

//service
export const fetchServices = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/services`, false, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

//service
export const createProvider = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/provider/add`, false, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const fetchProvider = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/provider`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const updateProvider = (payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(`${BASE_URL}/provider/update`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};


//service
export const userService = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/search`, false, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};


export const duplicate = (field, payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/duplicate/check/" +field, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};



// service List click to store data into backend
export const phoneEmailUrlClickCount = (payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(`${BASE_URL}/click`, false, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};
/**
 * @param {string} platform - google or facebook
 * @param {object} payload - {accessToken: google or facefook response token}
 * @returns
 */
export const socialLogin = (platform, payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/${platform}/signup`, false, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const checkAvailability = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/user/unique`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getAllAmenities = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/amenities`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const createAmenity = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/amenity`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const updateAmenity = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(`${BASE_URL}/amenity/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getAmenityById = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/amenity/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const createResortTeamMember = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/resort/teammember`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getAllResortTeamMember = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/resort/teammembers`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getResortTeamMemberById = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/resort/teammember/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const updateResortTeamMember = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(`${BASE_URL}/resort/teammember/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getSettings = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/settings`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getAllGroups = (payload) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/resort/groups`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const createChatGroup = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/resort/create/group`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const updateChatGroup = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(`${BASE_URL}/resort/group/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const removeParticipantFromGroup = (groupId, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(
      `${BASE_URL}/resort/participant/remove/${groupId}`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

// get chat group member
export const getChatGroupMemberDetails = (payload) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/resort/get/vacationer/booking`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

// update chat group member
export const updateChatGroupMemberDetails = (payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(
      `${BASE_URL}/resort/update/vacationer/booking`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getAllGroupMembers = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/resort/group/participants`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getAllMessagesByGroupId = (groupId, payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      `${BASE_URL}/resort/group/messages/${groupId}`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const deleteMessageById = (messageId, payload) => {
  return new Promise((resolve, reject) => {
    makeDeleteRequest(
      `${BASE_URL}/delete/group/message/${messageId}`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const updateMessageById = (messageId, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(
      `${BASE_URL}/update/group/message/${messageId}`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getAllRequests = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/resort/bookings`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getAllOffers = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/resort/offers`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const createOffer = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/resort/offer`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getOfferById = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/resort/offer/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const updateOffer = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(`${BASE_URL}/resort/offer/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

// Resort get amenities List
export const getAmenitiesList = (payload) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/resort/list/amenity`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getRequestById = (requestId) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/resort/booking/${requestId}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const updateRequest = (requestId, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(`${BASE_URL}/resort/booking/${requestId}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getAllMessagesByRequestId = (requestId, payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      `${BASE_URL}/resort/booking/messages/${requestId}`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

// Resort Admin Get All previous Bookings of specific vacationer
export const getAllPreviousRequest = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/resort/bookings/vacationer`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

// Resort get resortadmin list reagrding booking (Assignee List)
export const getAllAssigneeList = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/resort/list/booking/resort-admin`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

// Resort Admin get all Its own services list
export const getAllServicesList = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/resort/list/services`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

// =============================== admin api =============================== //

// Get a Admin Profile details
export const getLoggedInUserDetail = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/get/profile-details`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

// Admin update Profile details
export const updateLoggedInUserDetail = (payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(`${BASE_URL}/update/profile-details`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

// Admin get all Resorts
export const getAllResorts = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/resorts`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

// Admin update a Resort by id
export const updateResortById = (resortId, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(`${BASE_URL}/resort/update/${resortId}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const createTeamMember = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/teammember`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getAllTeamMember = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/teammembers`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getTeamMemberById = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/teammember/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const updateTeamMember = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(`${BASE_URL}/teammember/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const updateComplimentaryServices = (payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(`${BASE_URL}/setting/update/services`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const addAmenityCategories = (payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(`${BASE_URL}/setting/update/add/amenity`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const updateAmenityCategories = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(
      `${BASE_URL}/setting/update/edit/amenity/${id}`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const removeAmenityCategories = (payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(`${BASE_URL}/setting/update/remove/amenity`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getAllVacationers = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/vacationers`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const updateVacationerById = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(`${BASE_URL}/vacationer/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getVacationerTrips = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/vacationer/get/trips`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getResortSettings = (resortId) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/resort/get/settings/${resortId}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const updateResortComplimentaryServices = (resortId, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(
      `${BASE_URL}/resort/update/settings/services/${resortId}`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const addResortAmenityCategories = (resortId, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(
      `${BASE_URL}/resort/update/settings/add/amenity/${resortId}`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const updateResortAmenityCategories = (resortId, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(
      `${BASE_URL}/resort/update/settings/edit/amenity/${resortId}`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const removeResortAmenityCategories = (resortId, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(
      `${BASE_URL}/resort/update/settings/remove/amenity/${resortId}`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getResortDetails = (resortId) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/resort/get/resort-details/${resortId}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const updateResortDetails = (payload, resortId) => {
  return new Promise((resolve, reject) => {
    makePutRequest(
      `${BASE_URL}/resort/update/resort-details/${resortId}`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const sendEmailToUser = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/users/emails`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};
