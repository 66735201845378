/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Card,
  CardBody,
  CardHeader,
} from "reactstrap";
import Chart from "react-apexcharts";
import {
  DaysOfWeekConfig,
  MonthsInEngConfig,
} from "../../config/helper-config";
import CustomDateRangePicker from "../../components/CustomDateRangePicker";
import moment from "moment";

const KpiDashboard = () => {
  // #1.1
  const [resortsGraph, setResortsGraph] = useState({
    lineOptions: {
      chart: {
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        labels: {
          style: {
            colors: "#247BA0",
          },
        },
      },
      xaxis: {
        categories: MonthsInEngConfig.map((each) => each.short_name),
      },
      colors: ["#247BA0"],
      tooltip: {
        shared: false,
        x: {
          show: false,
        },
      },
    },
    lineSeries: [
      {
        name: "# Resorts",
        data: [0, 1, 2, 4, 6, 9, 11, 15, 22, 28, 29, 31],
      },
    ],
  });
  // #1.2
  const [registeredResortsGraph, setregisteredResortsGraph] = useState({
    barOptions: {
      chart: {
        zoom: {
          enabled: false,
        },
        type: "bar",
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        labels: {
          style: {
            colors: "#247BA0",
          },
        },
      },
      xaxis: {
        categories: [
          "1-50 Rooms",
          "51-100 Rooms",
          "101-150 Rooms",
          "151-200 Rooms",
          "201-250 Rooms",
          "251-300 Rooms",
          "301-350 Rooms",
          "351-400 Rooms",
          "401-450 Rooms",
          "451-500 Rooms",
          "500+ Rooms",
        ],
      },
      colors: ["#247BA0"],
      tooltip: {
        shared: false,
        x: {
          show: false,
        },
      },
    },
    barSeries: [
      {
        name: "# Resorts",
        data: [4, 2, 2, 6, 17, 8, 3, 1, 5, 1, 7],
      },
    ],
  });
  // #2.1
  const [activeUsersGraph, setActiveUsersGraph] = useState({
    barOptions: {
      chart: {
        zoom: {
          enabled: false,
        },
        type: "bar",
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        labels: {
          style: {
            colors: "#FF1654",
          },
        },
      },
      xaxis: {
        categories: MonthsInEngConfig.map((each) => each.short_name),
      },
      colors: ["#FF1654"],
      tooltip: {
        shared: false,
        x: {
          show: false,
        },
      },
    },
    barSeries: [
      {
        name: "# Active Users",
        data: [120, 140, 160, 180, 210, 255, 291, 349, 399, 456, 628, 727],
      },
    ],
  });
  // #2.2
  const [activeUsersAgeGraph, setActiveUsersAgeGraph] = useState({
    barOptions: {
      chart: {
        zoom: {
          enabled: false,
        },
        type: "bar",
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        labels: {
          style: {
            colors: "#FF1654",
          },
        },
      },
      xaxis: {
        categories: [
          "Not Given",
          "18 - 29",
          "30 - 44",
          "45 - 59",
          "60 - 74",
          "75+",
        ],
      },
      colors: ["#FF1654"],
      tooltip: {
        shared: false,
        x: {
          show: false,
        },
      },
    },
    barSeries: [
      {
        name: "# Active Users",
        data: [268, 884, 945, 938, 571, 465],
      },
    ],
  });
  // #3
  const [activeOutingsGraph, setActiveOutingsGraph] = useState({
    lineOptions: {
      chart: {
        zoom: {
          enabled: false,
        },
        type: "line",
      },
      stroke: {
        width: [0, 4],
        curve: "smooth",
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: [
        {
          labels: {
            style: {
              colors: "#247BA0",
            },
          },
          title: {
            text: "# Page Views",
          },
        },
        {
          opposite: true,
          labels: {
            formatter: function (value) {
              return `${value}%`;
            },
            style: {
              colors: "#FF1654",
            },
          },
          title: {
            text: "Click Through Rate",
          },
        },
      ],
      xaxis: {
        categories: DaysOfWeekConfig.map((each) => each.short_name),
      },
      colors: ["#247BA0", "#FF1654"],
      tooltip: {
        // shared: false,
        x: {
          show: false,
        },
      },
    },
    lineSeries: [
      {
        name: "# Page Views",
        type: "column",
        data: [220, 180, 340, 440, 260, 280, 320],
      },
      {
        name: "Click Through Rate",
        type: "line",
        data: [5, 7, 9, 5, 14, 11, 15],
      },
    ],
  });
  // #4
  const [activeOnChatGraph, setActiveOnChatGraph] = useState({
    lineOptions: {
      chart: {
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        tickAmount: 4,
        min: 0,
        max: 100,
        labels: {
          formatter: function (value) {
            return `${value}%`;
          },
          style: {
            colors: "#FF1654",
          },
        },
      },
      xaxis: {
        categories: DaysOfWeekConfig.map((each) => each.short_name),
      },
      colors: ["#FF1654"],
      tooltip: {
        shared: false,
        x: {
          show: false,
        },
      },
    },
    lineSeries: [
      {
        name: "% Using Chat",
        data: [25, 50, 38, 29, 67, 40, 45],
      },
    ],
  });
  // #5.1
  const [resortServiceBookingsGraph, setResortServiceBookingsGraph] = useState({
    lineOptions: {
      chart: {
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        tickAmount: 4,
        min: 0,
        max: 100,
        labels: {
          formatter: function (value) {
            return `${value}%`;
          },
          style: {
            colors: "#247BA0",
          },
        },
      },
      xaxis: {
        categories: DaysOfWeekConfig.map((each) => each.short_name),
      },
      colors: ["#247BA0"],
      tooltip: {
        shared: false,
        x: {
          show: false,
        },
      },
    },
    lineSeries: [
      {
        name: "% Booking Resort Services",
        data: [25, 50, 38, 29, 67, 40, 45],
      },
    ],
  });
  // #5.2
  const [resortServiceBookingsDonutGraph, setResortServiceBookingsDonutGraph] =
    useState({
      donutOptions: {
        plotOptions: {
          pie: {
            expandOnClick: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        labels: [
          "% Booking 1 Service",
          "% Booking 2 Service",
          "% Booking 3 Service",
          "% Booking 4 Service",
          "% Booking 5+ Service",
        ],
        tooltip: {
          y: {
            formatter: function (value) {
              return `${value}%`;
            },
          },
        },
        colors: ["#008ffb", "#FF1654", "#feb019", "#F1512E", "#255aee"],
      },
      donutSeries: [25, 50, 38, 29, 67],
    });
  // #6.1
  const [eConciergeServiceBarGraph, setEConciergeServiceBarGraph] = useState({
    barOptions: {
      chart: {
        zoom: {
          enabled: false,
        },
        stacked: true,
        type: "bar",
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {},
      xaxis: {
        categories: DaysOfWeekConfig.map((each) => each.short_name),
      },
      colors: ["#008ffb", "#FF1654", "#feb019", "#F1512E", "#255aee"],
      tooltip: {
        shared: false,
        x: {
          show: false,
        },
      },
    },
    barSeries: [
      {
        name: "Front Desk",
        data: [110, 140, 120, 180, 160, 140, 120],
      },
      {
        name: "House Keeping",
        data: [240, 520, 140, 180, 460, 520, 640],
      },
      {
        name: "Concierge Desk",
        data: [40, 50, 45, 37, 18, 21, 74],
      },
      {
        name: "In-Room Dining",
        data: [220, 180, 340, 440, 260, 280, 320],
      },
    ],
  });
  // #6.2
  const [eConciergeServiceGraph, setEConciergeServiceGraph] = useState({
    lineOptions: {
      chart: {
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        tickAmount: 4,
        min: 0,
        max: 100,
        labels: {
          formatter: function (value) {
            return `${value}%`;
          },
          style: {
            colors: "#FF1654",
          },
        },
      },
      xaxis: {
        categories: DaysOfWeekConfig.map((each) => each.short_name),
      },
      colors: ["#FF1654"],
      tooltip: {
        shared: false,
        x: {
          show: false,
        },
      },
    },
    lineSeries: [
      {
        name: "eConcierge Service",
        data: [25, 50, 38, 29, 67, 40, 45],
      },
    ],
  });

  const [filters, setFilters] = useState({
    dateRange: {
      startDate: null,
      endDate: null,
      maxDate: null,
    },
  });

  const _resetDateRangeFilters = () => {
    const newFilters = { ...filters };

    let to_date = moment().endOf("week");

    if (moment().isSame(to_date, "day")) {
      let from_date = moment().startOf("week");

      _onDatesChange(from_date, to_date);
    } else {
      let from_date = moment().subtract(1, "weeks").startOf("week");
      to_date = moment().subtract(1, "weeks").endOf("week");

      _onDatesChange(from_date, to_date);
    }

    newFilters.dateRange["maxDate"] = to_date;

    setFilters(newFilters);
  };

  const _onDatesChange = (startDate, endDate) => {
    const newFilters = { ...filters };

    if (startDate) startDate = moment(startDate).startOf("week");
    if (endDate) endDate = moment(endDate).endOf("week");

    newFilters.dateRange["startDate"] = startDate;
    newFilters.dateRange["endDate"] = endDate;

    setFilters(newFilters);
  };

  useEffect(() => {
    _resetDateRangeFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="content">
      <Container fluid className="mobilePadding-0">
        <div className="page_Inner_header">
          <Row className="align-items-center">
            <Col xl={5} md={4}>
              <div className="page_title">
                <h1 className="space_remove pr-2">KPIs</h1>
              </div>
            </Col>
          </Row>
        </div>

        {/* ======== Filter ======= */}
        <div className="mt-4 main_inner bg-white">
          <Row>
            {/* handle without date range value */}
            {/* #1.1 */}
            <Col md="6">
              <Card className="cardGraph">
                <CardHeader># Acitve Registered Resorts</CardHeader>
                <CardBody>
                  {resortsGraph.lineSeries[0]?.data?.length ? (
                    <Chart
                      options={resortsGraph.lineOptions}
                      series={resortsGraph.lineSeries}
                      type="area"
                      width="100%"
                      height="400"
                      className="chartWrap"
                    />
                  ) : null}
                </CardBody>
              </Card>
            </Col>

            {/* #1.2 */}
            <Col md="6">
              <Card className="cardGraph">
                {/* <CardHeader>Number of Resorts</CardHeader> */}
                <CardBody>
                  {registeredResortsGraph.barSeries[0]?.data?.length ? (
                    <Chart
                      options={registeredResortsGraph.barOptions}
                      series={registeredResortsGraph.barSeries}
                      type="bar"
                      width="100%"
                      height="400"
                      className="chartWrap"
                    />
                  ) : null}
                </CardBody>
              </Card>
            </Col>

            {/* handle without date range value */}
            {/* #2.1 */}
            <Col md="6">
              <Card className="cardGraph">
                <CardHeader># Active Users</CardHeader>
                <CardBody>
                  {activeUsersGraph.barSeries[0]?.data?.length ? (
                    <Chart
                      options={activeUsersGraph.barOptions}
                      series={activeUsersGraph.barSeries}
                      type="bar"
                      width="100%"
                      height="400"
                      className="chartWrap"
                    />
                  ) : null}
                </CardBody>
              </Card>
            </Col>

            {/* #2.2 */}
            <Col md="6">
              <Card className="cardGraph">
                {/* <CardHeader>Active Users</CardHeader> */}
                <CardBody>
                  {activeUsersAgeGraph.barSeries[0]?.data?.length ? (
                    <Chart
                      options={activeUsersAgeGraph.barOptions}
                      series={activeUsersAgeGraph.barSeries}
                      type="bar"
                      width="100%"
                      height="400"
                      className="chartWrap"
                    />
                  ) : null}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        <div className="mt-4 main_inner gridBox bg-white">
          <Row>
            {/* date range filter for next 4 graph */}
            <Col md="12">
              <div className="filterDashboard">
                <FormGroup className="mb-0">
                  <Label>Select Period</Label>
                  <CustomDateRangePicker
                    dateRange={filters.dateRange}
                    startDate={filters.dateRange.startDate}
                    endDate={filters.dateRange.endDate}
                    maxDate={filters.dateRange.maxDate}
                    startDateId={"startDate_kpi_dashboard"}
                    endDateId={`endDate_kpi_dashboard`}
                    isDisabledOutsideRange={true}
                    isMaxDateValidation={true}
                    onDatesChange={(startDate, endDate) =>
                      _onDatesChange(startDate, endDate)
                    }
                  />
                  <Button
                    color="primary"
                    className="btn-clear ml-4"
                    onClick={() => _resetDateRangeFilters()}
                  >
                    <i className="fa fa-refresh fs-15" />
                  </Button>
                </FormGroup>
              </div>
            </Col>

            {/* #3 */}
            <Col md="6">
              <Card className="cardGraph">
                <CardHeader>Outings</CardHeader>
                <CardBody>
                  {activeOutingsGraph.lineSeries[0]?.data?.length ? (
                    <Chart
                      options={activeOutingsGraph.lineOptions}
                      series={activeOutingsGraph.lineSeries}
                      type="line"
                      width="100%"
                      height="400"
                      className="chartWrap"
                    />
                  ) : null}
                </CardBody>
              </Card>
            </Col>

            {/* #4 */}
            <Col md="6">
              <Card className="cardGraph">
                <CardHeader>% Using Chat</CardHeader>
                <CardBody>
                  {activeOnChatGraph.lineSeries[0]?.data?.length ? (
                    <Chart
                      options={activeOnChatGraph.lineOptions}
                      series={activeOnChatGraph.lineSeries}
                      type="area"
                      width="100%"
                      height="400"
                      className="chartWrap"
                    />
                  ) : null}
                </CardBody>
              </Card>
            </Col>

            {/* #5.1 */}
            <Col md="6">
              <Card className="cardGraph">
                <CardHeader>% Booking Resort Services</CardHeader>
                <CardBody>
                  {resortServiceBookingsGraph.lineSeries[0]?.data?.length ? (
                    <Chart
                      options={resortServiceBookingsGraph.lineOptions}
                      series={resortServiceBookingsGraph.lineSeries}
                      type="area"
                      width="100%"
                      height="400"
                      className="chartWrap"
                    />
                  ) : null}
                </CardBody>
              </Card>
            </Col>

            {/* #5.2 */}
            <Col md="6">
              <Card className="cardGraph">
                {/* <CardHeader>% Booking Resort Services</CardHeader> */}
                <CardBody>
                  {resortServiceBookingsDonutGraph.donutSeries?.length ? (
                    <Chart
                      options={resortServiceBookingsDonutGraph.donutOptions}
                      series={resortServiceBookingsDonutGraph.donutSeries}
                      type="donut"
                      width="100%"
                      height="400"
                      className="chartWrap"
                    />
                  ) : null}
                </CardBody>
              </Card>
            </Col>

            {/* #6.1 */}
            <Col md="6">
              <Card className="cardGraph">
                <CardHeader># Guest Using eConcierge</CardHeader>
                <CardBody>
                  {eConciergeServiceBarGraph.barSeries[0]?.data?.length ? (
                    <Chart
                      options={eConciergeServiceBarGraph.barOptions}
                      series={eConciergeServiceBarGraph.barSeries}
                      type="bar"
                      width="100%"
                      height="400"
                      className="chartWrap"
                    />
                  ) : null}
                </CardBody>
              </Card>
            </Col>

            {/* #6.2 */}
            <Col md="6">
              <Card className="cardGraph">
                <CardHeader>% Active Users Booking eConcierge</CardHeader>
                <CardBody>
                  {eConciergeServiceGraph.lineSeries[0]?.data?.length ? (
                    <Chart
                      options={eConciergeServiceGraph.lineOptions}
                      series={eConciergeServiceGraph.lineSeries}
                      type="area"
                      width="100%"
                      height="400"
                      className="chartWrap"
                    />
                  ) : null}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default KpiDashboard;
