import React, { useEffect, useState } from "react";
import {
  Label,
  Form,
  FormGroup,
  Input,
  Table,
  CustomInput,
  InputGroupAddon,
  InputGroupText,
  Spinner,
  Tooltip,
} from "reactstrap";
import {
  Button,
  InputGroup,
  FormControl,
  Card,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import TextEditor from "../../components/TextEditor";
// import { useHistory } from "react-router-dom";
import {
  getAllResorts,
  sendEmailToUser,
  updateResortById,
} from "../../http/http-calls";
import {
  capitalize,
  formatDate,
  formatPhoneNumber,
  showToast,
  copyToClipboard,
} from "../../helper-methods";
import {
  subscriptionConfig,
  roomCountConfig,
} from "../../config/helper-config";
import ReactPaginate from "react-paginate";
import { DEFAULT_PROFILE_PICTURE } from "../../config";
import ResortRequestModal from "../../components/Modals/resort-request-modal";

let searchInterval;

const Resorts = () => {
  // const history = useHistory();

  const locations = ["Florida", "Massachusetts", "New Mexico", "New Jersey"];

  const [isShowTooltip, setIsShowTooltip] = useState({
    email: false,
    phone: false,
    comments: false,
  });

  const [resortRequestModal, setResortRequestModal] = useState({
    isOpen: false,
  });

  const [filters, setFilters] = useState({
    roomCount: "",
    subscription: "",
    location: "",
    isResortActivated: "",
  });
  const [getAllResortsPayload, setGetAllResortsPayload] = useState({
    filters: {},
    search: "",
    skip: 0,
    limit: 10,
    pageNumber: 0,
  });
  const [resorts, setResorts] = useState([]);
  const [resortsCount, setResortsCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [statusToogleLoading, setStatusToogleLoading] = useState(false);
  const [resortVerificationLoading, setResortVerificationLoading] =
    useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [filtersLoading, setFiltersLoading] = useState(false);

  const [selectedResortIds, setSelectedResortIds] = useState([]);

  const [emailFormField, setEmailFormField] = useState({
    subject: "",
    text: "",
  });
  const [sendEmailLoading, setSendEmailLoading] = useState(false);

  const _toggleResortRequestModal = (isOpen = false) => {
    setResortRequestModal({
      isOpen,
    });
  };

  const _getAllResorts = (payload) => {
    setIsShowTooltip({
      email: false,
      phone: false,
      comments: false,
    });
    setLoading(true);

    getAllResorts(payload)
      .then((res) => {
        setResorts(res.resorts);
        setResortsCount(res.count);
        setLoading(false);
        setSearchLoading(false);
        setFiltersLoading(false);
      })
      .catch((error) => {
        console.log("error>>", error);
        setLoading(false);
        setSearchLoading(false);
        setFiltersLoading(false);
        showToast(
          error?.reason?.length
            ? error.reason
            : "Server error, Try again after sometime.",
          "error"
        );
      });
  };

  const _onChangeFilters = (key, value) => {
    setFiltersLoading(true);

    const newFilters = { ...filters };
    newFilters[key] = value;
    setFilters(newFilters);

    const newGetAllResortsPayload = { ...getAllResortsPayload };
    newGetAllResortsPayload["skip"] = 0;
    newGetAllResortsPayload["pageNumber"] = 0;
    newGetAllResortsPayload["filters"] = {};

    Object.keys(newFilters).forEach((eachFilterKey) => {
      if (newFilters[eachFilterKey]) {
        if (eachFilterKey === "isResortActivated") {
          newGetAllResortsPayload.filters[eachFilterKey] =
            newFilters[eachFilterKey] === "pending" ? false : true;
        } else {
          try {
            newGetAllResortsPayload.filters[eachFilterKey] = JSON.parse(
              newFilters[eachFilterKey]
            );
          } catch (error) {
            newGetAllResortsPayload.filters[eachFilterKey] =
              newFilters[eachFilterKey];
          }
        }
      }
    });

    setGetAllResortsPayload(newGetAllResortsPayload);

    setSelectedResortIds([]);

    _getAllResorts(newGetAllResortsPayload);
  };

  const _clearFilters = () => {
    setFiltersLoading(true);

    const newFilters = { ...filters };
    Object.keys(newFilters).forEach((key) => {
      newFilters[key] = "";
    });
    setFilters(newFilters);

    const newGetAllResortsPayload = { ...getAllResortsPayload };
    newGetAllResortsPayload["search"] = "";
    newGetAllResortsPayload["skip"] = 0;
    newGetAllResortsPayload["pageNumber"] = 0;
    newGetAllResortsPayload["filters"] = {};
    setGetAllResortsPayload(newGetAllResortsPayload);

    setSelectedResortIds([]);

    _getAllResorts(newGetAllResortsPayload);
  };

  const _onPageChange = (pageNumber = 0) => {
    const newGetAllResortsPayload = { ...getAllResortsPayload };
    newGetAllResortsPayload["skip"] =
      pageNumber * newGetAllResortsPayload["limit"];
    newGetAllResortsPayload["pageNumber"] = pageNumber;
    setGetAllResortsPayload(newGetAllResortsPayload);
    _getAllResorts(newGetAllResortsPayload);
  };

  const _onChangeSearch = (searchValue) => {
    clearInterval(searchInterval);
    setSearchLoading(true);
    const newGetAllResortsPayload = { ...getAllResortsPayload };
    newGetAllResortsPayload["search"] = searchValue;
    newGetAllResortsPayload["skip"] = 0;
    newGetAllResortsPayload["pageNumber"] = 0;
    setGetAllResortsPayload(newGetAllResortsPayload);
    searchInterval = setTimeout(() => {
      setSelectedResortIds([]);
      _getAllResorts(newGetAllResortsPayload);
    }, 1000);
  };

  const _updateResortById = (resortId, payload) => {
    return new Promise((resolve, reject) => {
      updateResortById(resortId, payload)
        .then((res) => resolve(res))
        .catch((error) => reject(error));
    });
  };

  const _toggleResortStatus = async (resort, status = true) => {
    try {
      const newResorts = [...resorts];
      const findResort = newResorts.find((each) => each.id === resort.id);
      if (findResort) {
        setStatusToogleLoading(true);

        findResort["isActive"] = status;
        setResorts(newResorts);

        await _updateResortById(resort.id, { isActive: status ? true : false });

        showToast("Status updated", "success");

        setStatusToogleLoading(false);
      } else {
        showToast("Something went wrong, Try again after sometime.", "error");
      }
    } catch (error) {
      console.log("error>>", error);
      setStatusToogleLoading(false);
      _getAllResorts(getAllResortsPayload);
      showToast(
        error?.reason?.length
          ? error.reason
          : "Server error, Try again after sometime.",
        "error"
      );
    }
  };

  const _acceptResortInvitation = async (resort) => {
    try {
      const newResorts = [...resorts];
      const findResort = newResorts.find((each) => each.id === resort.id);
      if (findResort) {
        setResortVerificationLoading(resort.id);

        findResort["isResortActivated"] = true;
        setResorts(newResorts);

        await _updateResortById(resort.id, { isResortActivated: true });

        showToast("Resort verified successfully", "success");

        setResortVerificationLoading(false);
      } else {
        showToast("Something went wrong, Try again after sometime.", "error");
      }
    } catch (error) {
      console.log("error>>", error);
      setResortVerificationLoading(false);
      _getAllResorts(getAllResortsPayload);
      showToast(
        error?.reason?.length
          ? error.reason
          : "Server error, Try again after sometime.",
        "error"
      );
    }
  };

  const _isAllResortSelected = () => {
    if (resorts?.length && selectedResortIds?.length) {
      let isAllSelected = true;

      for (let resort of resorts) {
        if (
          !selectedResortIds.find(
            (each) =>
              resort?._resort?.length && each === resort?._resort[0]?._id
          )
        ) {
          isAllSelected = false;
          break;
        }
      }

      return isAllSelected;
    } else {
      return false;
    }
  };

  const _toggleAllResortSelection = (checked = true) => {
    if (checked) {
      let newSelectedResortIds = [];
      newSelectedResortIds = resorts?.length
        ? resorts
            .map((resort) =>
              resort?._resort?.length && resort?._resort[0]?._id
                ? resort?._resort[0]?._id
                : ""
            )
            .filter((each) => each)
        : [];
      setSelectedResortIds(newSelectedResortIds);
    } else {
      setSelectedResortIds([]);
    }
  };

  const _isResortSelected = (resort) => {
    return selectedResortIds?.length
      ? selectedResortIds.find(
          (each) => resort?._resort?.length && each === resort?._resort[0]?._id
        )
      : false;
  };

  const _toggleResortSelection = (resort, checked = true) => {
    let newSelectedResortIds = [...selectedResortIds];
    if (checked) {
      if (resort?._resort?.length && resort?._resort[0]?._id) {
        newSelectedResortIds.push(resort?._resort[0]?._id);
        setSelectedResortIds(newSelectedResortIds);
      } else {
        showToast("POC not found", "error");
        return;
      }
    } else {
      if (resort?._resort?.length && resort?._resort[0]?._id) {
        newSelectedResortIds = newSelectedResortIds.filter(
          (each) => each !== resort?._resort[0]?._id
        );
        setSelectedResortIds(newSelectedResortIds);
      } else {
        showToast("POC not found", "error");
        return;
      }
    }
  };

  const _toggleTooltip = (key, id = false) => {
    const newIsShowTooltip = { ...isShowTooltip };

    if (newIsShowTooltip[key] !== id) newIsShowTooltip[key] = id;
    else newIsShowTooltip[key] = false;

    setIsShowTooltip(newIsShowTooltip);
  };

  const _sendEmailToUser = () => {
    const payload = {
      idlist: selectedResortIds?.length ? selectedResortIds : [],
      subject: emailFormField?.subject?.trim().length
        ? emailFormField.subject.trim()
        : "",
      text: emailFormField?.text?.trim().length
        ? emailFormField.text.trim()
        : "",
    };

    if (!payload.idlist.length) {
      showToast("Please select atleast one user.", "error");
      return;
    }
    if (!payload.subject.length) {
      showToast("Please write some subject.", "error");
      return;
    }
    if (!payload.text.length) {
      showToast("Please write some message.", "error");
      return;
    }

    setSendEmailLoading(true);

    sendEmailToUser(payload)
      .then((res) => {
        showToast("Email sent successfully.", "success");

        setSelectedResortIds([]);
        setEmailFormField({
          subject: "",
          text: "",
        });
        setSendEmailLoading(false);
      })
      .catch((error) => {
        console.log("error>>", error);
        setSendEmailLoading(false);
        showToast(
          error?.reason?.length
            ? error.reason
            : "Server error, Try again after sometime.",
          "error"
        );
      });
  };

  useEffect(() => {
    _getAllResorts(getAllResortsPayload);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="content resort">
        <Container fluid className="mobilePadding-0">
          <div className="page_Inner_header">
            <Row className="align-items-center">
              <Col xl={5} md={4}>
                <div className="page_title">
                  <h1 className="space_remove">
                    Resorts {loading || filtersLoading ? <Spinner /> : null}
                  </h1>
                </div>
              </Col>
            </Row>
          </div>

          {/* ======== Filter ======= */}
          <Card body className="search-filter pt-2 pb-3 mb-4">
            <Form>
              <div className="d-flex justify-content-between align-items-end">
                <div className="d-flex justify-content-center align-items-end">
                  <i className="fa fa-filter" />
                  <FormGroup className="mb-0">
                    <Label>Room Count</Label>
                    <Input
                      type="select"
                      value={filters.roomCount}
                      name="roomCount"
                      onChange={(e) =>
                        _onChangeFilters("roomCount", e.target.value)
                      }
                    >
                      <option value="">All</option>
                      {roomCountConfig.map((each) => (
                        <option
                          key={JSON.stringify(each.value)}
                          value={JSON.stringify(each.value)}
                        >
                          {each.label}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                  <FormGroup className="mb-0 ml-3">
                    <Label>Plan</Label>
                    <Input
                      type="select"
                      value={filters.subscription}
                      name="subscription"
                      onChange={(e) =>
                        _onChangeFilters("subscription", e.target.value)
                      }
                    >
                      <option value="">All</option>
                      {subscriptionConfig.map((each) => (
                        <option key={each.value} value={each.value}>
                          {each.label}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                  <FormGroup className="mb-0 ml-3">
                    <Label>Location</Label>
                    <Input
                      type="select"
                      value={filters.location}
                      name="location"
                      onChange={(e) =>
                        _onChangeFilters("location", e.target.value)
                      }
                    >
                      <option value="">All</option>
                      {locations.map((each) => (
                        <option key={each} value={each}>
                          {capitalize(each)}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>

                  <FormGroup className="mb-0 ml-3">
                    <Label>Verification</Label>
                    <Input
                      type="select"
                      value={filters.isResortActivated}
                      name="isResortActivated"
                      onChange={(e) =>
                        _onChangeFilters("isResortActivated", e.target.value)
                      }
                    >
                      <option value="">All</option>
                      <option value="pending">Pending</option>
                      <option value="accepted">Accepted</option>
                    </Input>
                  </FormGroup>
                </div>

                <div className="d-flex align-items-end">
                  <FormGroup className="mb-0">
                    <Label>Search By Name</Label>
                    <InputGroup>
                      <FormControl
                        type="text"
                        placeholder="Enter"
                        autoComplete="off"
                        name="firstName"
                        value={getAllResortsPayload.search}
                        onChange={(e) => _onChangeSearch(e.target.value)}
                      />
                      <InputGroup.Text id="basic-addon2">
                        {searchLoading ? (
                          <i className="fa fa-spinner fa-spin" />
                        ) : getAllResortsPayload.search ? (
                          <i
                            className="fas fa-times"
                            onClick={() => _onChangeSearch("")}
                          />
                        ) : (
                          <i className="fas fa-search" />
                        )}
                      </InputGroup.Text>
                    </InputGroup>
                  </FormGroup>
                  <Button
                    color="primary"
                    className="btn-clear ml-4"
                    onClick={() => _clearFilters()}
                  >
                    <i className="fa fa-eraser fs-15" />
                  </Button>
                </div>
              </div>
            </Form>
          </Card>

          {selectedResortIds.length ? (
            <div>
              {selectedResortIds.length}{" "}
              {selectedResortIds.length > 1 ? "Resorts are" : "Resort is"}{" "}
              selected
            </div>
          ) : null}

          {/* ======== Table ======== */}
          <Table responsive className="check-switch m-0">
            <thead>
              <tr>
                <th className="p-0">
                  <div className="check-box-container">
                    <CustomInput
                      type="checkbox"
                      id={`custom_checkbox_resorts_selected_all`}
                      checked={_isAllResortSelected() ? true : false}
                      onChange={(e) =>
                        _toggleAllResortSelection(e.target.checked)
                      }
                    />
                  </div>
                </th>
                <th>Resort</th>
                <th>Feed</th>
                <th>Rooms</th>
                <th>Added On</th>
                <th>POC</th>
                <th>Amenities</th>
                <th>Staff</th>
                <th>Offers</th>
                <th>Request</th>
                <th>Plan</th>
                <th>Status</th>
                <th>Comments</th>
                <th>Verification</th>
              </tr>
            </thead>
            <tbody>
              {resorts.length ? (
                resorts.map((resort) => (
                  <tr key={resort.id}>
                    <td className="p-0">
                      <div className="check-box-container">
                        <CustomInput
                          type="checkbox"
                          id={`custom_checkbox_resorts_selected_${resort.id}`}
                          checked={_isResortSelected(resort) ? true : false}
                          onChange={(e) =>
                            _toggleResortSelection(resort, e.target.checked)
                          }
                        />
                      </div>
                    </td>
                    <td>
                      <img
                        src={resort.logo || DEFAULT_PROFILE_PICTURE}
                        onError={(e) =>
                          (e.target.src = DEFAULT_PROFILE_PICTURE)
                        }
                        alt="logo"
                        className="rounded-circle"
                        height="50"
                        width="50"
                        loading="lazy"
                      />
                      <span className="ml-2">
                        {resort.name ? (
                          <Link to={`/resort-details/${resort.id}`}>
                            {capitalize(resort.name)}
                          </Link>
                        ) : (
                          "N/A"
                        )}
                      </span>
                    </td>
                    <td>
                      <Link
                        title="Feed"
                        to={`/group-chat?resortId=${resort.id}`}
                      >
                        View
                      </Link>
                    </td>
                    <td>
                      {resort?.numberOfRooms || resort?.numberOfRooms === 0
                        ? resort.numberOfRooms
                        : "-"}
                    </td>
                    <td>
                      {resort.createdAt ? formatDate(resort.createdAt) : "N/A"}
                    </td>
                    <td>
                      <p className="fs-15 m-0">
                        {resort._resort?.length && resort._resort[0]?.name?.full
                          ? capitalize(resort._resort[0].name.full)
                          : "N/A"}
                      </p>

                      {resort?._resort?.length ? (
                        <>
                          {resort._resort[0]?.phone ? (
                            <i
                              className="fa fa-phone fs-11 cursor-pointer w-auto bg-theme text-white mr-3 p-1"
                              id={`resort_poc_phone_tooltip_${resort._id}`}
                              onClick={() =>
                                copyToClipboard(
                                  formatPhoneNumber(resort._resort[0]?.phone)
                                )
                              }
                            >
                              <Tooltip
                                placement="bottom"
                                isOpen={
                                  isShowTooltip.phone === resort._id
                                    ? true
                                    : false
                                }
                                target={`resort_poc_phone_tooltip_${resort._id}`}
                                toggle={() =>
                                  _toggleTooltip("phone", resort._id)
                                }
                              >
                                {formatPhoneNumber(resort._resort[0]?.phone)}
                              </Tooltip>
                            </i>
                          ) : null}

                          {resort._resort[0]?.email ? (
                            <i
                              className="fa fa-envelope fs-11 cursor-pointer w-auto bg-theme text-white p-1"
                              id={`resort_poc_email_tooltip_${resort._id}`}
                              onClick={() =>
                                copyToClipboard(resort._resort[0]?.email)
                              }
                            >
                              <Tooltip
                                placement="bottom"
                                isOpen={
                                  isShowTooltip.email === resort._id
                                    ? true
                                    : false
                                }
                                target={`resort_poc_email_tooltip_${resort._id}`}
                                toggle={() =>
                                  _toggleTooltip("email", resort._id)
                                }
                              >
                                {resort._resort[0]?.email}
                              </Tooltip>
                            </i>
                          ) : null}
                        </>
                      ) : null}
                    </td>
                    <td>
                      {resort?.amenityCount ? (
                        <Link to={`/hotel-amenities?resortId=${resort.id}`}>
                          {resort.amenityCount}
                        </Link>
                      ) : (
                        0
                      )}
                    </td>
                    <td>
                      {resort?.staffCount ? (
                        <Link to={`/staff?resortId=${resort.id}`}>
                          {resort.staffCount}
                        </Link>
                      ) : (
                        0
                      )}
                    </td>
                    <td>
                      {resort?.offerCount ? (
                        <Link to={`/offers?resortId=${resort.id}`}>
                          {resort.offerCount}
                        </Link>
                      ) : (
                        0
                      )}
                    </td>
                    <td>
                      {/* request modal */}
                      {resort?.requestCount ? (
                        <Button
                          className="btn_link p-0"
                          onClick={() => _toggleResortRequestModal(true)}
                        >
                          {resort.requestCount}
                        </Button>
                      ) : (
                        0
                      )}
                    </td>
                    <td>
                      <Link to={`/subscription-invoice?resortId=${resort.id}`}>
                        Premium
                      </Link>
                    </td>

                    <td>
                      <CustomInput
                        type="switch"
                        id={`custom_switch_resorts_active_${resort.id}`}
                        checked={resort.isActive}
                        label={resort.isActive ? "Active" : "Inactive"}
                        disabled={statusToogleLoading}
                        onChange={(e) =>
                          _toggleResortStatus(resort, e.target.checked)
                        }
                      />
                    </td>

                    <td>
                      {resort?.comments ? (
                        <i
                          className="fa fa-eye fs-11 cursor-pointer w-auto bg-theme text-white p-1"
                          id={`resort_poc_comments_tooltip_${resort._id}`}
                          onClick={() => copyToClipboard(resort.comments)}
                        >
                          <Tooltip
                            placement="bottom"
                            isOpen={
                              isShowTooltip.comments === resort._id
                                ? true
                                : false
                            }
                            target={`resort_poc_comments_tooltip_${resort._id}`}
                            toggle={() =>
                              _toggleTooltip("comments", resort._id)
                            }
                          >
                            {resort.comments}
                          </Tooltip>
                        </i>
                      ) : (
                        "-"
                      )}
                    </td>

                    <td>
                      {resort && resort.hasOwnProperty("isResortActivated") ? (
                        resort.isResortActivated ? (
                          <p className="text-green fw-bold fs-16 mb-0">
                            {resortVerificationLoading === resort.id ? (
                              <i className="fa fa-spinner fa-spin mr-1" />
                            ) : (
                              <i className="fa fa-check mr-1" />
                            )}
                            Accepted
                          </p>
                        ) : (
                          <Button
                            onClick={() => _acceptResortInvitation(resort)}
                            disabled={
                              resortVerificationLoading === resort.id
                                ? true
                                : false
                            }
                          >
                            {resortVerificationLoading === resort.id ? (
                              <i className="fa fa-spinner fa-spin mr-1" />
                            ) : null}
                            Accept
                          </Button>
                        )
                      ) : (
                        "-"
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr className="text-center">
                  <td colSpan="13">
                    {loading ? <Spinner /> : "There is no data to display."}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>

          {/* ====== Pagination ====== */}
          {resorts?.length && resortsCount ? (
            <div className="row mt-4">
              <div className="col-md-6">
                <span className="showng_entries">
                  Showing {resorts?.length} entries out of {resortsCount}
                </span>
              </div>
              <div className="col-md-6">
                <ReactPaginate
                  forcePage={getAllResortsPayload.pageNumber}
                  className="pagination justify-content-end mb-2"
                  breakLabel="..."
                  nextLabel={<i className="fa fa-chevron-right" />}
                  previousLabel={<i className="fa fa-chevron-left" />}
                  previousClassName="previous"
                  nextClassName="next"
                  onPageChange={({ selected }) => _onPageChange(selected)}
                  pageRangeDisplayed={3}
                  pageCount={Math.ceil(
                    resortsCount / getAllResortsPayload.limit
                  )}
                  pageClassName="page-item"
                  renderOnZeroPageCount={null}
                  containerClassName={"pagination"}
                  previousLinkClassName={"page-link"}
                  nextLinkClassName={"page-link"}
                  activeClassName={"active"}
                  disabledClassName={"page-item"}
                />
              </div>
            </div>
          ) : null}

          {/* ========= Contact ========= */}
          <div className="box-container d-flex flex-column  mt-5">
            <div className="d-flex justify-content-between">
              <div style={{ flex: 1 }}>
                <h6>Send Email</h6>

                <InputGroup className="mb-2">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>Subject</InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Enter the subject"
                    value={emailFormField.subject}
                    disabled={sendEmailLoading}
                    onChange={(e) =>
                      setEmailFormField((prev) => {
                        return {
                          subject: e.target.value,
                          text: prev.text,
                        };
                      })
                    }
                  />
                </InputGroup>

                <TextEditor
                  content={emailFormField.text}
                  onChange={(text) =>
                    setEmailFormField((prev) => {
                      return {
                        subject: prev.subject,
                        text,
                      };
                    })
                  }
                />

                <Button
                  className="BtnPurple mt-4 d-block mr-auto px-4"
                  disabled={sendEmailLoading}
                  onClick={() => _sendEmailToUser()}
                >
                  {sendEmailLoading ? (
                    <i className="fa fa-spinner fa-spin mr-1" />
                  ) : null}{" "}
                  Send
                </Button>
              </div>
            </div>
          </div>
        </Container>
      </div>

      <ResortRequestModal
        isOpen={resortRequestModal.isOpen}
        toggle={() => _toggleResortRequestModal()}
      />
    </>
  );
};

export default Resorts;
