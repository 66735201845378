import React from "react";
import { Link } from "react-router-dom";
import { Table, Modal, ModalHeader, ModalBody } from "reactstrap";
import { formatDate } from "../../helper-methods";

const ResortRequestModal = ({ isOpen, toggle }) => {
  const _closeModal = () => {
    toggle();
  };

  return (
    <Modal
      className="modal-large"
      modalTransition={{ timeout: 100 }}
      isOpen={isOpen}
      size="xl"
      toggle={() => _closeModal()}
      centered
    >
      <ModalHeader toggle={() => _closeModal()}>Resort Requests</ModalHeader>
      <ModalBody>
        {/* ======== Table ======== */}
        <Table responsive className="m-0">
          <thead>
            <tr>
              <th>Vacationer</th>
              <th>Resort</th>
              <th>Date</th>
              <th>Request</th>
              <th>Assignee</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Jhon doe</td>
              <td>Plaza</td>
              <td>{formatDate(new Date())}</td>
              <td>Towels</td>
              <td>Kith jen</td>
              <td>Completed</td>
              <td>
                <Link to="#">View details</Link>
              </td>
            </tr>
          </tbody>
        </Table>
      </ModalBody>
    </Modal>
  );
};

export default ResortRequestModal;
