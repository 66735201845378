import React, { useState } from "react";
import { Button, FormGroup, InputGroup, Modal } from "react-bootstrap";
import { Label, Input } from "reactstrap";
import TextEditor from "../../components/TextEditor";
import { showToast } from "../../helper-methods";

const AddForumPostModal = ({ isOpen, toggle }) => {
  const [formFields, setFormFields] = useState({
    title: {
      value: "",
      error: null,
      isDirty: false,
      isValidationRequired: true,
    },
    type: {
      value: "",
      error: null,
      isDirty: false,
      isValidationRequired: true,
    },
    description: {
      value: "",
      error: null,
      isDirty: false,
      isValidationRequired: true,
    },
  });

  const _resetFormField = () => {
    setFormFields({
      title: {
        value: "",
        error: null,
        isDirty: false,
        isValidationRequired: true,
      },
      type: {
        value: "",
        error: null,
        isDirty: false,
        isValidationRequired: true,
      },
      description: {
        value: "",
        error: null,
        isDirty: false,
        isValidationRequired: true,
      },
    });
  };

  const _onCloseModal = () => {
    _resetFormField();
    toggle();
  };

  const _validateFormFields = (newFormFields) => {
    return new Promise((resolve) => {
      let isFormValid = true;

      Object.keys(newFormFields).forEach((key) => {
        if (
          newFormFields[key].isDirty &&
          newFormFields[key].isValidationRequired
        ) {
          switch (key) {
            case "title":
            case "type":
            case "description": {
              if (newFormFields[key]?.value?.trim().length) {
                newFormFields[key].error = null;
                newFormFields[key].isDirty = false;
              } else {
                newFormFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            default:
          }
        }
      });

      setFormFields(newFormFields);

      resolve(isFormValid);
    });
  };

  const _onChangeFormFields = (key, value) => {
    const newFormFields = { ...formFields };

    newFormFields[key].value = value;
    newFormFields[key].isDirty = true;

    setFormFields(newFormFields);

    _validateFormFields(newFormFields);
  };

  const _onClickSave = async () => {
    try {
      const newFormFields = { ...formFields };
      Object.keys(newFormFields).forEach(
        (key) => (newFormFields[key].isDirty = true)
      );
      setFormFields(newFormFields);

      const isFormValid = await _validateFormFields(newFormFields);

      if (isFormValid) {
        console.log("api call", formFields);
      }
    } catch (error) {
      console.log("error>>", error);
      showToast(
        error?.reason?.length
          ? error.reason
          : "Something went wrong, Try again after sometime.",
        "error"
      );
    }
  };

  return (
    <Modal
      scrollable={true}
      show={isOpen}
      onHide={() => _onCloseModal()}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="add-admin-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title className="text-left">Create Post</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="innerForm">
          {/* ==== Title ==== */}
          <FormGroup>
            <Label>Title</Label>
            <InputGroup>
              <Input
                type="text"
                placeholder="Enter"
                autoComplete="off"
                value={formFields.title.value}
                onChange={(e) => _onChangeFormFields("title", e.target.value)}
              />
            </InputGroup>
            {formFields.title.error ? (
              <div className="form-error">{formFields.title.error}</div>
            ) : null}
          </FormGroup>

          {/* ==== Type ==== */}
          <FormGroup>
            <Label>Type</Label>
            <InputGroup>
              <Input
                type="select"
                value={formFields.type.value}
                onChange={(e) => _onChangeFormFields("type", e.target.value)}
              >
                <option value="">Select</option>
                <option value="Full Time">Full Time</option>
                <option value="Part Time">Part Time</option>
                <option value="Contract">Contract</option>
              </Input>
            </InputGroup>
            {formFields.type.error ? (
              <div className="form-error">{formFields.type.error}</div>
            ) : null}
          </FormGroup>

          {/* ==== Job Description ==== */}
          <FormGroup>
            <Label>Description</Label>
            <TextEditor
              content={formFields.description.value}
              onChange={(text) => _onChangeFormFields("description", text)}
            />
            {formFields.description.error ? (
              <div className="form-error">{formFields.description.error}</div>
            ) : null}
          </FormGroup>
        </div>
      </Modal.Body>

      <Modal.Footer className="d-flex justify-content-center pt-3">
        <Button className="btn-cancel mr-3" onClick={() => _onCloseModal()}>
          Cancel
        </Button>
        <Button
          className="btn-save"
          variant="primary"
          onClick={() => _onClickSave()}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddForumPostModal;
