export const zipCdes = [
  {
    name: "Berne",
    postalCode: 46711,
    country: "United States",
    state: "Indiana",
    county: "Adams",
    coordinates: { latitude: 40.672, longitude: -84.934 },
  },
  {
    name: "Decatur",
    postalCode: 46733,
    country: "United States",
    state: "Indiana",
    county: "Adams",
    coordinates: { latitude: 40.827, longitude: -84.931 },
  },
  {
    name: "Geneva",
    postalCode: 46740,
    country: "United States",
    state: "Indiana",
    county: "Adams",
    coordinates: { latitude: 40.607, longitude: -84.962 },
  },
  {
    name: "Linn Grove",
    postalCode: 46769,
    country: "United States",
    state: "Indiana",
    county: "Adams",
    coordinates: { latitude: 40.645, longitude: -85.033 },
  },
  {
    name: "Monroe",
    postalCode: 46772,
    country: "United States",
    state: "Indiana",
    county: "Adams",
    coordinates: { latitude: 40.701, longitude: -84.844 },
  },
  {
    name: "Pleasant Mills",
    postalCode: 46780,
    country: "United States",
    state: "Indiana",
    county: "Adams",
    coordinates: { latitude: 40.778, longitude: -84.842 },
  },
  {
    name: "Preble",
    postalCode: 46782,
    country: "United States",
    state: "Indiana",
    county: "Adams",
    coordinates: { latitude: 40.832, longitude: -85.005 },
  },
  {
    name: "Arcola",
    postalCode: 46704,
    country: "United States",
    state: "Indiana",
    county: "Allen",
    coordinates: { latitude: 41.104, longitude: -85.292 },
  },
  {
    name: "Grabill",
    postalCode: 46741,
    country: "United States",
    state: "Indiana",
    county: "Allen",
    coordinates: { latitude: 41.211, longitude: -84.941 },
  },
  {
    name: "Harlan",
    postalCode: 46743,
    country: "United States",
    state: "Indiana",
    county: "Allen",
    coordinates: { latitude: 41.228, longitude: -84.839 },
  },
  {
    name: "Hoagland",
    postalCode: 46745,
    country: "United States",
    state: "Indiana",
    county: "Allen",
    coordinates: { latitude: 40.952, longitude: -85.008 },
  },
  {
    name: "Huntertown",
    postalCode: 46748,
    country: "United States",
    state: "Indiana",
    county: "Allen",
    coordinates: { latitude: 41.239, longitude: -85.168 },
  },
  {
    name: "Leo",
    postalCode: 46765,
    country: "United States",
    state: "Indiana",
    county: "Allen",
    coordinates: { latitude: 41.225, longitude: -85.03 },
  },
  {
    name: "Monroeville",
    postalCode: 46773,
    country: "United States",
    state: "Indiana",
    county: "Allen",
    coordinates: { latitude: 40.987, longitude: -84.894 },
  },
  {
    name: "New Haven",
    postalCode: 46774,
    country: "United States",
    state: "Indiana",
    county: "Allen",
    coordinates: { latitude: 41.07, longitude: -85.012 },
  },
  {
    name: "Woodburn",
    postalCode: 46797,
    country: "United States",
    state: "Indiana",
    county: "Allen",
    coordinates: { latitude: 41.125, longitude: -84.853 },
  },
  {
    name: "Yoder",
    postalCode: 46798,
    country: "United States",
    state: "Indiana",
    county: "Allen",
    coordinates: { latitude: 40.937, longitude: -85.196 },
  },
  {
    name: "Zanesville",
    postalCode: 46799,
    country: "United States",
    state: "Indiana",
    county: "Allen",
    coordinates: { latitude: 40.919, longitude: -85.282 },
  },
  {
    name: "Fort Wayne",
    postalCode: 46801,
    country: "United States",
    state: "Indiana",
    county: "Allen",
    coordinates: { latitude: 41.094, longitude: -85.071 },
  },
  {
    name: "Fort Wayne",
    postalCode: 46802,
    country: "United States",
    state: "Indiana",
    county: "Allen",
    coordinates: { latitude: 41.071, longitude: -85.154 },
  },
  {
    name: "Fort Wayne",
    postalCode: 46803,
    country: "United States",
    state: "Indiana",
    county: "Allen",
    coordinates: { latitude: 41.069, longitude: -85.107 },
  },
  {
    name: "Fort Wayne",
    postalCode: 46804,
    country: "United States",
    state: "Indiana",
    county: "Allen",
    coordinates: { latitude: 41.051, longitude: -85.256 },
  },
  {
    name: "Fort Wayne",
    postalCode: 46805,
    country: "United States",
    state: "Indiana",
    county: "Allen",
    coordinates: { latitude: 41.098, longitude: -85.119 },
  },
  {
    name: "Fort Wayne",
    postalCode: 46806,
    country: "United States",
    state: "Indiana",
    county: "Allen",
    coordinates: { latitude: 41.048, longitude: -85.113 },
  },
  {
    name: "Fort Wayne",
    postalCode: 46807,
    country: "United States",
    state: "Indiana",
    county: "Allen",
    coordinates: { latitude: 41.049, longitude: -85.146 },
  },
  {
    name: "Fort Wayne",
    postalCode: 46808,
    country: "United States",
    state: "Indiana",
    county: "Allen",
    coordinates: { latitude: 41.094, longitude: -85.162 },
  },
  {
    name: "Fort Wayne",
    postalCode: 46809,
    country: "United States",
    state: "Indiana",
    county: "Allen",
    coordinates: { latitude: 41.025, longitude: -85.183 },
  },
  {
    name: "Fort Wayne",
    postalCode: 46815,
    country: "United States",
    state: "Indiana",
    county: "Allen",
    coordinates: { latitude: 41.105, longitude: -85.062 },
  },
  {
    name: "Fort Wayne",
    postalCode: 46816,
    country: "United States",
    state: "Indiana",
    county: "Allen",
    coordinates: { latitude: 41.017, longitude: -85.098 },
  },
  {
    name: "Fort Wayne",
    postalCode: 46818,
    country: "United States",
    state: "Indiana",
    county: "Allen",
    coordinates: { latitude: 41.147, longitude: -85.207 },
  },
  {
    name: "Fort Wayne",
    postalCode: 46819,
    country: "United States",
    state: "Indiana",
    county: "Allen",
    coordinates: { latitude: 41.005, longitude: -85.153 },
  },
  {
    name: "Fort Wayne",
    postalCode: 46825,
    country: "United States",
    state: "Indiana",
    county: "Allen",
    coordinates: { latitude: 41.146, longitude: -85.123 },
  },
  {
    name: "Fort Wayne",
    postalCode: 46835,
    country: "United States",
    state: "Indiana",
    county: "Allen",
    coordinates: { latitude: 41.137, longitude: -85.069 },
  },
  {
    name: "Fort Wayne",
    postalCode: 46845,
    country: "United States",
    state: "Indiana",
    county: "Allen",
    coordinates: { latitude: 41.196, longitude: -85.119 },
  },
  {
    name: "Fort Wayne",
    postalCode: 46850,
    country: "United States",
    state: "Indiana",
    county: "Allen",
    coordinates: { latitude: 41.094, longitude: -85.071 },
  },
  {
    name: "Fort Wayne",
    postalCode: 46851,
    country: "United States",
    state: "Indiana",
    county: "Allen",
    coordinates: { latitude: 41.094, longitude: -85.071 },
  },
  {
    name: "Fort Wayne",
    postalCode: 46852,
    country: "United States",
    state: "Indiana",
    county: "Allen",
    coordinates: { latitude: 41.094, longitude: -85.071 },
  },
  {
    name: "Fort Wayne",
    postalCode: 46853,
    country: "United States",
    state: "Indiana",
    county: "Allen",
    coordinates: { latitude: 41.094, longitude: -85.071 },
  },
  {
    name: "Fort Wayne",
    postalCode: 46854,
    country: "United States",
    state: "Indiana",
    county: "Allen",
    coordinates: { latitude: 41.094, longitude: -85.071 },
  },
  {
    name: "Fort Wayne",
    postalCode: 46855,
    country: "United States",
    state: "Indiana",
    county: "Allen",
    coordinates: { latitude: 41.094, longitude: -85.071 },
  },
  {
    name: "Fort Wayne",
    postalCode: 46856,
    country: "United States",
    state: "Indiana",
    county: "Allen",
    coordinates: { latitude: 41.094, longitude: -85.071 },
  },
  {
    name: "Fort Wayne",
    postalCode: 46857,
    country: "United States",
    state: "Indiana",
    county: "Allen",
    coordinates: { latitude: 41.094, longitude: -85.071 },
  },
  {
    name: "Fort Wayne",
    postalCode: 46858,
    country: "United States",
    state: "Indiana",
    county: "Allen",
    coordinates: { latitude: 41.094, longitude: -85.071 },
  },
  {
    name: "Fort Wayne",
    postalCode: 46859,
    country: "United States",
    state: "Indiana",
    county: "Allen",
    coordinates: { latitude: 41.094, longitude: -85.071 },
  },
  {
    name: "Fort Wayne",
    postalCode: 46860,
    country: "United States",
    state: "Indiana",
    county: "Allen",
    coordinates: { latitude: 41.094, longitude: -85.071 },
  },
  {
    name: "Fort Wayne",
    postalCode: 46861,
    country: "United States",
    state: "Indiana",
    county: "Allen",
    coordinates: { latitude: 41.094, longitude: -85.071 },
  },
  {
    name: "Fort Wayne",
    postalCode: 46862,
    country: "United States",
    state: "Indiana",
    county: "Allen",
    coordinates: { latitude: 41.094, longitude: -85.071 },
  },
  {
    name: "Fort Wayne",
    postalCode: 46863,
    country: "United States",
    state: "Indiana",
    county: "Allen",
    coordinates: { latitude: 41.094, longitude: -85.071 },
  },
  {
    name: "Fort Wayne",
    postalCode: 46864,
    country: "United States",
    state: "Indiana",
    county: "Allen",
    coordinates: { latitude: 41.094, longitude: -85.071 },
  },
  {
    name: "Fort Wayne",
    postalCode: 46865,
    country: "United States",
    state: "Indiana",
    county: "Allen",
    coordinates: { latitude: 41.126, longitude: -85.091 },
  },
  {
    name: "Fort Wayne",
    postalCode: 46866,
    country: "United States",
    state: "Indiana",
    county: "Allen",
    coordinates: { latitude: 41.094, longitude: -85.071 },
  },
  {
    name: "Fort Wayne",
    postalCode: 46867,
    country: "United States",
    state: "Indiana",
    county: "Allen",
    coordinates: { latitude: 41.094, longitude: -85.071 },
  },
  {
    name: "Fort Wayne",
    postalCode: 46868,
    country: "United States",
    state: "Indiana",
    county: "Allen",
    coordinates: { latitude: 41.094, longitude: -85.071 },
  },
  {
    name: "Fort Wayne",
    postalCode: 46869,
    country: "United States",
    state: "Indiana",
    county: "Allen",
    coordinates: { latitude: 41.094, longitude: -85.071 },
  },
  {
    name: "Fort Wayne",
    postalCode: 46885,
    country: "United States",
    state: "Indiana",
    county: "Allen",
    coordinates: { latitude: 41.094, longitude: -85.071 },
  },
  {
    name: "Fort Wayne",
    postalCode: 46895,
    country: "United States",
    state: "Indiana",
    county: "Allen",
    coordinates: { latitude: 41.094, longitude: -85.071 },
  },
  {
    name: "Fort Wayne",
    postalCode: 46896,
    country: "United States",
    state: "Indiana",
    county: "Allen",
    coordinates: { latitude: 41.094, longitude: -85.071 },
  },
  {
    name: "Fort Wayne",
    postalCode: 46897,
    country: "United States",
    state: "Indiana",
    county: "Allen",
    coordinates: { latitude: 41.094, longitude: -85.071 },
  },
  {
    name: "Fort Wayne",
    postalCode: 46898,
    country: "United States",
    state: "Indiana",
    county: "Allen",
    coordinates: { latitude: 41.094, longitude: -85.071 },
  },
  {
    name: "Fort Wayne",
    postalCode: 46899,
    country: "United States",
    state: "Indiana",
    county: "Allen",
    coordinates: { latitude: 41.094, longitude: -85.071 },
  },
  {
    name: "Fort Wayne",
    postalCode: 46814,
    country: "United States",
    state: "Indiana",
    county: "Allen",
    coordinates: { latitude: 41.046, longitude: -85.306 },
  },
  {
    name: "Columbus",
    postalCode: 47201,
    country: "United States",
    state: "Indiana",
    county: "Bartholomew",
    coordinates: { latitude: 39.206, longitude: -85.932 },
  },
  {
    name: "Columbus",
    postalCode: 47202,
    country: "United States",
    state: "Indiana",
    county: "Bartholomew",
    coordinates: { latitude: 39.201, longitude: -85.921 },
  },
  {
    name: "Columbus",
    postalCode: 47203,
    country: "United States",
    state: "Indiana",
    county: "Bartholomew",
    coordinates: { latitude: 39.23, longitude: -85.885 },
  },
  {
    name: "Clifford",
    postalCode: 47226,
    country: "United States",
    state: "Indiana",
    county: "Bartholomew",
    coordinates: { latitude: 39.283, longitude: -85.869 },
  },
  {
    name: "Elizabethtown",
    postalCode: 47232,
    country: "United States",
    state: "Indiana",
    county: "Bartholomew",
    coordinates: { latitude: 39.124, longitude: -85.815 },
  },
  {
    name: "Grammer",
    postalCode: 47236,
    country: "United States",
    state: "Indiana",
    county: "Bartholomew",
    coordinates: { latitude: 39.152, longitude: -85.726 },
  },
  {
    name: "Hartsville",
    postalCode: 47244,
    country: "United States",
    state: "Indiana",
    county: "Bartholomew",
    coordinates: { latitude: 39.268, longitude: -85.698 },
  },
  {
    name: "Hope",
    postalCode: 47246,
    country: "United States",
    state: "Indiana",
    county: "Bartholomew",
    coordinates: { latitude: 39.304, longitude: -85.771 },
  },
  {
    name: "Jonesville",
    postalCode: 47247,
    country: "United States",
    state: "Indiana",
    county: "Bartholomew",
    coordinates: { latitude: 39.059, longitude: -85.889 },
  },
  {
    name: "Taylorsville",
    postalCode: 47280,
    country: "United States",
    state: "Indiana",
    county: "Bartholomew",
    coordinates: { latitude: 39.295, longitude: -85.95 },
  },
  {
    name: "Ambia",
    postalCode: 47917,
    country: "United States",
    state: "Indiana",
    county: "Benton",
    coordinates: { latitude: 40.479, longitude: -87.48 },
  },
  {
    name: "Boswell",
    postalCode: 47921,
    country: "United States",
    state: "Indiana",
    county: "Benton",
    coordinates: { latitude: 40.518, longitude: -87.379 },
  },
  {
    name: "Earl Park",
    postalCode: 47942,
    country: "United States",
    state: "Indiana",
    county: "Benton",
    coordinates: { latitude: 40.694, longitude: -87.423 },
  },
  {
    name: "Fowler",
    postalCode: 47944,
    country: "United States",
    state: "Indiana",
    county: "Benton",
    coordinates: { latitude: 40.626, longitude: -87.309 },
  },
  {
    name: "Otterbein",
    postalCode: 47970,
    country: "United States",
    state: "Indiana",
    county: "Benton",
    coordinates: { latitude: 40.517, longitude: -87.122 },
  },
  {
    name: "Oxford",
    postalCode: 47971,
    country: "United States",
    state: "Indiana",
    county: "Benton",
    coordinates: { latitude: 40.522, longitude: -87.253 },
  },
  {
    name: "Talbot",
    postalCode: 47984,
    country: "United States",
    state: "Indiana",
    county: "Benton",
    coordinates: { latitude: 40.505, longitude: -87.454 },
  },
  {
    name: "Templeton",
    postalCode: 47986,
    country: "United States",
    state: "Indiana",
    county: "Benton",
  },
  {
    name: "Hartford City",
    postalCode: 47348,
    country: "United States",
    state: "Indiana",
    county: "Blackford",
    coordinates: { latitude: 40.454, longitude: -85.376 },
  },
  {
    name: "Montpelier",
    postalCode: 47359,
    country: "United States",
    state: "Indiana",
    county: "Blackford",
    coordinates: { latitude: 40.558, longitude: -85.251 },
  },
  {
    name: "Lebanon",
    postalCode: 46052,
    country: "United States",
    state: "Indiana",
    county: "Boone",
    coordinates: { latitude: 40.045, longitude: -86.464 },
  },
  {
    name: "Thorntown",
    postalCode: 46071,
    country: "United States",
    state: "Indiana",
    county: "Boone",
    coordinates: { latitude: 40.113, longitude: -86.59 },
  },
  {
    name: "Whitestown",
    postalCode: 46075,
    country: "United States",
    state: "Indiana",
    county: "Boone",
    coordinates: { latitude: 40, longitude: -86.351 },
  },
  {
    name: "Zionsville",
    postalCode: 46077,
    country: "United States",
    state: "Indiana",
    county: "Boone",
    coordinates: { latitude: 39.956, longitude: -86.277 },
  },
  {
    name: "Advance",
    postalCode: 46102,
    country: "United States",
    state: "Indiana",
    county: "Boone",
    coordinates: { latitude: 39.996, longitude: -86.62 },
  },
  {
    name: "Jamestown",
    postalCode: 46147,
    country: "United States",
    state: "Indiana",
    county: "Boone",
    coordinates: { latitude: 39.958, longitude: -86.624 },
  },
  {
    name: "Morgantown",
    postalCode: 46160,
    country: "United States",
    state: "Indiana",
    county: "Brown",
    coordinates: { latitude: 39.363, longitude: -86.28 },
  },
  {
    name: "Helmsburg",
    postalCode: 47435,
    country: "United States",
    state: "Indiana",
    county: "Brown",
    coordinates: { latitude: 39.196, longitude: -86.23 },
  },
  {
    name: "Nashville",
    postalCode: 47448,
    country: "United States",
    state: "Indiana",
    county: "Brown",
    coordinates: { latitude: 39.237, longitude: -86.222 },
  },
  {
    name: "Bringhurst",
    postalCode: 46913,
    country: "United States",
    state: "Indiana",
    county: "Carroll",
    coordinates: { latitude: 40.516, longitude: -86.52 },
  },
  {
    name: "Burlington",
    postalCode: 46915,
    country: "United States",
    state: "Indiana",
    county: "Carroll",
    coordinates: { latitude: 40.478, longitude: -86.387 },
  },
  {
    name: "Burrows",
    postalCode: 46916,
    country: "United States",
    state: "Indiana",
    county: "Carroll",
    coordinates: { latitude: 40.677, longitude: -86.508 },
  },
  {
    name: "Camden",
    postalCode: 46917,
    country: "United States",
    state: "Indiana",
    county: "Carroll",
    coordinates: { latitude: 40.6, longitude: -86.515 },
  },
  {
    name: "Cutler",
    postalCode: 46920,
    country: "United States",
    state: "Indiana",
    county: "Carroll",
    coordinates: { latitude: 40.478, longitude: -86.446 },
  },
  {
    name: "Delphi",
    postalCode: 46923,
    country: "United States",
    state: "Indiana",
    county: "Carroll",
    coordinates: { latitude: 40.573, longitude: -86.679 },
  },
  {
    name: "Flora",
    postalCode: 46929,
    country: "United States",
    state: "Indiana",
    county: "Carroll",
    coordinates: { latitude: 40.544, longitude: -86.502 },
  },
  {
    name: "Rockfield",
    postalCode: 46977,
    country: "United States",
    state: "Indiana",
    county: "Carroll",
    coordinates: { latitude: 40.641, longitude: -86.574 },
  },
  {
    name: "Yeoman",
    postalCode: 47997,
    country: "United States",
    state: "Indiana",
    county: "Carroll",
    coordinates: { latitude: 40.668, longitude: -86.724 },
  },
  {
    name: "Galveston",
    postalCode: 46932,
    country: "United States",
    state: "Indiana",
    county: "Cass",
    coordinates: { latitude: 40.586, longitude: -86.197 },
  },
  {
    name: "Lake Cicott",
    postalCode: 46942,
    country: "United States",
    state: "Indiana",
    county: "Cass",
    coordinates: { latitude: 40.736, longitude: -86.373 },
  },
  {
    name: "Logansport",
    postalCode: 46947,
    country: "United States",
    state: "Indiana",
    county: "Cass",
    coordinates: { latitude: 40.76, longitude: -86.36 },
  },
  {
    name: "Lucerne",
    postalCode: 46950,
    country: "United States",
    state: "Indiana",
    county: "Cass",
    coordinates: { latitude: 40.861, longitude: -86.408 },
  },
  {
    name: "New Waverly",
    postalCode: 46961,
    country: "United States",
    state: "Indiana",
    county: "Cass",
    coordinates: { latitude: 40.762, longitude: -86.194 },
  },
  {
    name: "Onward",
    postalCode: 46967,
    country: "United States",
    state: "Indiana",
    county: "Cass",
    coordinates: { latitude: 40.695, longitude: -86.195 },
  },
  {
    name: "Royal Center",
    postalCode: 46978,
    country: "United States",
    state: "Indiana",
    county: "Cass",
    coordinates: { latitude: 40.864, longitude: -86.508 },
  },
  {
    name: "Twelve Mile",
    postalCode: 46988,
    country: "United States",
    state: "Indiana",
    county: "Cass",
    coordinates: { latitude: 40.855, longitude: -86.213 },
  },
  {
    name: "Walton",
    postalCode: 46994,
    country: "United States",
    state: "Indiana",
    county: "Cass",
    coordinates: { latitude: 40.677, longitude: -86.281 },
  },
  {
    name: "Young America",
    postalCode: 46998,
    country: "United States",
    state: "Indiana",
    county: "Cass",
    coordinates: { latitude: 40.569, longitude: -86.347 },
  },
  {
    name: "Bethlehem",
    postalCode: 47104,
    country: "United States",
    state: "Indiana",
    county: "Clark",
    coordinates: { latitude: 38.54, longitude: -85.422 },
  },
  {
    name: "Borden",
    postalCode: 47106,
    country: "United States",
    state: "Indiana",
    county: "Clark",
    coordinates: { latitude: 38.436, longitude: -85.921 },
  },
  {
    name: "Charlestown",
    postalCode: 47111,
    country: "United States",
    state: "Indiana",
    county: "Clark",
    coordinates: { latitude: 38.457, longitude: -85.661 },
  },
  {
    name: "Henryville",
    postalCode: 47126,
    country: "United States",
    state: "Indiana",
    county: "Clark",
    coordinates: { latitude: 38.54, longitude: -85.773 },
  },
  {
    name: "Clarksville",
    postalCode: 47129,
    country: "United States",
    state: "Indiana",
    county: "Clark",
    coordinates: { latitude: 38.311, longitude: -85.764 },
  },
  {
    name: "Jeffersonville",
    postalCode: 47130,
    country: "United States",
    state: "Indiana",
    county: "Clark",
    coordinates: { latitude: 38.308, longitude: -85.736 },
  },
  {
    name: "Jeffersonville",
    postalCode: 47131,
    country: "United States",
    state: "Indiana",
    county: "Clark",
    coordinates: { latitude: 38.437, longitude: -85.705 },
  },
  {
    name: "Marysville",
    postalCode: 47141,
    country: "United States",
    state: "Indiana",
    county: "Clark",
    coordinates: { latitude: 38.586, longitude: -85.644 },
  },
  {
    name: "Memphis",
    postalCode: 47143,
    country: "United States",
    state: "Indiana",
    county: "Clark",
    coordinates: { latitude: 38.464, longitude: -85.778 },
  },
  {
    name: "Nabb",
    postalCode: 47147,
    country: "United States",
    state: "Indiana",
    county: "Clark",
    coordinates: { latitude: 38.613, longitude: -85.522 },
  },
  {
    name: "New Washington",
    postalCode: 47162,
    country: "United States",
    state: "Indiana",
    county: "Clark",
    coordinates: { latitude: 38.558, longitude: -85.46 },
  },
  {
    name: "Otisco",
    postalCode: 47163,
    country: "United States",
    state: "Indiana",
    county: "Clark",
    coordinates: { latitude: 38.542, longitude: -85.665 },
  },
  {
    name: "Sellersburg",
    postalCode: 47172,
    country: "United States",
    state: "Indiana",
    county: "Clark",
    coordinates: { latitude: 38.405, longitude: -85.788 },
  },
  {
    name: "Underwood",
    postalCode: 47177,
    country: "United States",
    state: "Indiana",
    county: "Clark",
    coordinates: { latitude: 38.604, longitude: -85.774 },
  },
  {
    name: "Jeffersonville",
    postalCode: 47199,
    country: "United States",
    state: "Indiana",
    county: "Clark",
    coordinates: { latitude: 38.287, longitude: -85.732 },
  },
  {
    name: "Jeffersonville",
    postalCode: 47133,
    country: "United States",
    state: "Indiana",
    county: "Clark",
    coordinates: { latitude: 38.287, longitude: -85.732 },
  },
  {
    name: "Jeffersonville",
    postalCode: 47132,
    country: "United States",
    state: "Indiana",
    county: "Clark",
    coordinates: { latitude: 38.287, longitude: -85.732 },
  },
  {
    name: "Jeffersonville",
    postalCode: 47134,
    country: "United States",
    state: "Indiana",
    county: "Clark",
    coordinates: { latitude: 38.287, longitude: -85.732 },
  },
  {
    name: "Jeffersonville",
    postalCode: 47144,
    country: "United States",
    state: "Indiana",
    county: "Clark",
    coordinates: { latitude: 38.287, longitude: -85.732 },
  },
  {
    name: "Jeffersonville",
    postalCode: 47190,
    country: "United States",
    state: "Indiana",
    county: "Clark",
    coordinates: { latitude: 38.437, longitude: -85.705 },
  },
  {
    name: "Bowling Green",
    postalCode: 47833,
    country: "United States",
    state: "Indiana",
    county: "Clay",
    coordinates: { latitude: 39.381, longitude: -87.005 },
  },
  {
    name: "Brazil",
    postalCode: 47834,
    country: "United States",
    state: "Indiana",
    county: "Clay",
    coordinates: { latitude: 39.521, longitude: -87.128 },
  },
  {
    name: "Carbon",
    postalCode: 47837,
    country: "United States",
    state: "Indiana",
    county: "Clay",
    coordinates: { latitude: 39.591, longitude: -87.113 },
  },
  {
    name: "Centerpoint",
    postalCode: 47840,
    country: "United States",
    state: "Indiana",
    county: "Clay",
    coordinates: { latitude: 39.391, longitude: -87.093 },
  },
  {
    name: "Clay City",
    postalCode: 47841,
    country: "United States",
    state: "Indiana",
    county: "Clay",
    coordinates: { latitude: 39.273, longitude: -87.108 },
  },
  {
    name: "Coalmont",
    postalCode: 47845,
    country: "United States",
    state: "Indiana",
    county: "Clay",
    coordinates: { latitude: 39.193, longitude: -87.231 },
  },
  {
    name: "Cory",
    postalCode: 47846,
    country: "United States",
    state: "Indiana",
    county: "Clay",
    coordinates: { latitude: 39.344, longitude: -87.196 },
  },
  {
    name: "Harmony",
    postalCode: 47853,
    country: "United States",
    state: "Indiana",
    county: "Clay",
    coordinates: { latitude: 39.537, longitude: -87.073 },
  },
  {
    name: "Knightsville",
    postalCode: 47857,
    country: "United States",
    state: "Indiana",
    county: "Clay",
    coordinates: { latitude: 39.527, longitude: -87.087 },
  },
  {
    name: "Poland",
    postalCode: 47868,
    country: "United States",
    state: "Indiana",
    county: "Clay",
    coordinates: { latitude: 39.446, longitude: -86.963 },
  },
  {
    name: "Staunton",
    postalCode: 47881,
    country: "United States",
    state: "Indiana",
    county: "Clay",
    coordinates: { latitude: 39.488, longitude: -87.189 },
  },
  {
    name: "Frankfort",
    postalCode: 46041,
    country: "United States",
    state: "Indiana",
    county: "Clinton",
    coordinates: { latitude: 40.304, longitude: -86.469 },
  },
  {
    name: "Colfax",
    postalCode: 46035,
    country: "United States",
    state: "Indiana",
    county: "Clinton",
    coordinates: { latitude: 40.196, longitude: -86.659 },
  },
  {
    name: "Forest",
    postalCode: 46039,
    country: "United States",
    state: "Indiana",
    county: "Clinton",
    coordinates: { latitude: 40.376, longitude: -86.32 },
  },
  {
    name: "Kirklin",
    postalCode: 46050,
    country: "United States",
    state: "Indiana",
    county: "Clinton",
    coordinates: { latitude: 40.203, longitude: -86.332 },
  },
  {
    name: "Michigantown",
    postalCode: 46057,
    country: "United States",
    state: "Indiana",
    county: "Clinton",
    coordinates: { latitude: 40.311, longitude: -86.375 },
  },
  {
    name: "Mulberry",
    postalCode: 46058,
    country: "United States",
    state: "Indiana",
    county: "Clinton",
    coordinates: { latitude: 40.343, longitude: -86.661 },
  },
  {
    name: "Rossville",
    postalCode: 46065,
    country: "United States",
    state: "Indiana",
    county: "Clinton",
    coordinates: { latitude: 40.411, longitude: -86.608 },
  },
  {
    name: "Sedalia",
    postalCode: 46067,
    country: "United States",
    state: "Indiana",
    county: "Clinton",
    coordinates: { latitude: 40.416, longitude: -86.515 },
  },
  {
    name: "Eckerty",
    postalCode: 47116,
    country: "United States",
    state: "Indiana",
    county: "Crawford",
    coordinates: { latitude: 38.319, longitude: -86.606 },
  },
  {
    name: "English",
    postalCode: 47118,
    country: "United States",
    state: "Indiana",
    county: "Crawford",
    coordinates: { latitude: 38.326, longitude: -86.443 },
  },
  {
    name: "Grantsburg",
    postalCode: 47123,
    country: "United States",
    state: "Indiana",
    county: "Crawford",
    coordinates: { latitude: 38.282, longitude: -86.484 },
  },
  {
    name: "Leavenworth",
    postalCode: 47137,
    country: "United States",
    state: "Indiana",
    county: "Crawford",
    coordinates: { latitude: 38.187, longitude: -86.384 },
  },
  {
    name: "Marengo",
    postalCode: 47140,
    country: "United States",
    state: "Indiana",
    county: "Crawford",
    coordinates: { latitude: 38.374, longitude: -86.358 },
  },
  {
    name: "Milltown",
    postalCode: 47145,
    country: "United States",
    state: "Indiana",
    county: "Crawford",
    coordinates: { latitude: 38.344, longitude: -86.3 },
  },
  {
    name: "Sulphur",
    postalCode: 47174,
    country: "United States",
    state: "Indiana",
    county: "Crawford",
    coordinates: { latitude: 38.226, longitude: -86.491 },
  },
  {
    name: "Taswell",
    postalCode: 47175,
    country: "United States",
    state: "Indiana",
    county: "Crawford",
    coordinates: { latitude: 38.346, longitude: -86.539 },
  },
  {
    name: "Washington",
    postalCode: 47501,
    country: "United States",
    state: "Indiana",
    county: "Daviess",
    coordinates: { latitude: 38.654, longitude: -87.171 },
  },
  {
    name: "Cannelburg",
    postalCode: 47519,
    country: "United States",
    state: "Indiana",
    county: "Daviess",
    coordinates: { latitude: 38.67, longitude: -86.998 },
  },
  {
    name: "Elnora",
    postalCode: 47529,
    country: "United States",
    state: "Indiana",
    county: "Daviess",
    coordinates: { latitude: 38.877, longitude: -87.085 },
  },
  {
    name: "Montgomery",
    postalCode: 47558,
    country: "United States",
    state: "Indiana",
    county: "Daviess",
    coordinates: { latitude: 38.652, longitude: -87.048 },
  },
  {
    name: "Odon",
    postalCode: 47562,
    country: "United States",
    state: "Indiana",
    county: "Daviess",
    coordinates: { latitude: 38.819, longitude: -86.975 },
  },
  {
    name: "Plainville",
    postalCode: 47568,
    country: "United States",
    state: "Indiana",
    county: "Daviess",
    coordinates: { latitude: 38.806, longitude: -87.152 },
  },
  {
    name: "Aurora",
    postalCode: 47001,
    country: "United States",
    state: "Indiana",
    county: "Dearborn",
    coordinates: { latitude: 39.072, longitude: -84.945 },
  },
  {
    name: "Dillsboro",
    postalCode: 47018,
    country: "United States",
    state: "Indiana",
    county: "Dearborn",
    coordinates: { latitude: 38.996, longitude: -85.055 },
  },
  {
    name: "Guilford",
    postalCode: 47022,
    country: "United States",
    state: "Indiana",
    county: "Dearborn",
    coordinates: { latitude: 39.206, longitude: -84.962 },
  },
  {
    name: "Lawrenceburg",
    postalCode: 47025,
    country: "United States",
    state: "Indiana",
    county: "Dearborn",
    coordinates: { latitude: 39.14, longitude: -84.866 },
  },
  {
    name: "Moores Hill",
    postalCode: 47032,
    country: "United States",
    state: "Indiana",
    county: "Dearborn",
    coordinates: { latitude: 39.094, longitude: -85.064 },
  },
  {
    name: "West Harrison",
    postalCode: 47060,
    country: "United States",
    state: "Indiana",
    county: "Dearborn",
    coordinates: { latitude: 39.301, longitude: -84.881 },
  },
  {
    name: "Clarksburg",
    postalCode: 47225,
    country: "United States",
    state: "Indiana",
    county: "Decatur",
    coordinates: { latitude: 39.424, longitude: -85.348 },
  },
  {
    name: "Greensburg",
    postalCode: 47240,
    country: "United States",
    state: "Indiana",
    county: "Decatur",
    coordinates: { latitude: 39.3, longitude: -85.492 },
  },
  {
    name: "Millhousen",
    postalCode: 47261,
    country: "United States",
    state: "Indiana",
    county: "Decatur",
    coordinates: { latitude: 39.292, longitude: -85.492 },
  },
  {
    name: "New Point",
    postalCode: 47263,
    country: "United States",
    state: "Indiana",
    county: "Decatur",
    coordinates: { latitude: 39.308, longitude: -85.33 },
  },
  {
    name: "Saint Paul",
    postalCode: 47272,
    country: "United States",
    state: "Indiana",
    county: "Decatur",
    coordinates: { latitude: 39.428, longitude: -85.599 },
  },
  {
    name: "Westport",
    postalCode: 47283,
    country: "United States",
    state: "Indiana",
    county: "Decatur",
    coordinates: { latitude: 39.175, longitude: -85.586 },
  },
  {
    name: "Ashley",
    postalCode: 46705,
    country: "United States",
    state: "Indiana",
    county: "DeKalb",
    coordinates: { latitude: 41.535, longitude: -85.05 },
  },
  {
    name: "Auburn",
    postalCode: 46706,
    country: "United States",
    state: "Indiana",
    county: "DeKalb",
    coordinates: { latitude: 41.359, longitude: -85.047 },
  },
  {
    name: "Butler",
    postalCode: 46721,
    country: "United States",
    state: "Indiana",
    county: "DeKalb",
    coordinates: { latitude: 41.429, longitude: -84.879 },
  },
  {
    name: "Corunna",
    postalCode: 46730,
    country: "United States",
    state: "Indiana",
    county: "DeKalb",
    coordinates: { latitude: 41.45, longitude: -85.137 },
  },
  {
    name: "Garrett",
    postalCode: 46738,
    country: "United States",
    state: "Indiana",
    county: "DeKalb",
    coordinates: { latitude: 41.348, longitude: -85.135 },
  },
  {
    name: "Saint Joe",
    postalCode: 46785,
    country: "United States",
    state: "Indiana",
    county: "DeKalb",
    coordinates: { latitude: 41.324, longitude: -84.904 },
  },
  {
    name: "Spencerville",
    postalCode: 46788,
    country: "United States",
    state: "Indiana",
    county: "DeKalb",
    coordinates: { latitude: 41.27, longitude: -84.94 },
  },
  {
    name: "Waterloo",
    postalCode: 46793,
    country: "United States",
    state: "Indiana",
    county: "DeKalb",
    coordinates: { latitude: 41.44, longitude: -85.022 },
  },
  {
    name: "Muncie",
    postalCode: 47302,
    country: "United States",
    state: "Indiana",
    county: "Delaware",
    coordinates: { latitude: 40.168, longitude: -85.381 },
  },
  {
    name: "Muncie",
    postalCode: 47303,
    country: "United States",
    state: "Indiana",
    county: "Delaware",
    coordinates: { latitude: 40.218, longitude: -85.379 },
  },
  {
    name: "Muncie",
    postalCode: 47304,
    country: "United States",
    state: "Indiana",
    county: "Delaware",
    coordinates: { latitude: 40.211, longitude: -85.429 },
  },
  {
    name: "Muncie",
    postalCode: 47305,
    country: "United States",
    state: "Indiana",
    county: "Delaware",
    coordinates: { latitude: 40.193, longitude: -85.386 },
  },
  {
    name: "Muncie",
    postalCode: 47306,
    country: "United States",
    state: "Indiana",
    county: "Delaware",
    coordinates: { latitude: 40.202, longitude: -85.408 },
  },
  {
    name: "Muncie",
    postalCode: 47307,
    country: "United States",
    state: "Indiana",
    county: "Delaware",
    coordinates: { latitude: 40.162, longitude: -85.443 },
  },
  {
    name: "Muncie",
    postalCode: 47308,
    country: "United States",
    state: "Indiana",
    county: "Delaware",
    coordinates: { latitude: 40.228, longitude: -85.397 },
  },
  {
    name: "Albany",
    postalCode: 47320,
    country: "United States",
    state: "Indiana",
    county: "Delaware",
    coordinates: { latitude: 40.292, longitude: -85.258 },
  },
  {
    name: "Daleville",
    postalCode: 47334,
    country: "United States",
    state: "Indiana",
    county: "Delaware",
    coordinates: { latitude: 40.126, longitude: -85.511 },
  },
  {
    name: "Eaton",
    postalCode: 47338,
    country: "United States",
    state: "Indiana",
    county: "Delaware",
    coordinates: { latitude: 40.338, longitude: -85.354 },
  },
  {
    name: "Gaston",
    postalCode: 47342,
    country: "United States",
    state: "Indiana",
    county: "Delaware",
    coordinates: { latitude: 40.295, longitude: -85.49 },
  },
  {
    name: "Oakville",
    postalCode: 47367,
    country: "United States",
    state: "Indiana",
    county: "Delaware",
    coordinates: { latitude: 40.079, longitude: -85.39 },
  },
  {
    name: "Selma",
    postalCode: 47383,
    country: "United States",
    state: "Indiana",
    county: "Delaware",
    coordinates: { latitude: 40.169, longitude: -85.274 },
  },
  {
    name: "Yorktown",
    postalCode: 47396,
    country: "United States",
    state: "Indiana",
    county: "Delaware",
    coordinates: { latitude: 40.184, longitude: -85.496 },
  },
  {
    name: "Birdseye",
    postalCode: 47513,
    country: "United States",
    state: "Indiana",
    county: "Dubois",
    coordinates: { latitude: 38.301, longitude: -86.71 },
  },
  {
    name: "Celestine",
    postalCode: 47521,
    country: "United States",
    state: "Indiana",
    county: "Dubois",
    coordinates: { latitude: 38.388, longitude: -86.757 },
  },
  {
    name: "Dubois",
    postalCode: 47527,
    country: "United States",
    state: "Indiana",
    county: "Dubois",
    coordinates: { latitude: 38.473, longitude: -86.783 },
  },
  {
    name: "Ferdinand",
    postalCode: 47532,
    country: "United States",
    state: "Indiana",
    county: "Dubois",
    coordinates: { latitude: 38.234, longitude: -86.861 },
  },
  {
    name: "Holland",
    postalCode: 47541,
    country: "United States",
    state: "Indiana",
    county: "Dubois",
    coordinates: { latitude: 38.246, longitude: -87.036 },
  },
  {
    name: "Huntingburg",
    postalCode: 47542,
    country: "United States",
    state: "Indiana",
    county: "Dubois",
    coordinates: { latitude: 38.298, longitude: -86.953 },
  },
  {
    name: "Ireland",
    postalCode: 47545,
    country: "United States",
    state: "Indiana",
    county: "Dubois",
    coordinates: { latitude: 38.414, longitude: -87.001 },
  },
  {
    name: "Jasper",
    postalCode: 47546,
    country: "United States",
    state: "Indiana",
    county: "Dubois",
    coordinates: { latitude: 38.36, longitude: -86.929 },
  },
  {
    name: "Jasper",
    postalCode: 47547,
    country: "United States",
    state: "Indiana",
    county: "Dubois",
    coordinates: { latitude: 38.365, longitude: -86.876 },
  },
  {
    name: "Jasper",
    postalCode: 47549,
    country: "United States",
    state: "Indiana",
    county: "Dubois",
    coordinates: { latitude: 38.365, longitude: -86.876 },
  },
  {
    name: "Saint Anthony",
    postalCode: 47575,
    country: "United States",
    state: "Indiana",
    county: "Dubois",
    coordinates: { latitude: 38.321, longitude: -86.823 },
  },
  {
    name: "Schnellville",
    postalCode: 47580,
    country: "United States",
    state: "Indiana",
    county: "Dubois",
    coordinates: { latitude: 38.341, longitude: -86.757 },
  },
  {
    name: "Bristol",
    postalCode: 46507,
    country: "United States",
    state: "Indiana",
    county: "Elkhart",
    coordinates: { latitude: 41.717, longitude: -85.826 },
  },
  {
    name: "Elkhart",
    postalCode: 46514,
    country: "United States",
    state: "Indiana",
    county: "Elkhart",
    coordinates: { latitude: 41.71, longitude: -85.973 },
  },
  {
    name: "Elkhart",
    postalCode: 46515,
    country: "United States",
    state: "Indiana",
    county: "Elkhart",
    coordinates: { latitude: 41.682, longitude: -85.977 },
  },
  {
    name: "Elkhart",
    postalCode: 46516,
    country: "United States",
    state: "Indiana",
    county: "Elkhart",
    coordinates: { latitude: 41.676, longitude: -85.962 },
  },
  {
    name: "Elkhart",
    postalCode: 46517,
    country: "United States",
    state: "Indiana",
    county: "Elkhart",
    coordinates: { latitude: 41.647, longitude: -85.973 },
  },
  {
    name: "Goshen",
    postalCode: 46526,
    country: "United States",
    state: "Indiana",
    county: "Elkhart",
    coordinates: { latitude: 41.585, longitude: -85.858 },
  },
  {
    name: "Goshen",
    postalCode: 46527,
    country: "United States",
    state: "Indiana",
    county: "Elkhart",
    coordinates: { latitude: 41.598, longitude: -85.858 },
  },
  {
    name: "Goshen",
    postalCode: 46528,
    country: "United States",
    state: "Indiana",
    county: "Elkhart",
    coordinates: { latitude: 41.625, longitude: -85.839 },
  },
  {
    name: "Middlebury",
    postalCode: 46540,
    country: "United States",
    state: "Indiana",
    county: "Elkhart",
    coordinates: { latitude: 41.675, longitude: -85.711 },
  },
  {
    name: "Millersburg",
    postalCode: 46543,
    country: "United States",
    state: "Indiana",
    county: "Elkhart",
    coordinates: { latitude: 41.534, longitude: -85.707 },
  },
  {
    name: "Nappanee",
    postalCode: 46550,
    country: "United States",
    state: "Indiana",
    county: "Elkhart",
    coordinates: { latitude: 41.449, longitude: -85.995 },
  },
  {
    name: "New Paris",
    postalCode: 46553,
    country: "United States",
    state: "Indiana",
    county: "Elkhart",
    coordinates: { latitude: 41.492, longitude: -85.834 },
  },
  {
    name: "Wakarusa",
    postalCode: 46573,
    country: "United States",
    state: "Indiana",
    county: "Elkhart",
    coordinates: { latitude: 41.54, longitude: -86.021 },
  },
  {
    name: "Glenwood",
    postalCode: 46133,
    country: "United States",
    state: "Indiana",
    county: "Fayette",
    coordinates: { latitude: 39.612, longitude: -85.274 },
  },
  {
    name: "Bentonville",
    postalCode: 47322,
    country: "United States",
    state: "Indiana",
    county: "Fayette",
    coordinates: { latitude: 39.657, longitude: -85.168 },
  },
  {
    name: "Connersville",
    postalCode: 47331,
    country: "United States",
    state: "Indiana",
    county: "Fayette",
    coordinates: { latitude: 39.644, longitude: -85.146 },
  },
  {
    name: "Floyds Knobs",
    postalCode: 47119,
    country: "United States",
    state: "Indiana",
    county: "Floyd",
    coordinates: { latitude: 38.351, longitude: -85.9 },
  },
  {
    name: "Georgetown",
    postalCode: 47122,
    country: "United States",
    state: "Indiana",
    county: "Floyd",
    coordinates: { latitude: 38.303, longitude: -85.962 },
  },
  {
    name: "Greenville",
    postalCode: 47124,
    country: "United States",
    state: "Indiana",
    county: "Floyd",
    coordinates: { latitude: 38.354, longitude: -86.008 },
  },
  {
    name: "Mount Saint Francis",
    postalCode: 47146,
    country: "United States",
    state: "Indiana",
    county: "Floyd",
    coordinates: { latitude: 38.298, longitude: -85.897 },
  },
  {
    name: "New Albany",
    postalCode: 47150,
    country: "United States",
    state: "Indiana",
    county: "Floyd",
    coordinates: { latitude: 38.309, longitude: -85.822 },
  },
  {
    name: "New Albany",
    postalCode: 47151,
    country: "United States",
    state: "Indiana",
    county: "Floyd",
    coordinates: { latitude: 38.286, longitude: -85.824 },
  },
  {
    name: "Attica",
    postalCode: 47918,
    country: "United States",
    state: "Indiana",
    county: "Fountain",
    coordinates: { latitude: 40.281, longitude: -87.224 },
  },
  {
    name: "Covington",
    postalCode: 47932,
    country: "United States",
    state: "Indiana",
    county: "Fountain",
    coordinates: { latitude: 40.133, longitude: -87.382 },
  },
  {
    name: "Hillsboro",
    postalCode: 47949,
    country: "United States",
    state: "Indiana",
    county: "Fountain",
    coordinates: { latitude: 40.083, longitude: -87.155 },
  },
  {
    name: "Kingman",
    postalCode: 47952,
    country: "United States",
    state: "Indiana",
    county: "Fountain",
    coordinates: { latitude: 40.017, longitude: -87.257 },
  },
  {
    name: "Mellott",
    postalCode: 47958,
    country: "United States",
    state: "Indiana",
    county: "Fountain",
    coordinates: { latitude: 40.164, longitude: -87.148 },
  },
  {
    name: "Newtown",
    postalCode: 47969,
    country: "United States",
    state: "Indiana",
    county: "Fountain",
    coordinates: { latitude: 40.203, longitude: -87.147 },
  },
  {
    name: "Veedersburg",
    postalCode: 47987,
    country: "United States",
    state: "Indiana",
    county: "Fountain",
    coordinates: { latitude: 40.119, longitude: -87.26 },
  },
  {
    name: "Wallace",
    postalCode: 47988,
    country: "United States",
    state: "Indiana",
    county: "Fountain",
    coordinates: { latitude: 39.987, longitude: -87.148 },
  },
  {
    name: "West College Corner",
    postalCode: 47003,
    country: "United States",
    state: "Indiana",
    county: "Franklin",
    coordinates: { latitude: 39.568, longitude: -84.816 },
  },
  {
    name: "Bath",
    postalCode: 47010,
    country: "United States",
    state: "Indiana",
    county: "Franklin",
    coordinates: { latitude: 39.499, longitude: -84.836 },
  },
  {
    name: "Brookville",
    postalCode: 47012,
    country: "United States",
    state: "Indiana",
    county: "Franklin",
    coordinates: { latitude: 39.421, longitude: -84.999 },
  },
  {
    name: "Cedar Grove",
    postalCode: 47016,
    country: "United States",
    state: "Indiana",
    county: "Franklin",
    coordinates: { latitude: 39.346, longitude: -84.892 },
  },
  {
    name: "Laurel",
    postalCode: 47024,
    country: "United States",
    state: "Indiana",
    county: "Franklin",
    coordinates: { latitude: 39.492, longitude: -85.208 },
  },
  {
    name: "Metamora",
    postalCode: 47030,
    country: "United States",
    state: "Indiana",
    county: "Franklin",
    coordinates: { latitude: 39.429, longitude: -85.15 },
  },
  {
    name: "New Trenton",
    postalCode: 47035,
    country: "United States",
    state: "Indiana",
    county: "Franklin",
    coordinates: { latitude: 39.31, longitude: -84.901 },
  },
  {
    name: "Oldenburg",
    postalCode: 47036,
    country: "United States",
    state: "Indiana",
    county: "Franklin",
    coordinates: { latitude: 39.386, longitude: -85.239 },
  },
  {
    name: "Akron",
    postalCode: 46910,
    country: "United States",
    state: "Indiana",
    county: "Fulton",
    coordinates: { latitude: 41.039, longitude: -86.039 },
  },
  {
    name: "Athens",
    postalCode: 46912,
    country: "United States",
    state: "Indiana",
    county: "Fulton",
    coordinates: { latitude: 41.054, longitude: -86.125 },
  },
  {
    name: "Delong",
    postalCode: 46922,
    country: "United States",
    state: "Indiana",
    county: "Fulton",
    coordinates: { latitude: 41.138, longitude: -86.416 },
  },
  {
    name: "Fulton",
    postalCode: 46931,
    country: "United States",
    state: "Indiana",
    county: "Fulton",
    coordinates: { latitude: 40.947, longitude: -86.263 },
  },
  {
    name: "Kewanna",
    postalCode: 46939,
    country: "United States",
    state: "Indiana",
    county: "Fulton",
    coordinates: { latitude: 41.009, longitude: -86.406 },
  },
  {
    name: "Leiters Ford",
    postalCode: 46945,
    country: "United States",
    state: "Indiana",
    county: "Fulton",
    coordinates: { latitude: 41.122, longitude: -86.386 },
  },
  {
    name: "Rochester",
    postalCode: 46975,
    country: "United States",
    state: "Indiana",
    county: "Fulton",
    coordinates: { latitude: 41.065, longitude: -86.231 },
  },
  {
    name: "Grass Creek",
    postalCode: 46935,
    country: "United States",
    state: "Indiana",
    county: "Fulton",
    coordinates: { latitude: 40.948, longitude: -86.404 },
  },
  {
    name: "Haubstadt",
    postalCode: 47639,
    country: "United States",
    state: "Indiana",
    county: "Gibson",
    coordinates: { latitude: 38.19, longitude: -87.58 },
  },
  {
    name: "Hazleton",
    postalCode: 47640,
    country: "United States",
    state: "Indiana",
    county: "Gibson",
    coordinates: { latitude: 38.462, longitude: -87.498 },
  },
  {
    name: "Buckskin",
    postalCode: 47647,
    country: "United States",
    state: "Indiana",
    county: "Gibson",
    coordinates: { latitude: 38.228, longitude: -87.412 },
  },
  {
    name: "Fort Branch",
    postalCode: 47648,
    country: "United States",
    state: "Indiana",
    county: "Gibson",
    coordinates: { latitude: 38.247, longitude: -87.568 },
  },
  {
    name: "Francisco",
    postalCode: 47649,
    country: "United States",
    state: "Indiana",
    county: "Gibson",
    coordinates: { latitude: 38.333, longitude: -87.454 },
  },
  {
    name: "Mackey",
    postalCode: 47654,
    country: "United States",
    state: "Indiana",
    county: "Gibson",
    coordinates: { latitude: 38.252, longitude: -87.392 },
  },
  {
    name: "Oakland City",
    postalCode: 47660,
    country: "United States",
    state: "Indiana",
    county: "Gibson",
    coordinates: { latitude: 38.336, longitude: -87.352 },
  },
  {
    name: "Owensville",
    postalCode: 47665,
    country: "United States",
    state: "Indiana",
    county: "Gibson",
    coordinates: { latitude: 38.274, longitude: -87.709 },
  },
  {
    name: "Patoka",
    postalCode: 47666,
    country: "United States",
    state: "Indiana",
    county: "Gibson",
    coordinates: { latitude: 38.414, longitude: -87.596 },
  },
  {
    name: "Princeton",
    postalCode: 47670,
    country: "United States",
    state: "Indiana",
    county: "Gibson",
    coordinates: { latitude: 38.353, longitude: -87.569 },
  },
  {
    name: "Somerville",
    postalCode: 47683,
    country: "United States",
    state: "Indiana",
    county: "Gibson",
    coordinates: { latitude: 38.276, longitude: -87.378 },
  },
  {
    name: "Fairmount",
    postalCode: 46928,
    country: "United States",
    state: "Indiana",
    county: "Grant",
    coordinates: { latitude: 40.419, longitude: -85.671 },
  },
  {
    name: "Fowlerton",
    postalCode: 46930,
    country: "United States",
    state: "Indiana",
    county: "Grant",
    coordinates: { latitude: 40.41, longitude: -85.571 },
  },
  {
    name: "Gas City",
    postalCode: 46933,
    country: "United States",
    state: "Indiana",
    county: "Grant",
    coordinates: { latitude: 40.488, longitude: -85.606 },
  },
  {
    name: "Jonesboro",
    postalCode: 46938,
    country: "United States",
    state: "Indiana",
    county: "Grant",
    coordinates: { latitude: 40.481, longitude: -85.636 },
  },
  {
    name: "Marion",
    postalCode: 46952,
    country: "United States",
    state: "Indiana",
    county: "Grant",
    coordinates: { latitude: 40.574, longitude: -85.674 },
  },
  {
    name: "Marion",
    postalCode: 46953,
    country: "United States",
    state: "Indiana",
    county: "Grant",
    coordinates: { latitude: 40.536, longitude: -85.662 },
  },
  {
    name: "Matthews",
    postalCode: 46957,
    country: "United States",
    state: "Indiana",
    county: "Grant",
    coordinates: { latitude: 40.389, longitude: -85.499 },
  },
  {
    name: "Swayzee",
    postalCode: 46986,
    country: "United States",
    state: "Indiana",
    county: "Grant",
    coordinates: { latitude: 40.511, longitude: -85.827 },
  },
  {
    name: "Sweetser",
    postalCode: 46987,
    country: "United States",
    state: "Indiana",
    county: "Grant",
    coordinates: { latitude: 40.565, longitude: -85.766 },
  },
  {
    name: "Upland",
    postalCode: 46989,
    country: "United States",
    state: "Indiana",
    county: "Grant",
    coordinates: { latitude: 40.455, longitude: -85.499 },
  },
  {
    name: "Van Buren",
    postalCode: 46991,
    country: "United States",
    state: "Indiana",
    county: "Grant",
    coordinates: { latitude: 40.617, longitude: -85.515 },
  },
  {
    name: "Bloomfield",
    postalCode: 47424,
    country: "United States",
    state: "Indiana",
    county: "Greene",
    coordinates: { latitude: 39.03, longitude: -86.868 },
  },
  {
    name: "Jasonville",
    postalCode: 47438,
    country: "United States",
    state: "Indiana",
    county: "Greene",
    coordinates: { latitude: 39.172, longitude: -87.202 },
  },
  {
    name: "Koleen",
    postalCode: 47439,
    country: "United States",
    state: "Indiana",
    county: "Greene",
    coordinates: { latitude: 39.037, longitude: -86.962 },
  },
  {
    name: "Linton",
    postalCode: 47441,
    country: "United States",
    state: "Indiana",
    county: "Greene",
    coordinates: { latitude: 39.046, longitude: -87.172 },
  },
  {
    name: "Lyons",
    postalCode: 47443,
    country: "United States",
    state: "Indiana",
    county: "Greene",
    coordinates: { latitude: 38.972, longitude: -87.102 },
  },
  {
    name: "Midland",
    postalCode: 47445,
    country: "United States",
    state: "Indiana",
    county: "Greene",
    coordinates: { latitude: 39.122, longitude: -87.192 },
  },
  {
    name: "Newberry",
    postalCode: 47449,
    country: "United States",
    state: "Indiana",
    county: "Greene",
    coordinates: { latitude: 38.923, longitude: -87.008 },
  },
  {
    name: "Owensburg",
    postalCode: 47453,
    country: "United States",
    state: "Indiana",
    county: "Greene",
    coordinates: { latitude: 38.925, longitude: -86.774 },
  },
  {
    name: "Scotland",
    postalCode: 47457,
    country: "United States",
    state: "Indiana",
    county: "Greene",
    coordinates: { latitude: 38.913, longitude: -86.904 },
  },
  {
    name: "Solsberry",
    postalCode: 47459,
    country: "United States",
    state: "Indiana",
    county: "Greene",
    coordinates: { latitude: 39.119, longitude: -86.738 },
  },
  {
    name: "Switz City",
    postalCode: 47465,
    country: "United States",
    state: "Indiana",
    county: "Greene",
    coordinates: { latitude: 39.037, longitude: -87.05 },
  },
  {
    name: "Worthington",
    postalCode: 47471,
    country: "United States",
    state: "Indiana",
    county: "Greene",
    coordinates: { latitude: 39.123, longitude: -86.999 },
  },
  {
    name: "Arcadia",
    postalCode: 46030,
    country: "United States",
    state: "Indiana",
    county: "Hamilton",
    coordinates: { latitude: 40.178, longitude: -86.041 },
  },
  {
    name: "Atlanta",
    postalCode: 46031,
    country: "United States",
    state: "Indiana",
    county: "Hamilton",
    coordinates: { latitude: 40.21, longitude: -86.019 },
  },
  {
    name: "Carmel",
    postalCode: 46032,
    country: "United States",
    state: "Indiana",
    county: "Hamilton",
    coordinates: { latitude: 39.971, longitude: -86.125 },
  },
  {
    name: "Carmel",
    postalCode: 46033,
    country: "United States",
    state: "Indiana",
    county: "Hamilton",
    coordinates: { latitude: 39.974, longitude: -86.083 },
  },
  {
    name: "Cicero",
    postalCode: 46034,
    country: "United States",
    state: "Indiana",
    county: "Hamilton",
    coordinates: { latitude: 40.13, longitude: -86.038 },
  },
  {
    name: "Fishers",
    postalCode: 46038,
    country: "United States",
    state: "Indiana",
    county: "Hamilton",
    coordinates: { latitude: 39.957, longitude: -86.023 },
  },
  {
    name: "Noblesville",
    postalCode: 46060,
    country: "United States",
    state: "Indiana",
    county: "Hamilton",
    coordinates: { latitude: 40.056, longitude: -86.016 },
  },
  {
    name: "Noblesville",
    postalCode: 46061,
    country: "United States",
    state: "Indiana",
    county: "Hamilton",
    coordinates: { latitude: 40.072, longitude: -86.052 },
  },
  {
    name: "Sheridan",
    postalCode: 46069,
    country: "United States",
    state: "Indiana",
    county: "Hamilton",
    coordinates: { latitude: 40.135, longitude: -86.221 },
  },
  {
    name: "Westfield",
    postalCode: 46074,
    country: "United States",
    state: "Indiana",
    county: "Hamilton",
    coordinates: { latitude: 40.049, longitude: -86.15 },
  },
  {
    name: "Carmel",
    postalCode: 46082,
    country: "United States",
    state: "Indiana",
    county: "Hamilton",
    coordinates: { latitude: 40.072, longitude: -86.052 },
  },
  {
    name: "Indianapolis",
    postalCode: 46280,
    country: "United States",
    state: "Indiana",
    county: "Hamilton",
    coordinates: { latitude: 39.942, longitude: -86.116 },
  },
  {
    name: "Indianapolis",
    postalCode: 46288,
    country: "United States",
    state: "Indiana",
    county: "Hamilton",
    coordinates: { latitude: 39.935, longitude: -86.163 },
  },
  {
    name: "Indianapolis",
    postalCode: 46290,
    country: "United States",
    state: "Indiana",
    county: "Hamilton",
    coordinates: { latitude: 39.935, longitude: -86.163 },
  },
  {
    name: "Fishers",
    postalCode: 46037,
    country: "United States",
    state: "Indiana",
    county: "Hamilton",
    coordinates: { latitude: 39.956, longitude: -85.96 },
  },
  {
    name: "Noblesville",
    postalCode: 46062,
    country: "United States",
    state: "Indiana",
    county: "Hamilton",
    coordinates: { latitude: 40.062, longitude: -86.055 },
  },
  {
    name: "Fishers",
    postalCode: 46085,
    country: "United States",
    state: "Indiana",
    county: "Hamilton",
    coordinates: { latitude: 39.956, longitude: -86.014 },
  },
  {
    name: "Indianapolis",
    postalCode: 46262,
    country: "United States",
    state: "Indiana",
    county: "Hamilton",
    coordinates: { latitude: 39.768, longitude: -86.158 },
  },
  {
    name: "Fortville",
    postalCode: 46040,
    country: "United States",
    state: "Indiana",
    county: "Hancock",
    coordinates: { latitude: 39.932, longitude: -85.848 },
  },
  {
    name: "Charlottesville",
    postalCode: 46117,
    country: "United States",
    state: "Indiana",
    county: "Hancock",
    coordinates: { latitude: 39.839, longitude: -85.626 },
  },
  {
    name: "Finly",
    postalCode: 46129,
    country: "United States",
    state: "Indiana",
    county: "Hancock",
    coordinates: { latitude: 39.822, longitude: -85.765 },
  },
  {
    name: "Greenfield",
    postalCode: 46140,
    country: "United States",
    state: "Indiana",
    county: "Hancock",
    coordinates: { latitude: 39.79, longitude: -85.814 },
  },
  {
    name: "Maxwell",
    postalCode: 46154,
    country: "United States",
    state: "Indiana",
    county: "Hancock",
    coordinates: { latitude: 39.857, longitude: -85.768 },
  },
  {
    name: "New Palestine",
    postalCode: 46163,
    country: "United States",
    state: "Indiana",
    county: "Hancock",
    coordinates: { latitude: 39.723, longitude: -85.905 },
  },
  {
    name: "Wilkinson",
    postalCode: 46186,
    country: "United States",
    state: "Indiana",
    county: "Hancock",
    coordinates: { latitude: 39.896, longitude: -85.614 },
  },
  {
    name: "Mccordsville",
    postalCode: 46055,
    country: "United States",
    state: "Indiana",
    county: "Hancock",
    coordinates: { latitude: 39.908, longitude: -85.923 },
  },
  {
    name: "Bradford",
    postalCode: 47107,
    country: "United States",
    state: "Indiana",
    county: "Harrison",
    coordinates: { latitude: 38.368, longitude: -86.062 },
  },
  {
    name: "Central",
    postalCode: 47110,
    country: "United States",
    state: "Indiana",
    county: "Harrison",
    coordinates: { latitude: 38.097, longitude: -86.172 },
  },
  {
    name: "Corydon",
    postalCode: 47112,
    country: "United States",
    state: "Indiana",
    county: "Harrison",
    coordinates: { latitude: 38.219, longitude: -86.114 },
  },
  {
    name: "Crandall",
    postalCode: 47114,
    country: "United States",
    state: "Indiana",
    county: "Harrison",
    coordinates: { latitude: 38.288, longitude: -86.066 },
  },
  {
    name: "Depauw",
    postalCode: 47115,
    country: "United States",
    state: "Indiana",
    county: "Harrison",
    coordinates: { latitude: 38.336, longitude: -86.211 },
  },
  {
    name: "Elizabeth",
    postalCode: 47117,
    country: "United States",
    state: "Indiana",
    county: "Harrison",
    coordinates: { latitude: 38.124, longitude: -85.959 },
  },
  {
    name: "Laconia",
    postalCode: 47135,
    country: "United States",
    state: "Indiana",
    county: "Harrison",
    coordinates: { latitude: 38.053, longitude: -86.084 },
  },
  {
    name: "Lanesville",
    postalCode: 47136,
    country: "United States",
    state: "Indiana",
    county: "Harrison",
    coordinates: { latitude: 38.245, longitude: -85.959 },
  },
  {
    name: "Mauckport",
    postalCode: 47142,
    country: "United States",
    state: "Indiana",
    county: "Harrison",
    coordinates: { latitude: 38.044, longitude: -86.185 },
  },
  {
    name: "New Middletown",
    postalCode: 47160,
    country: "United States",
    state: "Indiana",
    county: "Harrison",
    coordinates: { latitude: 38.164, longitude: -86.051 },
  },
  {
    name: "New Salisbury",
    postalCode: 47161,
    country: "United States",
    state: "Indiana",
    county: "Harrison",
    coordinates: { latitude: 38.34, longitude: -86.089 },
  },
  {
    name: "Palmyra",
    postalCode: 47164,
    country: "United States",
    state: "Indiana",
    county: "Harrison",
    coordinates: { latitude: 38.41, longitude: -86.089 },
  },
  {
    name: "Ramsey",
    postalCode: 47166,
    country: "United States",
    state: "Indiana",
    county: "Harrison",
    coordinates: { latitude: 38.303, longitude: -86.169 },
  },
  {
    name: "Amo",
    postalCode: 46103,
    country: "United States",
    state: "Indiana",
    county: "Hendricks",
    coordinates: { latitude: 39.689, longitude: -86.614 },
  },
  {
    name: "Brownsburg",
    postalCode: 46112,
    country: "United States",
    state: "Indiana",
    county: "Hendricks",
    coordinates: { latitude: 39.847, longitude: -86.387 },
  },
  {
    name: "Clayton",
    postalCode: 46118,
    country: "United States",
    state: "Indiana",
    county: "Hendricks",
    coordinates: { latitude: 39.668, longitude: -86.496 },
  },
  {
    name: "Coatesville",
    postalCode: 46121,
    country: "United States",
    state: "Indiana",
    county: "Hendricks",
    coordinates: { latitude: 39.688, longitude: -86.67 },
  },
  {
    name: "Danville",
    postalCode: 46122,
    country: "United States",
    state: "Indiana",
    county: "Hendricks",
    coordinates: { latitude: 39.763, longitude: -86.534 },
  },
  {
    name: "Lizton",
    postalCode: 46149,
    country: "United States",
    state: "Indiana",
    county: "Hendricks",
    coordinates: { latitude: 39.884, longitude: -86.543 },
  },
  {
    name: "North Salem",
    postalCode: 46165,
    country: "United States",
    state: "Indiana",
    county: "Hendricks",
    coordinates: { latitude: 39.867, longitude: -86.639 },
  },
  {
    name: "Pittsboro",
    postalCode: 46167,
    country: "United States",
    state: "Indiana",
    county: "Hendricks",
    coordinates: { latitude: 39.862, longitude: -86.465 },
  },
  {
    name: "Plainfield",
    postalCode: 46168,
    country: "United States",
    state: "Indiana",
    county: "Hendricks",
    coordinates: { latitude: 39.689, longitude: -86.392 },
  },
  {
    name: "Stilesville",
    postalCode: 46180,
    country: "United States",
    state: "Indiana",
    county: "Hendricks",
    coordinates: { latitude: 39.639, longitude: -86.618 },
  },
  {
    name: "Avon",
    postalCode: 46123,
    country: "United States",
    state: "Indiana",
    county: "Hendricks",
    coordinates: { latitude: 39.763, longitude: -86.4 },
  },
  {
    name: "Knightstown",
    postalCode: 46148,
    country: "United States",
    state: "Indiana",
    county: "Henry",
    coordinates: { latitude: 39.806, longitude: -85.526 },
  },
  {
    name: "Dunreith",
    postalCode: 47337,
    country: "United States",
    state: "Indiana",
    county: "Henry",
    coordinates: { latitude: 39.803, longitude: -85.437 },
  },
  {
    name: "Greensboro",
    postalCode: 47344,
    country: "United States",
    state: "Indiana",
    county: "Henry",
    coordinates: { latitude: 39.879, longitude: -85.464 },
  },
  {
    name: "Kennard",
    postalCode: 47351,
    country: "United States",
    state: "Indiana",
    county: "Henry",
    coordinates: { latitude: 39.904, longitude: -85.521 },
  },
  {
    name: "Lewisville",
    postalCode: 47352,
    country: "United States",
    state: "Indiana",
    county: "Henry",
    coordinates: { latitude: 39.828, longitude: -85.362 },
  },
  {
    name: "Middletown",
    postalCode: 47356,
    country: "United States",
    state: "Indiana",
    county: "Henry",
    coordinates: { latitude: 40.047, longitude: -85.537 },
  },
  {
    name: "Mooreland",
    postalCode: 47360,
    country: "United States",
    state: "Indiana",
    county: "Henry",
    coordinates: { latitude: 39.994, longitude: -85.258 },
  },
  {
    name: "Mount Summit",
    postalCode: 47361,
    country: "United States",
    state: "Indiana",
    county: "Henry",
    coordinates: { latitude: 40.004, longitude: -85.385 },
  },
  {
    name: "New Castle",
    postalCode: 47362,
    country: "United States",
    state: "Indiana",
    county: "Henry",
    coordinates: { latitude: 39.921, longitude: -85.366 },
  },
  {
    name: "New Lisbon",
    postalCode: 47366,
    country: "United States",
    state: "Indiana",
    county: "Henry",
    coordinates: { latitude: 39.863, longitude: -85.263 },
  },
  {
    name: "Shirley",
    postalCode: 47384,
    country: "United States",
    state: "Indiana",
    county: "Henry",
    coordinates: { latitude: 39.916, longitude: -85.518 },
  },
  {
    name: "Spiceland",
    postalCode: 47385,
    country: "United States",
    state: "Indiana",
    county: "Henry",
    coordinates: { latitude: 39.827, longitude: -85.445 },
  },
  {
    name: "Springport",
    postalCode: 47386,
    country: "United States",
    state: "Indiana",
    county: "Henry",
    coordinates: { latitude: 40.053, longitude: -85.383 },
  },
  {
    name: "Straughn",
    postalCode: 47387,
    country: "United States",
    state: "Indiana",
    county: "Henry",
    coordinates: { latitude: 39.832, longitude: -85.272 },
  },
  {
    name: "Sulphur Springs",
    postalCode: 47388,
    country: "United States",
    state: "Indiana",
    county: "Henry",
    coordinates: { latitude: 40.006, longitude: -85.443 },
  },
  {
    name: "Kokomo",
    postalCode: 46901,
    country: "United States",
    state: "Indiana",
    county: "Howard",
    coordinates: { latitude: 40.499, longitude: -86.145 },
  },
  {
    name: "Kokomo",
    postalCode: 46902,
    country: "United States",
    state: "Indiana",
    county: "Howard",
    coordinates: { latitude: 40.451, longitude: -86.135 },
  },
  {
    name: "Kokomo",
    postalCode: 46903,
    country: "United States",
    state: "Indiana",
    county: "Howard",
    coordinates: { latitude: 40.47, longitude: -86.119 },
  },
  {
    name: "Kokomo",
    postalCode: 46904,
    country: "United States",
    state: "Indiana",
    county: "Howard",
    coordinates: { latitude: 40.47, longitude: -86.119 },
  },
  {
    name: "Greentown",
    postalCode: 46936,
    country: "United States",
    state: "Indiana",
    county: "Howard",
    coordinates: { latitude: 40.479, longitude: -85.958 },
  },
  {
    name: "Hemlock",
    postalCode: 46937,
    country: "United States",
    state: "Indiana",
    county: "Howard",
    coordinates: { latitude: 40.419, longitude: -86.018 },
  },
  {
    name: "Oakford",
    postalCode: 46965,
    country: "United States",
    state: "Indiana",
    county: "Howard",
    coordinates: { latitude: 40.413, longitude: -86.101 },
  },
  {
    name: "Russiaville",
    postalCode: 46979,
    country: "United States",
    state: "Indiana",
    county: "Howard",
    coordinates: { latitude: 40.415, longitude: -86.267 },
  },
  {
    name: "West Middleton",
    postalCode: 46995,
    country: "United States",
    state: "Indiana",
    county: "Howard",
    coordinates: { latitude: 40.442, longitude: -86.215 },
  },
  {
    name: "Andrews",
    postalCode: 46702,
    country: "United States",
    state: "Indiana",
    county: "Huntington",
    coordinates: { latitude: 40.862, longitude: -85.607 },
  },
  {
    name: "Bippus",
    postalCode: 46713,
    country: "United States",
    state: "Indiana",
    county: "Huntington",
    coordinates: { latitude: 40.944, longitude: -85.624 },
  },
  {
    name: "Huntington",
    postalCode: 46750,
    country: "United States",
    state: "Indiana",
    county: "Huntington",
    coordinates: { latitude: 40.881, longitude: -85.505 },
  },
  {
    name: "Markle",
    postalCode: 46770,
    country: "United States",
    state: "Indiana",
    county: "Huntington",
    coordinates: { latitude: 40.833, longitude: -85.319 },
  },
  {
    name: "Roanoke",
    postalCode: 46783,
    country: "United States",
    state: "Indiana",
    county: "Huntington",
    coordinates: { latitude: 40.96, longitude: -85.353 },
  },
  {
    name: "Warren",
    postalCode: 46792,
    country: "United States",
    state: "Indiana",
    county: "Huntington",
    coordinates: { latitude: 40.689, longitude: -85.418 },
  },
  {
    name: "Brownstown",
    postalCode: 47220,
    country: "United States",
    state: "Indiana",
    county: "Jackson",
    coordinates: { latitude: 38.884, longitude: -86.049 },
  },
  {
    name: "Cortland",
    postalCode: 47228,
    country: "United States",
    state: "Indiana",
    county: "Jackson",
    coordinates: { latitude: 38.974, longitude: -85.963 },
  },
  {
    name: "Crothersville",
    postalCode: 47229,
    country: "United States",
    state: "Indiana",
    county: "Jackson",
    coordinates: { latitude: 38.807, longitude: -85.847 },
  },
  {
    name: "Freetown",
    postalCode: 47235,
    country: "United States",
    state: "Indiana",
    county: "Jackson",
    coordinates: { latitude: 38.996, longitude: -86.124 },
  },
  {
    name: "Kurtz",
    postalCode: 47249,
    country: "United States",
    state: "Indiana",
    county: "Jackson",
    coordinates: { latitude: 38.961, longitude: -86.203 },
  },
  {
    name: "Medora",
    postalCode: 47260,
    country: "United States",
    state: "Indiana",
    county: "Jackson",
    coordinates: { latitude: 38.826, longitude: -86.19 },
  },
  {
    name: "Norman",
    postalCode: 47264,
    country: "United States",
    state: "Indiana",
    county: "Jackson",
    coordinates: { latitude: 38.953, longitude: -86.275 },
  },
  {
    name: "Seymour",
    postalCode: 47274,
    country: "United States",
    state: "Indiana",
    county: "Jackson",
    coordinates: { latitude: 38.957, longitude: -85.882 },
  },
  {
    name: "Vallonia",
    postalCode: 47281,
    country: "United States",
    state: "Indiana",
    county: "Jackson",
    coordinates: { latitude: 38.817, longitude: -86.069 },
  },
  {
    name: "Demotte",
    postalCode: 46310,
    country: "United States",
    state: "Indiana",
    county: "Jasper",
    coordinates: { latitude: 41.171, longitude: -87.249 },
  },
  {
    name: "Tefft",
    postalCode: 46380,
    country: "United States",
    state: "Indiana",
    county: "Jasper",
    coordinates: { latitude: 41.195, longitude: -86.969 },
  },
  {
    name: "Wheatfield",
    postalCode: 46392,
    country: "United States",
    state: "Indiana",
    county: "Jasper",
    coordinates: { latitude: 41.178, longitude: -87.07 },
  },
  {
    name: "Fair Oaks",
    postalCode: 47943,
    country: "United States",
    state: "Indiana",
    county: "Jasper",
    coordinates: { latitude: 41.075, longitude: -87.258 },
  },
  {
    name: "Remington",
    postalCode: 47977,
    country: "United States",
    state: "Indiana",
    county: "Jasper",
    coordinates: { latitude: 40.767, longitude: -87.16 },
  },
  {
    name: "Rensselaer",
    postalCode: 47978,
    country: "United States",
    state: "Indiana",
    county: "Jasper",
    coordinates: { latitude: 40.995, longitude: -87.104 },
  },
  {
    name: "Bryant",
    postalCode: 47326,
    country: "United States",
    state: "Indiana",
    county: "Jay",
    coordinates: { latitude: 40.545, longitude: -84.912 },
  },
  {
    name: "Dunkirk",
    postalCode: 47336,
    country: "United States",
    state: "Indiana",
    county: "Jay",
    coordinates: { latitude: 40.388, longitude: -85.226 },
  },
  {
    name: "Pennville",
    postalCode: 47369,
    country: "United States",
    state: "Indiana",
    county: "Jay",
    coordinates: { latitude: 40.508, longitude: -85.149 },
  },
  {
    name: "Portland",
    postalCode: 47371,
    country: "United States",
    state: "Indiana",
    county: "Jay",
    coordinates: { latitude: 40.431, longitude: -84.993 },
  },
  {
    name: "Redkey",
    postalCode: 47373,
    country: "United States",
    state: "Indiana",
    county: "Jay",
    coordinates: { latitude: 40.326, longitude: -85.162 },
  },
  {
    name: "Salamonia",
    postalCode: 47381,
    country: "United States",
    state: "Indiana",
    county: "Jay",
    coordinates: { latitude: 40.382, longitude: -84.867 },
  },
  {
    name: "Canaan",
    postalCode: 47224,
    country: "United States",
    state: "Indiana",
    county: "Jefferson",
    coordinates: { latitude: 38.887, longitude: -85.221 },
  },
  {
    name: "Deputy",
    postalCode: 47230,
    country: "United States",
    state: "Indiana",
    county: "Jefferson",
    coordinates: { latitude: 38.776, longitude: -85.63 },
  },
  {
    name: "Dupont",
    postalCode: 47231,
    country: "United States",
    state: "Indiana",
    county: "Jefferson",
    coordinates: { latitude: 38.891, longitude: -85.509 },
  },
  {
    name: "Hanover",
    postalCode: 47243,
    country: "United States",
    state: "Indiana",
    county: "Jefferson",
    coordinates: { latitude: 38.714, longitude: -85.476 },
  },
  {
    name: "Madison",
    postalCode: 47250,
    country: "United States",
    state: "Indiana",
    county: "Jefferson",
    coordinates: { latitude: 38.765, longitude: -85.407 },
  },
  {
    name: "Butlerville",
    postalCode: 47223,
    country: "United States",
    state: "Indiana",
    county: "Jennings",
    coordinates: { latitude: 39.049, longitude: -85.479 },
  },
  {
    name: "Commiskey",
    postalCode: 47227,
    country: "United States",
    state: "Indiana",
    county: "Jennings",
    coordinates: { latitude: 38.853, longitude: -85.643 },
  },
  {
    name: "Hayden",
    postalCode: 47245,
    country: "United States",
    state: "Indiana",
    county: "Jennings",
    coordinates: { latitude: 38.983, longitude: -85.741 },
  },
  {
    name: "North Vernon",
    postalCode: 47265,
    country: "United States",
    state: "Indiana",
    county: "Jennings",
    coordinates: { latitude: 39.002, longitude: -85.627 },
  },
  {
    name: "Vernon",
    postalCode: 47282,
    country: "United States",
    state: "Indiana",
    county: "Jennings",
    coordinates: { latitude: 38.985, longitude: -85.609 },
  },
  {
    name: "Paris Crossing",
    postalCode: 47270,
    country: "United States",
    state: "Indiana",
    county: "Jennings",
    coordinates: { latitude: 38.856, longitude: -85.749 },
  },
  {
    name: "Scipio",
    postalCode: 47273,
    country: "United States",
    state: "Indiana",
    county: "Jennings",
    coordinates: { latitude: 39.067, longitude: -85.713 },
  },
  {
    name: "Bargersville",
    postalCode: 46106,
    country: "United States",
    state: "Indiana",
    county: "Johnson",
    coordinates: { latitude: 39.5, longitude: -86.18 },
  },
  {
    name: "Edinburgh",
    postalCode: 46124,
    country: "United States",
    state: "Indiana",
    county: "Johnson",
    coordinates: { latitude: 39.363, longitude: -85.971 },
  },
  {
    name: "Franklin",
    postalCode: 46131,
    country: "United States",
    state: "Indiana",
    county: "Johnson",
    coordinates: { latitude: 39.485, longitude: -86.061 },
  },
  {
    name: "Greenwood",
    postalCode: 46142,
    country: "United States",
    state: "Indiana",
    county: "Johnson",
    coordinates: { latitude: 39.622, longitude: -86.149 },
  },
  {
    name: "Greenwood",
    postalCode: 46143,
    country: "United States",
    state: "Indiana",
    county: "Johnson",
    coordinates: { latitude: 39.596, longitude: -86.131 },
  },
  {
    name: "Needham",
    postalCode: 46162,
    country: "United States",
    state: "Indiana",
    county: "Johnson",
    coordinates: { latitude: 39.561, longitude: -85.966 },
  },
  {
    name: "Nineveh",
    postalCode: 46164,
    country: "United States",
    state: "Indiana",
    county: "Johnson",
    coordinates: { latitude: 39.366, longitude: -86.098 },
  },
  {
    name: "Trafalgar",
    postalCode: 46181,
    country: "United States",
    state: "Indiana",
    county: "Johnson",
    coordinates: { latitude: 39.37, longitude: -86.184 },
  },
  {
    name: "Whiteland",
    postalCode: 46184,
    country: "United States",
    state: "Indiana",
    county: "Johnson",
    coordinates: { latitude: 39.561, longitude: -86.072 },
  },
  {
    name: "Bicknell",
    postalCode: 47512,
    country: "United States",
    state: "Indiana",
    county: "Knox",
    coordinates: { latitude: 38.773, longitude: -87.314 },
  },
  {
    name: "Bruceville",
    postalCode: 47516,
    country: "United States",
    state: "Indiana",
    county: "Knox",
    coordinates: { latitude: 38.756, longitude: -87.431 },
  },
  {
    name: "Decker",
    postalCode: 47524,
    country: "United States",
    state: "Indiana",
    county: "Knox",
    coordinates: { latitude: 38.508, longitude: -87.554 },
  },
  {
    name: "Edwardsport",
    postalCode: 47528,
    country: "United States",
    state: "Indiana",
    county: "Knox",
    coordinates: { latitude: 38.811, longitude: -87.252 },
  },
  {
    name: "Freelandville",
    postalCode: 47535,
    country: "United States",
    state: "Indiana",
    county: "Knox",
    coordinates: { latitude: 38.863, longitude: -87.313 },
  },
  {
    name: "Monroe City",
    postalCode: 47557,
    country: "United States",
    state: "Indiana",
    county: "Knox",
    coordinates: { latitude: 38.6, longitude: -87.364 },
  },
  {
    name: "Oaktown",
    postalCode: 47561,
    country: "United States",
    state: "Indiana",
    county: "Knox",
    coordinates: { latitude: 38.858, longitude: -87.388 },
  },
  {
    name: "Ragsdale",
    postalCode: 47573,
    country: "United States",
    state: "Indiana",
    county: "Knox",
    coordinates: { latitude: 38.744, longitude: -87.321 },
  },
  {
    name: "Sandborn",
    postalCode: 47578,
    country: "United States",
    state: "Indiana",
    county: "Knox",
    coordinates: { latitude: 38.882, longitude: -87.203 },
  },
  {
    name: "Vincennes",
    postalCode: 47591,
    country: "United States",
    state: "Indiana",
    county: "Knox",
    coordinates: { latitude: 38.673, longitude: -87.51 },
  },
  {
    name: "Westphalia",
    postalCode: 47596,
    country: "United States",
    state: "Indiana",
    county: "Knox",
    coordinates: { latitude: 38.863, longitude: -87.226 },
  },
  {
    name: "Wheatland",
    postalCode: 47597,
    country: "United States",
    state: "Indiana",
    county: "Knox",
    coordinates: { latitude: 38.667, longitude: -87.306 },
  },
  {
    name: "Atwood",
    postalCode: 46502,
    country: "United States",
    state: "Indiana",
    county: "Kosciusko",
    coordinates: { latitude: 41.254, longitude: -85.97 },
  },
  {
    name: "Burket",
    postalCode: 46508,
    country: "United States",
    state: "Indiana",
    county: "Kosciusko",
    coordinates: { latitude: 41.155, longitude: -85.969 },
  },
  {
    name: "Claypool",
    postalCode: 46510,
    country: "United States",
    state: "Indiana",
    county: "Kosciusko",
    coordinates: { latitude: 41.116, longitude: -85.869 },
  },
  {
    name: "Etna Green",
    postalCode: 46524,
    country: "United States",
    state: "Indiana",
    county: "Kosciusko",
    coordinates: { latitude: 41.292, longitude: -86.035 },
  },
  {
    name: "Leesburg",
    postalCode: 46538,
    country: "United States",
    state: "Indiana",
    county: "Kosciusko",
    coordinates: { latitude: 41.327, longitude: -85.816 },
  },
  {
    name: "Mentone",
    postalCode: 46539,
    country: "United States",
    state: "Indiana",
    county: "Kosciusko",
    coordinates: { latitude: 41.161, longitude: -86.03 },
  },
  {
    name: "Milford",
    postalCode: 46542,
    country: "United States",
    state: "Indiana",
    county: "Kosciusko",
    coordinates: { latitude: 41.401, longitude: -85.855 },
  },
  {
    name: "North Webster",
    postalCode: 46555,
    country: "United States",
    state: "Indiana",
    county: "Kosciusko",
    coordinates: { latitude: 41.333, longitude: -85.71 },
  },
  {
    name: "Pierceton",
    postalCode: 46562,
    country: "United States",
    state: "Indiana",
    county: "Kosciusko",
    coordinates: { latitude: 41.212, longitude: -85.706 },
  },
  {
    name: "Syracuse",
    postalCode: 46567,
    country: "United States",
    state: "Indiana",
    county: "Kosciusko",
    coordinates: { latitude: 41.406, longitude: -85.718 },
  },
  {
    name: "Warsaw",
    postalCode: 46580,
    country: "United States",
    state: "Indiana",
    county: "Kosciusko",
    coordinates: { latitude: 41.244, longitude: -85.851 },
  },
  {
    name: "Warsaw",
    postalCode: 46581,
    country: "United States",
    state: "Indiana",
    county: "Kosciusko",
    coordinates: { latitude: 41.239, longitude: -85.864 },
  },
  {
    name: "Winona Lake",
    postalCode: 46590,
    country: "United States",
    state: "Indiana",
    county: "Kosciusko",
    coordinates: { latitude: 41.211, longitude: -85.831 },
  },
  {
    name: "Silver Lake",
    postalCode: 46982,
    country: "United States",
    state: "Indiana",
    county: "Kosciusko",
    coordinates: { latitude: 41.074, longitude: -85.879 },
  },
  {
    name: "Warsaw",
    postalCode: 46582,
    country: "United States",
    state: "Indiana",
    county: "Kosciusko",
    coordinates: { latitude: 41.284, longitude: -85.855 },
  },
  {
    name: "Shipshewana",
    postalCode: 46565,
    country: "United States",
    state: "Indiana",
    county: "LaGrange",
    coordinates: { latitude: 41.663, longitude: -85.593 },
  },
  {
    name: "Topeka",
    postalCode: 46571,
    country: "United States",
    state: "Indiana",
    county: "LaGrange",
    coordinates: { latitude: 41.563, longitude: -85.532 },
  },
  {
    name: "Howe",
    postalCode: 46746,
    country: "United States",
    state: "Indiana",
    county: "LaGrange",
    coordinates: { latitude: 41.729, longitude: -85.473 },
  },
  {
    name: "Lagrange",
    postalCode: 46761,
    country: "United States",
    state: "Indiana",
    county: "LaGrange",
    coordinates: { latitude: 41.652, longitude: -85.404 },
  },
  {
    name: "Mongo",
    postalCode: 46771,
    country: "United States",
    state: "Indiana",
    county: "LaGrange",
    coordinates: { latitude: 41.685, longitude: -85.28 },
  },
  {
    name: "South Milford",
    postalCode: 46786,
    country: "United States",
    state: "Indiana",
    county: "LaGrange",
    coordinates: { latitude: 41.533, longitude: -85.272 },
  },
  {
    name: "Stroh",
    postalCode: 46789,
    country: "United States",
    state: "Indiana",
    county: "LaGrange",
    coordinates: { latitude: 41.583, longitude: -85.199 },
  },
  {
    name: "Wolcottville",
    postalCode: 46795,
    country: "United States",
    state: "Indiana",
    county: "LaGrange",
    coordinates: { latitude: 41.557, longitude: -85.315 },
  },
  {
    name: "Cedar Lake",
    postalCode: 46303,
    country: "United States",
    state: "Indiana",
    county: "Lake",
    coordinates: { latitude: 41.371, longitude: -87.476 },
  },
  {
    name: "Crown Point",
    postalCode: 46307,
    country: "United States",
    state: "Indiana",
    county: "Lake",
    coordinates: { latitude: 41.424, longitude: -87.356 },
  },
  {
    name: "Crown Point",
    postalCode: 46308,
    country: "United States",
    state: "Indiana",
    county: "Lake",
    coordinates: { latitude: 41.417, longitude: -87.365 },
  },
  {
    name: "Dyer",
    postalCode: 46311,
    country: "United States",
    state: "Indiana",
    county: "Lake",
    coordinates: { latitude: 41.492, longitude: -87.511 },
  },
  {
    name: "East Chicago",
    postalCode: 46312,
    country: "United States",
    state: "Indiana",
    county: "Lake",
    coordinates: { latitude: 41.635, longitude: -87.463 },
  },
  {
    name: "Griffith",
    postalCode: 46319,
    country: "United States",
    state: "Indiana",
    county: "Lake",
    coordinates: { latitude: 41.534, longitude: -87.423 },
  },
  {
    name: "Hammond",
    postalCode: 46320,
    country: "United States",
    state: "Indiana",
    county: "Lake",
    coordinates: { latitude: 41.61, longitude: -87.508 },
  },
  {
    name: "Munster",
    postalCode: 46321,
    country: "United States",
    state: "Indiana",
    county: "Lake",
    coordinates: { latitude: 41.554, longitude: -87.501 },
  },
  {
    name: "Highland",
    postalCode: 46322,
    country: "United States",
    state: "Indiana",
    county: "Lake",
    coordinates: { latitude: 41.55, longitude: -87.457 },
  },
  {
    name: "Hammond",
    postalCode: 46323,
    country: "United States",
    state: "Indiana",
    county: "Lake",
    coordinates: { latitude: 41.588, longitude: -87.453 },
  },
  {
    name: "Hammond",
    postalCode: 46324,
    country: "United States",
    state: "Indiana",
    county: "Lake",
    coordinates: { latitude: 41.584, longitude: -87.503 },
  },
  {
    name: "Hammond",
    postalCode: 46325,
    country: "United States",
    state: "Indiana",
    county: "Lake",
    coordinates: { latitude: 41.462, longitude: -87.373 },
  },
  {
    name: "Hammond",
    postalCode: 46327,
    country: "United States",
    state: "Indiana",
    county: "Lake",
    coordinates: { latitude: 41.633, longitude: -87.511 },
  },
  {
    name: "Hobart",
    postalCode: 46342,
    country: "United States",
    state: "Indiana",
    county: "Lake",
    coordinates: { latitude: 41.526, longitude: -87.252 },
  },
  {
    name: "Leroy",
    postalCode: 46355,
    country: "United States",
    state: "Indiana",
    county: "Lake",
    coordinates: { latitude: 41.359, longitude: -87.271 },
  },
  {
    name: "Lowell",
    postalCode: 46356,
    country: "United States",
    state: "Indiana",
    county: "Lake",
    coordinates: { latitude: 41.285, longitude: -87.419 },
  },
  {
    name: "Saint John",
    postalCode: 46373,
    country: "United States",
    state: "Indiana",
    county: "Lake",
    coordinates: { latitude: 41.449, longitude: -87.476 },
  },
  {
    name: "Schererville",
    postalCode: 46375,
    country: "United States",
    state: "Indiana",
    county: "Lake",
    coordinates: { latitude: 41.492, longitude: -87.461 },
  },
  {
    name: "Schneider",
    postalCode: 46376,
    country: "United States",
    state: "Indiana",
    county: "Lake",
    coordinates: { latitude: 41.19, longitude: -87.478 },
  },
  {
    name: "Shelby",
    postalCode: 46377,
    country: "United States",
    state: "Indiana",
    county: "Lake",
    coordinates: { latitude: 41.192, longitude: -87.34 },
  },
  {
    name: "Whiting",
    postalCode: 46394,
    country: "United States",
    state: "Indiana",
    county: "Lake",
    coordinates: { latitude: 41.679, longitude: -87.501 },
  },
  {
    name: "Gary",
    postalCode: 46401,
    country: "United States",
    state: "Indiana",
    county: "Lake",
    coordinates: { latitude: 41.591, longitude: -87.32 },
  },
  {
    name: "Gary",
    postalCode: 46402,
    country: "United States",
    state: "Indiana",
    county: "Lake",
    coordinates: { latitude: 41.6, longitude: -87.339 },
  },
  {
    name: "Gary",
    postalCode: 46403,
    country: "United States",
    state: "Indiana",
    county: "Lake",
    coordinates: { latitude: 41.604, longitude: -87.259 },
  },
  {
    name: "Gary",
    postalCode: 46404,
    country: "United States",
    state: "Indiana",
    county: "Lake",
    coordinates: { latitude: 41.59, longitude: -87.373 },
  },
  {
    name: "Lake Station",
    postalCode: 46405,
    country: "United States",
    state: "Indiana",
    county: "Lake",
    coordinates: { latitude: 41.569, longitude: -87.262 },
  },
  {
    name: "Gary",
    postalCode: 46406,
    country: "United States",
    state: "Indiana",
    county: "Lake",
    coordinates: { latitude: 41.588, longitude: -87.406 },
  },
  {
    name: "Gary",
    postalCode: 46407,
    country: "United States",
    state: "Indiana",
    county: "Lake",
    coordinates: { latitude: 41.58, longitude: -87.335 },
  },
  {
    name: "Gary",
    postalCode: 46408,
    country: "United States",
    state: "Indiana",
    county: "Lake",
    coordinates: { latitude: 41.542, longitude: -87.359 },
  },
  {
    name: "Gary",
    postalCode: 46409,
    country: "United States",
    state: "Indiana",
    county: "Lake",
    coordinates: { latitude: 41.541, longitude: -87.327 },
  },
  {
    name: "Merrillville",
    postalCode: 46410,
    country: "United States",
    state: "Indiana",
    county: "Lake",
    coordinates: { latitude: 41.496, longitude: -87.351 },
  },
  {
    name: "Merrillville",
    postalCode: 46411,
    country: "United States",
    state: "Indiana",
    county: "Lake",
    coordinates: { latitude: 41.462, longitude: -87.373 },
  },
  {
    name: "Hanna",
    postalCode: 46340,
    country: "United States",
    state: "Indiana",
    county: "LaPorte",
    coordinates: { latitude: 41.409, longitude: -86.776 },
  },
  {
    name: "Kingsbury",
    postalCode: 46345,
    country: "United States",
    state: "Indiana",
    county: "LaPorte",
    coordinates: { latitude: 41.526, longitude: -86.7 },
  },
  {
    name: "Kingsford Heights",
    postalCode: 46346,
    country: "United States",
    state: "Indiana",
    county: "LaPorte",
    coordinates: { latitude: 41.48, longitude: -86.692 },
  },
  {
    name: "La Crosse",
    postalCode: 46348,
    country: "United States",
    state: "Indiana",
    county: "LaPorte",
    coordinates: { latitude: 41.316, longitude: -86.868 },
  },
  {
    name: "La Porte",
    postalCode: 46350,
    country: "United States",
    state: "Indiana",
    county: "LaPorte",
    coordinates: { latitude: 41.599, longitude: -86.708 },
  },
  {
    name: "La Porte",
    postalCode: 46352,
    country: "United States",
    state: "Indiana",
    county: "LaPorte",
    coordinates: { latitude: 41.499, longitude: -86.71 },
  },
  {
    name: "Michigan City",
    postalCode: 46360,
    country: "United States",
    state: "Indiana",
    county: "LaPorte",
    coordinates: { latitude: 41.698, longitude: -86.87 },
  },
  {
    name: "Michigan City",
    postalCode: 46361,
    country: "United States",
    state: "Indiana",
    county: "LaPorte",
    coordinates: { latitude: 41.704, longitude: -86.915 },
  },
  {
    name: "Mill Creek",
    postalCode: 46365,
    country: "United States",
    state: "Indiana",
    county: "LaPorte",
    coordinates: { latitude: 41.609, longitude: -86.544 },
  },
  {
    name: "Rolling Prairie",
    postalCode: 46371,
    country: "United States",
    state: "Indiana",
    county: "LaPorte",
    coordinates: { latitude: 41.723, longitude: -86.584 },
  },
  {
    name: "Union Mills",
    postalCode: 46382,
    country: "United States",
    state: "Indiana",
    county: "LaPorte",
    coordinates: { latitude: 41.46, longitude: -86.836 },
  },
  {
    name: "Wanatah",
    postalCode: 46390,
    country: "United States",
    state: "Indiana",
    county: "LaPorte",
    coordinates: { latitude: 41.384, longitude: -86.876 },
  },
  {
    name: "Westville",
    postalCode: 46391,
    country: "United States",
    state: "Indiana",
    county: "LaPorte",
    coordinates: { latitude: 41.537, longitude: -86.901 },
  },
  {
    name: "Avoca",
    postalCode: 47420,
    country: "United States",
    state: "Indiana",
    county: "Lawrence",
    coordinates: { latitude: 38.913, longitude: -86.549 },
  },
  {
    name: "Bedford",
    postalCode: 47421,
    country: "United States",
    state: "Indiana",
    county: "Lawrence",
    coordinates: { latitude: 38.873, longitude: -86.487 },
  },
  {
    name: "Heltonville",
    postalCode: 47436,
    country: "United States",
    state: "Indiana",
    county: "Lawrence",
    coordinates: { latitude: 38.948, longitude: -86.37 },
  },
  {
    name: "Huron",
    postalCode: 47437,
    country: "United States",
    state: "Indiana",
    county: "Lawrence",
    coordinates: { latitude: 38.722, longitude: -86.671 },
  },
  {
    name: "Mitchell",
    postalCode: 47446,
    country: "United States",
    state: "Indiana",
    county: "Lawrence",
    coordinates: { latitude: 38.743, longitude: -86.476 },
  },
  {
    name: "Oolitic",
    postalCode: 47451,
    country: "United States",
    state: "Indiana",
    county: "Lawrence",
    coordinates: { latitude: 38.894, longitude: -86.525 },
  },
  {
    name: "Springville",
    postalCode: 47462,
    country: "United States",
    state: "Indiana",
    county: "Lawrence",
    coordinates: { latitude: 38.951, longitude: -86.614 },
  },
  {
    name: "Tunnelton",
    postalCode: 47467,
    country: "United States",
    state: "Indiana",
    county: "Lawrence",
    coordinates: { latitude: 38.769, longitude: -86.343 },
  },
  {
    name: "Williams",
    postalCode: 47470,
    country: "United States",
    state: "Indiana",
    county: "Lawrence",
    coordinates: { latitude: 38.773, longitude: -86.628 },
  },
  {
    name: "Alexandria",
    postalCode: 46001,
    country: "United States",
    state: "Indiana",
    county: "Madison",
    coordinates: { latitude: 40.256, longitude: -85.668 },
  },
  {
    name: "Anderson",
    postalCode: 46011,
    country: "United States",
    state: "Indiana",
    county: "Madison",
    coordinates: { latitude: 40.115, longitude: -85.725 },
  },
  {
    name: "Anderson",
    postalCode: 46012,
    country: "United States",
    state: "Indiana",
    county: "Madison",
    coordinates: { latitude: 40.131, longitude: -85.654 },
  },
  {
    name: "Anderson",
    postalCode: 46013,
    country: "United States",
    state: "Indiana",
    county: "Madison",
    coordinates: { latitude: 40.062, longitude: -85.68 },
  },
  {
    name: "Anderson",
    postalCode: 46014,
    country: "United States",
    state: "Indiana",
    county: "Madison",
    coordinates: { latitude: 40.162, longitude: -85.72 },
  },
  {
    name: "Anderson",
    postalCode: 46015,
    country: "United States",
    state: "Indiana",
    county: "Madison",
    coordinates: { latitude: 40.094, longitude: -85.658 },
  },
  {
    name: "Anderson",
    postalCode: 46016,
    country: "United States",
    state: "Indiana",
    county: "Madison",
    coordinates: { latitude: 40.099, longitude: -85.685 },
  },
  {
    name: "Anderson",
    postalCode: 46017,
    country: "United States",
    state: "Indiana",
    county: "Madison",
    coordinates: { latitude: 40.074, longitude: -85.607 },
  },
  {
    name: "Anderson",
    postalCode: 46018,
    country: "United States",
    state: "Indiana",
    county: "Madison",
    coordinates: { latitude: 40.162, longitude: -85.72 },
  },
  {
    name: "Elwood",
    postalCode: 46036,
    country: "United States",
    state: "Indiana",
    county: "Madison",
    coordinates: { latitude: 40.28, longitude: -85.839 },
  },
  {
    name: "Frankton",
    postalCode: 46044,
    country: "United States",
    state: "Indiana",
    county: "Madison",
    coordinates: { latitude: 40.229, longitude: -85.779 },
  },
  {
    name: "Ingalls",
    postalCode: 46048,
    country: "United States",
    state: "Indiana",
    county: "Madison",
    coordinates: { latitude: 39.957, longitude: -85.798 },
  },
  {
    name: "Lapel",
    postalCode: 46051,
    country: "United States",
    state: "Indiana",
    county: "Madison",
    coordinates: { latitude: 40.085, longitude: -85.844 },
  },
  {
    name: "Markleville",
    postalCode: 46056,
    country: "United States",
    state: "Indiana",
    county: "Madison",
    coordinates: { latitude: 39.994, longitude: -85.623 },
  },
  {
    name: "Orestes",
    postalCode: 46063,
    country: "United States",
    state: "Indiana",
    county: "Madison",
    coordinates: { latitude: 40.272, longitude: -85.728 },
  },
  {
    name: "Pendleton",
    postalCode: 46064,
    country: "United States",
    state: "Indiana",
    county: "Madison",
    coordinates: { latitude: 39.998, longitude: -85.747 },
  },
  {
    name: "Summitville",
    postalCode: 46070,
    country: "United States",
    state: "Indiana",
    county: "Madison",
    coordinates: { latitude: 40.34, longitude: -85.64 },
  },
  {
    name: "Beech Grove",
    postalCode: 46107,
    country: "United States",
    state: "Indiana",
    county: "Marion",
    coordinates: { latitude: 39.715, longitude: -86.093 },
  },
  {
    name: "Camby",
    postalCode: 46113,
    country: "United States",
    state: "Indiana",
    county: "Marion",
    coordinates: { latitude: 39.641, longitude: -86.312 },
  },
  {
    name: "West Newton",
    postalCode: 46183,
    country: "United States",
    state: "Indiana",
    county: "Marion",
    coordinates: { latitude: 39.653, longitude: -86.283 },
  },
  {
    name: "Indianapolis",
    postalCode: 46201,
    country: "United States",
    state: "Indiana",
    county: "Marion",
    coordinates: { latitude: 39.775, longitude: -86.109 },
  },
  {
    name: "Indianapolis",
    postalCode: 46202,
    country: "United States",
    state: "Indiana",
    county: "Marion",
    coordinates: { latitude: 39.785, longitude: -86.16 },
  },
  {
    name: "Indianapolis",
    postalCode: 46203,
    country: "United States",
    state: "Indiana",
    county: "Marion",
    coordinates: { latitude: 39.743, longitude: -86.118 },
  },
  {
    name: "Indianapolis",
    postalCode: 46204,
    country: "United States",
    state: "Indiana",
    county: "Marion",
    coordinates: { latitude: 39.772, longitude: -86.153 },
  },
  {
    name: "Indianapolis",
    postalCode: 46205,
    country: "United States",
    state: "Indiana",
    county: "Marion",
    coordinates: { latitude: 39.827, longitude: -86.139 },
  },
  {
    name: "Indianapolis",
    postalCode: 46206,
    country: "United States",
    state: "Indiana",
    county: "Marion",
    coordinates: { latitude: 39.761, longitude: -86.161 },
  },
  {
    name: "Indianapolis",
    postalCode: 46207,
    country: "United States",
    state: "Indiana",
    county: "Marion",
    coordinates: { latitude: 39.767, longitude: -86.161 },
  },
  {
    name: "Indianapolis",
    postalCode: 46208,
    country: "United States",
    state: "Indiana",
    county: "Marion",
    coordinates: { latitude: 39.83, longitude: -86.179 },
  },
  {
    name: "Indianapolis",
    postalCode: 46209,
    country: "United States",
    state: "Indiana",
    county: "Marion",
    coordinates: { latitude: 39.779, longitude: -86.133 },
  },
  {
    name: "Indianapolis",
    postalCode: 46211,
    country: "United States",
    state: "Indiana",
    county: "Marion",
    coordinates: { latitude: 39.779, longitude: -86.133 },
  },
  {
    name: "Indianapolis",
    postalCode: 46214,
    country: "United States",
    state: "Indiana",
    county: "Marion",
    coordinates: { latitude: 39.792, longitude: -86.287 },
  },
  {
    name: "Indianapolis",
    postalCode: 46216,
    country: "United States",
    state: "Indiana",
    county: "Marion",
    coordinates: { latitude: 39.865, longitude: -86.014 },
  },
  {
    name: "Indianapolis",
    postalCode: 46217,
    country: "United States",
    state: "Indiana",
    county: "Marion",
    coordinates: { latitude: 39.678, longitude: -86.198 },
  },
  {
    name: "Indianapolis",
    postalCode: 46218,
    country: "United States",
    state: "Indiana",
    county: "Marion",
    coordinates: { latitude: 39.808, longitude: -86.101 },
  },
  {
    name: "Indianapolis",
    postalCode: 46219,
    country: "United States",
    state: "Indiana",
    county: "Marion",
    coordinates: { latitude: 39.782, longitude: -86.05 },
  },
  {
    name: "Indianapolis",
    postalCode: 46220,
    country: "United States",
    state: "Indiana",
    county: "Marion",
    coordinates: { latitude: 39.865, longitude: -86.118 },
  },
  {
    name: "Indianapolis",
    postalCode: 46221,
    country: "United States",
    state: "Indiana",
    county: "Marion",
    coordinates: { latitude: 39.751, longitude: -86.192 },
  },
  {
    name: "Indianapolis",
    postalCode: 46222,
    country: "United States",
    state: "Indiana",
    county: "Marion",
    coordinates: { latitude: 39.789, longitude: -86.214 },
  },
  {
    name: "Indianapolis",
    postalCode: 46224,
    country: "United States",
    state: "Indiana",
    county: "Marion",
    coordinates: { latitude: 39.794, longitude: -86.271 },
  },
  {
    name: "Indianapolis",
    postalCode: 46225,
    country: "United States",
    state: "Indiana",
    county: "Marion",
    coordinates: { latitude: 39.741, longitude: -86.157 },
  },
  {
    name: "Indianapolis",
    postalCode: 46226,
    country: "United States",
    state: "Indiana",
    county: "Marion",
    coordinates: { latitude: 39.833, longitude: -86.084 },
  },
  {
    name: "Indianapolis",
    postalCode: 46227,
    country: "United States",
    state: "Indiana",
    county: "Marion",
    coordinates: { latitude: 39.679, longitude: -86.13 },
  },
  {
    name: "Indianapolis",
    postalCode: 46228,
    country: "United States",
    state: "Indiana",
    county: "Marion",
    coordinates: { latitude: 39.846, longitude: -86.195 },
  },
  {
    name: "Indianapolis",
    postalCode: 46229,
    country: "United States",
    state: "Indiana",
    county: "Marion",
    coordinates: { latitude: 39.789, longitude: -85.978 },
  },
  {
    name: "Indianapolis",
    postalCode: 46230,
    country: "United States",
    state: "Indiana",
    county: "Marion",
    coordinates: { latitude: 39.779, longitude: -86.133 },
  },
  {
    name: "Indianapolis",
    postalCode: 46231,
    country: "United States",
    state: "Indiana",
    county: "Marion",
    coordinates: { latitude: 39.704, longitude: -86.303 },
  },
  {
    name: "Indianapolis",
    postalCode: 46234,
    country: "United States",
    state: "Indiana",
    county: "Marion",
    coordinates: { latitude: 39.809, longitude: -86.316 },
  },
  {
    name: "Indianapolis",
    postalCode: 46235,
    country: "United States",
    state: "Indiana",
    county: "Marion",
    coordinates: { latitude: 39.847, longitude: -85.974 },
  },
  {
    name: "Indianapolis",
    postalCode: 46236,
    country: "United States",
    state: "Indiana",
    county: "Marion",
    coordinates: { latitude: 39.869, longitude: -85.977 },
  },
  {
    name: "Indianapolis",
    postalCode: 46237,
    country: "United States",
    state: "Indiana",
    county: "Marion",
    coordinates: { latitude: 39.673, longitude: -86.076 },
  },
  {
    name: "Indianapolis",
    postalCode: 46239,
    country: "United States",
    state: "Indiana",
    county: "Marion",
    coordinates: { latitude: 39.726, longitude: -86 },
  },
  {
    name: "Indianapolis",
    postalCode: 46240,
    country: "United States",
    state: "Indiana",
    county: "Marion",
    coordinates: { latitude: 39.907, longitude: -86.124 },
  },
  {
    name: "Indianapolis",
    postalCode: 46241,
    country: "United States",
    state: "Indiana",
    county: "Marion",
    coordinates: { latitude: 39.71, longitude: -86.261 },
  },
  {
    name: "Indianapolis",
    postalCode: 46242,
    country: "United States",
    state: "Indiana",
    county: "Marion",
    coordinates: { latitude: 39.779, longitude: -86.133 },
  },
  {
    name: "Indianapolis",
    postalCode: 46244,
    country: "United States",
    state: "Indiana",
    county: "Marion",
    coordinates: { latitude: 39.779, longitude: -86.133 },
  },
  {
    name: "Indianapolis",
    postalCode: 46247,
    country: "United States",
    state: "Indiana",
    county: "Marion",
    coordinates: { latitude: 39.779, longitude: -86.133 },
  },
  {
    name: "Indianapolis",
    postalCode: 46249,
    country: "United States",
    state: "Indiana",
    county: "Marion",
    coordinates: { latitude: 39.859, longitude: -86.006 },
  },
  {
    name: "Indianapolis",
    postalCode: 46250,
    country: "United States",
    state: "Indiana",
    county: "Marion",
    coordinates: { latitude: 39.905, longitude: -86.067 },
  },
  {
    name: "Indianapolis",
    postalCode: 46251,
    country: "United States",
    state: "Indiana",
    county: "Marion",
    coordinates: { latitude: 39.779, longitude: -86.133 },
  },
  {
    name: "Indianapolis",
    postalCode: 46253,
    country: "United States",
    state: "Indiana",
    county: "Marion",
    coordinates: { latitude: 39.779, longitude: -86.133 },
  },
  {
    name: "Indianapolis",
    postalCode: 46254,
    country: "United States",
    state: "Indiana",
    county: "Marion",
    coordinates: { latitude: 39.849, longitude: -86.272 },
  },
  {
    name: "Indianapolis",
    postalCode: 46255,
    country: "United States",
    state: "Indiana",
    county: "Marion",
    coordinates: { latitude: 39.768, longitude: -86.158 },
  },
  {
    name: "Indianapolis",
    postalCode: 46256,
    country: "United States",
    state: "Indiana",
    county: "Marion",
    coordinates: { latitude: 39.898, longitude: -86.009 },
  },
  {
    name: "Indianapolis",
    postalCode: 46259,
    country: "United States",
    state: "Indiana",
    county: "Marion",
    coordinates: { latitude: 39.667, longitude: -85.998 },
  },
  {
    name: "Indianapolis",
    postalCode: 46260,
    country: "United States",
    state: "Indiana",
    county: "Marion",
    coordinates: { latitude: 39.898, longitude: -86.18 },
  },
  {
    name: "Indianapolis",
    postalCode: 46266,
    country: "United States",
    state: "Indiana",
    county: "Marion",
    coordinates: { latitude: 39.768, longitude: -86.158 },
  },
  {
    name: "Indianapolis",
    postalCode: 46268,
    country: "United States",
    state: "Indiana",
    county: "Marion",
    coordinates: { latitude: 39.868, longitude: -86.212 },
  },
  {
    name: "Indianapolis",
    postalCode: 46274,
    country: "United States",
    state: "Indiana",
    county: "Marion",
    coordinates: { latitude: 39.779, longitude: -86.133 },
  },
  {
    name: "Indianapolis",
    postalCode: 46275,
    country: "United States",
    state: "Indiana",
    county: "Marion",
    coordinates: { latitude: 39.779, longitude: -86.133 },
  },
  {
    name: "Indianapolis",
    postalCode: 46277,
    country: "United States",
    state: "Indiana",
    county: "Marion",
    coordinates: { latitude: 39.779, longitude: -86.133 },
  },
  {
    name: "Indianapolis",
    postalCode: 46278,
    country: "United States",
    state: "Indiana",
    county: "Marion",
    coordinates: { latitude: 39.873, longitude: -86.277 },
  },
  {
    name: "Indianapolis",
    postalCode: 46282,
    country: "United States",
    state: "Indiana",
    county: "Marion",
    coordinates: { latitude: 39.779, longitude: -86.133 },
  },
  {
    name: "Indianapolis",
    postalCode: 46283,
    country: "United States",
    state: "Indiana",
    county: "Marion",
    coordinates: { latitude: 39.779, longitude: -86.133 },
  },
  {
    name: "Indianapolis",
    postalCode: 46285,
    country: "United States",
    state: "Indiana",
    county: "Marion",
    coordinates: { latitude: 39.779, longitude: -86.133 },
  },
  {
    name: "Indianapolis",
    postalCode: 46291,
    country: "United States",
    state: "Indiana",
    county: "Marion",
    coordinates: { latitude: 39.779, longitude: -86.133 },
  },
  {
    name: "Indianapolis",
    postalCode: 46295,
    country: "United States",
    state: "Indiana",
    county: "Marion",
    coordinates: { latitude: 39.779, longitude: -86.133 },
  },
  {
    name: "Indianapolis",
    postalCode: 46298,
    country: "United States",
    state: "Indiana",
    county: "Marion",
    coordinates: { latitude: 39.779, longitude: -86.133 },
  },
  {
    name: "Indianapolis",
    postalCode: 46213,
    country: "United States",
    state: "Indiana",
    county: "Marion",
    coordinates: { latitude: 39.921, longitude: -86.034 },
  },
  {
    name: "Indianapolis",
    postalCode: 46296,
    country: "United States",
    state: "Indiana",
    county: "Marion",
    coordinates: { latitude: 39.796, longitude: -86.15 },
  },
  {
    name: "Argos",
    postalCode: 46501,
    country: "United States",
    state: "Indiana",
    county: "Marshall",
    coordinates: { latitude: 41.231, longitude: -86.251 },
  },
  {
    name: "Bourbon",
    postalCode: 46504,
    country: "United States",
    state: "Indiana",
    county: "Marshall",
    coordinates: { latitude: 41.31, longitude: -86.117 },
  },
  {
    name: "Bremen",
    postalCode: 46506,
    country: "United States",
    state: "Indiana",
    county: "Marshall",
    coordinates: { latitude: 41.447, longitude: -86.193 },
  },
  {
    name: "Culver",
    postalCode: 46511,
    country: "United States",
    state: "Indiana",
    county: "Marshall",
    coordinates: { latitude: 41.22, longitude: -86.427 },
  },
  {
    name: "Donaldson",
    postalCode: 46513,
    country: "United States",
    state: "Indiana",
    county: "Marshall",
    coordinates: { latitude: 41.362, longitude: -86.444 },
  },
  {
    name: "Lapaz",
    postalCode: 46537,
    country: "United States",
    state: "Indiana",
    county: "Marshall",
    coordinates: { latitude: 41.46, longitude: -86.31 },
  },
  {
    name: "Plymouth",
    postalCode: 46563,
    country: "United States",
    state: "Indiana",
    county: "Marshall",
    coordinates: { latitude: 41.353, longitude: -86.301 },
  },
  {
    name: "Tippecanoe",
    postalCode: 46570,
    country: "United States",
    state: "Indiana",
    county: "Marshall",
    coordinates: { latitude: 41.217, longitude: -86.11 },
  },
  {
    name: "Tyner",
    postalCode: 46572,
    country: "United States",
    state: "Indiana",
    county: "Marshall",
    coordinates: { latitude: 41.41, longitude: -86.403 },
  },
  {
    name: "Crane",
    postalCode: 47522,
    country: "United States",
    state: "Indiana",
    county: "Martin",
    coordinates: { latitude: 38.895, longitude: -86.9 },
  },
  {
    name: "Loogootee",
    postalCode: 47553,
    country: "United States",
    state: "Indiana",
    county: "Martin",
    coordinates: { latitude: 38.663, longitude: -86.914 },
  },
  {
    name: "Shoals",
    postalCode: 47581,
    country: "United States",
    state: "Indiana",
    county: "Martin",
    coordinates: { latitude: 38.679, longitude: -86.776 },
  },
  {
    name: "Amboy",
    postalCode: 46911,
    country: "United States",
    state: "Indiana",
    county: "Miami",
    coordinates: { latitude: 40.611, longitude: -85.95 },
  },
  {
    name: "Bunker Hill",
    postalCode: 46914,
    country: "United States",
    state: "Indiana",
    county: "Miami",
    coordinates: { latitude: 40.642, longitude: -86.096 },
  },
  {
    name: "Converse",
    postalCode: 46919,
    country: "United States",
    state: "Indiana",
    county: "Miami",
    coordinates: { latitude: 40.577, longitude: -85.876 },
  },
  {
    name: "Deedsville",
    postalCode: 46921,
    country: "United States",
    state: "Indiana",
    county: "Miami",
    coordinates: { latitude: 40.91, longitude: -86.101 },
  },
  {
    name: "Denver",
    postalCode: 46926,
    country: "United States",
    state: "Indiana",
    county: "Miami",
    coordinates: { latitude: 40.867, longitude: -86.075 },
  },
  {
    name: "Macy",
    postalCode: 46951,
    country: "United States",
    state: "Indiana",
    county: "Miami",
    coordinates: { latitude: 40.962, longitude: -86.126 },
  },
  {
    name: "Mexico",
    postalCode: 46958,
    country: "United States",
    state: "Indiana",
    county: "Miami",
    coordinates: { latitude: 40.822, longitude: -86.116 },
  },
  {
    name: "Miami",
    postalCode: 46959,
    country: "United States",
    state: "Indiana",
    county: "Miami",
    coordinates: { latitude: 40.615, longitude: -86.106 },
  },
  {
    name: "Peru",
    postalCode: 46970,
    country: "United States",
    state: "Indiana",
    county: "Miami",
    coordinates: { latitude: 40.749, longitude: -86.068 },
  },
  {
    name: "Grissom Arb",
    postalCode: 46971,
    country: "United States",
    state: "Indiana",
    county: "Miami",
    coordinates: { latitude: 40.668, longitude: -86.138 },
  },
  {
    name: "Bloomington",
    postalCode: 47401,
    country: "United States",
    state: "Indiana",
    county: "Monroe",
    coordinates: { latitude: 39.14, longitude: -86.508 },
  },
  {
    name: "Bloomington",
    postalCode: 47402,
    country: "United States",
    state: "Indiana",
    county: "Monroe",
    coordinates: { latitude: 39.173, longitude: -86.502 },
  },
  {
    name: "Bloomington",
    postalCode: 47403,
    country: "United States",
    state: "Indiana",
    county: "Monroe",
    coordinates: { latitude: 39.126, longitude: -86.577 },
  },
  {
    name: "Bloomington",
    postalCode: 47404,
    country: "United States",
    state: "Indiana",
    county: "Monroe",
    coordinates: { latitude: 39.195, longitude: -86.576 },
  },
  {
    name: "Bloomington",
    postalCode: 47405,
    country: "United States",
    state: "Indiana",
    county: "Monroe",
    coordinates: { latitude: 39.168, longitude: -86.519 },
  },
  {
    name: "Bloomington",
    postalCode: 47406,
    country: "United States",
    state: "Indiana",
    county: "Monroe",
    coordinates: { latitude: 39.175, longitude: -86.518 },
  },
  {
    name: "Bloomington",
    postalCode: 47407,
    country: "United States",
    state: "Indiana",
    county: "Monroe",
    coordinates: { latitude: 39.173, longitude: -86.502 },
  },
  {
    name: "Bloomington",
    postalCode: 47408,
    country: "United States",
    state: "Indiana",
    county: "Monroe",
    coordinates: { latitude: 39.23, longitude: -86.469 },
  },
  {
    name: "Clear Creek",
    postalCode: 47426,
    country: "United States",
    state: "Indiana",
    county: "Monroe",
    coordinates: { latitude: 39.173, longitude: -86.502 },
  },
  {
    name: "Ellettsville",
    postalCode: 47429,
    country: "United States",
    state: "Indiana",
    county: "Monroe",
    coordinates: { latitude: 39.254, longitude: -86.62 },
  },
  {
    name: "Harrodsburg",
    postalCode: 47434,
    country: "United States",
    state: "Indiana",
    county: "Monroe",
    coordinates: { latitude: 39.013, longitude: -86.546 },
  },
  {
    name: "Smithville",
    postalCode: 47458,
    country: "United States",
    state: "Indiana",
    county: "Monroe",
    coordinates: { latitude: 39.071, longitude: -86.507 },
  },
  {
    name: "Stanford",
    postalCode: 47463,
    country: "United States",
    state: "Indiana",
    county: "Monroe",
    coordinates: { latitude: 39.086, longitude: -86.669 },
  },
  {
    name: "Stinesville",
    postalCode: 47464,
    country: "United States",
    state: "Indiana",
    county: "Monroe",
    coordinates: { latitude: 39.3, longitude: -86.648 },
  },
  {
    name: "Unionville",
    postalCode: 47468,
    country: "United States",
    state: "Indiana",
    county: "Monroe",
    coordinates: { latitude: 39.251, longitude: -86.419 },
  },
  {
    name: "Alamo",
    postalCode: 47916,
    country: "United States",
    state: "Indiana",
    county: "Montgomery",
    coordinates: { latitude: 39.984, longitude: -87.055 },
  },
  {
    name: "Crawfordsville",
    postalCode: 47933,
    country: "United States",
    state: "Indiana",
    county: "Montgomery",
    coordinates: { latitude: 40.033, longitude: -86.907 },
  },
  {
    name: "Darlington",
    postalCode: 47940,
    country: "United States",
    state: "Indiana",
    county: "Montgomery",
    coordinates: { latitude: 40.112, longitude: -86.765 },
  },
  {
    name: "Ladoga",
    postalCode: 47954,
    country: "United States",
    state: "Indiana",
    county: "Montgomery",
    coordinates: { latitude: 39.913, longitude: -86.803 },
  },
  {
    name: "Linden",
    postalCode: 47955,
    country: "United States",
    state: "Indiana",
    county: "Montgomery",
    coordinates: { latitude: 40.183, longitude: -86.891 },
  },
  {
    name: "New Market",
    postalCode: 47965,
    country: "United States",
    state: "Indiana",
    county: "Montgomery",
    coordinates: { latitude: 39.952, longitude: -86.921 },
  },
  {
    name: "New Richmond",
    postalCode: 47967,
    country: "United States",
    state: "Indiana",
    county: "Montgomery",
    coordinates: { latitude: 40.181, longitude: -86.979 },
  },
  {
    name: "New Ross",
    postalCode: 47968,
    country: "United States",
    state: "Indiana",
    county: "Montgomery",
    coordinates: { latitude: 39.988, longitude: -86.753 },
  },
  {
    name: "Waveland",
    postalCode: 47989,
    country: "United States",
    state: "Indiana",
    county: "Montgomery",
    coordinates: { latitude: 39.902, longitude: -87.018 },
  },
  {
    name: "Waynetown",
    postalCode: 47990,
    country: "United States",
    state: "Indiana",
    county: "Montgomery",
    coordinates: { latitude: 40.086, longitude: -87.051 },
  },
  {
    name: "Wingate",
    postalCode: 47994,
    country: "United States",
    state: "Indiana",
    county: "Montgomery",
    coordinates: { latitude: 40.167, longitude: -87.066 },
  },
  {
    name: "Brooklyn",
    postalCode: 46111,
    country: "United States",
    state: "Indiana",
    county: "Morgan",
    coordinates: { latitude: 39.54, longitude: -86.37 },
  },
  {
    name: "Eminence",
    postalCode: 46125,
    country: "United States",
    state: "Indiana",
    county: "Morgan",
    coordinates: { latitude: 39.521, longitude: -86.641 },
  },
  {
    name: "Martinsville",
    postalCode: 46151,
    country: "United States",
    state: "Indiana",
    county: "Morgan",
    coordinates: { latitude: 39.478, longitude: -86.467 },
  },
  {
    name: "Monrovia",
    postalCode: 46157,
    country: "United States",
    state: "Indiana",
    county: "Morgan",
    coordinates: { latitude: 39.571, longitude: -86.489 },
  },
  {
    name: "Mooresville",
    postalCode: 46158,
    country: "United States",
    state: "Indiana",
    county: "Morgan",
    coordinates: { latitude: 39.591, longitude: -86.364 },
  },
  {
    name: "Paragon",
    postalCode: 46166,
    country: "United States",
    state: "Indiana",
    county: "Morgan",
    coordinates: { latitude: 39.404, longitude: -86.578 },
  },
  {
    name: "Lake Village",
    postalCode: 46349,
    country: "United States",
    state: "Indiana",
    county: "Newton",
    coordinates: { latitude: 41.139, longitude: -87.445 },
  },
  {
    name: "Roselawn",
    postalCode: 46372,
    country: "United States",
    state: "Indiana",
    county: "Newton",
    coordinates: { latitude: 41.143, longitude: -87.322 },
  },
  {
    name: "Sumava Resorts",
    postalCode: 46379,
    country: "United States",
    state: "Indiana",
    county: "Newton",
    coordinates: { latitude: 41.167, longitude: -87.434 },
  },
  {
    name: "Thayer",
    postalCode: 46381,
    country: "United States",
    state: "Indiana",
    county: "Newton",
    coordinates: { latitude: 41.171, longitude: -87.331 },
  },
  {
    name: "Brook",
    postalCode: 47922,
    country: "United States",
    state: "Indiana",
    county: "Newton",
    coordinates: { latitude: 40.866, longitude: -87.353 },
  },
  {
    name: "Goodland",
    postalCode: 47948,
    country: "United States",
    state: "Indiana",
    county: "Newton",
    coordinates: { latitude: 40.767, longitude: -87.3 },
  },
  {
    name: "Kentland",
    postalCode: 47951,
    country: "United States",
    state: "Indiana",
    county: "Newton",
    coordinates: { latitude: 40.788, longitude: -87.447 },
  },
  {
    name: "Morocco",
    postalCode: 47963,
    country: "United States",
    state: "Indiana",
    county: "Newton",
    coordinates: { latitude: 40.965, longitude: -87.419 },
  },
  {
    name: "Mount Ayr",
    postalCode: 47964,
    country: "United States",
    state: "Indiana",
    county: "Newton",
    coordinates: { latitude: 40.952, longitude: -87.299 },
  },
  {
    name: "Albion",
    postalCode: 46701,
    country: "United States",
    state: "Indiana",
    county: "Noble",
    coordinates: { latitude: 41.348, longitude: -85.414 },
  },
  {
    name: "Avilla",
    postalCode: 46710,
    country: "United States",
    state: "Indiana",
    county: "Noble",
    coordinates: { latitude: 41.369, longitude: -85.241 },
  },
  {
    name: "Cromwell",
    postalCode: 46732,
    country: "United States",
    state: "Indiana",
    county: "Noble",
    coordinates: { latitude: 41.375, longitude: -85.603 },
  },
  {
    name: "Kendallville",
    postalCode: 46755,
    country: "United States",
    state: "Indiana",
    county: "Noble",
    coordinates: { latitude: 41.448, longitude: -85.261 },
  },
  {
    name: "Kimmell",
    postalCode: 46760,
    country: "United States",
    state: "Indiana",
    county: "Noble",
    coordinates: { latitude: 41.395, longitude: -85.548 },
  },
  {
    name: "Laotto",
    postalCode: 46763,
    country: "United States",
    state: "Indiana",
    county: "Noble",
    coordinates: { latitude: 41.299, longitude: -85.19 },
  },
  {
    name: "Ligonier",
    postalCode: 46767,
    country: "United States",
    state: "Indiana",
    county: "Noble",
    coordinates: { latitude: 41.466, longitude: -85.593 },
  },
  {
    name: "Rome City",
    postalCode: 46784,
    country: "United States",
    state: "Indiana",
    county: "Noble",
    coordinates: { latitude: 41.485, longitude: -85.374 },
  },
  {
    name: "Wawaka",
    postalCode: 46794,
    country: "United States",
    state: "Indiana",
    county: "Noble",
    coordinates: { latitude: 41.457, longitude: -85.482 },
  },
  {
    name: "Wolflake",
    postalCode: 46796,
    country: "United States",
    state: "Indiana",
    county: "Noble",
    coordinates: { latitude: 41.336, longitude: -85.5 },
  },
  {
    name: "Rising Sun",
    postalCode: 47040,
    country: "United States",
    state: "Indiana",
    county: "Ohio",
    coordinates: { latitude: 38.957, longitude: -84.881 },
  },
  {
    name: "French Lick",
    postalCode: 47432,
    country: "United States",
    state: "Indiana",
    county: "Orange",
    coordinates: { latitude: 38.532, longitude: -86.62 },
  },
  {
    name: "Orleans",
    postalCode: 47452,
    country: "United States",
    state: "Indiana",
    county: "Orange",
    coordinates: { latitude: 38.653, longitude: -86.453 },
  },
  {
    name: "Paoli",
    postalCode: 47454,
    country: "United States",
    state: "Indiana",
    county: "Orange",
    coordinates: { latitude: 38.551, longitude: -86.449 },
  },
  {
    name: "West Baden Springs",
    postalCode: 47469,
    country: "United States",
    state: "Indiana",
    county: "Orange",
    coordinates: { latitude: 38.621, longitude: -86.59 },
  },
  {
    name: "Coal City",
    postalCode: 47427,
    country: "United States",
    state: "Indiana",
    county: "Owen",
    coordinates: { latitude: 39.23, longitude: -87.046 },
  },
  {
    name: "Freedom",
    postalCode: 47431,
    country: "United States",
    state: "Indiana",
    county: "Owen",
    coordinates: { latitude: 39.215, longitude: -86.85 },
  },
  {
    name: "Gosport",
    postalCode: 47433,
    country: "United States",
    state: "Indiana",
    county: "Owen",
    coordinates: { latitude: 39.345, longitude: -86.658 },
  },
  {
    name: "Patricksburg",
    postalCode: 47455,
    country: "United States",
    state: "Indiana",
    county: "Owen",
    coordinates: { latitude: 39.316, longitude: -86.956 },
  },
  {
    name: "Quincy",
    postalCode: 47456,
    country: "United States",
    state: "Indiana",
    county: "Owen",
    coordinates: { latitude: 39.443, longitude: -86.67 },
  },
  {
    name: "Spencer",
    postalCode: 47460,
    country: "United States",
    state: "Indiana",
    county: "Owen",
    coordinates: { latitude: 39.289, longitude: -86.779 },
  },
  {
    name: "Bellmore",
    postalCode: 47830,
    country: "United States",
    state: "Indiana",
    county: "Parke",
    coordinates: { latitude: 39.779, longitude: -87.222 },
  },
  {
    name: "Bloomingdale",
    postalCode: 47832,
    country: "United States",
    state: "Indiana",
    county: "Parke",
    coordinates: { latitude: 39.835, longitude: -87.256 },
  },
  {
    name: "Bridgeton",
    postalCode: 47836,
    country: "United States",
    state: "Indiana",
    county: "Parke",
    coordinates: { latitude: 39.645, longitude: -87.178 },
  },
  {
    name: "Marshall",
    postalCode: 47859,
    country: "United States",
    state: "Indiana",
    county: "Parke",
    coordinates: { latitude: 39.906, longitude: -87.178 },
  },
  {
    name: "Mecca",
    postalCode: 47860,
    country: "United States",
    state: "Indiana",
    county: "Parke",
    coordinates: { latitude: 39.722, longitude: -87.33 },
  },
  {
    name: "Montezuma",
    postalCode: 47862,
    country: "United States",
    state: "Indiana",
    county: "Parke",
    coordinates: { latitude: 39.796, longitude: -87.361 },
  },
  {
    name: "Rockville",
    postalCode: 47872,
    country: "United States",
    state: "Indiana",
    county: "Parke",
    coordinates: { latitude: 39.768, longitude: -87.198 },
  },
  {
    name: "Rosedale",
    postalCode: 47874,
    country: "United States",
    state: "Indiana",
    county: "Parke",
    coordinates: { latitude: 39.624, longitude: -87.309 },
  },
  {
    name: "Branchville",
    postalCode: 47514,
    country: "United States",
    state: "Indiana",
    county: "Perry",
    coordinates: { latitude: 38.157, longitude: -86.586 },
  },
  {
    name: "Bristow",
    postalCode: 47515,
    country: "United States",
    state: "Indiana",
    county: "Perry",
    coordinates: { latitude: 38.198, longitude: -86.682 },
  },
  {
    name: "Cannelton",
    postalCode: 47520,
    country: "United States",
    state: "Indiana",
    county: "Perry",
    coordinates: { latitude: 37.95, longitude: -86.716 },
  },
  {
    name: "Derby",
    postalCode: 47525,
    country: "United States",
    state: "Indiana",
    county: "Perry",
    coordinates: { latitude: 38.024, longitude: -86.577 },
  },
  {
    name: "Leopold",
    postalCode: 47551,
    country: "United States",
    state: "Indiana",
    county: "Perry",
    coordinates: { latitude: 38.101, longitude: -86.604 },
  },
  {
    name: "Rome",
    postalCode: 47574,
    country: "United States",
    state: "Indiana",
    county: "Perry",
    coordinates: { latitude: 37.937, longitude: -86.534 },
  },
  {
    name: "Saint Croix",
    postalCode: 47576,
    country: "United States",
    state: "Indiana",
    county: "Perry",
    coordinates: { latitude: 38.183, longitude: -86.606 },
  },
  {
    name: "Tell City",
    postalCode: 47586,
    country: "United States",
    state: "Indiana",
    county: "Perry",
    coordinates: { latitude: 37.966, longitude: -86.746 },
  },
  {
    name: "Otwell",
    postalCode: 47564,
    country: "United States",
    state: "Indiana",
    county: "Pike",
    coordinates: { latitude: 38.466, longitude: -87.099 },
  },
  {
    name: "Petersburg",
    postalCode: 47567,
    country: "United States",
    state: "Indiana",
    county: "Pike",
    coordinates: { latitude: 38.479, longitude: -87.288 },
  },
  {
    name: "Spurgeon",
    postalCode: 47584,
    country: "United States",
    state: "Indiana",
    county: "Pike",
    coordinates: { latitude: 38.252, longitude: -87.257 },
  },
  {
    name: "Stendal",
    postalCode: 47585,
    country: "United States",
    state: "Indiana",
    county: "Pike",
    coordinates: { latitude: 38.283, longitude: -87.12 },
  },
  {
    name: "Velpen",
    postalCode: 47590,
    country: "United States",
    state: "Indiana",
    county: "Pike",
    coordinates: { latitude: 38.368, longitude: -87.099 },
  },
  {
    name: "Winslow",
    postalCode: 47598,
    country: "United States",
    state: "Indiana",
    county: "Pike",
    coordinates: { latitude: 38.364, longitude: -87.222 },
  },
  {
    name: "Beverly Shores",
    postalCode: 46301,
    country: "United States",
    state: "Indiana",
    county: "Porter",
    coordinates: { latitude: 41.693, longitude: -86.978 },
  },
  {
    name: "Boone Grove",
    postalCode: 46302,
    country: "United States",
    state: "Indiana",
    county: "Porter",
    coordinates: { latitude: 41.354, longitude: -87.13 },
  },
  {
    name: "Chesterton",
    postalCode: 46304,
    country: "United States",
    state: "Indiana",
    county: "Porter",
    coordinates: { latitude: 41.614, longitude: -87.047 },
  },
  {
    name: "Hebron",
    postalCode: 46341,
    country: "United States",
    state: "Indiana",
    county: "Porter",
    coordinates: { latitude: 41.316, longitude: -87.209 },
  },
  {
    name: "Kouts",
    postalCode: 46347,
    country: "United States",
    state: "Indiana",
    county: "Porter",
    coordinates: { latitude: 41.309, longitude: -87.024 },
  },
  {
    name: "Portage",
    postalCode: 46368,
    country: "United States",
    state: "Indiana",
    county: "Porter",
    coordinates: { latitude: 41.567, longitude: -87.176 },
  },
  {
    name: "Valparaiso",
    postalCode: 46383,
    country: "United States",
    state: "Indiana",
    county: "Porter",
    coordinates: { latitude: 41.455, longitude: -87.066 },
  },
  {
    name: "Valparaiso",
    postalCode: 46384,
    country: "United States",
    state: "Indiana",
    county: "Porter",
    coordinates: { latitude: 41.491, longitude: -87.076 },
  },
  {
    name: "Valparaiso",
    postalCode: 46385,
    country: "United States",
    state: "Indiana",
    county: "Porter",
    coordinates: { latitude: 41.471, longitude: -87.078 },
  },
  {
    name: "Wheeler",
    postalCode: 46393,
    country: "United States",
    state: "Indiana",
    county: "Porter",
    coordinates: { latitude: 41.512, longitude: -87.179 },
  },
  {
    name: "Cynthiana",
    postalCode: 47612,
    country: "United States",
    state: "Indiana",
    county: "Posey",
    coordinates: { latitude: 38.193, longitude: -87.699 },
  },
  {
    name: "Griffin",
    postalCode: 47616,
    country: "United States",
    state: "Indiana",
    county: "Posey",
    coordinates: { latitude: 38.207, longitude: -87.917 },
  },
  {
    name: "Mount Vernon",
    postalCode: 47620,
    country: "United States",
    state: "Indiana",
    county: "Posey",
    coordinates: { latitude: 37.951, longitude: -87.857 },
  },
  {
    name: "New Harmony",
    postalCode: 47631,
    country: "United States",
    state: "Indiana",
    county: "Posey",
    coordinates: { latitude: 38.125, longitude: -87.917 },
  },
  {
    name: "Poseyville",
    postalCode: 47633,
    country: "United States",
    state: "Indiana",
    county: "Posey",
    coordinates: { latitude: 38.172, longitude: -87.803 },
  },
  {
    name: "Wadesville",
    postalCode: 47638,
    country: "United States",
    state: "Indiana",
    county: "Posey",
    coordinates: { latitude: 38.083, longitude: -87.754 },
  },
  {
    name: "Monterey",
    postalCode: 46960,
    country: "United States",
    state: "Indiana",
    county: "Pulaski",
    coordinates: { latitude: 41.138, longitude: -86.518 },
  },
  {
    name: "Star City",
    postalCode: 46985,
    country: "United States",
    state: "Indiana",
    county: "Pulaski",
    coordinates: { latitude: 40.96, longitude: -86.54 },
  },
  {
    name: "Winamac",
    postalCode: 46996,
    country: "United States",
    state: "Indiana",
    county: "Pulaski",
    coordinates: { latitude: 41.056, longitude: -86.631 },
  },
  {
    name: "Francesville",
    postalCode: 47946,
    country: "United States",
    state: "Indiana",
    county: "Pulaski",
    coordinates: { latitude: 40.971, longitude: -86.855 },
  },
  {
    name: "Medaryville",
    postalCode: 47957,
    country: "United States",
    state: "Indiana",
    county: "Pulaski",
    coordinates: { latitude: 41.09, longitude: -86.881 },
  },
  {
    name: "Bainbridge",
    postalCode: 46105,
    country: "United States",
    state: "Indiana",
    county: "Putnam",
    coordinates: { latitude: 39.761, longitude: -86.812 },
  },
  {
    name: "Cloverdale",
    postalCode: 46120,
    country: "United States",
    state: "Indiana",
    county: "Putnam",
    coordinates: { latitude: 39.515, longitude: -86.794 },
  },
  {
    name: "Fillmore",
    postalCode: 46128,
    country: "United States",
    state: "Indiana",
    county: "Putnam",
    coordinates: { latitude: 39.648, longitude: -86.747 },
  },
  {
    name: "Greencastle",
    postalCode: 46135,
    country: "United States",
    state: "Indiana",
    county: "Putnam",
    coordinates: { latitude: 39.649, longitude: -86.869 },
  },
  {
    name: "Putnamville",
    postalCode: 46170,
    country: "United States",
    state: "Indiana",
    county: "Putnam",
    coordinates: { latitude: 39.574, longitude: -86.865 },
  },
  {
    name: "Reelsville",
    postalCode: 46171,
    country: "United States",
    state: "Indiana",
    county: "Putnam",
    coordinates: { latitude: 39.546, longitude: -86.95 },
  },
  {
    name: "Roachdale",
    postalCode: 46172,
    country: "United States",
    state: "Indiana",
    county: "Putnam",
    coordinates: { latitude: 39.833, longitude: -86.79 },
  },
  {
    name: "Russellville",
    postalCode: 46175,
    country: "United States",
    state: "Indiana",
    county: "Putnam",
    coordinates: { latitude: 39.837, longitude: -86.967 },
  },
  {
    name: "Farmland",
    postalCode: 47340,
    country: "United States",
    state: "Indiana",
    county: "Randolph",
    coordinates: { latitude: 40.194, longitude: -85.125 },
  },
  {
    name: "Lynn",
    postalCode: 47355,
    country: "United States",
    state: "Indiana",
    county: "Randolph",
    coordinates: { latitude: 40.052, longitude: -84.93 },
  },
  {
    name: "Modoc",
    postalCode: 47358,
    country: "United States",
    state: "Indiana",
    county: "Randolph",
    coordinates: { latitude: 40.058, longitude: -85.092 },
  },
  {
    name: "Parker City",
    postalCode: 47368,
    country: "United States",
    state: "Indiana",
    county: "Randolph",
    coordinates: { latitude: 40.194, longitude: -85.196 },
  },
  {
    name: "Ridgeville",
    postalCode: 47380,
    country: "United States",
    state: "Indiana",
    county: "Randolph",
    coordinates: { latitude: 40.28, longitude: -85.037 },
  },
  {
    name: "Saratoga",
    postalCode: 47382,
    country: "United States",
    state: "Indiana",
    county: "Randolph",
    coordinates: { latitude: 40.235, longitude: -84.918 },
  },
  {
    name: "Union City",
    postalCode: 47390,
    country: "United States",
    state: "Indiana",
    county: "Randolph",
    coordinates: { latitude: 40.202, longitude: -84.827 },
  },
  {
    name: "Winchester",
    postalCode: 47394,
    country: "United States",
    state: "Indiana",
    county: "Randolph",
    coordinates: { latitude: 40.17, longitude: -85.004 },
  },
  {
    name: "Losantville",
    postalCode: 47354,
    country: "United States",
    state: "Indiana",
    county: "Randolph",
    coordinates: { latitude: 40.047, longitude: -85.211 },
  },
  {
    name: "Batesville",
    postalCode: 47006,
    country: "United States",
    state: "Indiana",
    county: "Ripley",
    coordinates: { latitude: 39.3, longitude: -85.222 },
  },
  {
    name: "Cross Plains",
    postalCode: 47017,
    country: "United States",
    state: "Indiana",
    county: "Ripley",
    coordinates: { latitude: 38.949, longitude: -85.212 },
  },
  {
    name: "Friendship",
    postalCode: 47021,
    country: "United States",
    state: "Indiana",
    county: "Ripley",
    coordinates: { latitude: 38.97, longitude: -85.15 },
  },
  {
    name: "Holton",
    postalCode: 47023,
    country: "United States",
    state: "Indiana",
    county: "Ripley",
    coordinates: { latitude: 39.05, longitude: -85.374 },
  },
  {
    name: "Milan",
    postalCode: 47031,
    country: "United States",
    state: "Indiana",
    county: "Ripley",
    coordinates: { latitude: 39.15, longitude: -85.132 },
  },
  {
    name: "Morris",
    postalCode: 47033,
    country: "United States",
    state: "Indiana",
    county: "Ripley",
    coordinates: { latitude: 39.281, longitude: -85.174 },
  },
  {
    name: "Napoleon",
    postalCode: 47034,
    country: "United States",
    state: "Indiana",
    county: "Ripley",
    coordinates: { latitude: 39.205, longitude: -85.327 },
  },
  {
    name: "Osgood",
    postalCode: 47037,
    country: "United States",
    state: "Indiana",
    county: "Ripley",
    coordinates: { latitude: 39.157, longitude: -85.294 },
  },
  {
    name: "Pierceville",
    postalCode: 47039,
    country: "United States",
    state: "Indiana",
    county: "Ripley",
    coordinates: { latitude: 39.112, longitude: -85.255 },
  },
  {
    name: "Sunman",
    postalCode: 47041,
    country: "United States",
    state: "Indiana",
    county: "Ripley",
    coordinates: { latitude: 39.237, longitude: -85.095 },
  },
  {
    name: "Versailles",
    postalCode: 47042,
    country: "United States",
    state: "Indiana",
    county: "Ripley",
    coordinates: { latitude: 39.051, longitude: -85.223 },
  },
  {
    name: "Arlington",
    postalCode: 46104,
    country: "United States",
    state: "Indiana",
    county: "Rush",
    coordinates: { latitude: 39.649, longitude: -85.583 },
  },
  {
    name: "Carthage",
    postalCode: 46115,
    country: "United States",
    state: "Indiana",
    county: "Rush",
    coordinates: { latitude: 39.747, longitude: -85.575 },
  },
  {
    name: "Falmouth",
    postalCode: 46127,
    country: "United States",
    state: "Indiana",
    county: "Rush",
    coordinates: { latitude: 39.729, longitude: -85.333 },
  },
  {
    name: "Homer",
    postalCode: 46146,
    country: "United States",
    state: "Indiana",
    county: "Rush",
    coordinates: { latitude: 39.578, longitude: -85.578 },
  },
  {
    name: "Manilla",
    postalCode: 46150,
    country: "United States",
    state: "Indiana",
    county: "Rush",
    coordinates: { latitude: 39.574, longitude: -85.619 },
  },
  {
    name: "Mays",
    postalCode: 46155,
    country: "United States",
    state: "Indiana",
    county: "Rush",
    coordinates: { latitude: 39.744, longitude: -85.43 },
  },
  {
    name: "Milroy",
    postalCode: 46156,
    country: "United States",
    state: "Indiana",
    county: "Rush",
    coordinates: { latitude: 39.495, longitude: -85.504 },
  },
  {
    name: "Rushville",
    postalCode: 46173,
    country: "United States",
    state: "Indiana",
    county: "Rush",
    coordinates: { latitude: 39.619, longitude: -85.432 },
  },
  {
    name: "Granger",
    postalCode: 46530,
    country: "United States",
    state: "Indiana",
    county: "St. Joseph",
    coordinates: { latitude: 41.743, longitude: -86.141 },
  },
  {
    name: "Lakeville",
    postalCode: 46536,
    country: "United States",
    state: "Indiana",
    county: "St. Joseph",
    coordinates: { latitude: 41.525, longitude: -86.271 },
  },
  {
    name: "Mishawaka",
    postalCode: 46544,
    country: "United States",
    state: "Indiana",
    county: "St. Joseph",
    coordinates: { latitude: 41.651, longitude: -86.162 },
  },
  {
    name: "Mishawaka",
    postalCode: 46545,
    country: "United States",
    state: "Indiana",
    county: "St. Joseph",
    coordinates: { latitude: 41.683, longitude: -86.168 },
  },
  {
    name: "Mishawaka",
    postalCode: 46546,
    country: "United States",
    state: "Indiana",
    county: "St. Joseph",
    coordinates: { latitude: 41.662, longitude: -86.159 },
  },
  {
    name: "New Carlisle",
    postalCode: 46552,
    country: "United States",
    state: "Indiana",
    county: "St. Joseph",
    coordinates: { latitude: 41.705, longitude: -86.484 },
  },
  {
    name: "North Liberty",
    postalCode: 46554,
    country: "United States",
    state: "Indiana",
    county: "St. Joseph",
    coordinates: { latitude: 41.543, longitude: -86.413 },
  },
  {
    name: "Notre Dame",
    postalCode: 46556,
    country: "United States",
    state: "Indiana",
    county: "St. Joseph",
    coordinates: { latitude: 41.7, longitude: -86.238 },
  },
  {
    name: "Osceola",
    postalCode: 46561,
    country: "United States",
    state: "Indiana",
    county: "St. Joseph",
    coordinates: { latitude: 41.67, longitude: -86.087 },
  },
  {
    name: "Walkerton",
    postalCode: 46574,
    country: "United States",
    state: "Indiana",
    county: "St. Joseph",
    coordinates: { latitude: 41.467, longitude: -86.483 },
  },
  {
    name: "Wyatt",
    postalCode: 46595,
    country: "United States",
    state: "Indiana",
    county: "St. Joseph",
    coordinates: { latitude: 41.526, longitude: -86.169 },
  },
  {
    name: "South Bend",
    postalCode: 46601,
    country: "United States",
    state: "Indiana",
    county: "St. Joseph",
    coordinates: { latitude: 41.673, longitude: -86.253 },
  },
  {
    name: "South Bend",
    postalCode: 46613,
    country: "United States",
    state: "Indiana",
    county: "St. Joseph",
    coordinates: { latitude: 41.655, longitude: -86.248 },
  },
  {
    name: "South Bend",
    postalCode: 46614,
    country: "United States",
    state: "Indiana",
    county: "St. Joseph",
    coordinates: { latitude: 41.625, longitude: -86.243 },
  },
  {
    name: "South Bend",
    postalCode: 46615,
    country: "United States",
    state: "Indiana",
    county: "St. Joseph",
    coordinates: { latitude: 41.674, longitude: -86.21 },
  },
  {
    name: "South Bend",
    postalCode: 46616,
    country: "United States",
    state: "Indiana",
    county: "St. Joseph",
    coordinates: { latitude: 41.692, longitude: -86.265 },
  },
  {
    name: "South Bend",
    postalCode: 46617,
    country: "United States",
    state: "Indiana",
    county: "St. Joseph",
    coordinates: { latitude: 41.685, longitude: -86.235 },
  },
  {
    name: "South Bend",
    postalCode: 46619,
    country: "United States",
    state: "Indiana",
    county: "St. Joseph",
    coordinates: { latitude: 41.667, longitude: -86.315 },
  },
  {
    name: "South Bend",
    postalCode: 46624,
    country: "United States",
    state: "Indiana",
    county: "St. Joseph",
    coordinates: { latitude: 41.733, longitude: -86.283 },
  },
  {
    name: "South Bend",
    postalCode: 46626,
    country: "United States",
    state: "Indiana",
    county: "St. Joseph",
    coordinates: { latitude: 41.677, longitude: -86.253 },
  },
  {
    name: "South Bend",
    postalCode: 46628,
    country: "United States",
    state: "Indiana",
    county: "St. Joseph",
    coordinates: { latitude: 41.702, longitude: -86.295 },
  },
  {
    name: "South Bend",
    postalCode: 46634,
    country: "United States",
    state: "Indiana",
    county: "St. Joseph",
    coordinates: { latitude: 41.597, longitude: -86.293 },
  },
  {
    name: "South Bend",
    postalCode: 46635,
    country: "United States",
    state: "Indiana",
    county: "St. Joseph",
    coordinates: { latitude: 41.717, longitude: -86.208 },
  },
  {
    name: "South Bend",
    postalCode: 46637,
    country: "United States",
    state: "Indiana",
    county: "St. Joseph",
    coordinates: { latitude: 41.73, longitude: -86.241 },
  },
  {
    name: "South Bend",
    postalCode: 46660,
    country: "United States",
    state: "Indiana",
    county: "St. Joseph",
    coordinates: { latitude: 41.597, longitude: -86.293 },
  },
  {
    name: "South Bend",
    postalCode: 46680,
    country: "United States",
    state: "Indiana",
    county: "St. Joseph",
    coordinates: { latitude: 41.597, longitude: -86.293 },
  },
  {
    name: "South Bend",
    postalCode: 46699,
    country: "United States",
    state: "Indiana",
    county: "St. Joseph",
    coordinates: { latitude: 41.597, longitude: -86.293 },
  },
  {
    name: "Austin",
    postalCode: 47102,
    country: "United States",
    state: "Indiana",
    county: "Scott",
    coordinates: { latitude: 38.748, longitude: -85.796 },
  },
  {
    name: "Lexington",
    postalCode: 47138,
    country: "United States",
    state: "Indiana",
    county: "Scott",
    coordinates: { latitude: 38.652, longitude: -85.625 },
  },
  {
    name: "Scottsburg",
    postalCode: 47170,
    country: "United States",
    state: "Indiana",
    county: "Scott",
    coordinates: { latitude: 38.688, longitude: -85.799 },
  },
  {
    name: "Boggstown",
    postalCode: 46110,
    country: "United States",
    state: "Indiana",
    county: "Shelby",
    coordinates: { latitude: 39.567, longitude: -85.914 },
  },
  {
    name: "Fairland",
    postalCode: 46126,
    country: "United States",
    state: "Indiana",
    county: "Shelby",
    coordinates: { latitude: 39.629, longitude: -85.891 },
  },
  {
    name: "Fountaintown",
    postalCode: 46130,
    country: "United States",
    state: "Indiana",
    county: "Shelby",
    coordinates: { latitude: 39.675, longitude: -85.785 },
  },
  {
    name: "Gwynneville",
    postalCode: 46144,
    country: "United States",
    state: "Indiana",
    county: "Shelby",
    coordinates: { latitude: 39.662, longitude: -85.648 },
  },
  {
    name: "Morristown",
    postalCode: 46161,
    country: "United States",
    state: "Indiana",
    county: "Shelby",
    coordinates: { latitude: 39.667, longitude: -85.693 },
  },
  {
    name: "Shelbyville",
    postalCode: 46176,
    country: "United States",
    state: "Indiana",
    county: "Shelby",
    coordinates: { latitude: 39.504, longitude: -85.788 },
  },
  {
    name: "Waldron",
    postalCode: 46182,
    country: "United States",
    state: "Indiana",
    county: "Shelby",
    coordinates: { latitude: 39.469, longitude: -85.664 },
  },
  {
    name: "Flat Rock",
    postalCode: 47234,
    country: "United States",
    state: "Indiana",
    county: "Shelby",
    coordinates: { latitude: 39.377, longitude: -85.768 },
  },
  {
    name: "Dale",
    postalCode: 47523,
    country: "United States",
    state: "Indiana",
    county: "Spencer",
    coordinates: { latitude: 38.171, longitude: -87.007 },
  },
  {
    name: "Evanston",
    postalCode: 47531,
    country: "United States",
    state: "Indiana",
    county: "Spencer",
    coordinates: { latitude: 38.022, longitude: -86.836 },
  },
  {
    name: "Fulda",
    postalCode: 47536,
    country: "United States",
    state: "Indiana",
    county: "Spencer",
    coordinates: { latitude: 38.111, longitude: -86.836 },
  },
  {
    name: "Gentryville",
    postalCode: 47537,
    country: "United States",
    state: "Indiana",
    county: "Spencer",
    coordinates: { latitude: 38.085, longitude: -87.044 },
  },
  {
    name: "Lamar",
    postalCode: 47550,
    country: "United States",
    state: "Indiana",
    county: "Spencer",
    coordinates: { latitude: 38.043, longitude: -86.95 },
  },
  {
    name: "Lincoln City",
    postalCode: 47552,
    country: "United States",
    state: "Indiana",
    county: "Spencer",
    coordinates: { latitude: 38.124, longitude: -86.996 },
  },
  {
    name: "Mariah Hill",
    postalCode: 47556,
    country: "United States",
    state: "Indiana",
    county: "Spencer",
    coordinates: { latitude: 38.166, longitude: -86.917 },
  },
  {
    name: "Saint Meinrad",
    postalCode: 47577,
    country: "United States",
    state: "Indiana",
    county: "Spencer",
    coordinates: { latitude: 38.171, longitude: -86.809 },
  },
  {
    name: "Santa Claus",
    postalCode: 47579,
    country: "United States",
    state: "Indiana",
    county: "Spencer",
    coordinates: { latitude: 38.118, longitude: -86.929 },
  },
  {
    name: "Troy",
    postalCode: 47588,
    country: "United States",
    state: "Indiana",
    county: "Spencer",
    coordinates: { latitude: 38.033, longitude: -86.798 },
  },
  {
    name: "Chrisney",
    postalCode: 47611,
    country: "United States",
    state: "Indiana",
    county: "Spencer",
    coordinates: { latitude: 38.015, longitude: -87.036 },
  },
  {
    name: "Grandview",
    postalCode: 47615,
    country: "United States",
    state: "Indiana",
    county: "Spencer",
    coordinates: { latitude: 37.97, longitude: -86.957 },
  },
  {
    name: "Hatfield",
    postalCode: 47617,
    country: "United States",
    state: "Indiana",
    county: "Spencer",
    coordinates: { latitude: 37.904, longitude: -87.25 },
  },
  {
    name: "Richland",
    postalCode: 47634,
    country: "United States",
    state: "Indiana",
    county: "Spencer",
    coordinates: { latitude: 37.914, longitude: -87.201 },
  },
  {
    name: "Rockport",
    postalCode: 47635,
    country: "United States",
    state: "Indiana",
    county: "Spencer",
    coordinates: { latitude: 37.886, longitude: -87.077 },
  },
  {
    name: "North Judson",
    postalCode: 46366,
    country: "United States",
    state: "Indiana",
    county: "Starke",
    coordinates: { latitude: 41.215, longitude: -86.776 },
  },
  {
    name: "San Pierre",
    postalCode: 46374,
    country: "United States",
    state: "Indiana",
    county: "Starke",
    coordinates: { latitude: 41.211, longitude: -86.873 },
  },
  {
    name: "Grovertown",
    postalCode: 46531,
    country: "United States",
    state: "Indiana",
    county: "Starke",
    coordinates: { latitude: 41.35, longitude: -86.524 },
  },
  {
    name: "Hamlet",
    postalCode: 46532,
    country: "United States",
    state: "Indiana",
    county: "Starke",
    coordinates: { latitude: 41.393, longitude: -86.595 },
  },
  {
    name: "Knox",
    postalCode: 46534,
    country: "United States",
    state: "Indiana",
    county: "Starke",
    coordinates: { latitude: 41.281, longitude: -86.608 },
  },
  {
    name: "Ora",
    postalCode: 46968,
    country: "United States",
    state: "Indiana",
    county: "Starke",
    coordinates: { latitude: 41.176, longitude: -86.554 },
  },
  {
    name: "Angola",
    postalCode: 46703,
    country: "United States",
    state: "Indiana",
    county: "Steuben",
    coordinates: { latitude: 41.656, longitude: -85.02 },
  },
  {
    name: "Fremont",
    postalCode: 46737,
    country: "United States",
    state: "Indiana",
    county: "Steuben",
    coordinates: { latitude: 41.733, longitude: -84.945 },
  },
  {
    name: "Hamilton",
    postalCode: 46742,
    country: "United States",
    state: "Indiana",
    county: "Steuben",
    coordinates: { latitude: 41.557, longitude: -84.895 },
  },
  {
    name: "Hudson",
    postalCode: 46747,
    country: "United States",
    state: "Indiana",
    county: "Steuben",
    coordinates: { latitude: 41.533, longitude: -85.081 },
  },
  {
    name: "Orland",
    postalCode: 46776,
    country: "United States",
    state: "Indiana",
    county: "Steuben",
    coordinates: { latitude: 41.731, longitude: -85.147 },
  },
  {
    name: "Pleasant Lake",
    postalCode: 46779,
    country: "United States",
    state: "Indiana",
    county: "Steuben",
    coordinates: { latitude: 41.584, longitude: -85.021 },
  },
  {
    name: "Carlisle",
    postalCode: 47838,
    country: "United States",
    state: "Indiana",
    county: "Sullivan",
    coordinates: { latitude: 38.976, longitude: -87.367 },
  },
  {
    name: "Dugger",
    postalCode: 47848,
    country: "United States",
    state: "Indiana",
    county: "Sullivan",
    coordinates: { latitude: 39.069, longitude: -87.26 },
  },
  {
    name: "Fairbanks",
    postalCode: 47849,
    country: "United States",
    state: "Indiana",
    county: "Sullivan",
    coordinates: { latitude: 39.19, longitude: -87.547 },
  },
  {
    name: "Farmersburg",
    postalCode: 47850,
    country: "United States",
    state: "Indiana",
    county: "Sullivan",
    coordinates: { latitude: 39.268, longitude: -87.502 },
  },
  {
    name: "Graysville",
    postalCode: 47852,
    country: "United States",
    state: "Indiana",
    county: "Sullivan",
    coordinates: { latitude: 39.08, longitude: -87.45 },
  },
  {
    name: "Hymera",
    postalCode: 47855,
    country: "United States",
    state: "Indiana",
    county: "Sullivan",
    coordinates: { latitude: 39.183, longitude: -87.299 },
  },
  {
    name: "Merom",
    postalCode: 47861,
    country: "United States",
    state: "Indiana",
    county: "Sullivan",
    coordinates: { latitude: 39.056, longitude: -87.568 },
  },
  {
    name: "Paxton",
    postalCode: 47865,
    country: "United States",
    state: "Indiana",
    county: "Sullivan",
    coordinates: { latitude: 39.021, longitude: -87.389 },
  },
  {
    name: "Shelburn",
    postalCode: 47879,
    country: "United States",
    state: "Indiana",
    county: "Sullivan",
    coordinates: { latitude: 39.178, longitude: -87.394 },
  },
  {
    name: "Sullivan",
    postalCode: 47882,
    country: "United States",
    state: "Indiana",
    county: "Sullivan",
    coordinates: { latitude: 39.101, longitude: -87.41 },
  },
  {
    name: "Bennington",
    postalCode: 47011,
    country: "United States",
    state: "Indiana",
    county: "Switzerland",
    coordinates: { latitude: 38.876, longitude: -85.072 },
  },
  {
    name: "East Enterprise",
    postalCode: 47019,
    country: "United States",
    state: "Indiana",
    county: "Switzerland",
    coordinates: { latitude: 38.873, longitude: -84.988 },
  },
  {
    name: "Florence",
    postalCode: 47020,
    country: "United States",
    state: "Indiana",
    county: "Switzerland",
    coordinates: { latitude: 38.822, longitude: -84.94 },
  },
  {
    name: "Patriot",
    postalCode: 47038,
    country: "United States",
    state: "Indiana",
    county: "Switzerland",
    coordinates: { latitude: 38.854, longitude: -84.852 },
  },
  {
    name: "Vevay",
    postalCode: 47043,
    country: "United States",
    state: "Indiana",
    county: "Switzerland",
    coordinates: { latitude: 38.772, longitude: -85.085 },
  },
  {
    name: "Lafayette",
    postalCode: 47901,
    country: "United States",
    state: "Indiana",
    county: "Tippecanoe",
    coordinates: { latitude: 40.418, longitude: -86.888 },
  },
  {
    name: "Lafayette",
    postalCode: 47902,
    country: "United States",
    state: "Indiana",
    county: "Tippecanoe",
    coordinates: { latitude: 40.389, longitude: -86.895 },
  },
  {
    name: "Lafayette",
    postalCode: 47903,
    country: "United States",
    state: "Indiana",
    county: "Tippecanoe",
    coordinates: { latitude: 40.304, longitude: -86.824 },
  },
  {
    name: "Lafayette",
    postalCode: 47904,
    country: "United States",
    state: "Indiana",
    county: "Tippecanoe",
    coordinates: { latitude: 40.428, longitude: -86.873 },
  },
  {
    name: "Lafayette",
    postalCode: 47905,
    country: "United States",
    state: "Indiana",
    county: "Tippecanoe",
    coordinates: { latitude: 40.4, longitude: -86.86 },
  },
  {
    name: "West Lafayette",
    postalCode: 47906,
    country: "United States",
    state: "Indiana",
    county: "Tippecanoe",
    coordinates: { latitude: 40.444, longitude: -86.924 },
  },
  {
    name: "West Lafayette",
    postalCode: 47907,
    country: "United States",
    state: "Indiana",
    county: "Tippecanoe",
    coordinates: { latitude: 40.425, longitude: -86.916 },
  },
  {
    name: "Battle Ground",
    postalCode: 47920,
    country: "United States",
    state: "Indiana",
    county: "Tippecanoe",
    coordinates: { latitude: 40.525, longitude: -86.824 },
  },
  {
    name: "Buck Creek",
    postalCode: 47924,
    country: "United States",
    state: "Indiana",
    county: "Tippecanoe",
    coordinates: { latitude: 40.488, longitude: -86.763 },
  },
  {
    name: "Clarks Hill",
    postalCode: 47930,
    country: "United States",
    state: "Indiana",
    county: "Tippecanoe",
    coordinates: { latitude: 40.247, longitude: -86.725 },
  },
  {
    name: "Dayton",
    postalCode: 47941,
    country: "United States",
    state: "Indiana",
    county: "Tippecanoe",
    coordinates: { latitude: 40.376, longitude: -86.774 },
  },
  {
    name: "Montmorenci",
    postalCode: 47962,
    country: "United States",
    state: "Indiana",
    county: "Tippecanoe",
    coordinates: { latitude: 40.473, longitude: -87.028 },
  },
  {
    name: "Romney",
    postalCode: 47981,
    country: "United States",
    state: "Indiana",
    county: "Tippecanoe",
    coordinates: { latitude: 40.258, longitude: -86.921 },
  },
  {
    name: "Stockwell",
    postalCode: 47983,
    country: "United States",
    state: "Indiana",
    county: "Tippecanoe",
    coordinates: { latitude: 40.285, longitude: -86.772 },
  },
  {
    name: "West Lafayette",
    postalCode: 47996,
    country: "United States",
    state: "Indiana",
    county: "Tippecanoe",
    coordinates: { latitude: 40.389, longitude: -86.895 },
  },
  {
    name: "Westpoint",
    postalCode: 47992,
    country: "United States",
    state: "Indiana",
    county: "Tippecanoe",
    coordinates: { latitude: 40.358, longitude: -87.057 },
  },
  {
    name: "Lafayette",
    postalCode: 47909,
    country: "United States",
    state: "Indiana",
    county: "Tippecanoe",
    coordinates: { latitude: 40.323, longitude: -86.888 },
  },
  {
    name: "Goldsmith",
    postalCode: 46045,
    country: "United States",
    state: "Indiana",
    county: "Tipton",
    coordinates: { latitude: 40.29, longitude: -86.149 },
  },
  {
    name: "Hobbs",
    postalCode: 46047,
    country: "United States",
    state: "Indiana",
    county: "Tipton",
    coordinates: { latitude: 40.284, longitude: -85.947 },
  },
  {
    name: "Kempton",
    postalCode: 46049,
    country: "United States",
    state: "Indiana",
    county: "Tipton",
    coordinates: { latitude: 40.288, longitude: -86.23 },
  },
  {
    name: "Sharpsville",
    postalCode: 46068,
    country: "United States",
    state: "Indiana",
    county: "Tipton",
    coordinates: { latitude: 40.373, longitude: -86.109 },
  },
  {
    name: "Tipton",
    postalCode: 46072,
    country: "United States",
    state: "Indiana",
    county: "Tipton",
    coordinates: { latitude: 40.282, longitude: -86.043 },
  },
  {
    name: "Windfall",
    postalCode: 46076,
    country: "United States",
    state: "Indiana",
    county: "Tipton",
    coordinates: { latitude: 40.367, longitude: -85.948 },
  },
  {
    name: "Brownsville",
    postalCode: 47325,
    country: "United States",
    state: "Indiana",
    county: "Union",
    coordinates: { latitude: 39.684, longitude: -84.988 },
  },
  {
    name: "Liberty",
    postalCode: 47353,
    country: "United States",
    state: "Indiana",
    county: "Union",
    coordinates: { latitude: 39.613, longitude: -84.909 },
  },
  {
    name: "Inglefield",
    postalCode: 47618,
    country: "United States",
    state: "Indiana",
    county: "Vanderburgh",
    coordinates: { latitude: 38.108, longitude: -87.559 },
  },
  {
    name: "Evansville",
    postalCode: 47701,
    country: "United States",
    state: "Indiana",
    county: "Vanderburgh",
    coordinates: { latitude: 37.997, longitude: -87.575 },
  },
  {
    name: "Evansville",
    postalCode: 47702,
    country: "United States",
    state: "Indiana",
    county: "Vanderburgh",
    coordinates: { latitude: 37.997, longitude: -87.575 },
  },
  {
    name: "Evansville",
    postalCode: 47703,
    country: "United States",
    state: "Indiana",
    county: "Vanderburgh",
    coordinates: { latitude: 37.997, longitude: -87.575 },
  },
  {
    name: "Evansville",
    postalCode: 47704,
    country: "United States",
    state: "Indiana",
    county: "Vanderburgh",
    coordinates: { latitude: 37.997, longitude: -87.575 },
  },
  {
    name: "Evansville",
    postalCode: 47705,
    country: "United States",
    state: "Indiana",
    county: "Vanderburgh",
    coordinates: { latitude: 37.997, longitude: -87.575 },
  },
  {
    name: "Evansville",
    postalCode: 47706,
    country: "United States",
    state: "Indiana",
    county: "Vanderburgh",
    coordinates: { latitude: 37.997, longitude: -87.575 },
  },
  {
    name: "Evansville",
    postalCode: 47708,
    country: "United States",
    state: "Indiana",
    county: "Vanderburgh",
    coordinates: { latitude: 37.972, longitude: -87.572 },
  },
  {
    name: "Evansville",
    postalCode: 47710,
    country: "United States",
    state: "Indiana",
    county: "Vanderburgh",
    coordinates: { latitude: 38.009, longitude: -87.575 },
  },
  {
    name: "Evansville",
    postalCode: 47711,
    country: "United States",
    state: "Indiana",
    county: "Vanderburgh",
    coordinates: { latitude: 38.062, longitude: -87.555 },
  },
  {
    name: "Evansville",
    postalCode: 47712,
    country: "United States",
    state: "Indiana",
    county: "Vanderburgh",
    coordinates: { latitude: 37.929, longitude: -87.66 },
  },
  {
    name: "Evansville",
    postalCode: 47713,
    country: "United States",
    state: "Indiana",
    county: "Vanderburgh",
    coordinates: { latitude: 37.962, longitude: -87.558 },
  },
  {
    name: "Evansville",
    postalCode: 47714,
    country: "United States",
    state: "Indiana",
    county: "Vanderburgh",
    coordinates: { latitude: 37.959, longitude: -87.529 },
  },
  {
    name: "Evansville",
    postalCode: 47715,
    country: "United States",
    state: "Indiana",
    county: "Vanderburgh",
    coordinates: { latitude: 37.968, longitude: -87.486 },
  },
  {
    name: "Evansville",
    postalCode: 47716,
    country: "United States",
    state: "Indiana",
    county: "Vanderburgh",
    coordinates: { latitude: 37.997, longitude: -87.575 },
  },
  {
    name: "Evansville",
    postalCode: 47719,
    country: "United States",
    state: "Indiana",
    county: "Vanderburgh",
    coordinates: { latitude: 37.997, longitude: -87.575 },
  },
  {
    name: "Evansville",
    postalCode: 47720,
    country: "United States",
    state: "Indiana",
    county: "Vanderburgh",
    coordinates: { latitude: 38.06, longitude: -87.638 },
  },
  {
    name: "Evansville",
    postalCode: 47721,
    country: "United States",
    state: "Indiana",
    county: "Vanderburgh",
    coordinates: { latitude: 37.978, longitude: -87.601 },
  },
  {
    name: "Evansville",
    postalCode: 47722,
    country: "United States",
    state: "Indiana",
    county: "Vanderburgh",
    coordinates: { latitude: 37.97, longitude: -87.542 },
  },
  {
    name: "Evansville",
    postalCode: 47724,
    country: "United States",
    state: "Indiana",
    county: "Vanderburgh",
    coordinates: { latitude: 37.997, longitude: -87.575 },
  },
  {
    name: "Evansville",
    postalCode: 47728,
    country: "United States",
    state: "Indiana",
    county: "Vanderburgh",
    coordinates: { latitude: 37.997, longitude: -87.575 },
  },
  {
    name: "Evansville",
    postalCode: 47730,
    country: "United States",
    state: "Indiana",
    county: "Vanderburgh",
    coordinates: { latitude: 37.997, longitude: -87.575 },
  },
  {
    name: "Evansville",
    postalCode: 47731,
    country: "United States",
    state: "Indiana",
    county: "Vanderburgh",
    coordinates: { latitude: 37.997, longitude: -87.575 },
  },
  {
    name: "Evansville",
    postalCode: 47732,
    country: "United States",
    state: "Indiana",
    county: "Vanderburgh",
    coordinates: { latitude: 37.997, longitude: -87.575 },
  },
  {
    name: "Evansville",
    postalCode: 47733,
    country: "United States",
    state: "Indiana",
    county: "Vanderburgh",
    coordinates: { latitude: 37.997, longitude: -87.575 },
  },
  {
    name: "Evansville",
    postalCode: 47734,
    country: "United States",
    state: "Indiana",
    county: "Vanderburgh",
    coordinates: { latitude: 37.997, longitude: -87.575 },
  },
  {
    name: "Evansville",
    postalCode: 47735,
    country: "United States",
    state: "Indiana",
    county: "Vanderburgh",
    coordinates: { latitude: 37.997, longitude: -87.575 },
  },
  {
    name: "Evansville",
    postalCode: 47736,
    country: "United States",
    state: "Indiana",
    county: "Vanderburgh",
    coordinates: { latitude: 37.997, longitude: -87.575 },
  },
  {
    name: "Evansville",
    postalCode: 47737,
    country: "United States",
    state: "Indiana",
    county: "Vanderburgh",
    coordinates: { latitude: 37.997, longitude: -87.575 },
  },
  {
    name: "Evansville",
    postalCode: 47740,
    country: "United States",
    state: "Indiana",
    county: "Vanderburgh",
    coordinates: { latitude: 37.997, longitude: -87.575 },
  },
  {
    name: "Evansville",
    postalCode: 47747,
    country: "United States",
    state: "Indiana",
    county: "Vanderburgh",
    coordinates: { latitude: 37.997, longitude: -87.575 },
  },
  {
    name: "Evansville",
    postalCode: 47750,
    country: "United States",
    state: "Indiana",
    county: "Vanderburgh",
    coordinates: { latitude: 37.962, longitude: -87.506 },
  },
  {
    name: "Evansville",
    postalCode: 47725,
    country: "United States",
    state: "Indiana",
    county: "Vanderburgh",
    coordinates: { latitude: 38.107, longitude: -87.526 },
  },
  {
    name: "Blanford",
    postalCode: 47831,
    country: "United States",
    state: "Indiana",
    county: "Vermillion",
    coordinates: { latitude: 39.665, longitude: -87.521 },
  },
  {
    name: "Clinton",
    postalCode: 47842,
    country: "United States",
    state: "Indiana",
    county: "Vermillion",
    coordinates: { latitude: 39.659, longitude: -87.421 },
  },
  {
    name: "Dana",
    postalCode: 47847,
    country: "United States",
    state: "Indiana",
    county: "Vermillion",
    coordinates: { latitude: 39.839, longitude: -87.471 },
  },
  {
    name: "Hillsdale",
    postalCode: 47854,
    country: "United States",
    state: "Indiana",
    county: "Vermillion",
    coordinates: { latitude: 39.771, longitude: -87.404 },
  },
  {
    name: "Saint Bernice",
    postalCode: 47875,
    country: "United States",
    state: "Indiana",
    county: "Vermillion",
    coordinates: { latitude: 39.878, longitude: -87.444 },
  },
  {
    name: "Universal",
    postalCode: 47884,
    country: "United States",
    state: "Indiana",
    county: "Vermillion",
    coordinates: { latitude: 39.622, longitude: -87.455 },
  },
  {
    name: "Cayuga",
    postalCode: 47928,
    country: "United States",
    state: "Indiana",
    county: "Vermillion",
    coordinates: { latitude: 39.93, longitude: -87.459 },
  },
  {
    name: "Newport",
    postalCode: 47966,
    country: "United States",
    state: "Indiana",
    county: "Vermillion",
    coordinates: { latitude: 39.884, longitude: -87.409 },
  },
  {
    name: "Perrysville",
    postalCode: 47974,
    country: "United States",
    state: "Indiana",
    county: "Vermillion",
    coordinates: { latitude: 40.074, longitude: -87.465 },
  },
  {
    name: "La Fontaine",
    postalCode: 46940,
    country: "United States",
    state: "Indiana",
    county: "Wabash",
    coordinates: { latitude: 40.691, longitude: -85.697 },
  },
  {
    name: "Lagro",
    postalCode: 46941,
    country: "United States",
    state: "Indiana",
    county: "Wabash",
    coordinates: { latitude: 40.82, longitude: -85.72 },
  },
  {
    name: "Laketon",
    postalCode: 46943,
    country: "United States",
    state: "Indiana",
    county: "Wabash",
    coordinates: { latitude: 40.974, longitude: -85.838 },
  },
  {
    name: "Liberty Mills",
    postalCode: 46946,
    country: "United States",
    state: "Indiana",
    county: "Wabash",
    coordinates: { latitude: 41.036, longitude: -85.736 },
  },
  {
    name: "North Manchester",
    postalCode: 46962,
    country: "United States",
    state: "Indiana",
    county: "Wabash",
    coordinates: { latitude: 40.999, longitude: -85.784 },
  },
  {
    name: "Roann",
    postalCode: 46974,
    country: "United States",
    state: "Indiana",
    county: "Wabash",
    coordinates: { latitude: 40.948, longitude: -85.93 },
  },
  {
    name: "Servia",
    postalCode: 46980,
    country: "United States",
    state: "Indiana",
    county: "Wabash",
    coordinates: { latitude: 40.957, longitude: -85.741 },
  },
  {
    name: "Somerset",
    postalCode: 46984,
    country: "United States",
    state: "Indiana",
    county: "Wabash",
    coordinates: { latitude: 40.671, longitude: -85.829 },
  },
  {
    name: "Urbana",
    postalCode: 46990,
    country: "United States",
    state: "Indiana",
    county: "Wabash",
    coordinates: { latitude: 40.899, longitude: -85.748 },
  },
  {
    name: "Wabash",
    postalCode: 46992,
    country: "United States",
    state: "Indiana",
    county: "Wabash",
    coordinates: { latitude: 40.791, longitude: -85.832 },
  },
  {
    name: "Pine Village",
    postalCode: 47975,
    country: "United States",
    state: "Indiana",
    county: "Warren",
    coordinates: { latitude: 40.433, longitude: -87.232 },
  },
  {
    name: "State Line",
    postalCode: 47982,
    country: "United States",
    state: "Indiana",
    county: "Warren",
    coordinates: { latitude: 40.197, longitude: -87.527 },
  },
  {
    name: "West Lebanon",
    postalCode: 47991,
    country: "United States",
    state: "Indiana",
    county: "Warren",
    coordinates: { latitude: 40.266, longitude: -87.438 },
  },
  {
    name: "Williamsport",
    postalCode: 47993,
    country: "United States",
    state: "Indiana",
    county: "Warren",
    coordinates: { latitude: 40.314, longitude: -87.404 },
  },
  {
    name: "Boonville",
    postalCode: 47601,
    country: "United States",
    state: "Indiana",
    county: "Warrick",
    coordinates: { latitude: 38.047, longitude: -87.262 },
  },
  {
    name: "Chandler",
    postalCode: 47610,
    country: "United States",
    state: "Indiana",
    county: "Warrick",
    coordinates: { latitude: 38.042, longitude: -87.376 },
  },
  {
    name: "Elberfeld",
    postalCode: 47613,
    country: "United States",
    state: "Indiana",
    county: "Warrick",
    coordinates: { latitude: 38.205, longitude: -87.418 },
  },
  {
    name: "Lynnville",
    postalCode: 47619,
    country: "United States",
    state: "Indiana",
    county: "Warrick",
    coordinates: { latitude: 38.196, longitude: -87.293 },
  },
  {
    name: "Newburgh",
    postalCode: 47629,
    country: "United States",
    state: "Indiana",
    county: "Warrick",
    coordinates: { latitude: 38.062, longitude: -87.245 },
  },
  {
    name: "Newburgh",
    postalCode: 47630,
    country: "United States",
    state: "Indiana",
    county: "Warrick",
    coordinates: { latitude: 37.964, longitude: -87.394 },
  },
  {
    name: "Tennyson",
    postalCode: 47637,
    country: "United States",
    state: "Indiana",
    county: "Warrick",
    coordinates: { latitude: 38.123, longitude: -87.139 },
  },
  {
    name: "Campbellsburg",
    postalCode: 47108,
    country: "United States",
    state: "Indiana",
    county: "Washington",
    coordinates: { latitude: 38.669, longitude: -86.236 },
  },
  {
    name: "Fredericksburg",
    postalCode: 47120,
    country: "United States",
    state: "Indiana",
    county: "Washington",
    coordinates: { latitude: 38.433, longitude: -86.19 },
  },
  {
    name: "Hardinsburg",
    postalCode: 47125,
    country: "United States",
    state: "Indiana",
    county: "Washington",
    coordinates: { latitude: 38.463, longitude: -86.318 },
  },
  {
    name: "Pekin",
    postalCode: 47165,
    country: "United States",
    state: "Indiana",
    county: "Washington",
    coordinates: { latitude: 38.493, longitude: -86.017 },
  },
  {
    name: "Salem",
    postalCode: 47167,
    country: "United States",
    state: "Indiana",
    county: "Washington",
    coordinates: { latitude: 38.607, longitude: -86.079 },
  },
  {
    name: "Boston",
    postalCode: 47324,
    country: "United States",
    state: "Indiana",
    county: "Wayne",
    coordinates: { latitude: 39.741, longitude: -84.852 },
  },
  {
    name: "Cambridge City",
    postalCode: 47327,
    country: "United States",
    state: "Indiana",
    county: "Wayne",
    coordinates: { latitude: 39.818, longitude: -85.168 },
  },
  {
    name: "Centerville",
    postalCode: 47330,
    country: "United States",
    state: "Indiana",
    county: "Wayne",
    coordinates: { latitude: 39.808, longitude: -85.003 },
  },
  {
    name: "Dublin",
    postalCode: 47335,
    country: "United States",
    state: "Indiana",
    county: "Wayne",
    coordinates: { latitude: 39.813, longitude: -85.204 },
  },
  {
    name: "Economy",
    postalCode: 47339,
    country: "United States",
    state: "Indiana",
    county: "Wayne",
    coordinates: { latitude: 39.978, longitude: -85.088 },
  },
  {
    name: "Fountain City",
    postalCode: 47341,
    country: "United States",
    state: "Indiana",
    county: "Wayne",
    coordinates: { latitude: 39.963, longitude: -84.909 },
  },
  {
    name: "Greens Fork",
    postalCode: 47345,
    country: "United States",
    state: "Indiana",
    county: "Wayne",
    coordinates: { latitude: 39.892, longitude: -85.049 },
  },
  {
    name: "Hagerstown",
    postalCode: 47346,
    country: "United States",
    state: "Indiana",
    county: "Wayne",
    coordinates: { latitude: 39.923, longitude: -85.16 },
  },
  {
    name: "Milton",
    postalCode: 47357,
    country: "United States",
    state: "Indiana",
    county: "Wayne",
    coordinates: { latitude: 39.776, longitude: -85.142 },
  },
  {
    name: "Richmond",
    postalCode: 47374,
    country: "United States",
    state: "Indiana",
    county: "Wayne",
    coordinates: { latitude: 39.832, longitude: -84.894 },
  },
  {
    name: "Richmond",
    postalCode: 47375,
    country: "United States",
    state: "Indiana",
    county: "Wayne",
    coordinates: { latitude: 39.829, longitude: -84.89 },
  },
  {
    name: "Webster",
    postalCode: 47392,
    country: "United States",
    state: "Indiana",
    county: "Wayne",
    coordinates: { latitude: 39.912, longitude: -84.935 },
  },
  {
    name: "Williamsburg",
    postalCode: 47393,
    country: "United States",
    state: "Indiana",
    county: "Wayne",
    coordinates: { latitude: 39.958, longitude: -84.998 },
  },
  {
    name: "Pershing",
    postalCode: 47370,
    country: "United States",
    state: "Indiana",
    county: "Wayne",
    coordinates: { latitude: 39.861, longitude: -85.147 },
  },
  {
    name: "Bluffton",
    postalCode: 46714,
    country: "United States",
    state: "Indiana",
    county: "Wells",
    coordinates: { latitude: 40.737, longitude: -85.162 },
  },
  {
    name: "Craigville",
    postalCode: 46731,
    country: "United States",
    state: "Indiana",
    county: "Wells",
    coordinates: { latitude: 40.793, longitude: -85.09 },
  },
  {
    name: "Keystone",
    postalCode: 46759,
    country: "United States",
    state: "Indiana",
    county: "Wells",
    coordinates: { latitude: 40.59, longitude: -85.277 },
  },
  {
    name: "Liberty Center",
    postalCode: 46766,
    country: "United States",
    state: "Indiana",
    county: "Wells",
    coordinates: { latitude: 40.7, longitude: -85.277 },
  },
  {
    name: "Ossian",
    postalCode: 46777,
    country: "United States",
    state: "Indiana",
    county: "Wells",
    coordinates: { latitude: 40.881, longitude: -85.157 },
  },
  {
    name: "Petroleum",
    postalCode: 46778,
    country: "United States",
    state: "Indiana",
    county: "Wells",
    coordinates: { latitude: 40.611, longitude: -85.153 },
  },
  {
    name: "Poneto",
    postalCode: 46781,
    country: "United States",
    state: "Indiana",
    county: "Wells",
    coordinates: { latitude: 40.642, longitude: -85.256 },
  },
  {
    name: "Uniondale",
    postalCode: 46791,
    country: "United States",
    state: "Indiana",
    county: "Wells",
    coordinates: { latitude: 40.837, longitude: -85.24 },
  },
  {
    name: "Brookston",
    postalCode: 47923,
    country: "United States",
    state: "Indiana",
    county: "White",
    coordinates: { latitude: 40.601, longitude: -86.875 },
  },
  {
    name: "Buffalo",
    postalCode: 47925,
    country: "United States",
    state: "Indiana",
    county: "White",
    coordinates: { latitude: 40.883, longitude: -86.745 },
  },
  {
    name: "Burnettsville",
    postalCode: 47926,
    country: "United States",
    state: "Indiana",
    county: "White",
    coordinates: { latitude: 40.761, longitude: -86.594 },
  },
  {
    name: "Chalmers",
    postalCode: 47929,
    country: "United States",
    state: "Indiana",
    county: "White",
    coordinates: { latitude: 40.669, longitude: -86.863 },
  },
  {
    name: "Idaville",
    postalCode: 47950,
    country: "United States",
    state: "Indiana",
    county: "White",
    coordinates: { latitude: 40.767, longitude: -86.656 },
  },
  {
    name: "Monon",
    postalCode: 47959,
    country: "United States",
    state: "Indiana",
    county: "White",
    coordinates: { latitude: 40.861, longitude: -86.864 },
  },
  {
    name: "Monticello",
    postalCode: 47960,
    country: "United States",
    state: "Indiana",
    county: "White",
    coordinates: { latitude: 40.763, longitude: -86.755 },
  },
  {
    name: "Reynolds",
    postalCode: 47980,
    country: "United States",
    state: "Indiana",
    county: "White",
    coordinates: { latitude: 40.761, longitude: -86.869 },
  },
  {
    name: "Wolcott",
    postalCode: 47995,
    country: "United States",
    state: "Indiana",
    county: "White",
    coordinates: { latitude: 40.752, longitude: -87.029 },
  },
  {
    name: "Churubusco",
    postalCode: 46723,
    country: "United States",
    state: "Indiana",
    county: "Whitley",
    coordinates: { latitude: 41.229, longitude: -85.324 },
  },
  {
    name: "Columbia City",
    postalCode: 46725,
    country: "United States",
    state: "Indiana",
    county: "Whitley",
    coordinates: { latitude: 41.162, longitude: -85.474 },
  },
  {
    name: "Larwill",
    postalCode: 46764,
    country: "United States",
    state: "Indiana",
    county: "Whitley",
    coordinates: { latitude: 41.165, longitude: -85.614 },
  },
  {
    name: "South Whitley",
    postalCode: 46787,
    country: "United States",
    state: "Indiana",
    county: "Whitley",
    coordinates: { latitude: 41.073, longitude: -85.614 },
  },
  {
    name: "Terre Haute",
    postalCode: 47801,
    country: "United States",
    state: "Indiana",
    county: "Vigo",
    coordinates: { latitude: 39.467, longitude: -87.414 }
  },
  {
    name: "Terre Haute",
    postalCode: 47802,
    country: "United States",
    state: "Indiana",
    county: "Vigo",
    coordinates: { latitude: 39.407, longitude: -87.402 }
  },
  {
    name: "Terre Haute",
    postalCode: 47803,
    country: "United States",
    state: "Indiana",
    county: "Vigo",
    coordinates: { latitude: 39.466, longitude: -87.354 }
  },
  {
    name: "Terre Haute",
    postalCode: 47804,
    country: "United States",
    state: "Indiana",
    county: "Vigo",
    coordinates: { latitude: 39.494, longitude: -87.394 }
  },
  {
    name: "Terre Haute",
    postalCode: 47805,
    country: "United States",
    state: "Indiana",
    county: "Vigo",
    coordinates: { latitude: 39.533, longitude: -87.325 }
  },
  {
    name: "Terre Haute",
    postalCode: 47807,
    country: "United States",
    state: "Indiana",
    county: "Vigo",
    coordinates: { latitude: 39.471, longitude: -87.401 }
  },
  {
    name: "Terre Haute",
    postalCode: 47808,
    country: "United States",
    state: "Indiana",
    county: "Vigo",
    coordinates: { latitude: 39.434, longitude: -87.41 }
  },
  {
    name: "Terre Haute",
    postalCode: 47809,
    country: "United States",
    state: "Indiana",
    county: "Vigo",
    coordinates: { latitude: 39.471, longitude: -87.411 }
  },
  {
    name: "Fontanet",
    postalCode: 47851,
    country: "United States",
    state: "Indiana",
    county: "Vigo",
    coordinates: { latitude: 39.574, longitude: -87.245 }
  },
  {
    name: "Lewis",
    postalCode: 47858,
    country: "United States",
    state: "Indiana",
    county: "Vigo",
    coordinates: { latitude: 39.273, longitude: -87.264 }
  },
  {
    name: "New Goshen",
    postalCode: 47863,
    country: "United States",
    state: "Indiana",
    county: "Vigo",
    coordinates: { latitude: 39.58, longitude: -87.462 }
  },
  {
    name: "Pimento",
    postalCode: 47866,
    country: "United States",
    state: "Indiana",
    county: "Vigo",
    coordinates: { latitude: 39.293, longitude: -87.336 }
  },
  {
    name: "Prairie Creek",
    postalCode: 47869,
    country: "United States",
    state: "Indiana",
    county: "Vigo",
    coordinates: { latitude: 39.275, longitude: -87.497 }
  },
  {
    name: "Prairieton",
    postalCode: 47870,
    country: "United States",
    state: "Indiana",
    county: "Vigo",
    coordinates: { latitude: 39.371, longitude: -87.476 }
  },
  {
    name: "Riley",
    postalCode: 47871,
    country: "United States",
    state: "Indiana",
    county: "Vigo",
    coordinates: { latitude: 39.39, longitude: -87.3 }
  },
  {
    name: "Saint Mary Of The Woods",
    postalCode: 47876,
    country: "United States",
    state: "Indiana",
    county: "Vigo",
    coordinates: { latitude: 39.511, longitude: -87.467 }
  },
  {
    name: "Seelyville",
    postalCode: 47878,
    country: "United States",
    state: "Indiana",
    county: "Vigo",
    coordinates: { latitude: 39.493, longitude: -87.267 }
  },
  {
    name: "Shepardsville",
    postalCode: 47880,
    country: "United States",
    state: "Indiana",
    county: "Vigo",
    coordinates: { latitude: 39.601, longitude: -87.42 }
  },
  {
    name: "West Terre Haute",
    postalCode: 47885,
    country: "United States",
    state: "Indiana",
    county: "Vigo",
    coordinates: { latitude: 39.498, longitude: -87.469 }
  }
];
