export const BASE_URL =
  process.env.REACT_APP_BACKEND_ENV === "live"
    ? "https://api-vayco.logic-square.com/admin/v1"
    // : "https://api-sv.logic-square.com/api/v1";
    : "https://api.mvcrconnect.com/api/v1";
    
// : "https://leo-ill-reported-suits.trycloudflare.com/api/v1";

// : "https://66cc-45-249-80-23.ngrok-free.app/api/v1";

export const SOCKET_BASE_URL =
  process.env.REACT_APP_BACKEND_ENV === "live"
    ? "https://api-vayco.logic-square.com"
    : "https://api-vayco.logic-square.com";

export const SOCIAL_CREDENTIALS = {
  facebookAppId:
    process.env.REACT_APP_BACKEND_ENV === "live"
      ? "594500855125311"
      : "594500855125311",
  googleClientId:
    process.env.REACT_APP_BACKEND_ENV === "live"
      ? "894032215568-ta8bcs98t1ibmk52ol963c9mi2gaejad.apps.googleusercontent.com"
      : "894032215568-ta8bcs98t1ibmk52ol963c9mi2gaejad.apps.googleusercontent.com",
};

export const ReCAPTCHA_SITE_KEY = "6Ld6hW0dAAAAAOKS5Qv4XAad1-iRqrVksjiOPCuu";
export const ReCAPTCHA_SECRET_KEY = "6Ld6hW0dAAAAAJbrLP0nx_hJFv2ez-W0oSUBG4Ya";

export const AWS_IMAGE_BUCKET_NAME =
  process.env.REACT_APP_BACKEND_ENV === "live"
    ? "vayco-content"
    : "vayco-content";

export const DEFAULT_PROFILE_PICTURE =
  require("../assets/img/default_user_icon.svg").default;

export const APP_NAME = "Military Veteran Community Resources";

export const APP_LOGO = require("../assets/img/IDVA logo-01.png").default;

export const APP_DASHBOARD_LINK = "www.parentsmeet.com";

export const LOGIN_PAGE_BANNER =
  require("../assets/img/login_banner.jpg").default;

export const REGISTER_PAGE_BANNER =
  require("../assets/img/register_banner.jpg").default;
