import React from "react";
import { useHistory } from "react-router-dom";
import { Table, Button, Container, Row, Col } from "reactstrap";
import { formatDate } from "../../helper-methods";

const SubscriptionInvoice = (props) => {
  const history = useHistory();

  return (
    <>
      <div className="content">
        <Container fluid className="mobilePadding-0">
          <div className="page_Inner_header">
            <Row className="align-items-center">
              <Col xl={5} md={4}>
                <div className="page_title">
                  <Button
                    color="link"
                    className="pl-0 pr-2"
                    onClick={() => history.goBack()}
                  >
                    <i className="fa fa-chevron-left" />
                  </Button>
                  <h1 className="space_remove">Subscription Invoice</h1>
                </div>
              </Col>
            </Row>
          </div>

          {/* ======== Table ======== */}
          <Table responsive className="m-0">
            <thead>
              <tr>
                <th>Plan</th>
                <th>Billing Period</th>
                <th># of Users</th>
                <th>Amount</th>
                <th>Due Date</th>
                <th>Date</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Premium</td>
                <td>
                  {formatDate(new Date())} to {formatDate(new Date())}
                </td>
                <td>N/A</td>
                <td>$5</td>
                <td>{formatDate(new Date())}</td>
                <td>{formatDate(new Date())}</td>
                <td>Paid</td>
                <td>
                  <Button color="primary" className="px-4">
                    Download
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>
        </Container>
      </div>
    </>
  );
};

export default SubscriptionInvoice;
