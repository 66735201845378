import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Spinner,
} from "reactstrap";
import Chips from "react-chips";
// import { showToast } from "../../helper-methods";
import { getAndUpdateSettings } from "../../redux/actions/settings";
import { connect } from "react-redux";

const chipsTheme = {
  chipsContainer: {
    display: "flex",
    position: "relative",
    border: "1px solid #e4e7ea",
    backgroundColor: "#fff",
    fontSize: "13px",
    minHeight: 24,
    alignItems: "center",
    flexWrap: "wrap",
    padding: "3px 4px 4px",
    borderRadius: "4px",
    ":focus": {
      border: "1px solid #e4e7ea",
    },
  },
  container: {
    flex: 1,
  },
  containerOpen: {},
  input: {
    border: "none",
    outline: "none",
    boxSizing: "border-box",
    width: "100%",
    padding: 5,
    margin: 0,
  },
  suggestionsContainer: {},
  suggestionsList: {
    position: "absolute",
    border: "1px solid #ccc",
    zIndex: 10,
    left: 0,
    top: "100%",
    width: "100%",
    backgroundColor: "#fff",
    listStyle: "none",
    padding: 0,
    margin: 0,
  },
  suggestion: {
    padding: "5px 15px",
  },
  suggestionHighlighted: {
    background: "#ddd",
  },
  sectionContainer: {},
  sectionTitle: {},
};

const chipTheme = {
  chip: {
    padding: "1px 4px 0",
    background: "#fff",
    border: "1px solid #fd3762",
    color: "#fd3762",
    margin: 2.5,
    borderRadius: 6,
    textTransform: "capitalize",
  },
  chipSelected: {
    background: "#888",
  },
  chipRemove: {
    marginLeft: 4,
    marginRight: 1,
    fontSize: 17,
    color: "#f16667",
    cursor: "pointer",
    verticalAlign: -1,
    ":hover": {
      color: "#f16667",
    },
  },
};

class SettingsPage extends Component {
  state = {
    settings: {
      activities: [],
      timings: [],
      parentInterests: [],
    },
    loading: {
      settingsLoading: false,
    },
  };

  _manageLoading = (key, value) => {
    const { loading } = this.state;
    loading[key] = value;
    this.setState({ loading });
  };

  _setSettings = () => {
    // const { settings } = this.props;

    const { settings } = this.state;

    settings.activities = [];
    settings.timings = [];
    settings.parentInterests = [];

    // settings.timings = settings.settings
    //   ?.timings?.length
    //   ? settings.settings.timings
    //   : [];

    this.setState({
      settings,
    });
  };

  // _getSettings = async () => {
  //   try {
  //     this._manageLoading("settingsLoading", true);

  //     await this.props.getAndUpdateSettings();

  //     this._manageLoading("settingsLoading", false);

  //     this._setSettings();
  //   } catch (error) {
  //     this._manageLoading("settingsLoading", false);
  //     showToast(
  //       error?.reason?.length
  //         ? error.reason
  //         : "Server error, Try again after sometime.",
  //       "error"
  //     );
  //   }
  // };

  // componentDidMount = () => {
  //   // this._setSettings();

  //   // this._getSettings();
  // };

  _onChangeSettings = (key, arr) => {
    const { settings } = this.state;

    settings[key] = arr;

    this.setState({ settings });
  };

  _updateSettings = () => {
    // this._manageLoading("settingsLoading", true);
    // const { settings } = this.state;
    // updateSettings({ settings })
    //   .then((res) => {
    //     this._manageLoading("settingsLoading", false);
    //     showToast("Complimentary services updated", "success");
    //     this._getSettings();
    //   })
    //   .catch((error) => {
    //     this._manageLoading("settingsLoading", false);
    //     showToast(
    //       error?.reason?.length
    //         ? error.reason
    //         : "Server error, Try again after sometime.",
    //       "error"
    //     );
    //   });
  };

  render() {
    const { settings, loading } = this.state;

    return (
      <>
        <div className="content">
          <Container fluid className="mobilePadding-0">
            <div className="page_Inner_header">
              <Row className="align-items-center">
                <Col xl={5} md={4}>
                  <div className="page_title">
                    <Button
                      color="link"
                      className="pl-0 pr-2"
                      onClick={() => this.props.history.goBack()}
                    >
                      <i className="fa fa-chevron-left" />
                    </Button>
                    <h1 className="space_remove">
                      Settings {loading.settingsLoading ? <Spinner /> : null}
                    </h1>
                  </div>
                </Col>
              </Row>
            </div>

            <Card className="settingsWrap" data-test="left-card">
              <CardBody className="p-2">
                <div className="complimentary-services">
                  <Row>
                    <Col md="6">
                      <div className="setting-chips-row">
                        <h5 className="mb-2 mt-0" style={{ fontWeight: "600" }}>
                          Activities
                        </h5>

                        <Chips
                          style={{ width: "100%" }}
                          theme={chipsTheme}
                          chipTheme={chipTheme}
                          value={settings.activities}
                          onChange={(arr) =>
                            this._onChangeSettings("activities", arr)
                          }
                          createChipKeys={[9, 13]} //  Key codes to add chips
                          placeholder={"Add Activities.."}
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="setting-chips-row">
                        <h5 className="mb-2 mt-0" style={{ fontWeight: "600" }}>
                          Timings
                        </h5>

                        <Chips
                          style={{ width: "100%" }}
                          theme={chipsTheme}
                          chipTheme={chipTheme}
                          value={settings.timings}
                          onChange={(arr) =>
                            this._onChangeSettings("timings", arr)
                          }
                          createChipKeys={[9, 13]} //  Key codes to add chips
                          placeholder={"Add Timings.."}
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="mt-4 setting-chips-row">
                        <h5 className="mb-2 mt-0" style={{ fontWeight: "600" }}>
                          Parents' Interests
                        </h5>

                        <Chips
                          style={{ width: "100%" }}
                          theme={chipsTheme}
                          chipTheme={chipTheme}
                          value={settings.parentInterests}
                          onChange={(arr) =>
                            this._onChangeSettings("parentInterests", arr)
                          }
                          createChipKeys={[9, 13]} //  Key codes to add chips
                          placeholder={"Add Parents' Interests.."}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>

                <div className="d-flex equal_width justify-content-center mt-4">
                  <Button
                    className="btn-primary  fw-bold"
                    disabled={loading.settingsLoading}
                    onClick={() => this._updateSettings()}
                  >
                    {loading.settingsLoading ? (
                      <i className="fa fa-spinner fa-spin fs-13 mr-1" />
                    ) : null}
                    Save
                  </Button>

                  <Button
                    color="priamry"
                    className="btn-cancel ml-3"
                    disabled={loading.settingsLoading}
                    onClick={() => this._setSettings()}
                  >
                    Reset
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Container>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    settings: state.settings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAndUpdateSettings: () => dispatch(getAndUpdateSettings()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPage);
