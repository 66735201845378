import React, { Component } from "react";
import {
  Button,
  Col,
  Form,
  Label,
  FormGroup,
  InputGroup,
  InputGroupText,
  Input,
  Row,
  Spinner,
} from "reactstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { connect } from "react-redux";
import AuthFooter from "../../components/Footers/AuthFooter";
import { countryCodes } from "../../config/country-codes";
import { RegexConfig } from "../../config/RegexConfig";
import { checkAvailability, requestInvitation } from "../../http/http-calls";
import { deepClone, showToast } from "../../helper-methods";
import { StatesConfig } from "../../config/states";
import { showLoader, hideLoader } from "../../redux/actions/loader-data";
import { ReCAPTCHA_SITE_KEY } from "../../config";
import { APP_LOGO, REGISTER_PAGE_BANNER } from "../../config/index";

class Register extends Component {
  state = {
    formFields: {
      name: {
        value: "",
        error: null,
        isDirty: false,
        isValidationRequired: true,
      },
      email: {
        value: "",
        error: null,
        isDirty: false,
        isDublicateLoading: false,
        isValidationRequired: true,
      },
      phoneCode: {
        value: "+1",
        error: null,
        isDirty: false,
        isValidationRequired: false,
      },
      phone: {
        value: "",
        error: null,
        isDirty: false,
        isDublicateLoading: false,
        isValidationRequired: true,
      },
      resortName: {
        value: "",
        error: null,
        isDirty: false,
        isValidationRequired: true,
      },
      resortAddress: {
        value: "",
        error: null,
        isDirty: false,
        isValidationRequired: true,
      },
      country: {
        value: "US",
        error: null,
        isDirty: false,
        isValidationRequired: true,
      },
      state: {
        value: "",
        error: null,
        isDirty: false,
        isValidationRequired: true,
      },
      city: {
        value: "",
        error: null,
        isDirty: false,
        isValidationRequired: true,
      },
      zipcode: {
        value: "",
        error: null,
        isDirty: false,
        isValidationRequired: true,
      },
      numberOfRooms: {
        value: "",
        error: null,
        isDirty: false,
        isValidationRequired: true,
      },
      comments: {
        value: "",
        error: null,
        isDirty: false,
        isValidationRequired: false,
      },
      captchaValue: {
        value: "",
        error: null,
        isDirty: false,
        isValidationRequired: true,
      },
    },
    sumbitLoading: false,
  };

  /**
   * Check for duplicate username, email, phone
   */
  _checkDuplicateFields = (key, value) => {
    return new Promise(async (resolve) => {
      const { formFields } = deepClone(this.state);
      formFields[key].isDublicateLoading = true;
      this.setState({ formFields }, () => {
        const payload = {
          handle: value,
          handleType: key,
        };

        checkAvailability(payload)
          .then((res) => {
            resolve([res, null]);
          })
          .catch((error) => {
            resolve([null, error]);
          });
      });
    });
  };

  /**
   * validation or Check for duplicate username, email, phone , validate one at a time
   */
  _formFieldsApiValidation = (key) => {
    return new Promise(async (resolve) => {
      const { formFields: oldFormFields } = deepClone(this.state);

      let isFieldValid = true;

      if (
        oldFormFields[key].isDirty &&
        oldFormFields[key].isValidationRequired
      ) {
        if (oldFormFields[key].value?.trim().length) {
          if (
            RegexConfig[key].test(
              String(oldFormFields[key].value).toLowerCase()
            )
          ) {
            const checkingValue =
              key !== "phone"
                ? oldFormFields[key].value.trim()
                : `(${oldFormFields.phoneCode.value})${oldFormFields[
                    key
                  ].value.trim()}`;

            // always resolve
            const [res, resError] = await this._checkDuplicateFields(
              key,
              checkingValue
            );

            oldFormFields[key].isDublicateLoading = false;

            if (res?.error || resError?.error) {
              oldFormFields[key].error = `${oldFormFields[
                key
              ].value.trim()} already in use`;
              isFieldValid = false;
            } else {
              oldFormFields[key].error = null;
              oldFormFields[key].isDirty = false;
            }
          } else {
            oldFormFields[key].error = `*Invalid ${key}`;
            isFieldValid = false;
          }
        } else {
          oldFormFields[key].error = "*Required";
          isFieldValid = false;
        }
      }
      const { formFields: newFormFields } = deepClone(this.state);

      newFormFields[key].error = oldFormFields[key].error;
      newFormFields[key].isDirty = oldFormFields[key].isDirty;
      newFormFields[key].isDublicateLoading =
        oldFormFields[key].isDublicateLoading;

      this.setState({ formFields: newFormFields }, () => resolve(isFieldValid));
    });
  };

  _validateFormFields = () => {
    return new Promise((resolve) => {
      const { formFields } = deepClone(this.state);

      let isFormValid = true;

      Object.keys(formFields).forEach((key) => {
        if (formFields[key].isDirty && formFields[key].isValidationRequired) {
          switch (key) {
            case "zipcode":
            case "city":
            case "state":
            case "country":
            case "resortAddress":
            case "resortName":
            case "numberOfRooms":
            case "name": {
              if (formFields[key].value?.trim().length) {
                formFields[key].error = null;
                formFields[key].isDirty = false;
              } else {
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "captchaValue": {
              if (formFields[key].value?.trim().length) {
                formFields[key].error = null;
                formFields[key].isDirty = false;
              } else {
                formFields[key].error = "*Please verify, Check this checkbox";
                isFormValid = false;
              }
              break;
            }
            default:
          }
        }
      });

      this.setState({ formFields }, () => resolve(isFormValid));
    });
  };

  _onChangeFormFields = (key, value) => {
    if (
      (key === "numberOfRooms" || key === "phone") &&
      (isNaN(value) || value.includes("."))
    ) {
      return;
    }
    const { formFields } = deepClone(this.state);
    formFields[key].value = value;
    if (key === "country") {
      formFields.state.value = "";
      formFields.state.error = null;
      formFields.state.isDirty = false;
    }
    this.setState({ formFields });
  };

  _onBlurFormFields = (key) => {
    const { formFields } = deepClone(this.state);
    formFields[key].isDirty = true;
    if (key === "email" || key === "phone") {
      this.setState({ formFields }, () => this._formFieldsApiValidation(key));
    } else {
      this.setState({ formFields }, () => this._validateFormFields());
    }
  };

  _onChangeCaptcha = (captchaValue) => {
    const { formFields } = deepClone(this.state);
    formFields.captchaValue.value = captchaValue;
    formFields.captchaValue.isDirty = true;
    this.setState({ formFields }, () => this._validateFormFields());
  };

  _markAllFieldDirty = () => {
    return new Promise((resolve) => {
      const { formFields } = deepClone(this.state);
      Object.keys(formFields).forEach((key) => {
        formFields[key].isDirty = true;
      });
      this.setState({ formFields }, () => resolve(true));
    });
  };

  _onSubmitRequestForm = async (event) => {
    try {
      if (event) event.preventDefault();

      this.setState({ sumbitLoading: true });

      await this._markAllFieldDirty();

      const [isFormValid, isEmailFieldValid, isPhoneFieldValid] =
        await Promise.all([
          this._validateFormFields(),
          this._formFieldsApiValidation("email"),
          this._formFieldsApiValidation("phone"),
        ]);

      this.setState({ sumbitLoading: false });

      if (isFormValid && isEmailFieldValid && isPhoneFieldValid) {
        this.props.showLoader("Sign up...");

        const { formFields } = deepClone(this.state);

        let splitName = formFields.name.value.trim().split(" ");

        if (splitName?.length) {
          splitName = {
            first:
              splitName.length > 1
                ? splitName.slice(0, -1).join(" ")
                : splitName[0],
            last: splitName.length > 1 ? splitName[splitName.length - 1] : "",
          };
        }

        const payload = {
          contact: {
            name: {
              first: splitName.first?.trim() ? splitName.first.trim() : "",
              last: splitName.last?.trim() ? splitName.last.trim() : "",
            },
            email: formFields.email.value.trim(),
            phone: `(${
              formFields.phoneCode.value
            })${formFields.phone.value.trim()}`,
          },
          address: {
            street: formFields.resortAddress.value.trim(),
            city: formFields.city.value.trim(),
            state: formFields.state.value.trim(),
            zip: formFields.zipcode.value.trim(),
            country: formFields.country.value.trim(),
          },
          organizationName: formFields.resortName.value.trim(),
          numberOfRooms: formFields.numberOfRooms.value.trim(),
          comments: formFields.comments.value.trim(),
          captchaValue: formFields.captchaValue.value,
          invitationType: "resort",
          // userCategory: "resortadmin",
        };

        requestInvitation(payload)
          .then((res) => {
            this.props.hideLoader();
            showToast(
              "Invitation request submitted, Please wait for admin response.",
              "success"
            );
            this.props.history.push("/home");
          })
          .catch((error) => {
            console.log("error>>", error);
            this.props.hideLoader();
            showToast(
              error?.reason?.length
                ? error.reason
                : "Server error, Try again after sometime.",
              "error"
            );
          });
      }
    } catch (error) {
      console.log("error>>", error);
      this.setState({ sumbitLoading: false });
      showToast(
        error?.reason?.length
          ? error.reason
          : "Something went wrong, Try again after sometime.",
        "error"
      );
    }
  };

  render() {
    const { formFields, sumbitLoading } = this.state;

    return (
      <div className="vayco-public login_singup registerPage">
        <div className="public-header">
          <img
            src={APP_LOGO}
            onClick={() => this.props.history.push("/home")}
            alt="Logo"
            className="company-logo"
            width="140"
            loading="lazy"
          />

          <Button
            color="link"
            onClick={() => this.props.history.push("/home")}
            className="loginLink"
          >
            <span>Sign In</span>
          </Button>
        </div>
        <div className="app animated fadeIn ">
          <div className="login_wrapepr d-flex align-items-center">
            <div className="login_left">
              <img
                src={REGISTER_PAGE_BANNER}
                className="img-fluid"
                alt="banner"
                loading="lazy"
              />
            </div>
            <div className="login_right with_lognform">
              <div className="login_Container">
                <div className="form-title">
                  <h2 className="mt-0">Register Your Resort</h2>
                </div>

                <Form onSubmit={(e) => this._onSubmitRequestForm(e)}>
                  <p className="public-title">Contact Person Details</p>

                  <Row className="no-margin">
                    <Col md={6} className="mobilePadding-0">
                      <FormGroup>
                        <Label>Name</Label>
                        <InputGroup>
                          <InputGroupText>
                            <img
                              src={require("../../assets/img/user.svg").default}
                              className="img-fluid"
                              alt="user"
                              loading="lazy"
                            />
                          </InputGroupText>
                          <Input
                            type="text"
                            placeholder="Enter your name"
                            autoComplete="off"
                            name="name"
                            value={formFields.name.value}
                            onChange={(e) =>
                              this._onChangeFormFields("name", e.target.value)
                            }
                            onBlur={() => this._onBlurFormFields("name")}
                          />
                        </InputGroup>
                        {formFields.name.error ? (
                          <div className="form-error">
                            {formFields.name.error}
                          </div>
                        ) : null}
                      </FormGroup>
                    </Col>

                    <Col md={6} className="mobilePadding-0">
                      <FormGroup>
                        <Label>Email</Label>
                        <InputGroup>
                          <InputGroupText>
                            <img
                              src={
                                require("../../assets/img/at-svg.svg").default
                              }
                              className="img-fluid"
                              alt="user"
                              loading="lazy"
                            />
                          </InputGroupText>
                          <Input
                            type="text"
                            placeholder="Enter your email"
                            autoComplete="email"
                            name="email"
                            value={formFields.email.value}
                            onChange={(e) =>
                              this._onChangeFormFields("email", e.target.value)
                            }
                            onBlur={() => this._onBlurFormFields("email")}
                          />
                          {formFields.email.isDublicateLoading ? (
                            <div className="spinnerLogin">
                              <Spinner
                                style={{ width: "1rem", height: "1rem" }}
                              />
                            </div>
                          ) : null}
                        </InputGroup>
                        {formFields.email.error ? (
                          <div className="form-error">
                            {formFields.email.error}
                          </div>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>

                  <FormGroup>
                    <Label>Phone Number</Label>
                    <InputGroup className="countryCode">
                      <InputGroupText>
                        <img
                          src={require("../../assets/img/phone.svg").default}
                          className="img-fluid"
                          alt="user"
                          loading="lazy"
                        />
                        <Input
                          type="select"
                          className="pl-0"
                          value={formFields.phoneCode.value}
                          onChange={(e) =>
                            this._onChangeFormFields(
                              "phoneCode",
                              e.target.value
                            )
                          }
                        >
                          {countryCodes.map((each) => (
                            <option key={each.code} value={each.dial_code}>
                              {each.code} ({each.dial_code})
                            </option>
                          ))}
                        </Input>
                      </InputGroupText>
                      <Input
                        type="text"
                        placeholder="Enter your phone number"
                        autoComplete="off"
                        value={formFields.phone.value}
                        onChange={(e) =>
                          this._onChangeFormFields("phone", e.target.value)
                        }
                        onBlur={() => this._onBlurFormFields("phone")}
                      />
                      {formFields.phone.isDublicateLoading ? (
                        <div className="spinnerLogin">
                          <Spinner style={{ width: "1rem", height: "1rem" }} />
                        </div>
                      ) : null}
                    </InputGroup>
                    {formFields.phone.error ? (
                      <div className="form-error">{formFields.phone.error}</div>
                    ) : null}
                  </FormGroup>

                  <p className="public-title mt-4">Resort Details</p>

                  <Row className="no-margin">
                    <Col md={6} className="mobilePadding-0">
                      <FormGroup>
                        <Label>Resort Name</Label>
                        <InputGroup>
                          <InputGroupText>
                            <img
                              src={
                                require("../../assets/img/hotel.svg").default
                              }
                              className="img-fluid"
                              alt="user"
                              loading="lazy"
                            />
                          </InputGroupText>
                          <Input
                            type="text"
                            placeholder="Enter your resort name"
                            autoComplete="off"
                            value={formFields.resortName.value}
                            onChange={(e) =>
                              this._onChangeFormFields(
                                "resortName",
                                e.target.value
                              )
                            }
                            onBlur={() => this._onBlurFormFields("resortName")}
                          />
                        </InputGroup>
                        {formFields.resortName.error ? (
                          <div className="form-error">
                            {formFields.resortName.error}
                          </div>
                        ) : null}
                      </FormGroup>
                    </Col>

                    <Col md={6} className="mobilePadding-0">
                      <FormGroup>
                        <Label>Number of Rooms</Label>
                        <InputGroup>
                          <InputGroupText>
                            <img
                              src={
                                require("../../assets/img/hotel.svg").default
                              }
                              className="img-fluid"
                              alt="user"
                              loading="lazy"
                            />
                          </InputGroupText>
                          <Input
                            type="text"
                            placeholder="Enter the number of rooms in your resorts"
                            autoComplete="off"
                            value={formFields.numberOfRooms.value}
                            onChange={(e) =>
                              this._onChangeFormFields(
                                "numberOfRooms",
                                e.target.value
                              )
                            }
                            onBlur={() =>
                              this._onBlurFormFields("numberOfRooms")
                            }
                          />
                        </InputGroup>
                        {formFields.numberOfRooms.error ? (
                          <div className="form-error">
                            {formFields.numberOfRooms.error}
                          </div>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>

                  <FormGroup>
                    <Label>Resort Address</Label>
                    <InputGroup>
                      <InputGroupText>
                        <img
                          src={require("../../assets/img/location.svg").default}
                          className="img-fluid"
                          alt="user"
                          loading="lazy"
                        />
                      </InputGroupText>
                      <Input
                        type="text"
                        placeholder="Enter your resort address"
                        autoComplete="off"
                        value={formFields.resortAddress.value}
                        onChange={(e) =>
                          this._onChangeFormFields(
                            "resortAddress",
                            e.target.value
                          )
                        }
                        onBlur={() => this._onBlurFormFields("resortAddress")}
                      />
                    </InputGroup>
                    {formFields.resortAddress.error ? (
                      <div className="form-error">
                        {formFields.resortAddress.error}
                      </div>
                    ) : null}
                  </FormGroup>

                  <Row className="no-margin">
                    <Col md={6} className="mobilePadding-0">
                      <FormGroup>
                        <Label>Country</Label>
                        <InputGroup>
                          <InputGroupText>
                            <img
                              src={require("../../assets/img/city.svg").default}
                              className="img-fluid"
                              alt="State"
                              loading="lazy"
                            />
                          </InputGroupText>
                          <Input
                            type="select"
                            value={formFields.country.value}
                            onChange={(e) =>
                              this._onChangeFormFields(
                                "country",
                                e.target.value
                              )
                            }
                            onBlur={() => this._onBlurFormFields("country")}
                          >
                            {countryCodes.map((each) => (
                              <option key={each.code} value={each.code}>
                                {each.name}
                              </option>
                            ))}
                          </Input>
                        </InputGroup>
                        {formFields.country.error ? (
                          <div className="form-error">
                            {formFields.country.error}
                          </div>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col md={6} className="mobilePadding-0">
                      <FormGroup>
                        <Label>State</Label>
                        <InputGroup>
                          <InputGroupText>
                            <img
                              src={require("../../assets/img/city.svg").default}
                              className="img-fluid"
                              alt="city"
                              loading="lazy"
                            />
                          </InputGroupText>

                          {formFields.country.value &&
                          (formFields.country.value === "IN" ||
                            formFields.country.value === "US" ||
                            formFields.country.value === "MX") ? (
                            <Input
                              type="select"
                              name="state"
                              onChange={(e) =>
                                this._onChangeFormFields(
                                  "state",
                                  e.target.value
                                )
                              }
                              value={formFields.state.value}
                              onBlur={() => this._onBlurFormFields("state")}
                            >
                              <option value="">Select State</option>
                              {StatesConfig[formFields.country.value].map(
                                (stateCode, stateIndex) => (
                                  <option
                                    key={stateIndex}
                                    value={stateCode.code}
                                  >
                                    {stateCode.name}
                                  </option>
                                )
                              )}
                            </Input>
                          ) : (
                            <Input
                              type="text"
                              placeholder="Enter your state"
                              name="state"
                              value={formFields.state.value}
                              onChange={(e) =>
                                this._onChangeFormFields(
                                  "state",
                                  e.target.value
                                )
                              }
                              onBlur={() => this._onBlurFormFields("state")}
                            />
                          )}
                        </InputGroup>
                        {formFields.state.error ? (
                          <div className="form-error">
                            {formFields.state.error}
                          </div>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col md={6} className="mobilePadding-0">
                      <FormGroup>
                        <Label>City</Label>
                        <InputGroup>
                          <InputGroupText>
                            <img
                              src={require("../../assets/img/zip.svg").default}
                              className="img-fluid"
                              alt="city"
                              loading="lazy"
                            />
                          </InputGroupText>
                          <Input
                            type="text"
                            placeholder="Enter your city"
                            value={formFields.city.value}
                            onChange={(e) =>
                              this._onChangeFormFields("city", e.target.value)
                            }
                            onBlur={() => this._onBlurFormFields("city")}
                          />
                        </InputGroup>
                        {formFields.city.error ? (
                          <div className="form-error">
                            {formFields.city.error}
                          </div>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col md={6} className="mobilePadding-0">
                      <FormGroup>
                        <Label>Zipcode</Label>
                        <InputGroup>
                          <InputGroupText>
                            <img
                              src={require("../../assets/img/city.svg").default}
                              className="img-fluid"
                              alt="State"
                              loading="lazy"
                            />
                          </InputGroupText>
                          <Input
                            type="text"
                            placeholder="Enter your zipcode"
                            value={formFields.zipcode.value}
                            onChange={(e) =>
                              this._onChangeFormFields(
                                "zipcode",
                                e.target.value
                              )
                            }
                            onBlur={() => this._onBlurFormFields("zipcode")}
                          />
                        </InputGroup>
                        {formFields.zipcode.error ? (
                          <div className="form-error">
                            {formFields.zipcode.error}
                          </div>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>

                  <FormGroup className="commentArea">
                    <Label>Comments If Any</Label>
                    <InputGroup>
                      <InputGroupText>
                        <img
                          src={require("../../assets/img/comment.svg").default}
                          className="img-fluid"
                          alt="user"
                          loading="lazy"
                        />
                      </InputGroupText>
                      <Input
                        type="textarea"
                        placeholder="Enter your comments"
                        name="comments"
                        value={formFields.comments.value}
                        onChange={(e) =>
                          this._onChangeFormFields("comments", e.target.value)
                        }
                      />
                    </InputGroup>
                  </FormGroup>

                  <Row noGutters className="mt-sm-5 mt-3 align-items-center">
                    <Col sm={6} xs={12} className="text-sm-left text-center">
                      <div className="recaptcha">
                        <ReCAPTCHA
                          sitekey={ReCAPTCHA_SITE_KEY}
                          onChange={this._onChangeCaptcha}
                        />
                        {formFields.captchaValue.error ? (
                          <div className="form-error">
                            {formFields.captchaValue.error}
                          </div>
                        ) : null}
                      </div>
                    </Col>
                    <Col
                      sm={6}
                      xs={12}
                      className="text-sm-right text-center mt-sm-0 mt-3"
                    >
                      <Button
                        color="primary"
                        type="submit"
                        className="btn-submit"
                        disabled={sumbitLoading}
                      >
                        {sumbitLoading ? (
                          <i className="fa fa-spinner fa-spin mr-1" />
                        ) : null}{" "}
                        Register Resort
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </div>
              <AuthFooter />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
  };
};

export default connect(null, mapDispatchToProps)(Register);
