import React, { useEffect, forwardRef, useImperativeHandle } from "react";

import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  // LoadCanvasTemplateNoReload,
  validateCaptcha,
} from "react-simple-captcha";
import { Input, Label } from "reactstrap";
import { showToast } from "../helper-methods";
import { FormGroup } from "react-bootstrap";

const VerificationCaptcha = forwardRef((props, ref) => {
  useEffect(() => {
    loadCaptchaEnginge(4);
  }, []);

  useImperativeHandle(ref, () => ({
    doSubmit() {
      let user_captcha = document.getElementById("user_captcha_input").value;

      if (validateCaptcha(user_captcha) === true) {
        showToast("Captcha Matched", "success");
        loadCaptchaEnginge(6);
        document.getElementById("user_captcha_input").value = "";
        return true;
      } else {
        showToast("Captcha Does Not Match", "error");
        document.getElementById("user_captcha_input").value = "";
        return false;
      }
      // return validateCaptcha(user_captcha);
    },
  }));

  // const doSubmit = () => {
  //   let user_captcha = document.getElementById("user_captcha_input").value;

  //   if (validateCaptcha(user_captcha) === true) {
  //     alert("Captcha Matched");
  //     loadCaptchaEnginge(6);
  //     document.getElementById("user_captcha_input").value = "";
  //   } else {
  //     alert("Captcha Does Not Match");
  //     document.getElementById("user_captcha_input").value = "";
  //   }
  // };

  return (
    <>
      <FormGroup className="verification">
        <Label>Verification</Label>
        <div className="captcha">
          <LoadCanvasTemplate reloadText={`<i className="fas fa-user"></i>`} />
        </div>
        <Input
          placeholder="Enter code shown in above image*"
          id="user_captcha_input"
          name="user_captcha_input"
          type="text"
        />
        {/*                    
            <Button
                color="primary"
                className="payNow"
                onClick={() => this.doSubmit()}
            >
                Submit
            </Button> */}
      </FormGroup>
    </>
  );
});

export default VerificationCaptcha;
