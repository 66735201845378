import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Label,
  FormGroup,
  Input,
  Row,
  Card,
  CustomInput,
  Spinner,
} from "reactstrap";
import { InputGroup, FormControl } from "react-bootstrap";
import AddOfferModal from "../../components/Modals/add-offer-modal";
import {
  getAllGroups,
  getAllOffers,
  getAmenitiesList,
  updateOffer,
} from "../../http/http-calls";
import {
  capitalize,
  extractQueryParams,
  showToast,
} from "../../helper-methods";
import ReactPaginate from "react-paginate";
import { DEFAULT_PROFILE_PICTURE } from "../../config";
import ReadMoreAndLess from "react-read-more-less";
import { useHistory } from "react-router-dom";

let searchInterval;

const OffersPage = () => {
  const history = useHistory();

  const [addOfferModal, setAddOfferModal] = useState({
    isOpen: false,
    data: null,
  });

  const [amenityArray, setAmenityArray] = useState([]);
  const [groups, setGroups] = useState([]);

  const [filters, setFilters] = useState({
    status: "",
    _amenity: "",
  });
  const [getAllOffersPayload, setGetAllOffersPayload] = useState({
    filters: {},
    search: "",
    skip: 0,
    pageNumber: 0,
    limit: 8,
  });
  const [offers, setOffers] = useState([]);
  const [offersCount, setOffersCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [isActiveLoading, setIsActiveLoading] = useState(false);
  const [filtersLoading, setFiltersLoading] = useState(false);

  const _toggleAddOfferModal = (isOpen = false, data = null) => {
    setAddOfferModal({
      isOpen,
      data,
    });
  };

  const _getAmenitiesList = () => {
    const { resortId } = extractQueryParams();
    const payload = { resortid: resortId };

    getAmenitiesList(payload)
      .then((res) => {
        setAmenityArray(res.amenity?.length ? res.amenity : []);
      })
      .catch((error) => {
        console.log("error>>", error);
        showToast(
          error?.reason?.length
            ? error.reason
            : "Server error, Try again after sometime.",
          "error"
        );
      });
  };

  const _getAllGroups = () => {
    const { resortId } = extractQueryParams();
    const payload = { resortid: resortId };

    getAllGroups(payload)
      .then((res) => {
        setGroups(res.groups?.length ? res.groups : []);
      })
      .catch((error) => {
        console.log("error>>", error);
        showToast(
          error?.reason?.length
            ? error.reason
            : "Server error, Try again after sometime.",
          "error"
        );
      });
  };

  const _getAllOffers = (payload) => {
    setLoading(true);

    const { resortId } = extractQueryParams();
    payload["resortId"] = resortId;

    getAllOffers(payload)
      .then((res) => {
        setOffers(res.offers);
        setOffersCount(res.offersCount);
        setLoading(false);
        setSearchLoading(false);
        setFiltersLoading(false);
      })
      .catch((error) => {
        console.log("error>>", error);
        setLoading(false);
        setSearchLoading(false);
        setFiltersLoading(false);
        showToast(
          error?.reason?.length
            ? error.reason
            : "Server error, Try again after sometime.",
          "error"
        );
      });
  };

  useEffect(() => {
    _getAmenitiesList();
    _getAllGroups();
    _getAllOffers(getAllOffersPayload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _toggleOfferStatus = (id, value) => {
    setIsActiveLoading(id);

    const newOffers = [...offers];
    const findOffer = newOffers.find((offer) => offer._id === id);
    if (!findOffer) {
      showToast("Something went wrong, Try again after sometime.", "error");
      return;
    }
    findOffer["isActive"] = value;

    setOffers(newOffers);

    const payload = { isActive: value };

    const { resortId } = extractQueryParams();
    payload["resortId"] = resortId;

    updateOffer(id, payload)
      .then((res) => {
        setIsActiveLoading(false);
        showToast(
          value ? "Activated successfully" : "Deactivated successfully",
          "success"
        );
      })
      .catch((error) => {
        console.log("error>>", error);
        setIsActiveLoading(false);
        _getAllOffers(getAllOffersPayload);
        showToast(
          error?.reason?.length
            ? error.reason
            : "Server error, Try again after sometime.",
          "error"
        );
      });
  };

  const _onPageChange = (pageNumber = 0) => {
    const newGetAllOffersPayload = { ...getAllOffersPayload };
    newGetAllOffersPayload["skip"] =
      pageNumber * newGetAllOffersPayload["limit"];
    newGetAllOffersPayload["pageNumber"] = pageNumber;
    setGetAllOffersPayload(newGetAllOffersPayload);
    _getAllOffers(newGetAllOffersPayload);
  };

  const _onChangeSearch = (searchValue) => {
    clearInterval(searchInterval);
    setSearchLoading(true);
    const newGetAllOffersPayload = { ...getAllOffersPayload };
    newGetAllOffersPayload["search"] = searchValue;
    newGetAllOffersPayload["skip"] = 0;
    newGetAllOffersPayload["pageNumber"] = 0;
    setGetAllOffersPayload(newGetAllOffersPayload);
    searchInterval = setTimeout(() => {
      _getAllOffers(newGetAllOffersPayload);
    }, 1000);
  };

  const _clearFilters = () => {
    setFiltersLoading(true);

    const newFilters = { ...filters };
    Object.keys(newFilters).forEach((key) => {
      newFilters[key] = "";
    });
    setFilters(newFilters);

    const newGetAllOffersPayload = { ...getAllOffersPayload };
    newGetAllOffersPayload["search"] = "";
    newGetAllOffersPayload["skip"] = 0;
    newGetAllOffersPayload["pageNumber"] = 0;
    newGetAllOffersPayload["filters"] = {};
    setGetAllOffersPayload(newGetAllOffersPayload);

    _getAllOffers(newGetAllOffersPayload);
  };

  const _onChangeFilters = (key, value) => {
    setFiltersLoading(true);

    const newFilters = { ...filters };
    newFilters[key] = value;
    setFilters(newFilters);

    const newGetAllOffersPayload = { ...getAllOffersPayload };
    newGetAllOffersPayload["skip"] = 0;
    newGetAllOffersPayload["pageNumber"] = 0;
    newGetAllOffersPayload["filters"] = {};

    Object.keys(newFilters).forEach((eachFilterKey) => {
      if (eachFilterKey === "status" && newFilters[eachFilterKey]) {
        newGetAllOffersPayload.filters["isActive"] =
          newFilters[eachFilterKey] === "active" ? true : false;
      } else {
        if (newFilters[eachFilterKey])
          newGetAllOffersPayload.filters[eachFilterKey] =
            newFilters[eachFilterKey];
      }
    });

    setGetAllOffersPayload(newGetAllOffersPayload);

    _getAllOffers(newGetAllOffersPayload);
  };

  return (
    <div className="content">
      <Container fluid>
        {/* ===== Page Title ===== */}
        <div className="page_Inner_header">
          <Row className="align-items-center no-margin">
            <Col xl={8} className="mobilePadding-0">
              <div className="page_title">
                <Button
                  color="link"
                  className="pl-0 pr-2"
                  onClick={() => history.goBack()}
                >
                  <i className="fa fa-chevron-left" />
                </Button>
                <h1 className="space_remove">
                  Offers {loading || filtersLoading ? <Spinner /> : null}
                </h1>
              </div>
            </Col>
            <Col md={4} className="text-right">
              <Button
                onClick={() => _toggleAddOfferModal(true)}
                className="btn btn-primary"
              >
                Create Offer
              </Button>
            </Col>
          </Row>
        </div>
        {/* ======== Filter ======= */}
        <Card body className="search-filter pt-2 pb-3 mb-4">
          <Form>
            <div className="d-flex justify-content-between align-items-end">
              <div className="d-flex justify-content-center align-items-end">
                <FormGroup className="mb-0">
                  <Label for="exampleEmail">Status</Label>
                  <Input
                    type="select"
                    name="status"
                    value={filters.status}
                    onChange={(e) => _onChangeFilters("status", e.target.value)}
                  >
                    <option value="">All</option>
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </Input>
                </FormGroup>
                <FormGroup className="mb-0 ml-3">
                  <Label for="request">Amenity</Label>
                  <Input
                    type="select"
                    name="_amenity"
                    value={filters._amenity}
                    onChange={(e) =>
                      _onChangeFilters("_amenity", e.target.value)
                    }
                  >
                    <option value="">All</option>
                    {amenityArray.map(
                      (each) =>
                        (each.id || each._id) &&
                        each.name && (
                          <option
                            key={each.id || each._id}
                            value={each.id || each._id}
                          >
                            {capitalize(each.name)}
                          </option>
                        )
                    )}
                  </Input>
                </FormGroup>
              </div>
              <div className="d-flex align-items-end">
                <FormGroup className="mb-0">
                  <Label>Search By Name</Label>
                  <InputGroup>
                    <FormControl
                      type="text"
                      placeholder="Enter"
                      autoComplete="off"
                      name="firstName"
                      value={getAllOffersPayload.search}
                      onChange={(e) => _onChangeSearch(e.target.value)}
                    />
                    <InputGroup.Text id="basic-addon2">
                      {searchLoading ? (
                        <i className="fa fa-spinner fa-spin" />
                      ) : getAllOffersPayload.search ? (
                        <i
                          className="fas fa-times"
                          onClick={() => _onChangeSearch("")}
                        />
                      ) : (
                        <i className="fas fa-search" />
                      )}
                    </InputGroup.Text>
                  </InputGroup>
                </FormGroup>
                <Button
                  color="primary"
                  className="btn-clear ml-4"
                  onClick={() => _clearFilters()}
                >
                  <i className="fa fa-eraser fs-15" />
                </Button>
              </div>
            </div>
          </Form>
        </Card>

        <div className="mt-4  main_inner gridBox bg-white">
          <div className="userList">
            <Row className="text-center">
              {offers.length ? (
                offers.map((offer) => (
                  <Col xl="3" lg="4" md="4" sm="6" xs="12" key={offer.id}>
                    <Card>
                      <div className="user_box">
                        <div className="user_image">
                          <img
                            src={
                              offer.offerImage
                                ? offer.offerImage
                                : DEFAULT_PROFILE_PICTURE
                            }
                            alt="offer cover"
                            loading="lazy"
                          />
                          <div className="switch_box">
                            <CustomInput
                              type="switch"
                              id={`offer_page_custom_switch_${offer._id}`}
                              disabled={isActiveLoading === offer._id}
                              checked={offer.isActive}
                              onChange={(e) =>
                                _toggleOfferStatus(offer._id, e.target.checked)
                              }
                            />
                          </div>
                        </div>
                        <div className="user_content">
                          <h1>
                            {offer.name ? capitalize(offer.name) : "N/A"}
                            <span>
                              <i
                                title="Update Offer"
                                className="fas fa-pencil-alt edit"
                                onClick={() =>
                                  _toggleAddOfferModal(true, offer)
                                }
                              />
                            </span>
                          </h1>
                          {offer.description ? (
                            <ReadMoreAndLess
                              className="read-more-content"
                              charLimit={40}
                              readMoreText="Read more"
                              readLessText=" Read less"
                            >
                              {offer.description}
                            </ReadMoreAndLess>
                          ) : null}
                        </div>
                      </div>
                    </Card>
                  </Col>
                ))
              ) : (
                <Col sm="12">
                  {loading ? <Spinner /> : "There is no data to display."}
                </Col>
              )}
            </Row>
          </div>
        </div>

        {offers?.length && offersCount ? (
          <div className="row mt-4">
            <div className="col-md-6">
              <span className="showng_entries">
                Showing {offers?.length} entries out of {offersCount}
              </span>
            </div>
            <div className="col-md-6">
              <ReactPaginate
                forcePage={getAllOffersPayload.pageNumber}
                className="pagination justify-content-end mb-2"
                breakLabel="..."
                nextLabel={<i className="fa fa-chevron-right" />}
                previousLabel={<i className="fa fa-chevron-left" />}
                previousClassName="previous"
                nextClassName="next"
                onPageChange={({ selected }) => _onPageChange(selected)}
                pageRangeDisplayed={3}
                pageCount={Math.ceil(offersCount / getAllOffersPayload.limit)}
                pageClassName="page-item"
                renderOnZeroPageCount={null}
                containerClassName={"pagination"}
                previousLinkClassName={"page-link"}
                nextLinkClassName={"page-link"}
                activeClassName={"active"}
                disabledClassName={"page-item"}
              />
            </div>
          </div>
        ) : null}
      </Container>

      <AddOfferModal
        isOpen={addOfferModal.isOpen}
        data={addOfferModal.data}
        toggle={() => _toggleAddOfferModal()}
        amenityArray={amenityArray}
        groups={groups}
        getAllOffers={() => _onPageChange()}
        resetCurrentData={() => _getAllOffers(getAllOffersPayload)}
      />
    </div>
  );
};

export default OffersPage;
