import React, { Component } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Label,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Row,
  Collapse,
  Spinner,
  Badge,
  CustomInput,
} from "reactstrap";
import { daysOfWeek, timeValues } from "../../config/helper-config";
import {
  capitalize,
  deepClone,
  extractQueryParams,
  showToast,
  uploadFileOnServer,
} from "../../helper-methods";
import {
  createAmenity,
  getAmenityById,
  getResortSettings,
  updateAmenity,
} from "../../http/http-calls";

class AddAmenity extends Component {
  state = {
    resortId: null,
    amenity: null,
    amenityId: null,
    formFields: {
      name: {
        value: "",
        isDirty: false,
        error: null,
        isValidationRequired: true,
      },
      category: {
        value: "",
        isDirty: false,
        error: null,
        isValidationRequired: true,
      },
      description: {
        value: "",
        isDirty: false,
        error: null,
        isValidationRequired: false,
      },
      address: {
        value: "",
        isDirty: false,
        error: null,
        isValidationRequired: true,
      },
      photos: {
        value: [],
        isDirty: false,
        error: null,
        isValidationRequired: true,
      },
      serviceMenu: {
        value: [],
        isDirty: false,
        error: null,
        isValidationRequired: false,
      },
    },
    isAcceptBookingRequests: false,
    isUploadServiceMenuPdf: false,
    isOpenDayCollapse: false,
    faqs: [{ _id: Date.now(), question: "", answer: "" }],
    availability: {
      sunday: [],
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
    },
    availabilityDayErrors: {},
    mapOfProperty: {
      uploadData: null,
      previewBlob: null,
      type: null,
      uploadUrl: null,
    },
    serviceMenuPdf: {
      uploadData: null,
      previewBlob: null,
      type: null,
      uploadUrl: null,
    },
    amenityCategories: [],
    loading: false,
  };

  _setSettings = () => {
    const { settings } = this.state;

    this.setState({
      amenityCategories: settings?.amenityCategories || [],
    });
  };

  _getSettings = async () => {
    getResortSettings(this.state.resortId)
      .then((res) => {
        this.setState({ settings: res.setting }, () => {
          this._setSettings();
        });
      })
      .catch((error) => {
        showToast(
          error?.reason?.length
            ? error.reason
            : "Server error, Try again after sometime.",
          "error"
        );
      });
  };

  _addTime = async (key) => {
    const { availability } = this.state;
    const lastEnd = availability[key][availability[key].length - 1]?.end;
    if (lastEnd && lastEnd === timeValues[timeValues.length - 1].value) {
      showToast("Please serialize timing", "error");
      return;
    } else {
      const isTimeFind = availability[key][availability[key].length - 1];
      if (isTimeFind) {
        isTimeFind["startIsDirty"] = true;
        isTimeFind["endIsDirty"] = true;
        this.setState({ availability });

        const isFormValid = await this._validateAvailability();
        if (isFormValid) {
          availability[key].push({ _id: Date.now(), start: "", end: "" });
          this.setState({ availability });
        }
      } else {
        availability[key].push({ _id: Date.now(), start: "", end: "" });
        this.setState({ availability });
      }
    }
  };

  _removeTime = (key, index) => {
    const { availability } = this.state;
    availability[key].splice(index, 1);
    this.setState({ availability }, () => this._validateAvailability());
  };

  /**
   *
   * @param {string} key - monday, tuesday ...
   * @param {string} subKey - start / end
   * @param {string} value  - value
   * @param {number} index - index number
   */
  _onChangeAvailability = (key, subKey, value, index) => {
    const { availability } = deepClone(this.state);
    availability[key][index][subKey] = value;
    availability[key][index][`${subKey}IsDirty`] = true;
    if (subKey === "start") {
      if (
        Number(availability[key][index]["end"]) <=
        Number(availability[key][index]["start"])
      ) {
        availability[key][index]["end"] = "";
        availability[key][index][`endIsDirty`] = false;
      }
    }
    this.setState({ availability }, () => this._validateAvailability());
  };

  // key = mapOfProperty or serviceMenuPdf
  _resetLocalMedia = (key = "mapOfProperty") => {
    this.setState({
      [key]: {
        uploadData: null,
        previewBlob: null,
        type: null,
        uploadUrl: null,
      },
    });
  };

  // key = mapOfProperty or serviceMenuPdf
  _updateLocalMedia = (e, key = "mapOfProperty") => {
    try {
      if (e?.target?.files?.length) {
        const file = e.target.files[0];

        const fileType = file.type.split("/")[0];

        switch (key) {
          case "mapOfProperty": {
            if (fileType === "image") {
              const previewBlob = URL.createObjectURL(file);

              const { mapOfProperty } = deepClone(this.state);
              mapOfProperty["uploadData"] = file;
              mapOfProperty["previewBlob"] = previewBlob;
              mapOfProperty["type"] = fileType;
              mapOfProperty["uploadUrl"] = null;
              this.setState({ mapOfProperty });
            } else {
              showToast("Only image file is allowed", "error");
              return;
            }

            break;
          }
          case "serviceMenuPdf": {
            const fileType2 = file.type.split("/")[1];

            if (fileType === "application" && fileType2 === "pdf") {
              const previewBlob = URL.createObjectURL(file);

              const { serviceMenuPdf } = deepClone(this.state);
              serviceMenuPdf["uploadData"] = file;
              serviceMenuPdf["previewBlob"] = previewBlob;
              serviceMenuPdf["type"] = fileType2;
              serviceMenuPdf["uploadUrl"] = null;
              this.setState({ serviceMenuPdf });
            } else {
              showToast("Only pdf file is allowed", "error");
              return;
            }
            break;
          }
          default:
        }
      }
    } catch (error) {
      console.log("error>>", error);
      showToast("Something went wrong, Try again after sometime.", "error");
    }
  };

  // key = serviceMenu or photos
  _updateLocalPhoto = (e, key = "photos") => {
    try {
      if (e?.target?.files?.length) {
        const { formFields } = deepClone(this.state);
        for (let file of e.target.files) {
          const fileType = file.type.split("/")[0];
          if (fileType === "image") {
            const previewBlob = URL.createObjectURL(file);
            if (formFields[key].value.length) {
              formFields[key].value.push({
                uploadData: file,
                previewBlob,
                type: fileType,
                isCoverPhoto: false,
                forKeyName: key,
              });
            } else {
              if (key === "photos") {
                formFields[key].value.push({
                  uploadData: file,
                  previewBlob,
                  type: fileType,
                  isCoverPhoto: true,
                  forKeyName: "coverImage",
                });
              } else {
                formFields[key].value.push({
                  uploadData: file,
                  previewBlob,
                  type: fileType,
                  isCoverPhoto: false,
                  forKeyName: key,
                });
              }
            }
          } else {
            showToast("Only image file is allowed", "error");
            continue;
          }
        }
        formFields.photos.isDirty = true;
        this.setState({ formFields }, () => this._validateFormFields());
      }
    } catch (error) {
      console.log("error>>", error);
      showToast("Something went wrong, Try again after sometime.", "error");
    }
  };

  _makeCoverPhoto = (index) => {
    const { formFields } = deepClone(this.state);
    formFields.photos.value.forEach((photo) => {
      photo.isCoverPhoto = false;
      photo.forKeyName = "photos";
    });
    formFields.photos.value[index]["isCoverPhoto"] = true;
    formFields.photos.value[index]["forKeyName"] = "coverImage";
    this.setState({ formFields });
  };

  // key = serviceMenu or photos
  _removePhoto = (index, key = "photos") => {
    const { formFields } = deepClone(this.state);
    formFields[key].value.splice(index, 1);
    this.setState({ formFields });
  };

  _addMoreFaq = () => {
    const { faqs } = deepClone(this.state);
    faqs.push({ id: Date.now(), question: "", answer: "" });
    this.setState({ faqs });
  };

  _removeFaq = (index) => {
    const { faqs } = deepClone(this.state);
    faqs.splice(index, 1);
    this.setState({ faqs }, () => this._validateFaqs());
  };

  _onChangeFaq = (key, value, index) => {
    const { faqs } = deepClone(this.state);
    faqs[index][key] = value;
    faqs[index][`${key}IsDirty`] = true;
    this.setState({ faqs }, () => this._validateFaqs());
  };

  _toggleDayCollapse = (isOpenDayCollapse = null) => {
    if (this.state.isOpenDayCollapse === isOpenDayCollapse)
      this.setState({ isOpenDayCollapse: null });
    else this.setState({ isOpenDayCollapse });
  };

  _validateFormFields = () => {
    return new Promise((resolve) => {
      const { formFields } = deepClone(this.state);

      let isFormValid = true;

      Object.keys(formFields).forEach((key) => {
        if (formFields[key].isDirty && formFields[key].isValidationRequired) {
          switch (key) {
            case "name":
            case "category":
            case "address": {
              if (formFields[key].value?.trim().length) {
                formFields[key].error = null;
                formFields[key].isDirty = false;
              } else {
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "photos": {
              if (formFields[key].value?.length) {
                formFields[key].error = null;
                formFields[key].isDirty = false;
              } else {
                formFields[key].error = "*Atleast one image is required";
                isFormValid = false;
              }
              break;
            }
            default:
          }
        }
      });

      this.setState({ formFields }, () => resolve(isFormValid));
    });
  };

  _onChangeFormFields = (key, value) => {
    const { formFields } = deepClone(this.state);
    formFields[key].value = value;
    this.setState({ formFields });
  };

  _onBlurFormFields = (key) => {
    const { formFields } = deepClone(this.state);
    formFields[key].isDirty = true;
    this.setState({ formFields }, () => this._validateFormFields());
  };

  _setForm = (amenity) => {
    try {
      let {
        formFields,
        availability,
        faqs,
        mapOfProperty,
        serviceMenuPdf,
        isAcceptBookingRequests,
        isUploadServiceMenuPdf,
      } = deepClone(this.state);

      formFields.name.value = amenity?.name || "";
      formFields.description.value = amenity?.description || "";
      formFields.category.value = amenity?._group?._id || "";
      formFields.address.value = amenity?.address || "";

      availability = amenity?.availability || {};

      daysOfWeek.forEach((each) => {
        if (!availability[each.value]) {
          availability[each.value] = [];
        }
      });

      faqs = amenity?.faqs?.length
        ? amenity.faqs
        : [{ _id: Date.now(), question: "", answer: "" }];

      mapOfProperty["uploadUrl"] = amenity?.mapOfProperty || null;

      if (amenity?.coverImage) {
        formFields.photos.value.push({
          uploadData: null,
          type: "image",
          previewBlob: null,
          isCoverPhoto: true,
          uploadUrl: amenity.coverImage,
        });
      }

      if (amenity?.photos?.length) {
        amenity.photos.forEach((photoUrl) =>
          formFields.photos.value.push({
            uploadData: null,
            type: "image",
            previewBlob: null,
            uploadUrl: photoUrl,
          })
        );
      }

      if (amenity?.serviceMenu?.length) {
        if (
          amenity.serviceMenu.length === 1 &&
          amenity.serviceMenu[0].docType === "pdf"
        ) {
          serviceMenuPdf["uploadUrl"] = amenity.serviceMenu[0].url;
          serviceMenuPdf["name"] = amenity.serviceMenu[0].name;
          serviceMenuPdf["type"] = amenity.serviceMenu[0].docType;
          isUploadServiceMenuPdf = true;
        } else {
          amenity.serviceMenu.forEach((each) =>
            formFields.serviceMenu.value.push({
              uploadData: null,
              type: "image",
              name: each.name,
              previewBlob: null,
              uploadUrl: each.url,
            })
          );
          isUploadServiceMenuPdf = false;
        }
      }

      isAcceptBookingRequests = amenity?.isAcceptBookingRequests ? true : false;

      this.setState({
        amenity,
        formFields,
        availability,
        faqs,
        mapOfProperty,
        serviceMenuPdf,
        isAcceptBookingRequests,
        isUploadServiceMenuPdf,
      });
    } catch (error) {
      console.log("error>>", error);
      this.setState({ loading: false });
      showToast(
        error?.reason?.length
          ? error.reason
          : "Server error, Try again after sometime.",
        "error"
      );
      this.props.history.goBack();
    }
  };

  _getAmenityById = (id) => {
    this.setState({ loading: true });

    getAmenityById(id)
      .then((res) => {
        this.setState({ loading: false });
        this._setForm(res.aminity);
      })
      .catch((error) => {
        console.log("error>>", error);
        this.setState({ loading: false });
        showToast(
          error?.reason?.length
            ? error.reason
            : "Server error, Try again after sometime.",
          "error"
        );
        this.props.history.goBack();
      });
  };

  _updateAmenity = (id, payload) => {
    updateAmenity(id, payload)
      .then((res) => {
        this.setState({ loading: false });
        showToast("Hotel Amenity updated successfully.", "success");
        this.props.history.goBack();
      })
      .catch((error) => {
        console.log("error>>", error);
        this.setState({ loading: false });
        showToast(
          error?.reason?.length
            ? error.reason
            : "Server error, Try again after sometime.",
          "error"
        );
      });
  };

  _createAmenity = (payload) => {
    createAmenity(payload)
      .then((res) => {
        this.setState({ loading: false });
        showToast("Hotel Amenity Added successfully.", "success");
        this.props.history.goBack();
      })
      .catch((error) => {
        console.log("error>>", error);
        this.setState({ loading: false });
        showToast(
          error?.reason?.length
            ? error.reason
            : "Server error, Try again after sometime.",
          "error"
        );
      });
  };

  _compareAllOverlapTiming = (timeArray, time) => {
    let isValid = true;
    timeArray.forEach((each) => {
      if (
        each.end &&
        each.start &&
        Number(time.start) >= Number(each.start) &&
        Number(time.start) < Number(each.end)
      )
        isValid = false;

      if (
        each.end &&
        each.start &&
        Number(time.end) > Number(each.start) &&
        Number(time.end) <= Number(each.end)
      )
        isValid = false;

      if (
        each.end &&
        each.start &&
        Number(time.start) < Number(each.start) &&
        Number(time.end) > Number(each.end)
      )
        isValid = false;
    });

    return isValid;
  };

  _validateAvailability = () => {
    return new Promise((resolve) => {
      let isFormValid = true;

      const { availability, availabilityDayErrors } = deepClone(this.state);

      Object.keys(availability).forEach((key) => {
        if (availability[key].length) {
          availability[key].forEach((time, timeIndex) => {
            if (
              time.startIsDirty &&
              time.endIsDirty &&
              !time.start &&
              !time.end
            ) {
              time["startError"] = "*Required";
              time["endError"] = "*Required";
              availabilityDayErrors[key] = "*Invalid time";
              isFormValid = false;
            } else if (time.endIsDirty && time.start && !time.end) {
              time["startError"] = null;
              time["endError"] = "*Required";
              availabilityDayErrors[key] = "*Invalid time";
              isFormValid = false;
            } else if (time.startIsDirty && !time.start && time.end) {
              time["startError"] = "*Required";
              availabilityDayErrors[key] = "*Invalid time";
              time["endError"] = null;
              isFormValid = false;
            } else {
              const restTimeArray = [
                ...availability[key].slice(0, timeIndex),
                ...availability[key].slice(timeIndex + 1),
              ];
              if (this._compareAllOverlapTiming(restTimeArray, time)) {
                time["startError"] = null;
                time["endError"] = null;
                time["startIsDirty"] = false;
                time["endIsDirty"] = false;
                if (
                  !restTimeArray.some(
                    (each) => each.startError || each.endError
                  )
                )
                  availabilityDayErrors[key] = null;
              } else {
                time["startError"] = "*Invalid time";
                time["endError"] = "*Invalid time";
                availabilityDayErrors[key] = "*Invalid time";
                isFormValid = false;
              }
            }
          });
        } else {
          availabilityDayErrors[key] = null;
        }
      });

      this.setState({ availability, availabilityDayErrors }, () =>
        resolve(isFormValid)
      );
    });
  };

  _validateFaqs = () => {
    return new Promise((resolve) => {
      let isFormValid = true;

      const { faqs } = deepClone(this.state);
      if (faqs.length > 1) {
        faqs.forEach((faq) => {
          if (
            faq["answerIsDirty"] &&
            faq.question.trim().length &&
            !faq.answer.trim().length
          ) {
            isFormValid = false;
            faq["answerError"] = "*Required";
            faq["questionError"] = null;
            faq["questionIsDirty"] = false;
          } else if (
            faq["questionIsDirty"] &&
            !faq.question.trim().length &&
            faq.answer.trim().length
          ) {
            isFormValid = false;
            faq["questionError"] = "*Required";
            faq["answerError"] = null;
            faq["answerIsDirty"] = false;
          } else if (
            faq["answerIsDirty"] &&
            faq["questionIsDirty"] &&
            !faq.question.trim().length &&
            !faq.answer.trim().length
          ) {
            isFormValid = false;
            faq["questionError"] = "*Required";
            faq["answerError"] = "*Required";
          } else {
            faq["questionError"] = null;
            faq["answerError"] = null;
            faq["questionIsDirty"] = false;
            faq["answerIsDirty"] = false;
          }
        });
      } else {
        if (
          faqs[0]["answerIsDirty"] &&
          faqs[0].question.trim().length &&
          !faqs[0].answer.trim().length
        ) {
          isFormValid = false;
          faqs[0]["answerError"] = "*Required";
        } else if (
          faqs[0]["questionIsDirty"] &&
          !faqs[0].question.trim().length &&
          faqs[0].answer.trim().length
        ) {
          isFormValid = false;
          faqs[0]["questionError"] = "*Required";
        } else {
          faqs[0]["questionError"] = null;
          faqs[0]["answerError"] = null;
          faqs[0]["questionIsDirty"] = false;
          faqs[0]["answerIsDirty"] = false;
        }
      }

      this.setState({ faqs }, () => resolve(isFormValid));
    });
  };

  _markAllFieldDirty = () => {
    return new Promise((resolve) => {
      const { formFields, faqs, availability } = deepClone(this.state);
      Object.keys(formFields).forEach((key) => {
        formFields[key].isDirty = true;
      });
      faqs.forEach((faq) => {
        faq["questionIsDirty"] = true;
        faq["answerIsDirty"] = true;
      });
      Object.keys(availability).forEach((key) => {
        if (availability[key].length) {
          availability[key].forEach((time) => {
            time["startIsDirty"] = true;
            time["endIsDirty"] = true;
          });
        }
      });
      this.setState({ formFields, faqs, availability }, () => resolve(true));
    });
  };

  _onSubmitForm = async (event) => {
    try {
      if (event) event.preventDefault();

      await this._markAllFieldDirty();

      const [isFormValid, isFaqsFieldValid, isTimingValid] = await Promise.all([
        this._validateFormFields(),
        this._validateFaqs(),
        this._validateAvailability(),
      ]);

      if (isFormValid && isFaqsFieldValid && isTimingValid) {
        this.setState({ loading: true });

        const {
          formFields,
          amenityId,
          availability,
          faqs,
          mapOfProperty,
          isUploadServiceMenuPdf,
          serviceMenuPdf,
          isAcceptBookingRequests,
        } = deepClone(this.state);

        const payload = {
          name: formFields.name.value.trim(),
          description: formFields.description.value.trim(),
          _group: formFields.category.value,
          address: formFields.address.value.trim(),
          isAcceptBookingRequests: isAcceptBookingRequests ? true : false,
          photos: [],
          serviceMenu: [],
          faqs: [],
          availability: {},
          mapOfProperty: "",
        };

        faqs.forEach((faq) => {
          if (faq.question?.trim().length && faq.answer?.trim().length)
            payload.faqs.push({ question: faq.question, answer: faq.answer });
        });

        Object.keys(availability).forEach((key) => {
          if (availability[key].length) {
            payload.availability[key] = [];
            availability[key].forEach((time) => {
              payload.availability[key].push({
                start: time.start,
                end: time.end,
              });
            });
          }
        });

        if (mapOfProperty.uploadData) {
          mapOfProperty["forKeyName"] = "mapOfProperty";
          formFields.photos.value.push(mapOfProperty);
        } else if (mapOfProperty.uploadUrl) {
          payload["mapOfProperty"] = mapOfProperty.uploadUrl;
        }

        if (isUploadServiceMenuPdf && serviceMenuPdf.uploadData) {
          serviceMenuPdf["forKeyName"] = "serviceMenuPdf";
          formFields.photos.value.push(serviceMenuPdf);
        } else if (
          !isUploadServiceMenuPdf &&
          formFields.serviceMenu.value.length &&
          formFields.serviceMenu.value.some((each) => each.uploadData)
        ) {
          const serviceMenuFilter = formFields.serviceMenu.value.filter(
            (each) => each.uploadData
          );
          formFields.photos.value =
            formFields.photos.value.concat(serviceMenuFilter);
        }

        if (
          formFields.photos.value.length &&
          formFields.photos.value.some((photo) => photo.uploadData)
        ) {
          const uploadDataFilter = formFields.photos.value.filter(
            (photo) => photo.uploadData
          );

          const response = await uploadFileOnServer(uploadDataFilter);

          response.forEach((res) => {
            switch (res.forKeyName) {
              case "coverImage":
              case "mapOfProperty": {
                if (res.forKeyName === "mapOfProperty") {
                  mapOfProperty["uploadUrl"] = res.url;
                  mapOfProperty["uploadData"] = null;
                  this.setState({ mapOfProperty });
                }
                payload[res.forKeyName] = res.url;
                break;
              }
              case "photos": {
                payload["photos"].push(res.url);
                break;
              }
              case "serviceMenuPdf": {
                payload["serviceMenu"] = [
                  {
                    name: res.name,
                    docType: res.contentType,
                    url: res.url,
                  },
                ];
                break;
              }
              case "serviceMenu": {
                payload["serviceMenu"].push({
                  name: res.name,
                  docType: res.contentType,
                  url: res.url,
                });
                break;
              }
              default:
            }
          });
        }

        if (
          formFields.photos.value.length &&
          formFields.photos.value.some((photo) => photo.uploadUrl)
        ) {
          formFields.photos.value.forEach((photo) => {
            if (!photo.isCoverPhoto && photo.uploadUrl)
              payload.photos.push(photo.uploadUrl);
            else if (photo.isCoverPhoto && photo.uploadUrl)
              payload["coverImage"] = photo.uploadUrl;
          });
        }

        if (
          !isUploadServiceMenuPdf &&
          formFields.serviceMenu.value.length &&
          formFields.serviceMenu.value.some((each) => each.uploadUrl)
        ) {
          formFields.serviceMenu.value.forEach((each) => {
            if (each.uploadUrl)
              payload.serviceMenu.push({
                name: each.name,
                docType: each.type,
                url: each.uploadUrl,
              });
          });
        }

        if (isUploadServiceMenuPdf && serviceMenuPdf.uploadUrl) {
          payload.serviceMenu = [
            {
              name: serviceMenuPdf.name,
              docType: serviceMenuPdf.type,
              url: serviceMenuPdf.uploadUrl,
            },
          ];
        }

        if (
          (payload.photos.length &&
            formFields.photos.value.length &&
            formFields.photos.value.some((photo) => photo.uploadData)) ||
          (payload.serviceMenu.length &&
            ((!isUploadServiceMenuPdf &&
              formFields.serviceMenu.value.length &&
              formFields.serviceMenu.value.some((photo) => photo.uploadData)) ||
              (isUploadServiceMenuPdf && serviceMenuPdf.uploadData)))
        ) {
          formFields.photos.value = [];
          formFields.serviceMenu.value = [];

          if (payload["coverImage"]) {
            formFields.photos.value.push({
              uploadData: null,
              type: "image",
              previewBlob: null,
              isCoverPhoto: true,
              uploadUrl: payload["coverImage"],
            });
          }
          payload.photos.forEach((photoUrl) =>
            formFields.photos.value.push({
              uploadData: null,
              type: "image",
              previewBlob: null,
              uploadUrl: photoUrl,
            })
          );

          if (isUploadServiceMenuPdf) {
            serviceMenuPdf["uploadUrl"] = payload.serviceMenu[0].url;
            serviceMenuPdf["name"] = payload.serviceMenu[0].name;
            serviceMenuPdf["type"] = payload.serviceMenu[0].docType;
            serviceMenuPdf["uploadData"] = null;
          } else {
            payload.serviceMenu.forEach((each) =>
              formFields.serviceMenu.value.push({
                uploadData: null,
                type: each.docType,
                name: each.name,
                previewBlob: null,
                uploadUrl: each.url,
              })
            );
          }

          this.setState({ formFields, serviceMenuPdf });
        }

        payload["resortId"] = this.state.resortId;

        if (amenityId) {
          this._updateAmenity(amenityId, payload);
        } else {
          this._createAmenity(payload);
        }
      }
    } catch (error) {
      console.log("error>>", error);
      this.setState({ loading: false });
      showToast(
        error?.reason?.length
          ? error.reason
          : "Something went wrong, Try again after sometime.",
        "error"
      );
    }
  };

  componentDidMount = () => {
    const { amenityId, resortId } = extractQueryParams();

    if (amenityId && resortId) {
      this.setState({ amenityId, resortId }, () => {
        this._getSettings();
        this._getAmenityById(amenityId);
      });
    }
  };

  _startTimeValues = (key, index) => {
    const { availability } = deepClone(this.state);
    if (index - 1 > -1) {
      const prevEnd = availability[key][index - 1]["end"];
      const findIndex = timeValues.findIndex((each) => each.value === prevEnd);
      if (findIndex > -1) {
        return [...timeValues.slice(0, 1), ...timeValues.slice(findIndex, -1)];
      }
    }
    return timeValues.slice(0, -1);
  };

  _endTimeValues = (key, index) => {
    const { availability } = deepClone(this.state);
    const start = availability[key][index]["start"];
    if (start) {
      const findIndex = timeValues.findIndex((each) => each.value === start);
      if (findIndex > -1) {
        return [
          ...timeValues.slice(0, 1),
          ...timeValues.slice(findIndex + 1, timeValues.length),
        ];
      }
    }
    if (index - 1 > -1) {
      const prevEnd = availability[key][index - 1]["end"];
      const findIndex = timeValues.findIndex((each) => each.value === prevEnd);
      if (findIndex > -1) {
        return [
          ...timeValues.slice(0, 1),
          ...timeValues.slice(findIndex + 1, timeValues.length),
        ];
      }
    }
    return [
      ...timeValues.slice(0, 1),
      ...timeValues.slice(2, timeValues.length),
    ];
  };

  render() {
    const {
      formFields,
      isOpenDayCollapse,
      mapOfProperty,
      faqs,
      amenityCategories,
      loading,
      amenityId,
      availability,
      availabilityDayErrors,
      isAcceptBookingRequests,
      serviceMenuPdf,
      isUploadServiceMenuPdf,
    } = this.state;

    return (
      <div className="content">
        <Container fluid className="mobilePadding-0">
          <div className="page_Inner_header ">
            <Row className="align-items-center">
              <Col xs={6}>
                <div className="page_title">
                  <Button
                    color="link"
                    className="pl-0 pr-2"
                    onClick={() => this.props.history.goBack()}
                  >
                    <i className="fa fa-chevron-left" />
                  </Button>
                  <h1 className="space_remove">
                    {amenityId ? "Update" : "Add"} Amenity{" "}
                    {loading ? <Spinner /> : null}
                  </h1>
                </div>
              </Col>
            </Row>
          </div>

          {/* ====== Form ====== */}
          <Card body className="mt-lg-4 mt-2 py-1 px-3">
            <div className="innerForm">
              <Row>
                <Col xl={6} className="pt-3">
                  <h2 className="title_">Amenity Details</h2>
                  <FormGroup>
                    <InputGroup>
                      <InputGroupText>
                        <img
                          src={require("../../assets/img/user.svg").default}
                          className="img-fluid"
                          alt="user"
                          loading="lazy"
                        />
                      </InputGroupText>
                      <Input
                        type="text"
                        placeholder="Enter the amenity name"
                        name="name"
                        value={formFields.name.value}
                        onChange={(e) =>
                          this._onChangeFormFields("name", e.target.value)
                        }
                        onBlur={() => this._onBlurFormFields("name")}
                      />
                    </InputGroup>
                    {formFields.name.error ? (
                      <div className="form-error">{formFields.name.error}</div>
                    ) : null}
                  </FormGroup>
                  <FormGroup>
                    <InputGroup>
                      <InputGroupText>
                        <img
                          className="img-fluid"
                          style={{ width: "20px" }}
                          src={require("../../assets/img/category.svg").default}
                          alt="category"
                          loading="lazy"
                        />
                      </InputGroupText>
                      <Input
                        type="select"
                        value={formFields.category.value}
                        name="category"
                        onChange={(e) =>
                          this._onChangeFormFields("category", e.target.value)
                        }
                        onBlur={() => this._onBlurFormFields("category")}
                      >
                        <option value="">Select a Category</option>
                        {amenityCategories.map((each) => (
                          <option key={each._id} value={each._id}>
                            {capitalize(each.category)}
                          </option>
                        ))}
                      </Input>
                    </InputGroup>
                    {formFields.category.error ? (
                      <div className="form-error">
                        {formFields.category.error}
                      </div>
                    ) : null}
                  </FormGroup>

                  <CustomInput
                    type="checkbox"
                    id="add_amenity_isAcceptBookingRequests"
                    className="mt-4 mb-3"
                    label="Accept Booking Requests"
                    checked={isAcceptBookingRequests}
                    onChange={(e) =>
                      this.setState({
                        isAcceptBookingRequests: e.target.checked,
                      })
                    }
                  />

                  <FormGroup>
                    <InputGroup className="textarea">
                      <InputGroupText>
                        <i className="fa fa-paragraph" />
                      </InputGroupText>
                      <Input
                        type="textarea"
                        placeholder="Enter the description"
                        name="description"
                        value={formFields.description.value}
                        onChange={(e) =>
                          this._onChangeFormFields(
                            "description",
                            e.target.value
                          )
                        }
                      />
                    </InputGroup>
                  </FormGroup>

                  <FormGroup className="d-flex align-items-center mt-5">
                    <div className="coverPhoto">
                      {/* when set cover image hide this icon */}
                      {formFields.photos.value.length ? (
                        formFields.photos.value.map(
                          (photo) =>
                            photo.isCoverPhoto &&
                            (photo.previewBlob || photo.uploadUrl) && (
                              <img
                                key={photo.previewBlob || photo.uploadUrl}
                                src={photo.previewBlob || photo.uploadUrl}
                                alt="cover"
                                loading="lazy"
                              />
                            )
                        )
                      ) : (
                        <i className="fa fa-picture-o" />
                      )}
                    </div>
                    <div className="cover_content">
                      <h1>Upload Photos</h1>
                      <p>
                        Picture Should be in the standard format PNG, JPG and no
                        more than 5MB
                      </p>
                    </div>
                  </FormGroup>

                  <div className="input-gallery d-flex flex-wrap mb-2">
                    <div
                      className={`input-gallery-col plus ${
                        formFields.photos.error ? "mb-1" : ""
                      }`}
                    >
                      <div className="input-gallery-img">
                        <Label className="mb-0">
                          <Input
                            type="file"
                            value=""
                            multiple
                            disabled={loading}
                            accept="image/*"
                            onChange={(e) =>
                              this._updateLocalPhoto(e, "photos")
                            }
                          />
                          <img
                            src={require("../../assets/img/plus.png").default}
                            className="img-fluid"
                            alt=""
                            loading="lazy"
                          />
                        </Label>
                      </div>
                    </div>

                    {formFields.photos.value.length
                      ? formFields.photos.value.map(
                          (photo, index) =>
                            (photo.previewBlob || photo.uploadUrl) && (
                              <div
                                className={`input-gallery-col ${
                                  photo.isCoverPhoto ? "selected" : ""
                                }`}
                                key={photo.previewBlob || photo.uploadUrl}
                              >
                                <div className="input-gallery-img">
                                  <img
                                    src={photo.previewBlob || photo.uploadUrl}
                                    alt="cover"
                                    loading="lazy"
                                  />
                                  {!photo.isCoverPhoto && !loading ? (
                                    <i
                                      className="fa fa-close"
                                      onClick={() =>
                                        this._removePhoto(index, "photos")
                                      }
                                    />
                                  ) : null}
                                </div>
                                {photo.isCoverPhoto ? (
                                  <span>Cover Photo</span>
                                ) : !loading ? (
                                  <span
                                    onClick={() => this._makeCoverPhoto(index)}
                                  >
                                    Make Cover
                                  </span>
                                ) : null}
                              </div>
                            )
                        )
                      : null}

                    {formFields.photos.error ? (
                      <div className="form-error">
                        {formFields.photos.error}
                      </div>
                    ) : null}
                  </div>

                  {/* service menu photos */}
                  <div className="amenity-location switch_with_content">
                    <FormGroup className="mt-4 d-flex align-items-center">
                      <div className="cover_content">
                        <h1>Service Menu</h1>
                        <CustomInput
                          type="switch"
                          id="add_amenity_switch_service_menu_media"
                          className="mt-3 mb-2"
                          label={
                            isUploadServiceMenuPdf
                              ? "Upload PDF file"
                              : "Upload images should be in the standard format PNG, JPG and no more than 5MB"
                          }
                          checked={isUploadServiceMenuPdf}
                          onChange={(e) =>
                            this.setState({
                              isUploadServiceMenuPdf: e.target.checked,
                            })
                          }
                        />
                      </div>
                    </FormGroup>

                    {isUploadServiceMenuPdf ? (
                      // service pdf
                      <div className="d-flex align-items-center input-gallery d-flex flex-wrap mb-5">
                        {serviceMenuPdf?.previewBlob ||
                        serviceMenuPdf?.uploadUrl ? (
                          <>
                            <div className={`input-gallery-col`}>
                              <div className="input-gallery-img">
                                <img
                                  src={
                                    require("../../assets/img/pdf_icon.png")
                                      .default
                                  }
                                  onClick={() =>
                                    window.open(
                                      serviceMenuPdf?.previewBlob ||
                                        serviceMenuPdf?.uploadUrl,
                                      "_blank"
                                    )
                                  }
                                  alt="cover"
                                  loading="lazy"
                                />
                                {!loading ? (
                                  <i
                                    className="fa fa-close"
                                    onClick={() =>
                                      this._resetLocalMedia("serviceMenuPdf")
                                    }
                                  />
                                ) : null}
                              </div>

                              <span className="replace_map">
                                <Label>
                                  <Input
                                    type="file"
                                    value=""
                                    disabled={loading}
                                    accept=".pdf"
                                    onChange={(e) =>
                                      this._updateLocalMedia(
                                        e,
                                        "serviceMenuPdf"
                                      )
                                    }
                                  />
                                  Replace
                                </Label>
                              </span>
                            </div>
                            {serviceMenuPdf?.uploadData?.name ||
                            serviceMenuPdf?.name ? (
                              <span>
                                {serviceMenuPdf?.uploadData?.name ||
                                  serviceMenuPdf?.name}
                              </span>
                            ) : null}
                          </>
                        ) : (
                          <div className="input-gallery-col plus">
                            <div className="input-gallery-img">
                              <Label className="mb-0">
                                <Input
                                  type="file"
                                  value=""
                                  disabled={loading}
                                  accept=".pdf"
                                  onChange={(e) =>
                                    this._updateLocalMedia(e, "serviceMenuPdf")
                                  }
                                />
                                <img
                                  src={
                                    require("../../assets/img/plus.png").default
                                  }
                                  alt="pdf"
                                  className="img-fluid"
                                  loading="lazy"
                                />
                              </Label>
                            </div>
                          </div>
                        )}
                      </div>
                    ) : (
                      // service photos
                      <div className="input-gallery serviceMenu d-flex flex-wrap mb-5">
                        <div className="input-gallery-col plus">
                          <div className="input-gallery-img">
                            <Label className="mb-0">
                              <Input
                                type="file"
                                value=""
                                multiple
                                disabled={loading}
                                accept="image/*"
                                onChange={(e) =>
                                  this._updateLocalPhoto(e, "serviceMenu")
                                }
                              />
                              <img
                                src={
                                  require("../../assets/img/plus.png").default
                                }
                                alt=""
                                className="img-fluid"
                                loading="lazy"
                              />
                            </Label>
                          </div>
                        </div>

                        {formFields.serviceMenu.value.length
                          ? formFields.serviceMenu.value.map(
                              (photo, index) =>
                                (photo.previewBlob || photo.uploadUrl) && (
                                  <div
                                    className={`input-gallery-col`}
                                    key={photo.previewBlob || photo.uploadUrl}
                                  >
                                    <div className="input-gallery-img">
                                      <img
                                        src={
                                          photo.previewBlob || photo.uploadUrl
                                        }
                                        alt="cover"
                                        loading="lazy"
                                      />
                                      {!loading ? (
                                        <i
                                          className="fa fa-close"
                                          onClick={() =>
                                            this._removePhoto(
                                              index,
                                              "serviceMenu"
                                            )
                                          }
                                        />
                                      ) : null}
                                    </div>
                                  </div>
                                )
                            )
                          : null}

                        {formFields.serviceMenu.error ? (
                          <div className="form-error">
                            {formFields.serviceMenu.error}
                          </div>
                        ) : null}
                      </div>
                    )}
                  </div>

                  <div className="amenity-location">
                    <FormGroup>
                      <InputGroup>
                        <InputGroupText>
                          <img
                            src={
                              require("../../assets/img/location.svg").default
                            }
                            alt="user"
                            className="img-fluid"
                            loading="lazy"
                          />
                        </InputGroupText>
                        <Input
                          type="text"
                          placeholder="Enter the amenity address"
                          name="address"
                          value={formFields.address.value}
                          onChange={(e) =>
                            this._onChangeFormFields("address", e.target.value)
                          }
                          onBlur={() => this._onBlurFormFields("address")}
                        />
                      </InputGroup>
                      {formFields.address.error ? (
                        <div className="form-error">
                          {formFields.address.error}
                        </div>
                      ) : null}

                      <div className="d-flex align-items-center mt-4 input-gallery d-flex flex-wrap mb-5">
                        {mapOfProperty.previewBlob ||
                        mapOfProperty.uploadUrl ? (
                          <div className={`input-gallery-col`}>
                            <div className="input-gallery-img">
                              <img
                                src={
                                  mapOfProperty.previewBlob ||
                                  mapOfProperty.uploadUrl
                                }
                                alt="cover"
                                loading="lazy"
                              />
                              {!loading ? (
                                <i
                                  className="fa fa-close"
                                  onClick={() =>
                                    this._resetLocalMedia("mapOfProperty")
                                  }
                                />
                              ) : null}
                            </div>

                            <span className="replace_map">
                              <Label>
                                <Input
                                  type="file"
                                  value=""
                                  disabled={loading}
                                  accept="image/*"
                                  onChange={(e) =>
                                    this._updateLocalMedia(e, "mapOfProperty")
                                  }
                                />
                                Replace
                              </Label>
                            </span>
                          </div>
                        ) : (
                          <div className="input-gallery-col plus">
                            <div className="input-gallery-img">
                              <Label className="mb-0">
                                <Input
                                  type="file"
                                  value=""
                                  disabled={loading}
                                  accept="image/*"
                                  onChange={(e) =>
                                    this._updateLocalMedia(e, "mapOfProperty")
                                  }
                                />
                                <i className="fa fa-map-marker" />
                              </Label>
                            </div>
                          </div>
                        )}

                        <span className="fs-15 d-block location_text">
                          <img
                            className="mr-0"
                            src={
                              require("../../assets/img/direction_location_map_navigation_pin_icon.svg")
                                .default
                            }
                            alt="icon"
                            loading="lazy"
                          />
                          Map of the property
                        </span>
                      </div>
                    </FormGroup>
                  </div>
                </Col>

                {/* col right */}
                <Col xl={6} className="pt-3">
                  <h2 className="title_">Availability</h2>
                  <div className="availability mb-4">
                    {daysOfWeek.map((each) => (
                      <div className="collapse_Container" key={each.value}>
                        <Button
                          color="light"
                          onClick={() => this._toggleDayCollapse(each.value)}
                          style={{ marginBottom: "1rem" }}
                        >
                          <div className="d-flex">
                            {each.label}{" "}
                            {isOpenDayCollapse !== each.value ? (
                              <>
                                {availability[each.value].length ? (
                                  <Badge>
                                    {availability[each.value].length}{" "}
                                    {availability[each.value].length > 1
                                      ? "slots"
                                      : "slot"}{" "}
                                    added
                                  </Badge>
                                ) : null}
                                {availabilityDayErrors[each.value] ? (
                                  <div className="form-error">
                                    {availabilityDayErrors[each.value]}
                                  </div>
                                ) : null}
                              </>
                            ) : null}
                          </div>
                          <i
                            className={`fa ${
                              isOpenDayCollapse === each.value
                                ? "fa-chevron-up"
                                : "fa-chevron-down"
                            }`}
                          />
                        </Button>
                        <Collapse isOpen={isOpenDayCollapse === each.value}>
                          <Card>
                            <div className="timing">
                              <Button
                                className="add_timing"
                                disabled={loading}
                                onClick={() => this._addTime(each.value)}
                              >
                                <i className="fa fa-plus" />
                              </Button>

                              {availability[each.value].length
                                ? availability[each.value].map(
                                    (time, timeIndex) => (
                                      <div
                                        className="d-flex align-items-center w-100"
                                        key={`${time._id}_${timeIndex}`}
                                      >
                                        <FormGroup>
                                          <Label>Start Time</Label>
                                          <Input
                                            type="select"
                                            name="start"
                                            value={time.start}
                                            onChange={(e) =>
                                              this._onChangeAvailability(
                                                each.value,
                                                "start",
                                                e.target.value,
                                                timeIndex
                                              )
                                            }
                                          >
                                            {this._startTimeValues(
                                              each.value,
                                              timeIndex
                                            ).map((eachTime, eachTimeIndex) => (
                                              <option
                                                key={`${each.value}_${eachTime.value}_${eachTimeIndex}`}
                                                value={eachTime.value}
                                              >
                                                {eachTime.label}
                                              </option>
                                            ))}
                                          </Input>
                                          {time["startError"] ? (
                                            <div className="form-error">
                                              {time["startError"]}
                                            </div>
                                          ) : null}
                                        </FormGroup>
                                        <FormGroup>
                                          <Label>End Time</Label>
                                          <Input
                                            type="select"
                                            name="end"
                                            value={time.end}
                                            onChange={(e) =>
                                              this._onChangeAvailability(
                                                each.value,
                                                "end",
                                                e.target.value,
                                                timeIndex
                                              )
                                            }
                                          >
                                            {this._endTimeValues(
                                              each.value,
                                              timeIndex
                                            ).map((eachTime, eachTimeIndex) => (
                                              <option
                                                key={`${each.value}_${eachTime.value}_${eachTimeIndex}`}
                                                value={eachTime.value}
                                              >
                                                {eachTime.label}
                                              </option>
                                            ))}
                                          </Input>
                                          {time["endError"] ? (
                                            <div className="form-error">
                                              {time["endError"]}
                                            </div>
                                          ) : null}
                                        </FormGroup>

                                        <Button
                                          className="btn-delete"
                                          disabled={loading}
                                          onClick={() =>
                                            this._removeTime(
                                              each.value,
                                              timeIndex
                                            )
                                          }
                                        >
                                          <i className="fa fa-trash" />
                                        </Button>
                                      </div>
                                    )
                                  )
                                : null}
                            </div>
                          </Card>
                        </Collapse>
                      </div>
                    ))}
                  </div>
                  <h2 className="title_">Frequently Asked Questions</h2>
                  {faqs.map((faq, index) => (
                    <div key={`${faq._id}_${index}`}>
                      <FormGroup>
                        <InputGroup>
                          <InputGroupText>
                            <span className="text_">Q</span>
                          </InputGroupText>
                          <Input
                            placeholder="Question"
                            value={faq.question}
                            onChange={(e) =>
                              this._onChangeFaq(
                                "question",
                                e.target.value,
                                index
                              )
                            }
                          />
                        </InputGroup>
                        {faq["questionError"] ? (
                          <div className="form-error">
                            {faq["questionError"]}
                          </div>
                        ) : null}
                      </FormGroup>
                      <FormGroup>
                        <InputGroup>
                          <InputGroupText>
                            <span className="text_">A</span>
                          </InputGroupText>
                          <Input
                            placeholder="Answer"
                            value={faq.answer}
                            onChange={(e) =>
                              this._onChangeFaq("answer", e.target.value, index)
                            }
                          />
                        </InputGroup>
                        {faq["answerError"] ? (
                          <div className="form-error">{faq["answerError"]}</div>
                        ) : null}
                      </FormGroup>

                      {faqs.length > 1 ? (
                        <div className="text-right mt-2 mb-1">
                          <Button
                            className="btn-addMore remove fs-14"
                            disabled={loading}
                            onClick={() => this._removeFaq(index)}
                          >
                            Remove
                          </Button>
                        </div>
                      ) : null}
                    </div>
                  ))}

                  <div className="text-right mt-2 mb-5">
                    <Button
                      className="btn-addMore fs-14"
                      disabled={loading}
                      onClick={() => this._addMoreFaq()}
                    >
                      Add More
                    </Button>
                  </div>
                </Col>
                <Col xl={12} className="border-top mt-0 pt-2 pb-4 pt-4">
                  <FormGroup className="text-center mb-4">
                    <Button
                      color="primary"
                      className="btn-cancel mr-3"
                      onClick={() => this.props.history.goBack()}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      className="btn-save"
                      disabled={loading}
                      onClick={(e) => this._onSubmitForm(e)}
                    >
                      {loading ? (
                        <i className="fa fa-spinner fa-spin mr-1" />
                      ) : null}{" "}
                      {amenityId ? "Update" : "Save"}
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </Card>
        </Container>
      </div>
    );
  }
}

export default AddAmenity;
